<template>
    <div class="cz3__add-collections">
        <div class="cz3__add-collections__header">
            <div class="cz3__type--title cz3__type--center">
                {{caption}}
            </div>
        </div>

        <div class="cz3__add-collections__collections">
            <button v-if="hasOwnCategory || ownCategory" id="gallery-own"
                class="cz3__stock-image cz3__stock-image--mine"
                :class="{
                    'cz3__stock-image--sticker': isCategory && isStickers,
                    'cz3__stock-image--leaf': isCategory,
                }"
                @click.prevent="selectOwn"
            >
                <span class="cz3__stock-image__background">
                    <icon-my-images />
                </span>

                <span v-if="ownCategory" class="cz3__stock-image__name">
                    {{isStickers ? $t('ART_ADD_MY_STICKERS') : $t('ART_ADD_MY_IMAGES')}}
                </span>

                <span v-else class="cz3__stock-image__name">
                    {{isStickers ? $t('ART_MY_STICKERS') : $t('ART_MY_IMAGES')}}
                </span>
            </button>

            <button
                v-for="c in images"
                :key="c.image"
                :id="`gallery-${c.title || 'no-gallery'}`"
                class="cz3__stock-image"
                :class="{
                    'cz3__stock-image--selected': selected && selected.image === c.image,
                    'cz3__stock-image--sticker': isCategory && isStickers,
                    'cz3__stock-image--leaf': isCategory,
                }"
                @click.prevent="select(c)"
            >
                <span class="cz3__stock-image__background">
                    <span class="cz3__stock-image__background-thumbnail"
                        :style="{
                            backgroundImage: `url(${c.thumbnail})`,
                        }"
                    ></span>
                </span>

                <span class="cz3__stock-image__name">
                    {{c.title}}
                </span>
            </button>
        </div>

        <div class="cz3__add-collections__prompt">
            <div class="cz3__add-collections__shade">
            </div>

            <div class="cz3__type--header-responsive cz3__type--center cz3__mb--standard">
                {{prompt}}
            </div>

            <div class="cz3__add-collections__prompt-buttons">
                <button v-if="hasBack"
                    id="back"
                    class="cz3__grey-rounded-no-shadow-button"
                    @click.prevent="back"
                >
                    {{$t('BACK')}}
                </button>

                <transition name="cz3__fade">
                    <button v-if="hasSelect && selected"
                        id="select"
                        class="cz3__outline-button cz3__outline-button--responsive"
                        @click.prevent="useSelected"
                    >
                        {{isStickers ? $t('ART_STICKER_USE_SELECTED') : $t('ART_USE_SELECTED')}}
                    </button>
                </transition>

                <button v-if="hasUpload"
                    id="upload"
                    class="cz3__outline-button cz3__outline-button--responsive"
                    @click.prevent="uploadOwn"
                >
                    {{isStickers ? $t('ART_STICKER_UPLOAD_OWN') : $t('ART_UPLOAD_OWN')}}
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__add-collections {
            position: absolute;

            left: 20px;
            top: 20px;
            right: 20px;
            bottom: 20px;

            display: flex;

            flex-direction: column;

            @include respond-above(sm) {
                left: 39px;
                top: 39px;
                right: 39px;
                bottom: 39px;
            }
        }

        .cz3__add-collections__header {
            flex: 0 0 auto;
        }

        .cz3__add-collections__collections {
            flex: 1 1 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: start;

            padding-top: 20px;
            margin-left: -5px;
            margin-right: -5px;

            overflow: auto;
            --webkit-overflow-scrolling: touch;

            @include respond-above(sm) {
                margin-left: -7px;
                margin-right: -30px;
            }
        }

        .cz3__add-collections__prompt {
            position: relative;

            display: flex;
            flex-direction: column;

            flex: 0 0 auto;

            padding-top: 60px;
            min-height: 104px;

            > * {
                flex: 1 1 0;
            }
        }

        .cz3__add-collections__shade {
            display: none;

            position: absolute;

            pointer-events: none;

            left: 0;
            right: 0;

            bottom: 100%;

            height: 15px;

            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }

        .cz3__add-collections__prompt-buttons {
            flex: 0 0 auto;

            min-height: 40px;

            display: flex;
            justify-content: space-between;

            button {
                flex: 0 0 auto;
            }
        }

        .cz3__stock-image {
            flex: 0 0 auto;

            display: flex;

            justify-content: flex-start;
            align-items: center;
            align-content: flex-start;
            flex-direction: column;

            width: 92px;
            height: 150px;

            margin-bottom: 15px;
            margin-left: 5px;
            margin-right: 5px;

            padding: 0;

            border: none;
            background: transparent;

            color: $color-primary-black;

            &.cz3__stock-image--leaf {
                height: 128px;

                .cz3__stock-image__name {
                    display: none;
                }
            }

            @include respond-above(sm) {
                margin-left: 7px;
                margin-right: 7px;
            }

            .cz3__stock-image__background {
                display: flex;

                justify-content: center;
                align-items: center;

                width: 92px;
                height: 128px;

                border-radius: 12px;

                border: 1px solid $color-gray-mid;
            }

            .cz3__stock-image__background-thumbnail {
                display: block;

                width: 90px;
                height: 126px;

                border-radius: 12px;

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            &.cz3__stock-image--sticker {
                .cz3__stock-image__background-thumbnail {
                    width: 84px;
                    height: 120px;
                    background-size: contain;
                }
            }

            .cz3__stock-image__name {
                display: block;

                margin-top: 5px;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }

            &--mine {
                .cz3__stock-image__background {
                    position: relative;

                    background: $color-gray-light;
                }

                svg {
                    position: absolute;

                    width: 40px;
                    height: 40px;

                    left: 50%;
                    top: 50%;

                    transform: translate(-50%, -50%);
                }
            }

            &--selected {
                .cz3__stock-image__background {
                    position: relative;

                    // Could use outline, but we'll reserve outline for accessibility for now.
                    &:after {
                        content: '';

                        position: absolute;

                        left: -3px;
                        top: -3px;

                        width: calc(100% + 6px);
                        height: calc(100% + 6px);

                        border-radius: 15px;

                        border: 2px solid $color-primary-black;
                    }
                }
            }
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import IconMyImages from '../assets/my-images.svg';

    export default {
        mixins: [allStores],

        components: {
            IconMyImages,
        },

        props: {
            caption: {
                type: String,
                default: '',
            },

            prompt: {
                type: String,
                default: '',
            },

            type: {
                type: String,
                default: 'images',
            },

            images: Array,

            hasOwnCategory: Boolean,

            ownCategory: Boolean,

            hasBack: Boolean,

            hasUpload: Boolean,

            hasSelect: Boolean,

            isCategory: Boolean,
        },

        data() {
            return {
                adding: false,

                selected: null,
            };
        },

        computed: {
            isStickers() {
                return this.type === 'stickers';
            },
        },

        methods: {
            uploadOwn() {
                this.$emit('uploadOwn');
            },

            selectOwn() {
                // track 'upload your own' selection
                this.appStore.trackAnalyticsEvent({
                    action: 'cyo_upload',
                });

                if (this.ownCategory) {
                    this.$emit('uploadOwn');
                } else {
                    this.$emit('selectOwn');
                }
            },

            select(c) {
                if (this.hasSelect) {
                    this.selected = c;
                } else {
                    this.$emit('select', c);
                }
            },

            useSelected() {
                if (!this.adding) {
                    this.adding = true;

                    if (this.selected.details) {
                        this.$emit('select', this.selected.details);
                    } else {
                        this.$emit('select', this.selected);
                    }

                    // track image/sticker collection selection
                    const collectionAction = this.isStickers ? 'cyo_collection_sticker' : 'cyo_collection_image';

                    // Prettify a bit.
                    const selectedCode = this.selected?.code.replace('C3ImageGallery-', '').replace('C3StickerGallery-', '').replace('-', '_');

                    this.appStore.trackAnalyticsEvent({
                        action: collectionAction,
                        label: `asset_${selectedCode}`,
                    });
                }
            },

            back() {
                this.$emit('back');
            },
        },
    };
</script>
