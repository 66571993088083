export default {
    methods: {
        popsocketsSelectView(view) {
            // Restore previously hidden meshes.
            Object.entries(this.placements).forEach(([, placement]) => {
                if (placement.meshes) {
                    placement.meshes.forEach((mesh) => {
                        if (mesh.disabledByView) {
                            mesh.disabledByView = false;

                            if (mesh.mesh) {
                                mesh.mesh.setEnabled(true);
                                mesh.mesh.getChildMeshes(false, (c) => {
                                    c.setEnabled(true);
                                });
                            }

                            if (mesh.decals) {
                                mesh.decals.forEach((d) => {
                                    d.setEnabled(true);
                                    d.getChildMeshes(false, (c) => {
                                        c.setEnabled(true);
                                    });
                                });
                            }
                        }
                    });
                }
            });

            let useView = this.view;

            if (view) {
                useView = view;
            }

            const showOnly = useView?.custom?.['ps-show-only'];

            if (showOnly) {
                // Show only controllers of the select type.
                const types = showOnly.split(',').map((x) => x.trim()).filter((x) => x.length > 0);

                const sockets = this.controller.socketsState;

                sockets.forEach((s) => {
                    if (s.on) {
                        const type = s.on.blueprint.custom['ps-type'].split(',').map((x) => x.trim()).filter((x) => x.length > 0);
                        if (!types.some((t) => type.includes(t))) {
                            // Hide placements in this controller.
                            s.on.blueprint.groups.forEach((group) => {
                                group.placements.forEach((placement) => {
                                    const placementKey = this.placementKey(s.on, placement.code);

                                    if (this.placements[placementKey]?.meshes) {
                                        this.placements[placementKey]?.meshes.forEach((mesh) => {
                                            mesh.disabledByView = true;

                                            if (mesh.mesh) {
                                                mesh.mesh.setEnabled(false);
                                            }

                                            if (mesh.decals) {
                                                mesh.decals.forEach((d) => {
                                                    d.setEnabled(false);
                                                });
                                            }
                                        });
                                    }
                                });
                            });
                        }
                    }
                });
            }
        },
    },
};
