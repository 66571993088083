<template>
    <div class="cz3__options cz3__options-options cz3__renderer-exclude">
        <edit-options-placement v-for="p in placements"
            :key="p.placementCode"
            :placement="p"
            :controller="controller"
        ></edit-options-placement>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options.cz3__options-options {
            width: calc(100% - #{$gap-standard-small + $gap-standard-small});

            margin-left: $gap-standard-small;
            margin-right: $gap-standard-small;
            margin-bottom: $margin-large-small;

            padding: $margin-standard-small;

            background: rgba(255, 255, 255, 0.95);
            border-radius: 8px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

            @include respond-above(sm) {
                margin-bottom: $margin-large-large;
                margin-left: 0;
                margin-right: 0;

                padding: $margin-standard-large;

                width: $content-large-width;
                left: calc(50% - 251px);

                background: rgba(255, 255, 255, 0.8);
            }
        }
    }
</style>

<script>
    import EditOptionsPlacement from './EditOptionsPlacement.vue';

    import allStores from '../stores/all';

    // import * as constants from '../constants';

    export default {
        mixins: [allStores],

        components: {
            EditOptionsPlacement,
        },

        props: {
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            selectedJoint() {
                return this.customizerStore.selectedJoint;
            },

            controller() {
                return this.selectedJoint?.controller;
            },

            placements() {
                const list = [];

                if (this.controller == null) {
                    return list;
                }

                this.controller.blueprint.groups.forEach((group) => {
                    group.placements.forEach((placement) => {
                        if (placement?.custom['ps-editable'] === 'true') {
                            list.push({
                                placementCode: placement.code,
                                placement,
                                title: placement.custom['ps-editable-title'],
                                order: +placement.custom['ps-editable-order'],
                            });
                        }
                    });
                });

                list.sort((a, b) => a.order - b.order);

                return list;
            },
        },

        methods: {
        },
    };
</script>
