<template>
    <div class="cz3__customizer">
        <transition name="cz3__fade">
            <main-logo v-if="initialLoaded"></main-logo>
        </transition>

        <share-button v-if="canShare" />

        <!-- Hide extra add to cart
        <transition name="cz3__fade">
            <add-to-cart v-if="canAddToCart"></add-to-cart>
        </transition>
        -->

        <product-renderer></product-renderer>

        <step-options></step-options>

        <transition name="cz3__fade">
            <navigation-panel v-if="initialLoaded && currentStep !== 'editImage'"></navigation-panel>
        </transition>

        <transition name="cz3__fade">
            <confirmation-popup v-if="confirmationPopup"
                :title="confirmationPopup.title"
                :message="confirmationPopup.message"
                :message-html="confirmationPopup.messageHtml"
                :confirm-button="confirmationPopup.confirmButton"
                :cancel-button="confirmationPopup.cancelButton"
                :confirm-action="confirmationPopup.confirmAction"
                :cancel-action="confirmationPopup.cancelAction"
                :close-action="confirmationPopup.closeAction"
                :confirm-countdown="confirmationPopup.confirmCountdown"
            />
        </transition>

        <transition name="cz3__fade">
            <div v-if="colorPickerSelected" class="cz3__color-picker" ref="colorPicker">
                <div class="cz3__color-picker__pointer" ref="colorPickerPointer">
                </div>

                <button class="cz3__color-picker__closer" :aria-label="$t('DIALOG_CLOSE')" @click.prevent.stop="closePickerByButton"></button>

                <color-picker
                    :color="colorPickerColor"
                    :visible-formats="['hex']"
                    alpha-channel="hide"
                    @color-change="onColorPicker"
                ></color-picker>
            </div>
        </transition>

        <transition name="cz3__fade">
            <div v-if="globalBusy" class="cz3__busy">
                <easy-spinner :type="'circular'" :color="'#1818185f'" :size="'40'" />
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__customizer {
            @include absolute-overlay();

            overflow: hidden;
        }

        * {
            -webkit-user-select: none;
            user-select: none;
        }

        input, textarea {
            -webkit-user-select: auto;
            user-select: auto;
        }

        .cz3__busy {
            @include absolute-overlay();

            background: rgba(255, 255, 255, 0.8);

            z-index: 1;

            svg {
                position: absolute;

                left: 50%;
                top: 50%;

                transform: translate(-50%, -50%);
            }
        }

        .cz3__color-picker {
            position: absolute;
            z-index: 1;

            padding: 12px;

            background: rgba(255, 255, 255, 1);

            border-radius: 8px;

            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.18);

            user-select: none;

            .vacp-color-picker {
                gap: 0;
                padding: 0;

                background: transparent;
            }

            .vacp-copy-button {
                display: none;
            }

            .vacp-color-inputs {
                margin-top: 0px;
            }

            .vacp-color-input {
                height: auto;

                padding: 5px 5px;
                margin: 0;

                line-height: 1;
            }

            .cz3__color-picker__closer {
                display: none;
            }

            .vacp-color-input-label-text {
                height: 1px;

                color: $color-gray-dark;

                font-family: $font-main;
                font-size: 1px;

                opacity: 0;

                overflow: hidden;
            }

            .vacp-range-input-group {
                margin-top: 0px !important;
            }

            .vacp-range-input-label-text {
                color: $color-gray-dark;

                font-family: $font-main;
                font-size: 12px;
            }

            .vacp-range-input {
                padding: 0;
                height: auto;
                line-height: 1;

                border-radius: 8px;
                border: none;
            }

            .vacp-range-input-label-text--hue {
                height: 1px;

                font-size: 1px;

                overflow: hidden;
                opacity: 0;
            }

            .vacp-range-input--hue::-moz-range-track {
                border-radius: 8px;
                border: none;
            }

            .vacp-range-input--hue::-webkit-slider-runnable-track {
                border-radius: 8px;
                border: none;
            }

            .vacp-range-input--hue::-ms-track {
                border-radius: 8px;
                border: none;
            }
        }
    }
</style>

<script>
    import { ColorPicker } from 'vue-accessible-color-picker';

    import MainLogo from './MainLogo.vue';
    import ProductRenderer from './ProductRenderer.vue';
    import StepOptions from './StepOptions.vue';
    import NavigationPanel from './NavigationPanel.vue';
    // import AddToCart from './AddToCart.vue';
    import ConfirmationPopup from './ConfirmationPopup.vue';
    import ShareButton from './ShareButton.vue';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    export default {
        mixins: [allStores],

        components: {
            MainLogo,
            ProductRenderer,
            StepOptions,
            NavigationPanel,
            // AddToCart,
            ConfirmationPopup,
            ColorPicker,
            ShareButton,
        },

        data() {
            return {
                initialLoaded: false,

                colorPickerColor: {
                    r: 1,
                    g: 1,
                    b: 1,
                },
            };
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            globalBusy() {
                return this.appStore.globalBusy;
            },

            currentStep() {
                return this.customizerStore.currentStep;
            },

            confirmationPopup() {
                return this.appStore.confirmationPopup;
            },

            canAddToCart() {
                return this.customizerStore.currentStep === constants.STEP_EDIT
                    || this.customizerStore.currentStep === constants.STEP_PREVIEW;
            },

            colorPickerSelected() {
                return this.appStore.colorPickerSelected;
            },

            colorPickerTarget() {
                return this.appStore.colorPickerTarget;
            },

            colorPickerStartColor() {
                return this.appStore.colorPickerStartColor;
            },

            canShare() {
                return this.viewController?.blueprint?.custom?.['ps-can-share'] !== 'false';
            },
        },

        watch: {
            colorPickerSelected() {
                this.setupColorPicker();
            },

            currentStep() {
                if (this.currentStep !== 'loading') {
                    let delay = 50;

                    if (this.viewController.blueprint?.custom['ps-auto-ai'] === 'true') {
                        delay = 1000;
                    }

                    setTimeout(() => {
                        this.initialLoaded = true;
                    }, delay);
                }
            },
        },

        methods: {
            setupColorPicker() {
                if (this.colorPickerSelected) {
                    let color = this.colorPickerStartColor || 'ffffff';

                    if (color.startsWith('#')) {
                        color = color.substr(1);
                    }

                    this.colorPickerColor = {
                        r: Math.floor(parseInt(color.substr(0, 2), 16) / 255),
                        g: Math.floor(parseInt(color.substr(2, 2), 16) / 255),
                        b: Math.floor(parseInt(color.substr(4, 2), 16) / 255),
                    };

                    window.addEventListener('mousedown', this.closePicker, false);

                    this.$nextTick(() => {
                        if (this.$refs.colorPicker && this.$refs.colorPickerPointer && this.colorPickerTarget) {
                            let target = this.colorPickerTarget;
                            if (Array.isArray(target)) {
                                // eslint-disable-next-line
                                target = target.find(x => x.offsetWidth > 0);
                            }

                            const windowWidth = window.innerWidth;

                            const position = this.getOffset(target);

                            const width = this.$refs.colorPicker.offsetWidth;
                            const height = this.$refs.colorPicker.offsetHeight;

                            let x = position.left - (width / 2) + (target.offsetWidth / 2);
                            let offsetX = width / 2;
                            if (x < 0) {
                                offsetX -= x;
                                x = 0;
                            }

                            if (x + width > windowWidth) {
                                const dx = windowWidth - (x + width);

                                offsetX -= dx;
                                x += dx;
                            }

                            let y = position.top - height - 30;
                            if (y < 0) {
                                y = 0;
                            }

                            this.$refs.colorPicker.style.left = `${x}px`;
                            this.$refs.colorPicker.style.top = `${y}px`;

                            this.$refs.colorPickerPointer.style.left = `${offsetX}px`;
                        }
                    });
                } else {
                    window.removeEventListener('mousedown', this.closePicker);
                }
            },

            getOffset(target) {
                let el = target;
                let x = 0;
                let y = 0;

                // eslint-disable-next-line
                while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                    x += el.offsetLeft - el.scrollLeft;
                    y += el.offsetTop - el.scrollTop;
                    el = el.offsetParent;
                }

                return { top: y, left: x };
            },

            toHex(v) {
                if (v < 16) {
                    return `0${v.toString(16)}`;
                }

                return v.toString(16);
            },

            onColorPicker(color) {
                if (this.appStore.colorPickerSelected) {
                    const r = this.toHex(Math.floor(color.colors.rgb.r * 255));
                    const g = this.toHex(Math.floor(color.colors.rgb.g * 255));
                    const b = this.toHex(Math.floor(color.colors.rgb.b * 255));

                    this.appStore.colorPickerSelected(`${r}${g}${b}`);
                }
            },

            closePicker(e) {
                if (this.$refs.colorPicker) {
                    if (
                        !this.$refs.colorPicker.contains(e.target)
                        || e.target.classList.contains('cz2__color-picker__closer')
                    ) {
                        this.appStore.pickColor({
                            selected: null,
                            near: null,
                        });
                    }
                }
            },

            closePickerByButton(e) {
                window.removeEventListener('mousedown', this.closePicker);
                this.closePicker(e);
            },
        },
    };
</script>
