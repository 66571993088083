<template>
    <div>
        <div class="cz3__options cz3__options-edit cz3__renderer-exclude">
            <div class="cz3__mb--options">
                <buttons-panel
                    :show-edit-buttons="showButtons"
                    :show-preview-toggle="true"
                    :show-tooltips="true"
                    @preview3d="preview3d"
                    @addImage="addImage"
                    @addStickers="addStickers"
                    @addText="addText"
                    @addAi="addAi"
                />
            </div>
        </div>

        <transition name="cz3__fade">
            <add-ai v-if="mode === 'addAi'"
                @close="closeAddAi"
            ></add-ai>
        </transition>

        <transition name="cz3__fade">
            <add-image v-if="mode === 'addImage'"
                type="images"
                @close="closeAdd"
            ></add-image>
        </transition>

        <transition name="cz3__fade">
            <add-image v-if="mode === 'addSticker'"
                type="stickers"
                @close="closeAdd"
            ></add-image>
        </transition>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options-edit {
            height: auto;
        }
    }
</style>

<script>
    import ButtonsPanel from './ButtonsPanel.vue';
    import AddImage from './AddImage.vue';
    import AddAi from './AddAi.vue';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    export default {
        mixins: [allStores],

        components: {
            ButtonsPanel,
            AddImage,
            AddAi,
        },

        props: {
            cases: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                mode: null,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            currentStepOptions() {
                return this.customizerStore.currentStepOptions;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            activeJoints() {
                return this.customizerStore.activeJoints;
            },

            selectedJoint() {
                return this.customizerStore.selectedJoint;
            },

            editViewJoint() {
                return this.customizerStore.editViewJoint;
            },

            small() {
                return this.appStore.small;
            },

            showButtons() {
                return true; // this.small
            },
        },

        watch: {
            editViewJoint() {
                this.showAi();
            },

            selectedJoint() {
                this.showAi();
            },
        },

        mounted() {
            this.setupInitial();
        },

        beforeUnmount() {
        },

        methods: {
            /**
             * Go into the 3D preview mode.
             */
            preview3d() {
                this.customizerStore.setCurrentStep(constants.STEP_PREVIEW);
            },

            /**
             * Closes add popup.
             */
            closeAdd(editImage) {
                if (this.mode === 'addSticker') {
                    this.setMode(null);

                    // Drop into the image mode.
                    this.customizerStore.setCurrentStep({
                        step: constants.STEP_EDIT_IMAGE,
                        image: editImage,
                    });
                } else {
                    this.setMode(null);

                    this.customizerStore.selectJoint(null);
                }
            },

            /**
             * Closes add popup.
             */
            closeAddAi({ editImage, useStep }) {
                this.setMode(null);

                // Drop into the image mode.
                this.customizerStore.setCurrentStep({
                    step: useStep ?? constants.STEP_EDIT_IMAGE,
                    image: editImage,
                });
            },

            setupInitial() {
                this.$nextTick(() => {
                    if (this.currentStepOptions && this.currentStepOptions.mode) {
                        if (this.currentStepOptions.mode === 'addImage') {
                            this.addImage();
                        }

                        if (this.currentStepOptions.mode === 'addSticker') {
                            this.addStickers();
                        }

                        if (this.currentStepOptions.mode === 'addText') {
                            this.addText();
                        }
                    } else if (this.viewController.blueprint?.custom['ps-use-ai'] === 'true'
                        && this.viewController.blueprint?.custom['ps-auto-ai'] === 'true') {
                        this.showAi();
                    }
                });
            },

            showAi() {
                if (this.viewController.blueprint?.custom['ps-use-ai'] === 'true' && this.viewController.blueprint?.custom['ps-auto-ai'] === 'true') {
                    // Should we show initial AI popup?
                    if (!this.customizerStore.aiShown) {
                        // Can we show?
                        if (this.editViewJoint != null) {
                            this.addAi();

                            this.customizerStore.setAiShown();
                        }
                    }
                }

                // Do we have a stashed image from the AI generator?
                if (this.editViewJoint != null) {
                    this.customizerStore.checkStashedLayers(this.editViewJoint.joint.controller);
                }
            },

            addAi() {
                if (this.selectedJoint == null) {
                    if (this.editViewJoint) {
                        this.customizerStore.selectJoint(this.editViewJoint.joint);

                        this.setMode('addAi');
                    } else {
                        // Is there an active (aka selected on the current slide) joint of image type?
                        const imageJoint = this.activeJoints.find((j) => j.type === 'image');

                        // Select that joint and automatically jump into image selection.
                        if (imageJoint) {
                            this.customizerStore.selectJoint(imageJoint);

                            this.setMode('addAi');
                        }
                    }
                } else {
                    this.setMode('addAi');
                }
            },

            addImage() {
                if (this.selectedJoint == null) {
                    if (this.editViewJoint) {
                        this.customizerStore.selectJoint(this.editViewJoint.joint);

                        this.setMode('addImage');
                    } else {
                        // Is there an active (aka selected on the current slide) joint of image type?
                        const imageJoint = this.activeJoints.find((j) => j.type === 'image');

                        // Select that joint and automatically jump into image selection.
                        if (imageJoint) {
                            this.customizerStore.selectJoint(imageJoint);

                            this.setMode('addImage');
                        }
                    }
                } else {
                    this.setMode('addImage');
                }
            },

            addStickers() {
                if (this.selectedJoint == null) {
                    if (this.editViewJoint) {
                        this.customizerStore.selectJoint(this.editViewJoint.joint);

                        this.setMode('addSticker');
                    } else {
                        // Is there an active (aka selected on the current slide) joint of image type?
                        const imageJoint = this.activeJoints.find((j) => j.type === 'image');

                        // Select that joint and automatically jump into image selection.
                        if (imageJoint) {
                            this.customizerStore.selectJoint(imageJoint);

                            this.setMode('addSticker');
                        }
                    }
                } else {
                    this.setMode('addSticker');
                }
            },

            addText() {
                if (this.selectedJoint == null) {
                    if (this.editViewJoint) {
                        this.customizerStore.selectJoint(this.editViewJoint.joint);
                    } else {
                        // Is there an active (aka selected on the current slide) joint of image type?
                        const imageJoint = this.activeJoints.find((j) => j.type === 'image');

                        // Select that joint and automatically jump into image selection.
                        if (imageJoint) {
                            this.customizerStore.selectJoint(imageJoint);
                        }
                    }
                }

                if (this.selectedJoint !== null) {
                    const placement = this.customizerStore.addText({
                    });

                    // Drop into the image mode.
                    this.customizerStore.setCurrentStep({
                        step: constants.STEP_EDIT_IMAGE,
                        text: placement,
                    });
                }
            },

            setMode(mode) {
                this.mode = mode;
            },
        },
    };
</script>
