<template>
    <transition name="cz3__fade">
        <button v-if="productHasCustomizations" id="share-button" class="cz3__icon-button cz3__share" @click.prevent="share">
            <icon-share />
        </button>
    </transition>

    <transition name="cz3__fade">
        <share-progress v-if="preparing"
            @quit="cancelSave"
        ></share-progress>
    </transition>

    <transition name="cz3__fade">
        <share-ready v-if="ready"
            :ai-image="recipe?.aiImage"
            @share="continueShare"
            @shareEmail="continueShareEmail"
            @shareAi="continueShareAi"
            @quit="cancelSave"
        ></share-ready>
    </transition>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__share {
            position: absolute;

            right: 11px;
            top: 12px;

            width: 40px;
            height: 40px;

            border: none;
            background: none;

            z-index: 1;

            @include respond-above(sm) {
                right: 30px;
                top: 40px;

                width: 48px;
                height: 48px;
            }

            svg {
                @include absolute-overlay();
            }
        }
    }
</style>

<script>
    import ShareProgress from './ShareProgress.vue';
    import ShareReady from './ShareReady.vue';

    import IconShare from '../assets/share.svg';

    import allStores from '../stores/all';

    export default {
        mixins: [allStores],

        components: {
            IconShare,
            ShareProgress,
            ShareReady,
        },

        data() {
            return {
                preparing: false,
                cancelled: false,

                ready: false,
                recipe: null,
            };
        },
        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            productHasCustomizations() {
                return this.customizerStore.productHasCustomizations;
            },

            environmentIsLocal() {
                return this.appStore.environmentIsLocal;
            },

            sharingTarget() {
                // set fallback as the US custom page,
                // and replace with custom attr if available.
                let target = 's/AMER/en-us/pages/custom.html';

                if (this.viewController.blueprint.custom['ps-share-target']) {
                    target = this.viewController.blueprint.custom['ps-share-target'];
                }

                return target;
            },
        },
        methods: {
            buildBaseUrl() {
                // local / drivecdn envs only - return hard-coded AMER CYO page link.
                if (this.environmentIsLocal) {
                    return `https://www.popsockets.com/en-us/pages/custom.html/${this.sharingTarget}`;
                }

                const { host, protocol } = window.location;

                return `${protocol}//${host}/${this.sharingTarget}`;
            },

            async getFile(url, recipe) {
                const imgResponse = await fetch(url);

                if (!imgResponse.ok) {
                    throw new Error('Network response was not ok');
                }

                if (imgResponse) {
                    const imgBlob = await imgResponse.blob();
                    const fileName = `recipe-${recipe.id}-preview.png`;
                    const imgFile = new File([imgBlob], fileName, { type: 'image/png' });

                    return imgFile;
                }

                return null;
            },

            cancelSave() {
                this.cancelled = true;
                this.ready = false;
            },

            async fetchAndParsePreview(recipe) {
                const url = recipe?.location;

                try {
                    const response = await fetch(url);

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const json = await response.json();

                    const files = [];

                    const productResults = json.views.find((v) => v.name === 'Product');

                    if (productResults && productResults.previewJpg) {
                        const file = await this.getFile(`https:${productResults.previewJpg}`, recipe);
                        if (file) {
                            files.push(file);
                        }
                    }

                    // const omsResults = json.views.find((v) => v.name === 'OMSFront1');

                    // if (omsResults && omsResults.previewJpg) {
                    //     const file = await this.getFile(`https:${omsResults.previewJpg}`, recipe);
                    //     if (file) {
                    //         files.push(file);
                    //     }
                    // }

                    return files;
                } catch (error) {
                    return null;
                }
            },

            async continueShareAi() {
                const { recipe, aiImage } = this.recipe;

                this.ready = false;

                let url = aiImage;

                if (url.startsWith('//')) {
                    url = `https:${url}`;
                }

                url += '?fmt=jpg&qlt=80';

                const recipeFile = [await this.getFile(url, recipe)];

                const tryClipboard = (link) => {
                    // copy link to clipboard
                    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                        if (result.state === 'granted' || result.state === 'prompt') {
                            const copyContent = async (content) => {
                                try {
                                    await navigator.clipboard.writeText(content).then(() => {
                                        this.appStore.confirmPopup({
                                            message: this.$t('SHARE_COPY_SUCCESS'),
                                            confirmButton: this.$t('SHARE_COPY_CONFIRM'),
                                            confirmAction: () => {
                                                this.appStore.confirmPopup();
                                            },
                                        });
                                    });

                                    this.preparing = false;
                                } catch (err) {
                                    this.preparing = false;
                                }
                            };

                            copyContent(link);
                        }
                    }).catch(() => {
                        if (navigator.clipboard) {
                            // try the clipboard with a different approach
                            if (navigator.clipboard.write) {
                                navigator.clipboard.write(url).then(() => {
                                    this.preparing = false;
                                }).catch(() => {
                                    this.preparing = false;
                                });
                            }
                        } else {
                            this.preparing = false;
                        }
                    });
                };

                if (navigator.share) {
                    try {
                        this.preparing = false;

                        const toShare = {
                            title: this.$t('SHARE_TITLE'),
                            url,
                            files: recipeFile,
                        };

                        // eslint-disable-next-line
                        console.log('To share:', toShare);

                        navigator.share(toShare);
                    } catch (error) {
                        // eslint-disable-next-line
                        console.log('Share error:', error);

                        tryClipboard(url);
                    }
                } else {
                    tryClipboard(url);
                }
            },

            continueShare() {
                const { recipe, recipeFile } = this.recipe;

                this.ready = false;

                // example url
                // 'https://www.popsockets.com/en-us/pages/custom.html?utm_source=customizer&utm_medium=social&recipe_id=';

                let url = this.buildBaseUrl();

                // eslint-disable-next-line max-len
                url += `?utm_source=customizer&utm_medium=social&recipe_id=${recipe.id}&config=${encodeURIComponent(`{"type": "recipe", "recipe": "${recipe.id}"}`)}`;

                const tryClipboard = (link) => {
                    // copy link to clipboard
                    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
                        if (result.state === 'granted' || result.state === 'prompt') {
                            const copyContent = async (content) => {
                                try {
                                    await navigator.clipboard.writeText(content).then(() => {
                                        this.appStore.confirmPopup({
                                            message: this.$t('SHARE_COPY_SUCCESS'),
                                            confirmButton: this.$t('SHARE_COPY_CONFIRM'),
                                            confirmAction: () => {
                                                this.appStore.confirmPopup();
                                            },
                                        });
                                    });

                                    this.preparing = false;
                                } catch (err) {
                                    this.preparing = false;
                                }
                            };

                            copyContent(link);
                        }
                    }).catch(() => {
                        if (navigator.clipboard) {
                            // try the clipboard with a different approach
                            if (navigator.clipboard.write) {
                                navigator.clipboard.write(url).then(() => {
                                    this.preparing = false;
                                }).catch(() => {
                                    this.preparing = false;
                                });
                            }
                        } else {
                            this.preparing = false;
                        }
                    });
                };

                if (navigator.share) {
                    try {
                        this.preparing = false;

                        const toShare = {
                            title: this.$t('SHARE_TITLE'),
                            url,
                            files: recipeFile,
                        };

                        // eslint-disable-next-line
                        console.log('To share:', toShare);

                        navigator.share(toShare);
                    } catch (error) {
                        // eslint-disable-next-line
                        console.log('Share error:', error);

                        tryClipboard(url);
                    }
                } else {
                    tryClipboard(url);
                }
            },

            continueShareEmail() {
                const { recipe } = this.recipe;

                this.ready = false;

                // example url
                // 'https://www.popsockets.com/en-us/pages/custom.html?utm_source=customizer&utm_medium=social&recipe_id=';

                let url = this.buildBaseUrl();

                // eslint-disable-next-line max-len
                url += `?utm_source=customizer&utm_medium=social&recipe_id=${recipe.id}&config=${encodeURIComponent(`{"type": "recipe", "recipe": "${recipe.id}"}`)}`;

                // create the message body/subject
                const subject = encodeURIComponent(this.$t('SHARE_EMAIL_SUBJECT'));
                const body = encodeURIComponent(`${this.$t('SHARE_EMAIL_BODY')} ${url}`);

                const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;

                // open the email in a new tab
                window.open(mailtoUrl, '_blank');
            },

            async share() {
                this.preparing = true;
                this.cancelled = false;
                this.ready = false;

                try {
                    const recipe = await this.customizerStore.addToCart(true, false);

                    const recipeFile = await this.fetchAndParsePreview(recipe);

                    if (this.cancelled) {
                        this.ready = false;
                        this.preparing = false;
                    } else {
                        this.ready = true;
                        this.preparing = false;

                        let aiImage = null;

                        this.viewController.forAllControllers((controller) => {
                            controller.state.forEach((s) => {
                                if (s.component.custom?.['ps-ai-prompt-raw'] != null) {
                                    if (aiImage == null) {
                                        aiImage = s.component.custom['dynamic-image-url'];
                                    }
                                }
                            });
                        });

                        this.recipe = {
                            recipe,
                            recipeFile,
                            aiImage,
                        };
                    }
                } catch (error) {
                    this.preparing = false;
                }
            },
        },
    };
</script>
