<template>
    <div class="cz3__ai-header">
        <div class="cz3__ai-header__logo">
        </div>

        <div></div>

        <button
            v-if="preventBack !== true || isAutoAiMode"
            class="cz3__ai-header__close"
            :aria-label="$t('CLOSE')"
            @click.prevent="close"
        >
            <icon-close />
        </button>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-header {
            flex: 0 0 auto;

            position: relative;
            z-index: 3;

            height: 20px;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .cz3__ai-header__logo {
            width: 147px;
            height: 20px;

            margin-left: 32px;

            background: transparent url(../assets/ps-ai-logo@2x.png) no-repeat center/contain;
        }

        .cz3__ai-header__close,
        .cz3__ai-header__back,
        .cz3__ai-header__help {
            width: 44px;
            height: 44px;

            margin-right: 14px;

            border: none;
            background: transparent;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .cz3__ai-header__help {
            svg {
                width: 29px;
                height: 29px;
            }
        }
    }
</style>

<script>
    import IconClose from '../assets/small-x.svg';

    import allStores from '../stores/all';

    export default {
        mixins: [allStores],

        props: {
            preventBack: Boolean,
            mode: {
                type: String,
                default: '',
            },
        },

        components: {
            IconClose,
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            isAutoAiMode() {
                return this.viewController?.blueprint?.custom['ps-auto-ai'] === 'true';
            },

            showHelpIcon() {
                return this.mode === 'enter' && this.uploadDisclaimerShown && !this.showAiHelpModal;
            },

            showAiHelpModal() {
                return this.appStore.showAiHelpModal;
            },

            uploadDisclaimerShown() {
                return this.appStore.uploadDisclaimerShown;
            },
        },

        methods: {
            openHelpModal() {
                this.appStore.setShowAiHelpModal(true);
            },

            back() {
                this.$emit('back');
            },

            close() {
                if (this.isAutoAiMode && !this.showAiHelpModal) {
                    this.appStore.confirmPopup({
                        message: this.$t('CANCEL_BODY'),
                        confirmButton: this.$t('CANCEL_CONFIRM'),
                        cancelButton: this.$t('CANCEL_CANCEL'),
                        confirmAction: () => {
                            this.appStore.confirmPopup();
                            this.customizerStore.globalCancel();
                        },
                        cancelAction: () => {
                            this.appStore.confirmPopup();
                        },
                    });
                } else if (this.mode === 'preview') {
                    this.back();
                } else if (this.showAiHelpModal) {
                    this.appStore.setShowAiHelpModal(false);
                } else {
                    this.$emit('close');
                }
            },
        },
    };
</script>
