<template>
    <div class="cz3__edit-text">
        <div class="cz3__edit-text">
            <div class="cz3__edit-text__box">
                <input type="text" v-model="text" @input="updateText">
            </div>
        </div>
        <slider-simple class="cz3__edit-text__fonts" :edge-to-edge="true" :save-position="true" :monitor="true">
            <button v-for="f in availableFonts"
                :key="f.code"
                :id="f.code"
                class="cz3__edit-text__font swiper-slide"
                :class="{
                    'cz3__edit-text__font--selected': selectedFont && selectedFont.code === f.code
                }"
                @click.prevent="selectFont(f)"
            >
                <span :style="getFontStyle(f)">{{f.name}}</span>
            </button>
        </slider-simple>
        <div class="cz3__edit-text__controls">
            <button class="cz3__edit-text__button"
                @click.prevent="alignLeft"
            >
                <icon-left></icon-left>
            </button>

            <button class="cz3__edit-text__button"
                @click.prevent="alignCenter"
            >
                <icon-center></icon-center>
            </button>

            <button class="cz3__edit-text__button"
                @click.prevent="alignRight"
            >
                <icon-right></icon-right>
            </button>

            <button
                ref="colorTrigger"
                class="cz3__edit-text__button"
                @click.prevent="pickColor"
            >
                <img src="../assets/text-color.png" width="24" />
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__edit-text__box {
            padding-top: $margin-standard-small;
            padding-left: $margin-standard-small;
            padding-right: $margin-standard-small;
        }

        .cz3__edit-text__controls {
            margin-top: 12px;

            display: flex;
            justify-content: center;

            gap: 24px;
        }

        .cz3__edit-text__button {
            flex: 0 0 auto;

            border: none;
            background: none;

            width: 24px;
            height: 24px;
            padding: 0;

            img {
                vertical-align: top;
            }
        }

        .cz3__edit-text__fonts {
            margin-top: 12px;
        }

        .cz3__edit-text__font.swiper-slide {
            padding: 0 16px;

            width: auto;
            height: 30px;
            line-height: 30px;

            background: $color-primary-white;
            color: $color-primary-black;

            border: 1px solid $color-gray-mid;
            border-radius: 8px;

            white-space: nowrap;

            &.cz3__edit-text__font--selected {
                border-color: $color-primary-black;
            }
        }
    }
</style>

<script>
    import SliderSimple from './SliderSimple.vue';

    import IconLeft from '../assets/text-align-left.svg';
    import IconCenter from '../assets/text-align-center.svg';
    import IconRight from '../assets/text-align-right.svg';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    import designAreaSafe from '../helpers/designAreaSafe';
    import extent from '../helpers/extent';

    export default {
        mixins: [allStores],

        components: {
            SliderSimple,
            IconLeft,
            IconCenter,
            IconRight,
        },

        data() {
            return {
                text: '',
                selectedFont: '',
            };
        },

        computed: {
            selectedLayer() {
                return this.customizerStore.selectedLayer;
            },

            editPlacement() {
                if (this.selectedLayer.controller && this.selectedLayer.layer) {
                    return this.selectedLayer.controller.findPlacement(this.selectedLayer.layer.code);
                }

                return null;
            },

            /**
             * Figure out a list of available fonts.
             */
            availableFonts() {
                if (this.editPlacement) {
                    const fonts = [];

                    this.editPlacement.components.forEach((c) => {
                        const font = c.description.find((d) => d.definition === constants.DEFINITION_FONT);

                        if (font) {
                            if (!fonts.some((f) => f.code === font.code)) {
                                const copy = { ...font };

                                if (copy.custom == null) {
                                    copy.custom = {};
                                }

                                copy.custom = { ...copy.custom, ...c.custom };

                                fonts.push(copy);
                            }
                        }
                    });

                    // fonts.sort((a, b) => {
                    //     const aKey = +(a.custom?.['ps-order'] || 10000000);
                    //     const bKey = +(b.custom?.['ps-order'] || 10000000);

                    //     return aKey - bKey;
                    // });

                    this.appStore.loadFonts(fonts);

                    return fonts;
                }

                return [];
            },
        },

        mounted() {
            if (this.selectedLayer.controller && this.selectedLayer.layer) {
                const text = this.selectedLayer.controller.selectedAtPlacement(this.selectedLayer.layer.code);

                this.text = text?.custom?.['personalization-text'] ?? '';
            }

            this.updateSelectedFont();
        },

        methods: {
            updateText() {
                if (this.selectedLayer.controller && this.selectedLayer.layer) {
                    this.selectedLayer.controller.updatePersonalization(this.selectedLayer.layer.code, this.text);
                }
            },

            updateSelectedFont() {
                if (this.selectedLayer.controller && this.selectedLayer.layer) {
                    const text = this.selectedLayer.controller.selectedAtPlacement(this.selectedLayer.layer.code);

                    if (text) {
                        const font = text.description.find((d) => d.definition === constants.DEFINITION_FONT);
                        if (font) {
                            this.selectedFont = { ...font, ...{ custom: { ...font.custom, ...text.custom } } };
                        }
                    }
                }
            },

            /**
             * Build font style object for the font selector.
             */
            getFontStyle(option) {
                const css = {};

                if (option.custom && option.custom['webfont-family']) {
                    css.fontFamily = `"${option.custom['webfont-family']}"`;
                }

                return css;
            },

            selectFont(f) {
                if (this.selectedLayer?.controller && this.selectedLayer?.layer) {
                    this.selectedLayer.controller.updateSimilarComponent(this.selectedLayer.layer.code, constants.DEFINITION_FONT, f.code);

                    this.appStore.trackAnalyticsEvent({
                        action: 'cyo_font',
                        label: `font_${f?.name}`,
                    });

                    this.updateSelectedFont();
                }
            },

            alignLeft() {
                if (this.selectedLayer?.controller && this.selectedLayer?.layer) {
                    const { left } = designAreaSafe(this.selectedLayer?.controller, '1', 0.95);

                    if (left != null) {
                        const placement = this.selectedLayer.controller.findPlacement(this.selectedLayer.layer.code);
                        const text = this.selectedLayer.controller.selectedAtPlacement(this.selectedLayer.layer.code);

                        const { dx } = extent(placement, text);

                        this.selectedLayer.controller.commitCustomAttributes(placement.code, {
                            'personalization-x': (left + dx).toString(),
                        }, {
                            propagate: true,
                        });
                    }
                }
            },

            alignCenter() {
                if (this.selectedLayer?.controller && this.selectedLayer?.layer) {
                    const { left, right } = designAreaSafe(this.selectedLayer?.controller, '1', 0.95);

                    if (left != null) {
                        const placement = this.selectedLayer.controller.findPlacement(this.selectedLayer.layer.code);

                        this.selectedLayer.controller.commitCustomAttributes(placement.code, {
                            'personalization-x': ((left + right) * 0.5).toString(),
                        }, {
                            propagate: true,
                        });
                    }
                }
            },

            alignRight() {
                if (this.selectedLayer?.controller && this.selectedLayer?.layer) {
                    const { right } = designAreaSafe(this.selectedLayer?.controller, '1', 0.95);

                    if (right != null) {
                        const placement = this.selectedLayer.controller.findPlacement(this.selectedLayer.layer.code);
                        const text = this.selectedLayer.controller.selectedAtPlacement(this.selectedLayer.layer.code);

                        const { dx } = extent(placement, text);

                        this.selectedLayer.controller.commitCustomAttributes(placement.code, {
                            'personalization-x': (right - dx).toString(),
                        }, {
                            propagate: true,
                        });
                    }
                }
            },

            pickColor() {
                if (this.selectedLayer?.controller && this.selectedLayer?.layer) {
                    const selected = this.selectedLayer.controller.selectedAtPlacement(this.selectedLayer.layer.code);

                    const hex = selected.custom['personalization-color'];

                    this.appStore.pickColor({
                        selected: (color) => {
                            this.selectedLayer?.controller.commitCustomAttributes(this.selectedLayer.layer.code, {
                                'personalization-color': color,
                            }, {
                                propagate: true,
                            });
                        },

                        color: hex,

                        near: this.$refs.colorTrigger,
                    });
                }
            },
        },
    };
</script>
