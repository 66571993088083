/**
 * Get design area dimensions.
 */
export default function designArea(controller, layout, border) {
    const useController = controller;

    if (layout) {
        const width = +useController.blueprint.custom[`layout-${layout}-width`];
        const height = +useController.blueprint.custom[`layout-${layout}-height`];
        const safeWidth = (+useController.blueprint.custom[`layout-${layout}-width-safe`]) * border;
        const safeHeight = (+useController.blueprint.custom[`layout-${layout}-height-safe`]) * border;

        const left = +(useController.blueprint.custom[`layout-${layout}-x`] || '0') + ((width - (safeWidth)) / 2);
        const top = +(useController.blueprint.custom[`layout-${layout}-y`] || '0') + ((height - (safeHeight)) / 2);

        return {
            left,
            top,
            right: left + safeWidth,
            bottom: top + safeHeight,
            safeWidth,
            safeHeight,
        };
    }

    return {
    };
}
