<template>
    <div class="cz3__ai-tab-headers">
        <button v-for="t in tabs"
            :key="t.displayName"
            class="cz3__ai-tab"
            :class="{
                'cz3__ai-tab--expanded': t.showSubButtons,
            }"
            @click.select="selectTab(t)"
        >
            <span>{{t.displayName}}</span>

            <!--eslint-disable -->
            <span class="cz3__ai-tab__expand">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 2005">
                <path id="Shape" d="M1 11L7 6L1 1" stroke="#181818" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </svg>
            </span>
            <!-- eslint-enable -->
        </button>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-tab-headers {
            margin-top: 16px;

            display: flex;
            flex-wrap: wrap;

            gap: 5px;
        }

        .cz3__ai-tab {
            padding: 8px;

            display: flex;
            align-items: center;

            border: none;
            background: none;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 400;

            transition: all 0.25s ease-out;

            border-radius: 8px;
        }

        .cz3__ai-selected-tab {
            margin: 8px -12px -20px;

            padding: 10px;

            border-radius: 8px;

            background: #e8e8e8;
        }

        .cz3__ai-tab__expand {
            display: block;

            margin-left: 5px;

            transition: transform 0.25s ease-out;
        }

        .cz3__ai-tab--expanded {
            background: #e8e8e8;
            font-weight: 700;

            .cz3__ai-tab__expand {
                transform: rotate(90deg);
            }
        }
    }
</style>

<script>
    export default {
        props: {
            tabs: Object,
        },

        methods: {
            selectTab(tab) {
                for (const t of this.tabs) {
                    if (t !== tab) {
                        t.showSubButtons = false;
                    }
                }

                tab.showSubButtons = true;
            },
        },
    };
</script>
