<template>
    <div class="cz3__looks">
        <div class="cz3__looks-header">
            <span class="cz3__looks-header__icon">{{$t('LOOKS')}}</span>
        </div>
        <div class="cz3__looks-info">
            {{$t('LOOKS_INFO')}}

            <button
                class="cz3__icon-button cz3__select-case-info"
                @click.prevent="showInfo">
                <icon-info/>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__looks {
            height: 95px;
        }

        .cz3__looks-header {
            text-align: center;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .cz3__looks-header__icon {
            position: relative;
        }

        .cz3__looks-header__icon:before {
            content: '';

            position: absolute;
            right: calc(100% + 5px);
            top: -6px;

            display: inline-block;

            width: 45px;
            height: 15px;

            margin-right: 10px;

            background: transparent url(../assets/looks-arrow@2x.png) no-repeat center/contain;
        }

        .cz3__looks-info {
            max-width: 256px;
            margin-left: auto;
            margin-right: auto;

            margin-top: 8px;

            text-align: left;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
</style>

<script>
    import IconInfo from '../assets/info.svg';

    import allStores from '../stores/all';

    export default {
        mixins: [allStores],

        components: {
            IconInfo,
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            looksInfo() {
                return this.viewController?.blueprint?.custom?.['ps-looks-info-html'];
            },
        },

        methods: {
            showInfo() {
                this.appStore.confirmPopup({
                    messageHtml: this.looksInfo,
                    confirmButton: this.$t('GOT_IT'),
                    closeAction: () => {
                        this.appStore.confirmPopup();
                    },
                    confirmAction: () => {
                        this.appStore.confirmPopup();
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();
                    },
                });
            },
        },
    };
</script>
