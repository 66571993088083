<template>
    <div class="cz3__upload-help-popup" @click.prevent="close">
        <div class="cz3__upload-help-popup__body">
            <video
                ref="helpVideo"
                width="100%"
                height="100%"
                muted
                playsinline
                autoplay
                preload="metadata"
            >
                <source
                    src="https://cdn.drrv.co/popsockets/customizer-cyo3-assets/ai-help-v4.mp4"
                    type="video/mp4"
                />
            </video>
        </div>

        <div class="cz3__upload-help-popup__tooltip">
            <div class="cs3__upload-help-popup__tooltip-arrow"></div>

            <p class="cs3__upload-help-popup__tooltip-copy">
                {{ $t('HELP_TOOLTIP') }}
            </p>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__add-ai__body {
            .cz3__upload-help-popup {
                z-index: 2;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                padding: 48px 20px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;

                background: #F1F1F1F7;

                .cz3__upload-help-popup__body {
                    position: relative;

                    border-radius: 30px;

                    aspect-ratio: 960/1246;
                    max-width: 100%;
                    overflow: hidden;

                    @media (min-width: 650px) {
                        flex-grow: 1;
                    }

                    video {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }

                .cz3__upload-help-popup__tooltip {
                    position: relative;

                    .cs3__upload-help-popup__tooltip-arrow {
                        position: absolute;
                        left: 18px;

                        width: 0px;
                        height: 0px;

                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-bottom: 15px solid $color-accent-green;
                    }

                    .cs3__upload-help-popup__tooltip-copy {
                        margin: 10px 0 0 0;
                        border-radius: 12px;

                        padding: 18px;
                        background-color: $color-accent-green;
                        font-size: 13px;
                    }
                }
            }
        }
    }
</style>

<script>
    export default {
        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>
