// A mixin to import all stores.
import { mapStores } from 'pinia';

import { useCustomizerStore } from './customizer';
import { useAppStore } from './app';

export default {
    props: {
        options: Object,
    },

    computed: {
        ...mapStores(useCustomizerStore, useAppStore),
    },

    methods: {
    },
};
