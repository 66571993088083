<template>
    <div class="cz3__ai-preview__tiles" ref="container">
        <div class="cz3__ai-preview__tiles-inner">
            <transition name="cz3__fade">
                <div v-if="previewData == null" class="cz3__ai-preview__tiles-empty">
                </div>
            </transition>

            <transition-group v-if="previewData != null"
                name="cz3__tiles"
                class="cz3__ai-preview__tiles-filled"
                tag="div"
            >
                <div v-for="(image, index) in images"
                    :key="index"
                    class="cz3__ai-preview__tile"
                    :class="[
                        `cz3__ai-preview__tile--${index + 1}`,
                        `cz3__ai-preview__tile--of-${previewData.images.length}`,
                        {
                            'cz3__ai-preview__tile--zoomed': zoomed === index,
                        },
                    ]"
                >
                    <img v-if="image.image" class="cz3__ai-preview__tile-image"
                        :src="image.image"
                    />

                    <span v-if="image.image == null" class="cz3__ai-preview__tile-image cz3__ai-preview__tile-image--empty"
                        :style="{
                            backgroundImage: `url(&quot;${notEmpty?.image}&quot;)`
                        }"
                    >
                    </span>

                    <img v-if="combinedImages[index]" class="cz3__ai-preview__tile-image cz3__ai-preview__tile-image--combo"
                        :src="combinedImages[index]"
                    />

                    <span v-else-if="image.image && previewData.overlay" class="cz3__ai-preview__tile-image cz3__ai-preview__tile-image--from-user"
                        :style="{
                            backgroundImage: `url(&quot;${previewData.overlay}&quot;)`
                        }"
                    >
                    </span>

                    <span v-if="index === selected" class="cz3__ai-preview__indicator cz3__ai-preview__indicator--selected">
                        <span class="cz3__ai-preview__indicator-center">
                        </span>
                    </span>
                    <span v-if="index !== selected" class="cz3__ai-preview__indicator cz3__ai-preview__indicator">
                    </span>

                    <button class="cz3__ai-preview__select-tile"
                        @click.prevent="selectTile(index)"
                    ></button>

                    <transition name="cz3__fade">
                        <button
                            v-if="images.length > 1 && zoomed !== index"
                            class="cz3__ai-preview__zoom-tile"
                            @click.prevent="zoomTile(index)"
                        >
                            <!-- eslint-disable -->
                            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="7" y="8" width="30" height="30" rx="8" fill="#D9D9D9" fill-opacity="0.7"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8501 15.1607C30.8501 14.9618 30.7711 14.7711 30.6304 14.6304C30.4898 14.4898 30.299 14.4107 30.1001 14.4107L25.8999 14.4107C25.4857 14.4107 25.1499 14.7465 25.1499 15.1607C25.1499 15.575 25.4857 15.9107 25.8999 15.9107L28.2894 15.9107L24.4695 19.7306C24.1767 20.0235 24.1767 20.4984 24.4695 20.7913C24.7624 21.0842 25.2373 21.0842 25.5302 20.7913L29.3501 16.9714L29.3501 19.3609C29.3501 19.7751 29.6859 20.1109 30.1001 20.1109C30.5143 20.1109 30.8501 19.7751 30.8501 19.3609L30.8501 15.1607ZM16.9101 29.4114L20.73 25.5915C21.0229 25.2986 21.0229 24.8237 20.73 24.5308C20.4371 24.2379 19.9622 24.2379 19.6693 24.5308L15.8495 28.3507L15.8495 25.9612C15.8495 25.547 15.5137 25.2112 15.0995 25.2112C14.6852 25.2112 14.3495 25.547 14.3495 25.9612L14.3495 30.1614C14.3495 30.5756 14.6852 30.9114 15.0995 30.9114L19.2996 30.9114C19.7138 30.9114 20.0496 30.5756 20.0496 30.1614C20.0496 29.7472 19.7138 29.4114 19.2996 29.4114L16.9101 29.4114Z" fill="#181818"/>
                            </svg>
                            <!-- eslint-enable -->
                        </button>
                    </transition>

                    <transition name="cz3__fade">
                        <button v-if="zoomed === index" class="cz3__ai-preview__collapse-tile"
                            @click.prevent="zoomTile()"
                        >
                            <!-- eslint-disable -->
                            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="7" y="8" width="30" height="30" rx="8" fill="#D9D9D9" fill-opacity="0.7"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0302 15.2912C31.3231 14.9983 31.3231 14.5234 31.0302 14.2305C30.7373 13.9376 30.2624 13.9376 29.9695 14.2305L25.85 18.3501L25.85 16.0001C25.85 15.5859 25.5142 15.2501 25.1 15.2501C24.6858 15.2501 24.35 15.5859 24.35 16.0001L24.35 20.2003C24.35 20.3992 24.429 20.59 24.5696 20.7306C24.7103 20.8713 24.9011 20.9503 25.1 20.9503H29.3001C29.7144 20.9503 30.0501 20.6145 30.0501 20.2003C30.0501 19.7861 29.7144 19.4503 29.3001 19.4503L26.8711 19.4503L31.0302 15.2912ZM18.4503 30.3003L18.4503 27.8715L14.23 32.0918C13.9371 32.3847 13.4622 32.3847 13.1693 32.0918C12.8764 31.7989 12.8764 31.324 13.1693 31.0311L17.3503 26.8501L15.0001 26.8501C14.5859 26.8501 14.2501 26.5143 14.2501 26.1001C14.2501 25.6859 14.5859 25.3501 15.0001 25.3501L19.2003 25.3501C19.6145 25.3501 19.9503 25.6859 19.9503 26.1001L19.9503 30.3003C19.9503 30.7145 19.6145 31.0503 19.2003 31.0503C18.7861 31.0503 18.4503 30.7145 18.4503 30.3003Z" fill="#181818"/>
                            </svg>

                            <!-- eslint-enable -->
                        </button>
                    </transition>
                </div>
            </transition-group>

            <transition name="cz3__fade">
                <ai-progress-mini v-if="generating"
                    @quit="stopVariations"
                ></ai-progress-mini>
            </transition>
        </div>
    </div>

    <div class="cz3__add-ai__rhythm-space cz3__add-ai__fixed"></div>

    <div class="cz3__ai-preview__buttons">
        <button
            class="cz3__outline-button cz3__button-with-icon cz3__ai-preview__variations"
            @click.prevent="redo"
            :disabled="previewData == null || !canDoVariations || !canCreateVariations"
        >
            {{$t('AI_VARIATIONS')}}
        </button>

        <button class="cz3__primary-button cz3__ai-preview__apply"
            :disabled="selected == null"
            @click.prevent="selectProduct"
        >
            <span>{{$t('AI_APPLY')}}</span>

            <!-- eslint-disable -->
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" transform="rotate(-180 20 20)" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4502 28.8247C18.0952 28.4697 18.0952 27.894 18.4502 27.539L25.9871 20.0022L18.4503 12.4654C18.0952 12.1103 18.0952 11.5347 18.4503 11.1797C18.8053 10.8247 19.3809 10.8247 19.7359 11.1797L27.9156 19.3594C28.2706 19.7144 28.2706 20.29 27.9156 20.645L19.7359 28.8247C19.3809 29.1797 18.8053 29.1797 18.4502 28.8247Z" fill="white"/>
            </svg>
            <!-- eslint-enable -->

            <transition name="cz3__fade-slow">
                <span v-if="canShowApplyTooltip"
                    class="cz3__button-tooltip cz3__button-tooltip--above-left">
                    <span class="cz3__button-tooltip__body">
                        {{$t('AI_TOOLTIP_APPLY')}}
                    </span>
                </span>
            </transition>
        </button>
    </div>

    <template v-if="enableSelectProduct">
        <Teleport to=".cz3__add-ai__body">
            <transition name="cz3__fade">
                <div v-if="selectingProduct" class="cz3__ai-preview__select-product">
                    <div class="cz3__ai-preview__select-bundle">
                        <button
                            class="cz3__ai-preview__select-close"
                            :aria-label="$t('CLOSE')"
                            @click.prevent="backToPreview"
                        >
                            <icon-close />
                        </button>

                        <div class="cz3__mb--standard cz3__type--header cz3__type--center">
                            {{$t('AI_SELECT_PRODUCT')}}
                        </div>

                        <bundle-slider
                            :compatible-options="compatibleOptions"
                            :last-loaded-product="selectedCategory"
                            :product-tile="true"
                            @select-bundle="selectBundle"
                        ></bundle-slider>

                        <div class="cz3__ai-preview__select-buttons">
                            <button class="cz3__primary-button cz3__ai-preview__back"
                                @click.prevent="backToPreview"
                            >
                                <!-- eslint-disable -->
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" transform="rotate(-180 20 20)" fill="black"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4502 28.8247C18.0952 28.4697 18.0952 27.894 18.4502 27.539L25.9871 20.0022L18.4503 12.4654C18.0952 12.1103 18.0952 11.5347 18.4503 11.1797C18.8053 10.8247 19.3809 10.8247 19.7359 11.1797L27.9156 19.3594C28.2706 19.7144 28.2706 20.29 27.9156 20.645L19.7359 28.8247C19.3809 29.1797 18.8053 29.1797 18.4502 28.8247Z" fill="white"/>
                                </svg>
                                <!-- eslint-enable -->

                                <span>{{$t('AI_BACK')}}</span>
                            </button>

                            <button class="cz3__primary-button cz3__ai-preview__configure"
                                :disabled="!canApply"
                                @click.prevent="addToDesign"
                            >
                                <span>{{$t('AI_CONFIGURE_PRODUCT')}}</span>

                                <!-- eslint-disable -->
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" transform="rotate(-180 20 20)" fill="black"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4502 28.8247C18.0952 28.4697 18.0952 27.894 18.4502 27.539L25.9871 20.0022L18.4503 12.4654C18.0952 12.1103 18.0952 11.5347 18.4503 11.1797C18.8053 10.8247 19.3809 10.8247 19.7359 11.1797L27.9156 19.3594C28.2706 19.7144 28.2706 20.29 27.9156 20.645L19.7359 28.8247C19.3809 29.1797 18.8053 29.1797 18.4502 28.8247Z" fill="white"/>
                                </svg>
                                <!-- eslint-enable -->
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </Teleport>
    </template>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    :root {
        --matching-button-width: 100%;
    }

    #cz3.cz3 {
        .cz3__ai-preview__indicator {
            position: absolute;

            left: 15px;
            top: 15px;

            width: 15px;
            height: 15px;

            border-radius: 15px;

            background: rgba(255, 255, 255, 0.8);
            border: 1px solid #000;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cz3__ai-preview__indicator-center {
            width: 11px;
            height: 11px;

            border-radius: 15px;

            background: $color-accent-green;
        }

        .cz3__tiles-enter-active,
        .cz3__tiles-leave-active {
            transition: all 0.25s ease;
        }

        .cz3__tiles-enter-from,
        .cz3__tiles-leave-to {
            opacity: 0;
            transform: translateY(30px);
        }

        .cz3__ai-preview__tiles {
            position: relative;

            margin: 0 24px;

            flex: 0 0 auto;

            width: calc(100% - 48px);
            height: auto;

            aspect-ratio: 1 / 1;
        }

        .cz3__ai-preview__tiles-inner {
            position: relative;

            aspect-ratio: 1 / 1;

            max-height: 100%;
            margin: auto;
        }

        .cz3__ai-preview__tiles-empty {
            position: absolute;
            inset: 0;

            border-radius: 24px;

            background: #f9f9f9;
        }

        .cz3__ai-preview__buttons {
            padding: 0 24px;

            flex: 0 0 auto;

            display: flex;
            justify-content: space-between;

            button {
                flex: 0 0 auto;
            }
        }

        .cz3__ai-preview__tile {
            position: absolute;

            width: calc(50% - 4px);
            aspect-ratio: 1 / 1;

            border: none;
            background: #f9f9f9;

            border-radius: 24px;
            overflow: hidden;

            transition: all 0.1s ease-out;

            &.cz3__ai-preview__tile--of-1 {
                width: 100%;

                .cz3__ai-preview__indicator {
                    display: none;
                }
            }

            &.cz3__ai-preview__tile--1 {
                left: 0;
                top: 0;

                &.cz3__ai-preview__tile--zoomed {
                    width: 100%;

                    left: 0;
                    top: 0;

                    z-index: 100;
                }
            }

            &.cz3__ai-preview__tile--2 {
                left: calc(50% + 4px);
                top: 0;

                &.cz3__ai-preview__tile--zoomed {
                    width: 100%;

                    left: 0;
                    top: 0;

                    z-index: 100;
                }
            }

            &.cz3__ai-preview__tile--3 {
                left: 0;
                top: calc(50% + 4px);

                &.cz3__ai-preview__tile--zoomed {
                    width: 100%;

                    left: 0;
                    top: 0;

                    z-index: 100;
                }
            }

            &.cz3__ai-preview__tile--4 {
                left: calc(50% + 4px);
                top: calc(50% + 4px);

                &.cz3__ai-preview__tile--zoomed {
                    width: 100%;

                    left: 0;
                    top: 0;

                    z-index: 100;
                }
            }
        }

        .cz3__ai-preview__select-tile {
            position: absolute;

            padding: 0;
            margin: 0;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            border: none;
            background: transparent;
        }

        .cz3__ai-preview__zoom-tile {
            position: absolute;

            padding: 0;
            margin: 0;

            width: 45px;
            height: 45px;

            left: 4px;
            bottom: 4px;

            border: none;
            background: transparent;
        }

        .cz3__ai-preview__collapse-tile {
            position: absolute;

            width: 45px;
            height: 45px;

            left: 2px;
            bottom: 8px;

            border: none;
            background: transparent;
        }

        .cz3__ai-preview__tile-image {
            position: absolute;

            inset: 0;

            width: 100%;

            background-color: transparent;
            background-repeat: no-repeat;
            background-size: contain;

            object-fit: contain;

            &.cz3__ai-preview__tile-image--empty {
                filter: blur(10px);

                &:after {
                    content: '';

                    position: absolute;

                    inset: 0;

                    background: rgba(255, 255, 255, 0.4);
                }
            }
        }

        .cz3__outline-button.cz3__ai-preview__variations {
            width: 150px;

            justify-content: center;

            transition: all 0.1s ease-out;

            border: 2px solid $color-primary-black;

            &:disabled {
                background: $color-primary-black;
                color: #fff;
            }
        }

        .cz3__primary-button.cz3__ai-preview__apply {
            position: relative;

            width: auto;
            padding: 0;

            display: flex;
            align-items: center;

            border: none;
            background: transparent;

            color: #000;

            gap: 8px;
        }

        .cz3__ai-preview__select-product {
            position: absolute;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            z-index: 1000;

            background: rgba(213, 213, 213, 0.40);
        }

        .cz3__ai-preview__select-bundle {
            position: absolute;

            bottom: 0;
            width: 100%;
            height: 350px;

            padding: 0 24px 24px;

            background: #fff;
            border-radius: 10px 10px 0 0;

            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-end;

            .cz3__options-bundle__slider {
                margin-top: 50px;
            }

            .cz3__options-bundle {
                position: initial;
            }

            .cz3__type--header {
                font-size: 16px;
                font-weight: 600;
            }

            .cz3__bundle-name {
                text-align: center !important;
                font-size: 14px;
                font-weight: 500;
            }

            .cz3__bundle-caption {
                display: none;
            }
        }

        .cz3__ai-preview__select-buttons {
            margin-top: 50px;

            flex: 0 0 auto;

            display: flex;
            justify-content: space-between;

            button {
                flex: 0 0 auto;
            }
        }

        .cz3__primary-button.cz3__ai-preview__back {
            width: auto;
            padding: 0;

            display: flex;
            align-items: center;

            border: none;
            background: transparent;

            color: #000;

            gap: 8px;

            svg {
                transform: rotate(180deg);
            }
        }

        .cz3__primary-button.cz3__ai-preview__configure {
            width: auto;
            padding: 0;

            display: flex;
            align-items: center;

            border: none;
            background: transparent;

            color: #000;

            gap: 8px;
        }

        .cz3__ai-preview__select-close {
            position: absolute;

            right: 18px;
            top: 34px;

            width: 44px;
            height: 44px;

            border: none;
            background: transparent;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
</style>

<script>
    import tooltips from './tooltips';
    import allStores from '../stores/all';

    import BundleSlider from './BundleSlider.vue';
    import IconClose from '../assets/close.svg';

    import AiProgressMini from './AiProgressMini.vue';

    export default {
        mixins: [allStores, tooltips],

        components: {
            BundleSlider,
            IconClose,
            AiProgressMini,
        },

        props: {
            previewData: Object,
            productSwitcher: Boolean,
            compatibleOptions: Array,
            canDoVariations: Boolean,
            generating: Boolean,
        },

        data() {
            return {
                selected: null,

                zoomed: null,

                selectingProduct: false,

                selectedBundle: null,

                useHeight: null,

                enableSelectProduct: false,

                canShowApplyTooltip: false,

                combinedImages: [],
            };
        },

        computed: {
            tooltipApplyVisible() {
                return this.tooltipVisible('AI_APPLY');
            },

            tooltipApplyRequested() {
                return this.tooltipRequestedFirst('AI_APPLY');
            },

            canApply() {
                if (this.customizerStore.cameraLoading) {
                    return false;
                }

                if (this.customizerStore.bundleLoading) {
                    return false;
                }

                if (this.productSwitcher && this.selectedBundle == null) {
                    return false;
                }

                return true;
            },

            canSelectProduct() {
                return this.productSwitcher;
            },

            selectedCategory() {
                return this.selectedBundle?.product;
            },

            images() {
                if (this.previewData == null) {
                    return null;
                }

                const current = [];

                this.previewData.images.forEach((image) => {
                    current.push({
                        image,
                    });
                });

                return current;
            },

            notEmpty() {
                return this.images.find((x) => x.image);
            },

            canCreateVariations() {
                return true; // !this.images.some((x) => x.image == null);
            },
        },

        watch: {
            previewData() {
                if (this.previewData) {
                    this.selected = 0;
                }
            },

            selected(newValue, oldValue) {
                if (this.selected != null && oldValue == null) {
                    this.canShowApplyTooltip = true;

                    setTimeout(() => {
                        this.canShowApplyTooltip = false;
                    }, 4000);
                }
            },

            generating(newValue, oldValue) {
                // if new images were generated, check for an image from the user.
                // if one exists, combine it with each AI image.
                // otherwise, clear out any previously combined images.
                if (oldValue === true && newValue === false) {
                    // previewData.overlay is user uploaded image.
                    if (this.previewData.overlay) {
                        const userImg = this.previewData.overlay;

                        this.images.forEach((image, i) => {
                            this.generateCombinedImage(image.image, userImg).then((comboImg) => {
                                this.combinedImages[i] = comboImg;
                            });
                        });
                    }
                } else {
                    this.combinedImages = [];
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.enableSelectProduct = true;
            });
        },

        emits: ['redo', 'addToDesign'],

        methods: {
            redo() {
                this.zoomed = null;

                this.$emit('redo', {
                    keep: this.selected,
                });
            },

            addToDesign() {
                this.$emit('addToDesign', {
                    bundle: this.selectedBundle,
                    image: this.previewData.images[this.selected],
                    overlay: this.previewData.overlay,
                });
            },

            selectTile(tile) {
                if (this.images[tile]?.image) {
                    this.selected = tile;

                    if (this.zoomed === tile) {
                        this.zoomed = null;
                    }
                }
            },

            selectBundle(bundle) {
                // Select a bundle but apply later.
                this.selectedBundle = bundle;
            },

            zoomTile(index) {
                this.zoomed = index;

                if (index != null) {
                    this.selected = index;
                }
            },

            selectProduct() {
                if (this.canSelectProduct) {
                    this.selectingProduct = true;
                } else if (this.canApply) {
                    this.addToDesign();
                }
            },

            backToPreview() {
                this.selectingProduct = false;
            },

            stopVariations() {
                this.$emit('stopVariations');
            },

            // eslint-disable-next-line consistent-return
            async generateCombinedImage(aiImage, userImage) {
                try {
                    return await this.combineImagesPromise(aiImage, userImage);
                } catch (err) {
                    // eslint-disable-next-line
                    console.error('Error combining images: ', err);
                }
            },

            combineImagesPromise(aiImage, userImage) {
                return new Promise((resolve, reject) => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const imageBackground = new Image();
                    const imageForeground = new Image();

                    // make sure both images are loaded before trying to combine.
                    imageBackground.onload = () => {
                        imageForeground.onload = () => {
                            // set canvas dims based on largest dims of each image.
                            canvas.width = Math.max(imageBackground.width, imageForeground.width);
                            canvas.height = Math.max(imageBackground.height, imageForeground.height);

                            // draw the images to the canvas.
                            ctx.drawImage(imageBackground, 0, 0);
                            ctx.drawImage(imageForeground, 0, 0);

                            // save the canvas as base64
                            // IMPORTANT: keep the type as jpeg,
                            // because png will randomly not allow you to save the image.
                            const combinedImage = canvas.toDataURL('image/jpeg');

                            // get rid of canvas el.
                            document.body.removeChild(canvas);

                            resolve(combinedImage);
                        };

                        imageForeground.onerror = reject;
                        imageForeground.src = userImage;
                    };

                    imageBackground.onerror = reject;
                    imageBackground.src = aiImage;

                    // add canvas to page so it can be used.
                    // it will not be visible, and will be removed asap.
                    document.body.appendChild(canvas);
                });
            },
        },
    };
</script>
