<template>
    <div class="cz3__ai-image-radios-tab">
        <button v-for="b in buttons"
            :key="b.displayName"
            class="cz3__ai-image-radio-toggle"
            :class="{
                'cz3__ai-image-radio-toggle--enabled': b.isActive,
            }"
            @click.prevent="toggle(b)"
        >
            <span v-if="b.isActive"
                class="cz3__ai-image-radio-toggle__on"
            >
                <span
                    :style="{
                        backgroundImage: `url(&quot;${b.selectionImageURL}&quot;)`
                    }"
                >
                </span>

                <!-- eslint-disable -->
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="18" height="18" rx="9" stroke="#01DF9D" stroke-width="4"/>
                <rect x="4" y="4" width="14" height="14" rx="9" stroke="#fff" stroke-width="1"/>
                </svg>
                <!-- eslint-enable -->
            </span>

            <span v-else
                class="cz3__ai-image-radio-toggle__off"
            >
                <span
                    :style="{
                        backgroundImage: `url(&quot;${b.selectionImageURL}&quot;)`
                    }"
                >
                </span>
            </span>

            <span>
                {{b.displayName}}
            </span>
        </button>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-image-radios-tab {
            display: flex;
            flex-wrap: wrap;

            gap: 16px 8px;
        }

        .cz3__ai-image-radio-toggle {
            display: flex;
            align-items: center;

            padding: 0;

            border: none;
            background: transparent;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 400;

            &.cz3__ai-image-radio-toggle--enabled {
                font-weight: 700;
            }
        }

        .cz3__ai-image-radio-toggle__off, .cz3__ai-image-radio-toggle__on {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 22px;
            height: 22px;

            margin-right: 5px;

            svg {
                position: relative;
            }

            span {
                position: absolute;

                width: 20px;
                height: 20px;

                border-radius: 20px;

                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
    }
</style>

<script>
    export default {
        props: {
            buttons: Array,
            multiselect: Boolean,
        },

        emits: ['beforeSelect'],

        methods: {
            toggle(button) {
                this.$emit('beforeSelect');

                if (!this.multiselect) {
                    for (const b of this.buttons) {
                        if (b !== button) {
                            b.isActive = false;
                        }
                    }
                }

                button.isActive = !button.isActive;
            },
        },
    };
</script>
