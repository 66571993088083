<template>
    <div class="cz3__add-ai__progress-mini">
        <div class="cz3__add-ai__progress-mini__body">
            <img src="../assets/spinner.gif" width="60" height="60" />

            <div class="cz3__add-ai__progress-mini__text cz3__add-ai__progress-mini__text--bottom">
                <div
                    class="cz3__add-ai__progress-mini__quip"
                >
                    {{message ?? $t('AI_GENERATING')}}
                </div>
            </div>

            <button
                @click="closeAndQuit"
                :aria-label="$t('CLOSE')"
                type="button"
                class="cz3__add-ai__progress-mini__close"
            >
                <icon-close />
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__add-ai__progress-mini {
            position: absolute;

            inset: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            background: rgba(255, 255, 255, 0.6);

            z-index: 2000;
        }

        .cz3__add-ai__progress-mini__body {
            position: relative;

            width: 178px;
            height: 178px;

            border-radius: 22px;
            background: #fff;
            box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.05);

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            overflow: hidden;
        }

        .cz3__add-ai__progress-mini__close {
            position: absolute;
            top: 14px;
            right: 14px;

            width: 44px;
            height: 44px;

            border: none;
            background: transparent;

            svg {
                width: calc(18px * 1.208);
                height: calc(18px * 1.208);
            }
        }

        .cz3__add-ai__progress-mini__text {
            padding: 14px 20px 0;
        }

        .cz3__add-ai__progress-mini__quip {
            text-align: center;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        .cz3__change-text-enter-active {
            transition: all 0.1s ease-out;
        }

        .cz3__change-text-leave-active {
            transition: all 0.1s ease-out;
        }

        .cz3__change-text-enter-from {
            transform: translateY(20px);
            opacity: 0;
        }

        .cz3__change-text-leave-to {
            transform: translateY(-20px);
            opacity: 0;
        }
    }
</style>

<script>
    import IconClose from '../assets/close.svg';

    export default {
        props: {
            message: String,
        },

        components: {
            IconClose,
        },

        data() {
            return {
            };
        },

        mounted() {
            this.start();
        },

        beforeUnmount() {
        },

        methods: {
            start() {
            },

            closeAndQuit() {
                this.$emit('quit');
            },
        },
    };
</script>
