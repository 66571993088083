export const STEP_LOADING = 'loading';
export const STEP_CANCEL = 'cancel';
export const STEP_EDIT = 'edit';
export const STEP_SELECT_BUNDLE = 'selectBundle';
export const STEP_SELECT_CASE_BUNDLE = 'selectCaseBundle';
export const STEP_SELECT_PHONE = 'selectPhone';
export const STEP_ADD_TO_CART = 'addToCart';
export const STEP_EDIT_OPTIONS = 'editOptions';
export const STEP_EDIT_IMAGE = 'editImage';
export const STEP_PREVIEW = 'preview';

export const PLACEMENT_PHONE = 'C3Phone';
export const PLACEMENT_PHONE_COLOR = 'C3PhoneColor';
export const PLACEMENT_IMAGES = 'C3ImageGallery';
export const PLACEMENT_STICKERS = 'C3StickerGallery';
export const PLACEMENT_STYLE = 'C3StyleGallery';
export const PLACEMENT_IMAGE = 'MONOGRAMMINGIMAGE';
export const PLACEMENT_TEXT = 'MONOGRAMMINGTEXT';
export const PLACEMENT_BASE = 'MONOGRAMMINGBASE';
export const PLACEMENT_RESULT = 'MONOGRAMMINGRESULT';

export const VIEW_INITIAL = 'Initial';
export const VIEW_OVERVIEW = 'Overview';

export const GALLERY_THUMBNAIL = 'rwid=200';

/**
 * Names of the definition with font options.
 */
export const DEFINITION_FONT = 'Monogramming Font';

/**
 * Names of the definition with font options.
 */
export const DEFINITION_COLOR = 'Monogramming Color';
