<template>
    <div class="cz3__confirmation-popup">
        <div class="cz3__confirmation-popup__body">
            <button
                id="close"
                class="cz3__confirmation-popup__close"
                :aria-label="$t('CLOSE')"
                @click.prevent="close"
            >
                <icon-close/>
            </button>

            <div v-if="title" class="cz3__confirmation-popup__title cz3__type--header cz3__type--center">
                {{title}}
            </div>

            <div v-if="message" class="cz3__confirmation-popup__message cz3__type--body cz3__type--center">
                {{message}}
            </div>

            <div v-if="messageHtml" class="cz3__confirmation-popup__message cz3__type--body cz3__type--center" v-html="messageHtml">
            </div>

            <div v-if="confirmButton" class="cz3__confirmation-popup__confirm cz3__type--center">
                <button
                    class="cz3__primary-button"
                    id="confirm"
                    @click.prevent="confirm"
                    :disabled="!confirmEnabled"
                >
                    {{confirmButton}}
                    <span v-if="!confirmEnabled">({{confirmBlocked}})</span>
                </button>
            </div>

            <div v-if="cancelButton" class="cz3__confirmation-popup__cancel cz3__type--center">
                <button id="cancel" @click.prevent="cancel">
                    {{cancelButton}}
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__confirmation-popup {
            @include absolute-overlay();

            background: rgba(24, 24, 24, 0.9);

            z-index: 10;
        }

        .cz3__confirmation-popup__body {
            position: absolute;

            left: 50%;
            top: 50%;

            transform: translate(-50%, -50%);

            width: 320px;

            padding: 60px 12px 24px;

            background: #fff;
            border-radius: 12px;
        }

        .cz3__confirmation-popup__title {
            margin-bottom: 10px;
        }

        .cz3__confirmation-popup__message {
            b {
                font-weight: 700;
            }

            ul {
                padding-left: 0;
                margin-left: 20px;
            }
        }

        .cz3__confirmation-popup__close {
            position: absolute;

            width: 10px + 24px + 10px;
            height: 10px + 24px + 10px;

            right: 0;
            top: 0;

            border: none;
            background: transparent;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        button.cz3__primary-button {
            width: auto;

            min-width: 90px;
        }

        .cz3__confirmation-popup__confirm {
            margin-top: 24px;
        }

        .cz3__confirmation-popup__cancel {
            margin-top: 24px;

            button {
                border: none;
                background: transparent;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                text-decoration: underline;
                text-underline-offset: 3px;

                color: $color-primary-black;
            }
        }
    }
</style>

<script>
    import IconClose from '../assets/close.svg';

    export default {
        components: {
            IconClose,
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            message: {
                type: String,
                default: '',
            },
            messageHtml: {
                type: String,
                default: '',
            },
            confirmButton: {
                type: String,
                default: '',
            },
            cancelButton: {
                type: String,
                default: '',
            },
            confirmAction: {
                type: Function,
            },
            cancelAction: {
                type: Function,
            },
            closeAction: {
                type: Function,
            },
            confirmCountdown: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                confirmBlocked: this.confirmCountdown ?? (-1),
            };
        },

        computed: {
            confirmEnabled() {
                return this.confirmBlocked <= 0;
            },
        },

        mounted() {
            this.confirmTimer = setInterval(() => {
                this.confirmBlocked -= 1;
            }, 1000);
        },

        beforeUnmount() {
            clearInterval(this.confirmTimer);
        },

        methods: {
            close() {
                if (this.closeAction) {
                    this.closeAction();
                } else if (this.cancelAction) {
                    this.cancelAction();
                } else if (this.confirmAction) {
                    this.confirmAction();
                }
            },

            confirm() {
                if (this.confirmAction) {
                    this.confirmAction();
                }
            },

            cancel() {
                if (this.cancelAction) {
                    this.cancelAction();
                }
            },
        },
    };
</script>
