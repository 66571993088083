<template>
    <div class="cz3__ai-large-image-radios">
        <div class="cz3__ai-large-image-radios__header">
            <span>{{$t('AI_SELECT')}} {{button.displayName}}</span>
        </div>

        <slider-simple class="cz3__ai-large-image__slider"
            :save-position="true"
            :space-between="16"
        >
            <div class="cz3__ai-large-image__spacer swiper-slide">
            </div>

            <button
                v-for="b in buttons"
                :key="b.displayName"
                class="cz3__ai-large-image__button swiper-slide"
                :class="{
                    'cz3__ai-large-image__button--selected': b.isActive
                }"
                @click.prevent="toggleButton(b)"
            >
                <span class="cz3__ai-large-image__button-background"
                    :style="{
                        backgroundImage: `url(&quot;${b.selectionImageURL}&quot;)`
                    }"
                >
                </span>

                <span class="cz3__type--small cz3__ai-large-image__button-title">
                    {{b.displayName}}
                </span>
            </button>

            <div class="cz3__ai-large-image__spacer swiper-slide">
            </div>
        </slider-simple>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-large-image-radios {
            margin-top: 16px;

            padding: 18px 12px;

            border-radius: 8px;
            background: $color-panel-background;
        }

        .cz3__ai-large-image-radios__header {
            display: flex;
            justify-content: space-between;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 700;
        }

        .cz3__ai-large-image__spacer.swiper-slide {
            width: 0px;
        }

        .cz3__ai-large-image__button.swiper-slide {
            width: 60px;
            height: 120px;

            padding: 0;
            padding-top: 3px;
            margin: 0;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            background: transparent;
            border: none;
        }

        .cz3__ai-large-image__button-background {
            position: relative;

            width: 60px;
            height: 60px;

            border-radius: 50%;
            background: $color-gray-mid-light;

            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            &:after {
                content: '';

                position: absolute;
                left: 0px;
                top: 0px;

                width: calc(100% + 0px);
                height: calc(100% + 0px);

                border-radius: 50%;

                border: 4px solid transparent;

                pointer-events: none;

                transition: border 0.25s ease-out;
            }
        }

        .cz3__ai-large-image__button--selected {
            .cz3__ai-large-image__button-background {
                // Could use outline, but we'll reserve outline for accessibility for now.
                &:after {
                    border: 4px solid $color-better-green;
                }
            }

            .cz3__ai-large-image__button-title {
                font-weight: 700;
            }
        }

        .cz3__type--small.cz3__ai-large-image__button-title {
            margin-top: 8px;

            max-height: 44px;

            font-size: 12px;
            line-height: 16px;

            overflow: hidden;
            text-overflow: ellipsis;

            -webkit-line-clamp: 3;
        }

        .swiper.cz3__ai-large-image__slider {
            margin-top: 20px;

            margin-left: -12px;
            margin-right: -12px;

            width: calc(100% + 24px);
        }
    }
</style>

<script>
    import SliderSimple from './SliderSimple.vue';

    export default {
        components: {
            SliderSimple,
        },

        props: {
            button: Object,
        },

        computed: {
            buttons() {
                return this.button.selectionButtons;
            },
        },

        methods: {
            toggleButton(button) {
                if (!this.button.multiSelect) {
                    for (const b of this.buttons) {
                        if (b !== button) {
                            b.isActive = false;
                        }
                    }
                }

                button.isActive = !button.isActive;
            },
        },
    };
</script>
