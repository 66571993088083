import {
    Vector3,
    Matrix,
} from '@babylonjs/core/Maths/math.vector';

export default {
    methods: {
        /**
         * Calculates the world size per 10 pixels at given distance.
         */
        sizePerTenPixels(distance) {
            const { engine } = this;

            const w = engine.getRenderWidth();
            const h = engine.getRenderHeight();

            const left = new Vector3((w * 0.5) - 5, h * 0.5, 0);
            const right = new Vector3((w * 0.5) + 5, h * 0.5, 0);

            const m = Matrix.Identity();

            const leftRay = this.scene.createPickingRay(left.x, left.y, m, this.camera, false);
            const rightRay = this.scene.createPickingRay(right.x, right.y, m, this.camera, false);

            const leftUnproject = this.makePoint(leftRay.origin, leftRay.direction, distance, 1);
            const rightUnproject = this.makePoint(rightRay.origin, rightRay.direction, distance, 1);

            const delta = leftUnproject.subtract(rightUnproject).length();

            return delta;
        },
    },
};
