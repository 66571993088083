<template>
    <div>
        <div class="cz3__options cz3__options-image cz3__renderer-exclude">
        </div>

        <image-layers></image-layers>

        <transition name="cz3__fade">
            <add-ai v-if="replaceMode === 'ai'"
                type="images"
                :usePlacement="selectedLayer.layer.code"
                :useController="selectedLayer.controller"
                @close="setReplaceMode(null)"
            ></add-ai>
        </transition>

        <transition name="cz3__fade">
            <add-image v-if="replaceMode === 'images'"
                type="images"
                :usePlacement="selectedLayer.layer.code"
                :useController="selectedLayer.controller"
                @close="setReplaceMode(null)"
            ></add-image>
        </transition>

        <transition name="cz3__fade">
            <add-image v-if="replaceMode === 'stickers'"
                type="stickers"
                :usePlacement="selectedLayer.layer.code"
                :useController="selectedLayer.controller"
                @close="setReplaceMode(null)"
            ></add-image>
        </transition>

        <navigation-panel-edit-mode
            @replaceImage="replaceImage"
        ></navigation-panel-edit-mode>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options.cz3__options-image {
        }
    }
</style>

<script>
    import AddImage from './AddImage.vue';
    import AddAi from './AddAi.vue';
    import ImageLayers from './ImageLayers.vue';
    import NavigationPanelEditMode from './NavigationPanelEditMode.vue';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    export default {
        mixins: [allStores],

        components: {
            AddImage,
            AddAi,
            ImageLayers,
            NavigationPanelEditMode,
        },

        props: {
        },

        data() {
            return {
                replaceMode: null,
                lastController: null,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            currentStepOptions() {
                return this.customizerStore.currentStepOptions;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            selectedJoint() {
                return this.customizerStore.selectedJoint;
            },

            selectedLayer() {
                return this.customizerStore.selectedLayer;
            },

            controller() {
                return this.selectedJoint?.controller;
            },

            layers() {
                return this.customizerStore.layers;
            },

            layersReversed() {
                const l = [...this.layers];

                l.reverse();

                return l;
            },
        },

        watch: {
            layersReversed() {
                this.ensureSelectedLayer();
            },
        },

        mounted() {
            this.lastController = this.controller;

            let selected = false;

            // Automatically select an image layer on load.
            if (this.currentStepOptions?.image) {
                this.selectImage = this.currentStepOptions?.image;

                const toSelect = this.layersReversed.find((l) => l.image === this.selectImage.imageUrl);

                if (toSelect) {
                    selected = true;

                    const selectImageTimer = setInterval(() => {
                        if (toSelect.state.component.custom['layer-rendered']) {
                            setTimeout(() => {
                                this.selectLayer(toSelect);
                            }, 150);

                            clearInterval(selectImageTimer);
                        }
                    }, 10);
                }
            }

            if (this.currentStepOptions?.text) {
                this.selectText = this.currentStepOptions?.text;

                const toSelect = this.layersReversed.find((l) => l.state.placement.code === this.selectText);

                if (toSelect) {
                    selected = true;

                    const selectImageTimer = setInterval(() => {
                        if (toSelect.state.component.custom['layer-rendered']) {
                            setTimeout(() => {
                                this.selectLayer(toSelect);
                            }, 150);

                            clearInterval(selectImageTimer);
                        }
                    }, 10);
                }
            }

            if (!selected) {
                this.ensureSelectedLayer();
            }
        },

        beforeUnmount() {
            this.emitter.$emit('selectLayer');
        },

        methods: {
            ensureSelectedLayer() {
                // Nothing is selected yet.
                if (this.selectedLayer == null) {
                    if (this.layersReversed.length > 0) {
                        // Don't select automagically.
                        // Or do select automagically.
                        this.selectLayer(this.layersReversed[0]);
                    } else if (this.layersReversed.length === 0) {
                        // The last layer is now gone probably.
                        // All layers deleted, go back.
                        this.customizerStore.setCurrentStep(constants.STEP_EDIT);
                    }
                } else {
                    // Check if the selected layer still exists in the layer list.
                    const code = this.selectedLayer.layer.code;

                    const exists = this.layersReversed.find((l) => l.state.placement.code === code);

                    if (!exists) {
                        if (this.layersReversed.length > 0) {
                            this.selectLayer(this.layersReversed[0]);
                        } else {
                            // All layers deleted, go back.
                            this.customizerStore.setCurrentStep(constants.STEP_EDIT);
                        }
                    }
                }

                if (this.lastLayerCount != null) {
                    if (this.lastLayerCount < this.layersReversed.length) {
                        // We've got a new layer. Select it.
                        if (this.layersReversed.length > 0) {
                            const selectNewLayerTimer = setInterval(() => {
                                if (this.layersReversed[0].state.component.custom['layer-rendered']) {
                                    this.$nextTick(() => {
                                        this.selectLayer(this.layersReversed[0]);
                                    });

                                    clearInterval(selectNewLayerTimer);
                                }
                            }, 10);
                        }
                    }
                }

                this.lastLayerCount = this.layersReversed.length;
            },

            selectLayer(layer) {
                this.emitter.$emit('selectLayer', this.controller, layer.state.placement.code);
            },

            replaceImage(type) {
                this.setReplaceMode(type);
            },

            setReplaceMode(type) {
                this.replaceMode = type;
            },
        },
    };
</script>
