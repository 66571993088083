import {
    Vector3,
    Quaternion,
} from '@babylonjs/core/Maths/math.vector';

import {
    Color3,
    Color4,
} from '@babylonjs/core/Maths/math.color';

/**
 * Converts string to float.
 */
export function toFloat(x, defaultValue) {
    return +(x || (defaultValue || '0'));
}

/**
 * Converts degrees to radians.
 */
export function toRad(x) {
    return ((+(x || '0')) * Math.PI) / 180;
}

/**
 * Converts a string in the #rrggbb or #rrggbbaa or r,g,b format to Babylon color.
 */
export function toColor3(x) {
    let c = x;

    if (c == null) {
        c = '000000';
    }

    if (c[0] === '#') {
        c = c.substr(1);
    }

    if (c.includes(',')) {
        const parts = c.split(',').map((p) => parseFloat(p.trim()) / 255);

        if (parts.length >= 3) {
            return new Color3(parts[0], parts[1], parts[2]);
        }
    }

    if (c.length === 6) {
        return new Color3(
            parseInt(c.substr(0, 2), 16) / 255,
            parseInt(c.substr(2, 2), 16) / 255,
            parseInt(c.substr(4, 2), 16) / 255,
        );
    }

    if (c.length === 8) {
        return new Color3(
            parseInt(c.substr(0, 2), 16) / 255,
            parseInt(c.substr(2, 2), 16) / 255,
            parseInt(c.substr(4, 2), 16) / 255,
        );
    }

    return new Color3(0, 0, 0);
}

/**
 * Converts a string in the #rrggbb or #rrggbbaa or r,g,b format to Babylon color.
 */
export function toColor4(x) {
    let c = x;

    if (c == null) {
        c = '000000FF';
    }

    if (c[0] === '#') {
        c = c.substr(1);
    }

    if (c.includes(',')) {
        const parts = c.split(',').map((p) => parseFloat(p.trim()) / 255);

        if (parts.length >= 4) {
            return new Color4(parts[0], parts[1], parts[2], parts[3]);
        }

        if (parts.length >= 3) {
            return new Color4(parts[0], parts[1], parts[2], 1);
        }

        return new Color4(0, 0, 0, 1);
    }

    if (c.length === 6) {
        return new Color4(
            parseInt(c.substr(0, 2), 16) / 255,
            parseInt(c.substr(2, 2), 16) / 255,
            parseInt(c.substr(4, 2), 16) / 255,
            1,
        );
    }

    if (c.length === 8) {
        return new Color4(
            parseInt(c.substr(0, 2), 16) / 255,
            parseInt(c.substr(2, 2), 16) / 255,
            parseInt(c.substr(4, 2), 16) / 255,
            parseInt(c.substr(6, 2), 16) / 255,
        );
    }

    return new Color4(0, 0, 0, 1);
}

/**
 * Converts from Color4 to unpacked presentation compatible with Tweakpane for example.
 */
export function unpackColor4(c) {
    if (c == null) {
        return {
            r: 0,
            g: 0,
            b: 0,
            a: 1,
        };
    }

    return {
        r: c.r * 255,
        g: c.g * 255,
        b: c.b * 255,
        a: c.a,
    };
}

/**
 * Converts from Tweakpane to Color4
 */
export function packColor4(c) {
    return new Color4(c.r / 255, c.g / 255, c.b / 255, c.a);
}

/**
 * Converts a string in the #rrggbb or #rrggbbaa or r,g,b format to Babylon color.
 */
export function toColor4Unnormalized(x) {
    let c = x;

    if (c == null) {
        c = '000000FF';
    }

    if (c[0] === '#') {
        c = c.substr(1);
    }

    if (c.includes(',')) {
        const parts = c.split(',').map((p) => parseFloat(p.trim()));

        if (parts.length >= 4) {
            return {
                r: parts[0],
                g: parts[1],
                b: parts[2],
                a: parts[3] / 255,
            };
        }

        if (parts.length >= 3) {
            return {
                r: parts[0],
                g: parts[1],
                b: parts[2],
                a: 255 / 255,
            };
        }

        return {
            r: 0,
            g: 0,
            b: 0,
            a: 255 / 255,
        };
    }

    if (c.length === 6) {
        return {
            r: parseInt(c.substr(0, 2), 16),
            g: parseInt(c.substr(2, 2), 16),
            b: parseInt(c.substr(4, 2), 16),
            a: 255 / 255,
        };
    }

    if (c.length === 8) {
        return {
            r: parseInt(c.substr(0, 2), 16),
            g: parseInt(c.substr(2, 2), 16),
            b: parseInt(c.substr(4, 2), 16),
            a: parseInt(c.substr(6, 2), 16) / 255,
        };
    }

    return {
        r: 0,
        g: 0,
        b: 0,
        a: 255 / 255,
    };
}

function toColorComponent(v) {
    // eslint-disable-next-line no-bitwise
    const hex = (v | 0).toString(16);

    return hex.length >= 2 ? hex : `0${hex}`;
}

export function formatColor3(c) {
    const r = toColorComponent(c.r * 255);
    const g = toColorComponent(c.g * 255);
    const b = toColorComponent(c.b * 255);

    return `${r}${g}${b}`;
}

export function formatColor4(c) {
    const r = toColorComponent(c.r * 255);
    const g = toColorComponent(c.g * 255);
    const b = toColorComponent(c.b * 255);
    const a = toColorComponent(c.a * 255);

    return `${r}${g}${b}${a}`;
}

export function formatColor4Unnormalized(c) {
    const r = toColorComponent(c.r);
    const g = toColorComponent(c.g);
    const b = toColorComponent(c.b);
    const a = toColorComponent(c.a * 255);

    return `${r}${g}${b}${a}`;
}

export function toVector3(x, asNull) {
    if (x == null) {
        if (asNull) {
            return null;
        }

        return new Vector3(0, 0, 0);
    }

    let parts;

    if (x.indexOf(' ')) {
        parts = x.split(' ').map((p) => p.trim()).filter((p) => p.length > 0).map((p) => parseFloat(p));
    } else {
        parts = x.split(',').map((p) => parseFloat(p.trim()));
    }

    return new Vector3(
        parts.length > 0 ? parts[0] : 0,
        parts.length > 1 ? parts[1] : 0,
        parts.length > 2 ? parts[2] : 0,
    );
}

export function toQuaternion(x) {
    if (x == null) {
        return new Quaternion(0, 0, 0, 1);
    }

    let parts;

    if (x.indexOf(' ')) {
        parts = x.split(' ').map((p) => p.trim()).filter((p) => p.length > 0).map((p) => parseFloat(p));
    } else {
        parts = x.split(',').map((p) => parseFloat(p.trim()));
    }

    return new Quaternion(
        parts.length > 0 ? parts[0] : 0,
        parts.length > 1 ? parts[1] : 0,
        parts.length > 2 ? parts[2] : 0,
        parts.length > 3 ? parts[3] : 1,
    );
}

export function formatVector3(v) {
    return `${v.x.toFixed(4)} ${v.y.toFixed(4)} ${v.z.toFixed(4)}`;
}

export function formatQuaternion(v) {
    return `${v.x.toFixed(4)} ${v.y.toFixed(4)} ${v.z.toFixed(4)} ${v.w.toFixed(4)}`;
}
