<template>
    <button id="popsockets-logo" class="cz3__logo" @click.prevent="close">
        <icon-logo/>
    </button>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__logo {
            position: absolute;

            left: 11px;
            top: 16px;

            width: 32px;
            height: 32px;

            border: none;
            background: none;

            z-index: 1;

            @include respond-above(sm) {
                left: 30px;
                top: 40px;

                width: 64px;
                height: 64px;
            }

            svg {
                @include absolute-overlay();
            }
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import IconLogo from '../assets/logo.svg';

    export default {
        mixins: [allStores],

        components: {
            IconLogo,
        },

        methods: {
            close() {
                this.appStore.confirmPopup({
                    message: this.$t('CANCEL_BODY'),
                    confirmButton: this.$t('CANCEL_CONFIRM'),
                    cancelButton: this.$t('CANCEL_CANCEL'),
                    confirmAction: () => {
                        this.appStore.confirmPopup();
                        this.customizerStore.globalCancel();
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();
                    },
                });
            },
        },
    };
</script>
