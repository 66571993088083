<template>
    <div class="cz3__ai-prompt__input">
        <ai-prompt-text
            :start-prompt="startPrompt"
            :buttons="buttons"
            @updatePrompt="updatePrompt"
        ></ai-prompt-text>
    </div>

    <div class="cz3__add-ai__rhythm-space cz3__add-ai__fixed"></div>

    <div class="cz3__ai-prompt__options">
        <button class="cz3__ai-prompt__guided"
            :class="{
                'cz3__ai-prompt__option--expanded': mode === 'guided',
            }"
            @click.prevent="setMode('guided')"
        >
            <span class="cz3__ai-prompt__guided-icon">
            </span>

            <span>{{$t('AI_GUIDED')}}</span>

            <!-- eslint-disable -->
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.40448 15.0422L7.648 8.3695L1.39152 1.70891" stroke="#181818" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <!-- eslint-enable -->
        </button>

        <button class="cz3__ai-prompt__photo"
            :class="{
                'cz3__ai-prompt__option--expanded': mode === 'photo',
            }"
            @click.prevent="setMode('photo')"
        >
            <span class="cz3__ai-prompt__photo-icon">
            </span>

            <span v-if="fromImage == null">
                {{$t('AI_ADD_PHOTO')}}
            </span>
            <span v-else>
                {{$t('AI_REMOVE_PHOTO')}}
            </span>

            <!-- eslint-disable -->
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.40448 15.0422L7.648 8.3695L1.39152 1.70891" stroke="#181818" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <!-- eslint-enable -->
        </button>
    </div>

    <div v-auto-animate class="cz3__ai-prompt__guide-container-outer">
        <div v-if="mode === 'guided'" key="guide" class="cz3__ai-prompt__guide-container">
            <ai-guided
                :structure="structure"
                :buttons="buttons"
            ></ai-guided>
        </div>
    </div>

    <div v-auto-animate class="cz3__ai-prompt__upload-container-outer">
        <div v-if="mode === 'photo'" key="upload" class="cz3__ai-prompt__upload-container">
            <ai-upload
                :from-image="fromImage"
                :play-video="playVideo"
                @uploaded="uploaded"
            ></ai-upload>
        </div>
    </div>

    <div class="cz3__add-ai__rhythm-space cz3__add-ai__fixed"></div>

    <div class="cz3__ai-prompt__generate">
        <button
            class="cz3__primary-button cz3__primary-button--full-width"
            @click.prevent="generate"
            :disabled="!canAdd"
        >
            {{generated ? $t('AI_CREATE_MODIFY') : $t('AI_CREATE')}}
        </button>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-prompt__input {
            position: relative;

            flex: 0 0 auto;
            padding: 0 24px;

            textarea {
                border: none;
                border-radius: 24px;

                padding: 2px 16px;

                width: 100%;
                // height: 82px;

                resize: none;

                background-color: #f1f1f1;

                border: 2px solid #f1f1f1;

                &:focus-visible {
                    border: 2px solid #000;

                    outline: none;
                }
            }
        }

        .cz3__ai-prompt__options {
            flex: 0 0 auto;
            padding: 0 24px;

            height: 25px;

            display: flex;
            justify-content: space-between;

            button {
                display: flex;

                align-items: center;

                border: none;
                background: none;
                padding: 0;

                font-family: $font-main;
                font-size: 14px;
                font-weight: 500;

                span {
                    margin-right: 5px;
                }

                svg {
                    margin-left: 1px;

                    transition: transform 0.25s ease-out;
                }

                &.cz3__ai-prompt__option--expanded {
                    font-weight: 700;

                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .cz3__ai-prompt__guided-icon {
            display: inline-block;

            width: 25px;
            height: 25px;

            background: transparent url(../assets/popify@2x.png) no-repeat center/contain;
        }

        .cz3__ai-prompt__photo-icon {
            display: inline-block;

            width: 25px;
            height: 25px;

            background: transparent url(../assets/image-background@2x.png) no-repeat center/contain;
        }

        .cz3__type--body--title {
            font-weight: 600;
            font-size: 16px;
        }

        .cz3__ai-prompt__style {
            margin-top: 20px;
        }

        .cz3__ai-prompt__guide-container-outer {
            flex: 0 0 auto;

            overflow: hidden;
        }

        .cz3__ai-prompt__upload-container-outer {
            flex: 0 0 auto;

            padding: 0 24px;

            overflow: hidden;
        }

        .cz3__ai-prompt__upload {
            position: relative;

            margin-top: 30px;

            .cz3__type--body {
                display: flex;
                align-items: center;
            }
        }

        .cz3__ai-prompt__upload--icon {
            margin-left: 8px;
            display: inline-block;

            width: 24px;
            height: 24px;

            background-image: url(../assets/your-image.svg);
            background-size: contain;
        }

        .cz3__ai-prompt__generate {
            flex: 0 0 auto;
            padding: 0 24px;

            display: flex;
            justify-content: center;

            button {
                flex: 0 0 auto;

                font-size: 14px;
            }
        }

        .cz3__ai-prompt__prompt-buttons {
            flex: 0 0 auto;

            min-height: 40px;

            display: flex;
            justify-content: space-between;

            button {
                flex: 0 0 auto;
            }
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import AiUpload from './AiUpload.vue';
    import AiGuided from './AiGuided.vue';
    import AiPromptText from './AiPromptText.vue';

    export default {
        mixins: [allStores],

        components: {
            AiUpload,
            AiGuided,
            AiPromptText,
        },

        props: {
            structure: Object,
            buttons: Array,

            preventBack: Boolean,

            startPrompt: String,

            startStyle: String,

            showUploadDisclaimer: Boolean,

            generated: Boolean,
        },

        emits: ['generate', 'updatePrompt'],

        data() {
            return {
                prompt: 'aliens',

                style: 'none',

                fromImage: null,

                mode: null,

                playVideo: false,
            };
        },

        computed: {
            canAdd() {
                return this.prompt?.prompt?.length > 0;
            },
        },

        watch: {
            showUploadDisclaimer() {
            },

            startPrompt() {
                // eslint-disable-next-line
                console.log('Start prompt updated', this.startPrompt);
            },
        },

        mounted() {
            if (!this.customizerStore.expandedGuidedPrompts) {
                this.customizerStore.expandedGuidedPrompts = true;

                this.mode = 'guided';
            }
        },

        methods: {
            setStyle(style) {
                this.style = style;
            },

            uploaded(image) {
                this.fromImage = image;
            },

            generate() {
                this.mode = null;

                this.$emit('generate', {
                    ...this.prompt,
                    fromImage: this.fromImage,
                });
            },

            setMode(type) {
                if (this.mode === type) {
                    this.mode = null;
                } else {
                    this.mode = type;

                    if (this.mode === 'photo') {
                        this.$nextTick(() => {
                            this.playVideo = true;
                        });
                    }
                }
            },

            updatePrompt(v) {
                this.prompt = v;

                this.$emit('updatePrompt', v);
            },
        },
    };
</script>
