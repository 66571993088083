<template>
    <div class="cz3__preorder-bar">
        <div v-if="bundle.preorderTitle" class="cz3__preorder-bar__title">
            <!-- eslint-disable -->
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path d="M10 0.5C8.02219 0.5 6.08879 1.08649 4.4443 2.1853C2.79981 3.28412 1.51809 4.8459 0.761209 6.67317C0.00433284 8.50043 -0.193701 10.5111 0.192152 12.4509C0.578004 14.3907 1.53041 16.1725 2.92894 17.5711C4.32746 18.9696 6.10929 19.922 8.0491 20.3079C9.98891 20.6937 11.9996 20.4957 13.8268 19.7388C15.6541 18.9819 17.2159 17.7002 18.3147 16.0557C19.4135 14.4112 20 12.4778 20 10.5C19.9971 7.84874 18.9425 5.30691 17.0678 3.43219C15.1931 1.55746 12.6513 0.502949 10 0.5ZM10 18.5C8.41775 18.5 6.87104 18.0308 5.55544 17.1518C4.23985 16.2727 3.21447 15.0233 2.60897 13.5615C2.00347 12.0997 1.84504 10.4911 2.15372 8.93928C2.4624 7.38743 3.22433 5.96197 4.34315 4.84315C5.46197 3.72433 6.88743 2.9624 8.43928 2.65372C9.99113 2.34504 11.5997 2.50346 13.0615 3.10896C14.5233 3.71447 15.7727 4.73984 16.6518 6.05544C17.5308 7.37103 18 8.91775 18 10.5C17.9976 12.621 17.1539 14.6544 15.6542 16.1542C14.1544 17.6539 12.121 18.4976 10 18.5ZM10 10C9.73479 10 9.48043 10.1054 9.2929 10.2929C9.10536 10.4804 9 10.7348 9 11V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10ZM10 6C9.75278 6 9.5111 6.07331 9.30554 6.21066C9.09998 6.34801 8.93976 6.54324 8.84516 6.77165C8.75055 7.00005 8.72579 7.25139 8.77402 7.49386C8.82225 7.73634 8.94131 7.95907 9.11612 8.13388C9.29094 8.3087 9.51367 8.42775 9.75614 8.47598C9.99862 8.52421 10.25 8.49946 10.4784 8.40485C10.7068 8.31024 10.902 8.15002 11.0393 7.94446C11.1767 7.7389 11.25 7.49723 11.25 7.25C11.25 6.91848 11.1183 6.60054 10.8839 6.36612C10.6495 6.1317 10.3315 6 10 6Z" fill="#181818"></path></svg>
            <!-- eslint-enaable -->
            <span>{{bundle.preorderTitle}}</span>
        </div>

        <div v-if="bundle.preorderDate" class="cz3__preorder-bar__bar">
        </div>

        <div v-if="bundle.preorderDate" class="cz3__preorder-bar__date">
            <!-- eslint-disable -->
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path d="M6.66666 2.16666V4.66666" stroke="#292D32" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M13.3333 2.16666V4.66666" stroke="#292D32" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2.91666 8.07501H17.0833" stroke="#292D32" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.5 7.58332V14.6667C17.5 17.1667 16.25 18.8333 13.3333 18.8333H6.66667C3.75 18.8333 2.5 17.1667 2.5 14.6667V7.58332C2.5 5.08332 3.75 3.41666 6.66667 3.41666H13.3333C16.25 3.41666 17.5 5.08332 17.5 7.58332Z" stroke="#292D32" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.99623 11.9167H10.0037" stroke="#292D32" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.91194 11.9167H6.91942" stroke="#292D32" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.91194 14.4167H6.91942" stroke="#292D32" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <!-- eslint-enaable -->
            <span class="cz3__preorder-bar__date-value">
                <span class="cz3__preorder-bar__date-title">
                    {{bundle.preorderDateTitle}}
                </span>
                <span class="cz3__preorder-bar__date-when">
                    {{bundle.preorderDate}}
                </span>
            </span>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__preorder-bar {
            position: relative;

            width: calc(100vw - 20px);
            left: 50%;

            transform: translate(-50%, 0);

            @include respond-above(sm) {
                width: $content-large-width;
            }

            background: #f9f9f9;
            padding: 16px;
            border-radius: 8px;

            display: flex;
            align-items: center;
            gap: 10px;

            > * {
                flex: 1 1 0;
            }
        }

        .cz3__preorder-bar__bar {
            flex: 0 0 1px;

            height: 24px;

            border-left: 1px solid #ccc;
        }

        .cz3__preorder-bar__title {
            display: flex;
            justify-content: center;
            align-items: center;

            gap: 10px;
            padding: 10px;

            > * {
                flex: 0 0 auto;
            }

            font-family: $font-main;
            font-size: 14px;
            font-weight: 700;

            span {
                max-width: 100%;
            }
        }

        .cz3__preorder-bar__date {
            display: flex;
            justify-content: center;
            align-items: center;

            gap: 10px;

            > * {
                flex: 0 0 auto;
            }
        }

        .cz3__preorder-bar__date-value {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .cz3__preorder-bar__date-title {
            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;
        }

        .cz3__preorder-bar__date-when {
            font-family: $font-main;
            font-size: 14px;
            font-weight: 700;
        }
    }
</style>

<script>
    export default {
        props: {
            bundle: Object,
        },
    };
</script>
