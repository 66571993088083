/**
 * Gets the property value using the property name. Property name can be in the dotted form.
 */
export function getValuePath(o, p) {
    let v = o;

    const path = p.split('.');

    path.forEach((c) => {
        if (v != null) {
            v = v[c];
        }
    });

    return v;
}

/**
 * Sets the property value using the property name and value. Property name can be in the dotted form.
 */
export function setValuePath(o, p, value) {
    let v = o;

    const path = p.split('.');

    path.slice(0, path.length - 1).forEach((c) => {
        if (v != null) {
            v = v[c];
        }
    });

    if (v) {
        v[path[path.length - 1]] = value;
    }
}

// eslint-disable-next-line import/prefer-default-export
export function mergeReset(oldValue, newValue, tolerance) {
    const isOldNull = oldValue == null;
    const isNewNull = newValue == null;

    if (isOldNull !== isNewNull) {
        return false;
    }

    if (oldValue.length !== newValue.length) {
        return false;
    }

    for (let i = 0; i < oldValue.length; i += 1) {
        const o = oldValue[i];
        const n = newValue[i];

        if (o.type !== n.type) {
            return false;
        }

        if (o.label !== n.label) {
            return false;
        }

        if (o.title !== n.title) {
            return false;
        }

        if (o.type === 'folder') {
            if (!mergeReset(o.inputs, n.inputs, tolerance)) {
                return false;
            }
        }

        if (o.type === 'boolean') {
            if (o.value !== n.value) {
                return false;
            }
        }

        if (o.type === 'number' || o.type === 'color') {
            if (n.value.x != null) {
                if (Math.abs(o.value.x - n.value.x) > tolerance) {
                    return false;
                }

                if (Math.abs(o.value.y - n.value.y) > tolerance) {
                    return false;
                }

                if (Math.abs(o.value.z - n.value.z) > tolerance) {
                    return false;
                }
            } else if (n.value.r != null) {
                if (Math.abs(o.value.r - n.value.r) > tolerance) {
                    return false;
                }

                if (Math.abs(o.value.g - n.value.g) > tolerance) {
                    return false;
                }

                if (Math.abs(o.value.b - n.value.b) > tolerance) {
                    return false;
                }

                if (Math.abs(o.value.a - n.value.a) > tolerance) {
                    return false;
                }
            } else if (Math.abs(o.value - n.value) > tolerance) {
                return false;
            }

            if (o.min != null && Math.abs(o.min - n.min) > tolerance) {
                return false;
            }

            if (o.max != null && Math.abs(o.max - n.max) > tolerance) {
                return false;
            }
        }
    }

    return true;
}
