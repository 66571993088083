const imageBuffers = {};

// eslint-disable-next-line
export function getRenderBuffer(type, width, height) {
    let buffer = imageBuffers[type];

    if (buffer == null || buffer.width !== width || buffer.height !== height) {
        if (buffer?.buffer) {
            buffer.buffer.remove();
        }

        buffer = {
            buffer: document.createElement('canvas'),
            width,
            height,
        };

        buffer.buffer.width = buffer.width;
        buffer.buffer.height = buffer.height;

        imageBuffers[type] = buffer;
    }

    return {
        buffer: buffer.buffer,
        width,
        height,
    };
}
