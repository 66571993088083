/**
 * Checks if component is filled.
 */
// eslint-disable-next-line import/prefer-default-export
export function isFilled(component) {
    if (component.custom['widget-type'] === 'personalization') {
        return (component.custom['personalization-text'] || '').length > 0
            || (component.custom['personalization-filled'] === 'true');
    }

    if (component.custom['widget-type'] === 'dynamic-image') {
        return (component.custom['dynamic-image-url'] || '').length > 0;
    }

    return !component.placeholder;
}

/**
 * Checks if component is filled, ignoring "reserved" text placements.
 */
export function isFilledish(component) {
    if (component.custom['widget-type'] === 'personalization') {
        return (component.custom['personalization-text'] || '').length > 0;
    }

    if (component.custom['widget-type'] === 'dynamic-image') {
        return (component.custom['dynamic-image-url'] || '').length > 0;
    }

    return !component.placeholder;
}

/**
 * Is there at least one filled monogramming placement?
 */
export function isAnyFilled(controller) {
    const filled = controller.state.some((s) => {
        if (s.placement.code.startsWith('MONOGRAM')
            && !s.placement.code.startsWith('MONOGRAMMINGBASE')
            && !s.placement.code.startsWith('MONOGRAMMINGRESULT')
            && isFilled(s.component)) {
            return true;
        }

        return false;
    });

    return filled;
}

/**
 * Are there any special selections?
 */
export function isAnySpecial(controller) {
    const filled = controller.state.some((s) => {
        if (s.component?.custom?.['ps-special'] === 'true') {
            return true;
        }

        return false;
    });

    return filled;
}

/**
 * Puts monogramming layer on top.
 */
export function assignTopmostLayer(controller, placement) {
    let topmost = null;

    controller.state.forEach((s) => {
        if (s.placement.code.startsWith('MONOGRAM')
            && !s.placement.code.startsWith('MONOGRAMMINGBASE')
            && !s.placement.code.startsWith('MONOGRAMMINGRESULT')) {
            const index = s.placement.custom['explicit-order'];

            if (topmost == null || index > topmost) {
                topmost = index;
            }
        }
    });

    if (topmost != null) {
        placement.custom['explicit-order'] = topmost + 1;
    } else {
        placement.custom['explicit-order'] = 1;
    }
}
