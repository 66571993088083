/**
 * Retrieve rotated component extents.
 */
export default function getExtent(placement, component, offsetX, offsetY, offsetScale, offsetRotate) {
    let x;
    let y;

    let w;
    let h;
    let ht;
    let hd;

    let s;
    let r;

    let descent = 0;
    let ascent = 0;
    let spacing = 0;

    if (component.custom['widget-type'] === 'personalization') {
        x = +((component.custom && component.custom['personalization-x']) || '0');
        y = +((component.custom && component.custom['personalization-y']) || '0');

        w = (+((component.custom && component.custom['personalization-width']) || '0'));
        h = (+((component.custom && component.custom['personalization-height']) || '0'));

        s = +((component.custom && component.custom['personalization-scale']) || '1');
        r = +((component.custom && component.custom['personalization-rotate']) || '0');

        // const unR = r;
        // const unscale = 1;

        if (offsetScale != null) {
            s = offsetScale;
        }

        if (offsetRotate != null) {
            r = offsetRotate;
        }

        w *= 0.5;
        h *= 0.5;

        x += w;
        y += h;

        descent = 0; // +((placement.custom['personalization-descent']) || '0');

        ht = h;
        hd = h + descent;

        spacing = +((placement.custom['personalization-spacing']) || '0');
        ascent = 0; // +((placement.custom['personalization-ascent']) || '0');

        // Use exact sizes if available.
        if (placement.custom['personalization-bbox-width']) {
            const rw = +(placement.custom['personalization-bbox-width']);
            const rh = +(placement.custom['personalization-bbox-height']);
            const rl = +(placement.custom['personalization-bbox-left']);
            const rt = +(placement.custom['personalization-bbox-top']);

            x -= w;
            y -= h;

            x += rl;
            y += rt;

            w = rw * 0.5;
            h = rh * 0.5;

            x += w;
            y += h;

            ht = h;
            hd = h;

            spacing = 0;
            ascent = 0;
            descent = 0;
        }
    } else {
        x = +((component.custom && component.custom['dynamic-image-x']) || '0');
        y = +((component.custom && component.custom['dynamic-image-y']) || '0');

        x += +((component.custom && component.custom['dynamic-image-offset-x']) || '0');
        y += +((component.custom && component.custom['dynamic-image-offset-y']) || '0');

        w = (+((component.custom && component.custom['dynamic-image-width']) || '0'));
        h = (+((component.custom && component.custom['dynamic-image-height']) || '0'));

        s = +(component.custom['dynamic-image-scale'] || '1');
        r = +(component.custom['dynamic-image-rotate'] || '0');

        if (offsetScale != null) {
            s = offsetScale;
        }

        if (offsetRotate != null) {
            r = offsetRotate;
        }

        w *= s * 0.5;
        h *= s * 0.5;

        ht = h;
        hd = h;
    }

    if (offsetX != null) {
        x += offsetX;
    }

    if (offsetY != null) {
        y += offsetY;
    }

    r = (r * Math.PI) / 180;

    const cr = Math.cos(r);
    const sr = Math.sin(r);

    const p1x = cr * (+w) - sr * (+hd);
    const p1y = sr * (+w) + cr * (+hd);

    const p2x = cr * (+w) - sr * (-ht);
    const p2y = sr * (+w) + cr * (-ht);

    const p3x = cr * (-w) - sr * (-ht);
    const p3y = sr * (-w) + cr * (-ht);

    const p4x = cr * (-w) - sr * (+hd);
    const p4y = sr * (-w) + cr * (+hd);

    const dx = Math.max(p1x, p2x, p3x, p4x);
    const dyTop = Math.min(p1y, p2y, p3y, p4y);
    const dyBottom = Math.max(p1y, p2y, p3y, p4y);

    const result = {
        x,
        y,
        dx,
        dy: -dyTop,
        dyBottom,
        dw: dx * 2,
        dh: dyTop * 2,
        descent,
        w,
        h,
        spacing,
        ascent,
    };

    return result;
}
