<template>
    <div ref="swiper" class="cz3__simple-slider swiper" :class="{ 'cz3__simple-slider--edge-to-edge': edgeToEdge }">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss">
    #cz3.cz3 {
        .cz3__simple-slider {
            width: 100%;
        }

        .swiper-wrapper {
            width: max-content;
        }

        .cz3__simple-slider--edge-to-edge {
            .swiper-wrapper {
                .swiper-slide:first-child {
                    margin-left: 12px;
                }

                .swiper-slide:last-child {
                    margin-right: 12px;
                }
            }
        }
    }
</style>

<script>
    // eslint-disable-next-line import/no-unresolved
    import Swiper from 'swiper/bundle';

    export default {
        props: {
            edgeToEdge: {
                type: Boolean,
                default: false,
            },
            savePosition: {
                type: Boolean,
                default: false,
            },
            monitor: {
                type: Boolean,
                default: false,
            },
            spaceBetween: {
                type: Number,
                default: 12,
            },
        },

        mounted() {
            this.createSwiper();

            if (this.monitor) {
                this.monitorTimer = setInterval(this.monitorSlides, 250);
            }
        },

        updated() {
            this.createSwiper();
        },

        unmounted() {
            // Vue calls unmounted before transitions had a chance to complete. Removing swiper too early messes up styling.
            setTimeout(() => {
                if (this.swiper) {
                    this.swiper.destroy();
                }
            }, 1000);

            if (this.monitorTimer) {
                clearInterval(this.monitorTimer);
            }
        },

        methods: {
            createSwiper() {
                // eslint-disable-next-line
                let lastTranslate = null;

                if (this.swiper) {
                    // eslint-disable-next-line
                    lastTranslate = this.swiper.translate;

                    this.swiper.destroy();
                }

                const options = {
                    slidesPerView: 'auto',

                    centeredSlides: false,
                    centerInsufficientSlides: true,

                    // Seems to be standard for all breakpoints.
                    spaceBetween: this.spaceBetween,

                    freeMode: {
                        momentumRatio: 0.5,
                    },

                    loop: false,

                    preventClicks: true,

                    threshold: 10,
                };

                this.swiper = new Swiper(this.$refs.swiper, options);

                if (lastTranslate && this.savePosition) {
                    this.$nextTick(() => {
                        this.swiper.setTranslate(lastTranslate);
                        this.swiper.update();
                    });
                }
            },

            monitorSlides() {
                let needsUpdate = false;

                Array.from(this.$el.querySelectorAll('.swiper-slide')).forEach((el) => {
                    const current = el.offsetWidth.toString();

                    if (el.dataset.width !== current) {
                        needsUpdate = true;
                        el.dataset.width = current;
                    }
                });

                if (needsUpdate) {
                    this.createSwiper();
                }
            },
        },
    };
</script>
