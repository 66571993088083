<!-- eslint-disable max-len -->
<template>
    <div class="cz3__edit-image">
        <template v-if="waitingOnPicsart">
            <transition name="cz3__fade">
                <div class="cz3__edit-image__loading">
                    <div class="cz3__edit-image__loading-message">
                        {{$t('PICSART_LOADING')}}
                    </div>
                    <easy-spinner :type="'circular'" :color="'#181818e6'" :size="'40'" />
                    <transition name="cz3__fade">
                        <button v-if="showPicsartCancelButton" id="picsart-cancel" class="cz3__outline-button cz3__outline-button--responsive cz3__loading-button" @click.prevent="abortPicsartRequest">{{ $t('PICSART_CANCEL') }}</button>
                    </transition>
                </div>
            </transition>
        </template>

        <div class="cz3__edit-image__header">
            <div class="cz3__type--title cz3__type--center">
                {{$isStickers ? $t('ART_STICKER_EDIT_IMAGE') : $t('ART_EDIT_IMAGE')}}
            </div>
        </div>

        <div class="cz3__edit-image__body">
            <div ref="preview" class="cz3__edit-image__preview">
                <div v-if="imageWidth && imageHeight"
                    class="cz3__edit-image__background"
                    :style="{
                        width: `${imageWidth * previewScale}px`,
                        // height: `${imageHeight * previewScale}px`,
                    }"
                >
                </div>
                <transition name="cz3__fade">
                    <img v-if="!picsartPreview && imageWidth && imageHeight"
                        class="cz3__edit-image__image"
                        :src="imageLoaded"
                    >
                    <img v-else-if="picsartPreview !== null" class="cz3__edit-image__image" :src="picsartPreview">

                </transition>
            </div>
        </div>

        <div v-if="!bypassPicsart" class="cz3__edit-image__pixart">
            <img src="../assets/picsart.png" width="113" />
        </div>

        <template v-if="!previewingEffects && !bypassPicsart">
            <transition name="cz3__fade">
                <slider-simple class="cz3__edit-image__actions">
                    <button id="picsart-removebg" class="cz3__action-tile cz3__action-tile--flex swiper-slide"
                        @click.prevent="setEffectPreview('removal')"
                        :disabled="picsartUpload == null"
                    >
                        <span class="cz3__action-tile__picsart-icon">
                            <icon-picsart-bg />
                        </span>
                        <span class="cz3__action-tile__name">{{$t('PICSART_REMOVE_BG')}}</span>
                    </button>
                    <template v-if="!isStickers">
                        <button id="picsart-effects" class="cz3__action-tile cz3__action-tile--flex swiper-slide"
                            @click.prevent="setEffectPreview('filter')"
                            :disabled="picsartUpload == null"
                        >
                            <span class="cz3__action-tile__picsart-icon">
                                <icon-picsart-fx />
                            </span>
                            <span class="cz3__action-tile__name">{{$t('PICSART_EFFECTS')}}</span>
                        </button>
                        <button id="picsart-textures" class="cz3__action-tile cz3__action-tile--flex swiper-slide"
                            @click.prevent="setEffectPreview('texture')"
                            :disabled="picsartUpload == null"
                        >
                            <span class="cz3__action-tile__picsart-icon">
                                <icon-picsart-texture />
                            </span>
                            <span class="cz3__action-tile__name">{{$t('PICSART_TEXTURES')}}</span>
                        </button>
                        <!--
                            ***
                            * Hiding Style Transfer button
                            ***
                        <button id="picsart-transfer" class="cz3__action-tile cz3__action-tile--flex swiper-slide" @click.prevent="setEffectPreview('transfer')">
                            <span class="cz3__action-tile__picsart-icon">
                                <icon-picsart-style />
                            </span>
                            <span class="cz3__action-tile__name">{{$t('PICSART_TRANSFERS')}}</span>
                        </button>
                        -->
                    </template>
                </slider-simple>
            </transition>

            <div class="cz3__edit-image__prompt">
                <div class="cz3__edit-image__prompt-buttons">
                    <button
                        id="back"
                        class="cz3__grey-rounded-no-shadow-button"
                        @click.prevent="back"
                    >
                        {{$t('BACK')}}
                    </button>

                    <button
                        id="select"
                        class="cz3__outline-button cz3__outline-button--responsive"
                        @click.prevent="select"
                    >
                        {{isStickers ? $t('ART_STICKER_ADD_THIS') : $t('ART_ADD_THIS')}}
                    </button>
                </div>
            </div>
        </template>

        <template v-else>
            <template v-if="effectStyle === 'filter' && !!picsartEffectPreviews">
                <transition name="cz3__fade">
                    <slider-simple class="cz3__edit-image__actions" :save-position="true">
                        <button
                            v-for="preview in picsartEffectPreviews"
                            :key="preview.effect_name"
                            :id="`picsart-${preview.effect_name}`"
                            class="cz3__action-tile cz3__action-tile--no-pad swiper-slide"
                            :class="{'cz3__action-tile--selected': picsartSelection === preview.effect_name}"
                            @click.prevent="selectEffect(preview.effect_name)"
                        >
                            <span class="cz3__action-tile__background"
                                :style="{
                                    backgroundImage: `url(${preview.url})`,
                                }"
                            >
                            </span>

                            <span class="cz3__action-tile__name">
                                {{ cleanLabel(preview.effect_name) }}
                            </span>
                        </button>
                    </slider-simple>
                </transition>
            </template>

            <template v-if="effectStyle === 'texture' && !picsartSelection && !!picsartTextures">
                <transition name="cz3__fade">
                    <slider-simple class="cz3__edit-image__actions" :save-position="true">
                        <button
                            v-for="texture in picsartTextures"
                            :key="texture"
                            :id="`picsart-${texture}`"
                            class="cz3__action-tile swiper-slide"
                            @click.prevent="selectEffect(texture)"
                        >
                            <span class="cz3__action-tile__picsart-icon">
                                <template v-if="texture === 'diamond'">
                                    <icon-picsart-diamond />
                                </template>
                                <template v-if="texture === 'hex'">
                                    <icon-picsart-hex-one />
                                </template>
                                <template v-if="texture === 'hex2'">
                                    <icon-picsart-hex-two />
                                </template>
                                <template v-if="texture === 'mirror'">
                                    <icon-picsart-mirror />
                                </template>
                                <template v-if="texture === 'tile'">
                                    <icon-picsart-tile />
                                </template>
                            </span>
                            <span class="cz3__action-tile__name">
                                {{ capitalize(texture) }}
                            </span>
                        </button>
                    </slider-simple>
                </transition>
            </template>
            <template v-else-if="effectStyle === 'texture'">
                <transition name="cz3__fade">
                    <div class="cz3__picsart-actions">
                        <div class="cz3__picsart-effect__name">{{ picsartSelection }}</div>
                        <div class="cz3__picsart-effect__scale">
                            <label for="scale">Scale</label>
                            <slider v-model="picsartTextureScale" :min="0.1" :max="10" :step="0.1" :tooltips="false" @change="updatePreview" />
                            <span class="cz3__picsart-effect__scale-value">{{ picsartTextureScale * 10 }}</span>
                        </div>
                    </div>
                </transition>
            </template>

            <!--
                ***
                * We are hiding Style Transfer for now
                ***
            <template v-if="effectStyle === 'transfer' && !!picsartStyleTransfers">
                <div class="cz3__testing-box">
                    <label for="transfer-level">Level:
                        <select name="transfer-level" v-model="picsartTransferLevel">
                            <option value="l1">1</option>
                            <option value="l2">2</option>
                            <option value="l3">3</option>
                            <option value="l4">4</option>
                            <option value="l5">5</option>
                        </select>
                    </label>
                </div>
                <transition name="cz3__fade">
                    <slider-simple class="cz3__edit-image__actions" :save-position="true">
                        <button
                            v-for="(style, i) in picsartStyleTransfers"
                            :key="i"
                            :id="`picsart-${style.name}`"
                            class="cz3__action-tile cz3__action-tile--no-pad swiper-slide"
                            :class="{'cz3__action-tile--selected': picsartSelection.name === style.name}"
                            @click.prevent="selectEffect(style)"
                        >
                            <span class="cz3__action-tile__background"
                                :style="{
                                    backgroundImage: `url(${style.url})`,
                                }"
                            >
                            </span>

                            <span class="cz3__action-tile__name">
                                {{ style.name }}
                            </span>
                        </button>
                    </slider-simple>
                </transition>
            </template>
            -->

            <div class="cz3__edit-image__prompt">
                <div class="cz3__edit-image__prompt-buttons">
                    <button
                        id="back"
                        class="cz3__grey-rounded-no-shadow-button"
                        @click.prevent="undoPicsart"
                    >
                        {{$t('BACK')}}
                    </button>

                    <button
                        id="select"
                        class="cz3__outline-button cz3__outline-button--responsive"
                        @click.prevent="selectPicsart"
                    >
                        {{isStickers ? $t('ART_STICKER_ADD_THIS') : $t('ART_ADD_THIS')}}
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__edit-image {
            position: absolute;

            left: 20px;
            top: 20px;
            right: 20px;
            bottom: 20px;

            display: flex;

            flex-direction: column;

            @include respond-above(sm) {
                left: 32px;
                top: 32px;
                right: 32px;
                bottom: 32px;
            }
        }

        .cz3__edit-image__header {
            flex: 0 0 auto;
        }

        .cz3__edit-image__body {
            position: relative;

            flex: 1 1 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: start;

            margin-top: 20px;

            overflow: auto;
            --webkit-overflow-scrolling: touch;
        }

        .cz3__edit-image__preview {
            @include absolute-overlay();

            display: flex;

            justify-content: center;
            align-content: center;
            align-items: center;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .cz3__edit-image__pixart {
            flex: 0 0 auto;

            display: flex;

            justify-content: center;
            align-items: center;

            height: 50px;
        }

        .cz3__edit-image__actions {
            height: 100px;

            .cz3__action-tile.swiper-slide {
                &.swiper-slide-active {
                    border-color: $color-gray-mid;
                }

                &.cz3__action-tile--selected {
                    border-color: $color-gray-dark;
                }
            }
        }

        .cz3__edit-image__prompt {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            flex: 0 0 auto;

            > * {
                flex: 1 1 0;
            }
        }

        .cz3__edit-image__prompt-buttons {
            flex: 0 0 auto;

            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                flex: 0 0 auto;
            }
        }

        .cz3__edit-image__image {
            object-fit: contain;

            position: absolute;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;
        }

        .cz3__edit-image__background {
            height: 100%;

            background: transparent url(../assets/checkers.png) repeat;
        }

        .cz3__add-collections__collections--picsart {
            width: 100%;
            margin: auto;
            flex-wrap: nowrap;

            overflow-x: scroll;
        }

        .cz3__action-tile.swiper-slide {
            position: relative;

            width: 64px;
            height: 64px;
            // padding: 6px;
            border: 1px solid $color-gray-mid;
            // border-radius: 12px;
            // comp values
            padding: 8px 4px 6px;
            border-radius: 8px;

            font-size: 11px;

            background-color: transparent;
            color: $color-primary-black;

            &.cz3__action-tile--no-pad {
                padding: 0;
            }

            &.cz3__action-tile--flex {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &.swiper-slide-active {
                border-color: $color-gray-dark;
            }

            .cz3__action-tile__background {
                display: block;
                width: 100%;
                // height: 100%;
                height: 0;
                padding-top: 100%;
                // the nested border radius of a child element
                // should be the border radius of the parent (8px)
                // minus the distance between them (1px border)
                border-radius: 7px;

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                position: relative;
            }

            .cz3__action-tile__picsart-icon {
                display: block;
                width: 24px;
                height: 24px;
                margin-left: auto;
                margin-right: auto;
            }

            .cz3__action-tile__name {
                display: block;
                width: 100%;
                margin-top: 4px;

                font-family: $font-main;
                // font-size: 12px;
                // line-height: 16px;
                // actual values from comps...
                font-size: 9px;
                line-height: 12px;

                // for some reason, the effect labels
                // aren't appearing in Safari but
                // setting this property fixes that.
                transform: translateZ(0);
            }
        }

        .cz3__picsart-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }

        .cz3__picsart-effect__scale {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            gap: 10px;
            width: 100%;

            font-size: 12px;

            label {
                display: inline;
            }

            input[type="range"] {
                flex-grow: 2;

                cursor: pointer;
            }

            .slider-target.slider-horizontal {
                width: 100%;
                flex-grow: 2;
            }
        }

        .cz3__picsart-effect__name {
            margin-bottom: 8px;

            color: inherit;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
        }

        .cz3__edit-image__loading {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1000;

            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background: $color-primary-white-background;

            transform: translate(-50%, -50%);
        }

        .cz3__loading-button {
            // position: absolute;
            top: 6px;
            left: 6px;

            margin-top: 20px;

            background: transparent;
        }

        .cz3__edit-image__loading-message {
            margin-bottom: 20px;
        }

        .slider-handle {
            // fixes misalignment of circle on slide bar
            margin-top: 1px;
        }

        .cz3__testing-box {
            display: none;

            position: absolute;
            bottom: 5px;
            left: 45%;

            background: white;

            transform: translateX(-50%);
        }
    }

    // overrides for slider
    :root {
        --slider-bg: #181818;
        --slider-connect-bg: #181818;
        --slider-height: 2px;
        --slider-handle-width: 11px;
        --slider-handle-height: 11px;
        --slider-handle-bg: #181818;
        --slider-handle-ring-width: 0;
    }
</style>

<style src="../../node_modules/@vueform/slider/themes/default.css"></style>

<script>
    import Slider from '@vueform/slider';

    import axios from 'axios';

    import SliderSimple from './SliderSimple.vue';

    import IconPicsartBg from '../assets/picsart__background_remove.svg';
    import IconPicsartFx from '../assets/picsart__effects.svg';
    // import IconPicsartStyle from '../assets/picsart__style_transfer.svg';
    import IconPicsartTexture from '../assets/picsart__texture.svg';
    import IconPicsartDiamond from '../assets/picsart__diamond.svg';
    import IconPicsartHexOne from '../assets/picsart__hex_1.svg';
    import IconPicsartHexTwo from '../assets/picsart__hex_2.svg';
    import IconPicsartMirror from '../assets/picsart__mirror.svg';
    import IconPicsartTile from '../assets/picsart__tile.svg';

    import allStores from '../stores/all';

    export default {
        mixins: [allStores],

        props: {
            startImage: Object,
            type: {
                type: String,
                default: 'images',
            },
        },

        components: {
            Slider,
            SliderSimple,
            IconPicsartBg,
            IconPicsartFx,
            // IconPicsartStyle,
            IconPicsartTexture,
            IconPicsartDiamond,
            IconPicsartHexOne,
            IconPicsartHexTwo,
            IconPicsartMirror,
            IconPicsartTile,
        },

        data() {
            const currentImage = { ...this.startImage };

            return {
                currentImage,

                imageWidth: null,
                imageHeight: null,
                imageLoaded: null,

                previewScale: null,

                effectsApplied: null,

                availableEffects: null,
                previewingEffects: false,
                effectStyle: null,
                picsartSelection: '',
                picsartTextures: ['diamond', 'hex', 'hex2', 'mirror', 'tile'],
                picsartTextureScale: 5,
                showPicsartSlider: false,
                picsartPreview: null,
                picsartUpload: null,
                picsartEffectPreviews: null,
                waitingOnPicsart: false,
                bypassPicsart: false,
                // picsartStyleTransfers: [
                //     { name: 'AirBrush', url: 'https://drivecdn.blob.core.windows.net/drive/test/AirBrush-test.jpg' },
                //     { name: 'Geopress', url: 'https://drivecdn.blob.core.windows.net/drive/test/Geopress-test.jpg' },
                //     { name: 'Pencils', url: 'https://drivecdn.blob.core.windows.net/drive/test/Pencils-test.jpg' },
                //     { name: 'Splatter', url: 'https://drivecdn.blob.core.windows.net/drive/test/Splatter-test.jpg' },
                //     { name: 'Vibro', url: 'https://drivecdn.blob.core.windows.net/drive/test/Vibro-test.jpeg' },
                //     { name: 'WaterColor', url: 'https://drivecdn.blob.core.windows.net/drive/test/WaterColor-test.jpg' },
                // ],
                picsartController: new AbortController(),
                showPicsartCancelButton: false,
                picsartTransferLevel: 'l1',
            };
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            isStickers() {
                return this.type === 'stickers';
            },

            activeImageUrl() {
                if (this.effectsApplied) {
                    return this.effectsApplied;
                }
                return this.currentImage?.jpgPreviewImageUrl || this.currentImage.image;
            },

            picsartEffects() {
                return this.availableEffects
                    .map(({ name }) => name)
                    .filter((effect) => effect.endsWith('1'))
                    .slice(0, 10);
            },

            picsartBlocker() {
                const possibleValues = ['y', 'yes', 'true'];
                const picsartAttribute = this.viewController.blueprint?.custom?.['ps-block-picsart'];

                return picsartAttribute && possibleValues.includes(picsartAttribute.toLowerCase());
            },
        },

        watch: {
            activeImageUrl() {
                this.loadImage();
            },
        },

        mounted() {
            this.loadImage();

            if (!this.bypassPicsart && !this.picsartBlocker) {
                this.getEffects();
            }

            this.uploadToPicsart();

            window.addEventListener('resize', this.resize);
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.resize);
        },

        methods: {
            back() {
                this.$emit('back');
            },

            resize() {
                if (this.imageWidth && this.imageHeight) {
                    if (this.$refs.preview) {
                        const w = this.$refs.preview.offsetWidth;
                        const h = this.$refs.preview.offsetHeight;

                        const fx = w / this.imageWidth;
                        const fy = h / this.imageHeight;

                        const f = Math.min(fx, fy);

                        this.previewScale = f;
                    }
                }
            },

            select() {
                if (this.effectsApplied && !this.bypassPicsart) {
                    // Reupload to Customizer.
                    let endpoint = '//api.images.drivecommerce.com/api/v1/';

                    if (this.viewController.blueprint.imageRootUrl) {
                        endpoint = this.viewController.blueprint.imageRootUrl.replace('http://', '//').replace('https://', '//');
                    }

                    endpoint = window.location.protocol + endpoint;

                    endpoint += endpoint.substr(-1) === '/' ? 'upload' : '/upload';

                    const formData = new FormData();

                    let image = this.effectsApplied;
                    if (image.startsWith('//')) {
                        image = `https:${image}`;
                    }

                    formData.append('copy-from', image);

                    axios({
                        method: 'post',
                        url: endpoint,
                        data: formData,
                        config: {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        },
                    }).then((response) => {
                        const { data } = response;

                        this.customizerStore.addOwnImage(data);

                        this.$emit('select', data);
                    });
                } else {
                    this.customizerStore.addOwnImage(this.currentImage);

                    this.$emit('select', this.currentImage);
                }
            },

            selectPicsart() {
                if (this.picsartPreview) {
                    this.effectsApplied = this.picsartPreview;
                }

                this.select();
            },

            fixUrl(u) {
                return u.startsWith('//') ? `https:${u}` : u;
            },

            loadImage() {
                this.loader = new Image();
                this.loader.src = this.activeImageUrl;

                this.loader.onload = () => {
                    this.imageWidth = this.loader.width;
                    this.imageHeight = this.loader.height;
                    this.imageLoaded = this.loader.src;

                    this.resize();
                };
            },

            getEffects() {
                this.appStore.getPicsart({
                    route: 'effects',
                }).then((response) => {
                    if (response.status && response.status === 'success') {
                        this.availableEffects = response.data;
                    }
                });
            },

            async getEffectPreviews() {
                if (this.picsartEffectPreviews !== null) {
                    return;
                }

                // clear previous cancel button status
                this.showPicsartCancelButton = false;

                // set loading state
                this.waitingOnPicsart = true;
                const beforeTime = new Date();

                this.wait(3000).then(() => {
                    this.showPicsartCancelButton = true;
                });

                // eslint-disable-next-line
                console.log('initiating picsart request');

                const picsartPromises = [];
                const picsartResults = [];
                const effectsList = [
                    this.picsartEffects,
                    ['noise', 'sketcher2', 'pixelize'],
                ];

                effectsList.forEach((effects) => {
                    const filterOptions = {
                        route: 'effects/previews',
                        data: {
                            effect_names: effects,
                            preview_size: 240,
                        },
                    };

                    if (this.picsartUpload) {
                        filterOptions.data.image_id = this.picsartUpload.id;
                    } else {
                        filterOptions.data.image_url = this.fixUrl(this.currentImage.imageUrl);
                    }

                    picsartPromises.push(this.appStore.postPicsart(filterOptions));
                });

                const picsartResponses = await Promise.all(picsartPromises);

                picsartResponses.forEach((response) => {
                    const { status, data } = response;

                    if (status === 'success') {
                        picsartResults.push(data);
                    }
                });

                if (picsartResults.length) {
                    this.picsartEffectPreviews = this.sortByProp(picsartResults.flat(), 'effect_name');
                }

                const afterTime = new Date();
                // eslint-disable-next-line
                console.log(`concluding picsart requests. time passed:  ${afterTime - beforeTime}ms`);
                // reset loading state
                this.waitingOnPicsart = false;
                this.showPicsartCancelButton = false;
            },

            selectEffect(effect) {
                this.picsartSelection = effect;

                if (this.effectStyle) {
                    if (this.effectStyle === 'texture') {
                        // texture / content generation
                        const textureOptions = {
                            route: 'background/texture',
                            data: {
                                scale: this.picsartTextureScale,
                                pattern: this.picsartSelection,
                                width: 2000,
                                height: 2000,
                            },
                        };

                        if (this.picsartUpload && this.picsartUpload.id) {
                            textureOptions.data.image_id = this.picsartUpload.id;
                        } else {
                            textureOptions.data.image_url = this.fixUrl(this.currentImage.imageUrl);
                        }

                        this.appStore.trackAnalyticsEvent({
                            action: 'cyo_picsart',
                            label: `picsart_texture_${this.picsartSelection}`,
                        });

                        this.sendToPicsart(textureOptions);
                        this.showPicsartSlider = true;
                    } else if (this.effectStyle === 'transfer') {
                        // style transfer
                        const transferOptions = {
                            route: 'styletransfer',
                            data: {
                                image_id: this.picsartUpload.id,
                                level: this.picsartTransferLevel,
                                reference_image_url: effect.url,
                            },
                        };

                        // TODO: if we ever re-enable picsart style transers,
                        // we need to make sure they get tracked too

                        this.sendToPicsart(transferOptions);
                    } else {
                        // filter effects
                        const filterOptions = {
                            route: 'effects',
                            data: {
                                image_id: this.picsartUpload.id,
                                effect_name: this.picsartSelection,
                            },
                        };

                        this.appStore.trackAnalyticsEvent({
                            action: 'cyo_picsart',
                            label: `picsart_filter_${this.picsartSelection}`,
                        });

                        this.sendToPicsart(filterOptions);
                    }
                }
            },

            setEffectPreview(effectType) {
                if (effectType === 'removal') {
                    this.appStore.trackAnalyticsEvent({
                        action: 'cyo_picsart',
                        label: 'picsart_bgremoval',
                    });

                    this.removeBackground();
                }

                if (effectType === 'filter') {
                    this.getEffectPreviews();
                }

                this.effectStyle = effectType;
                this.previewingEffects = true;
            },

            sendToPicsart(options) {
                // clear previous cancel button status
                this.showPicsartCancelButton = false;

                // set loading state
                this.waitingOnPicsart = true;

                this.wait(3000).then(() => {
                    this.showPicsartCancelButton = true;
                });

                const picsartAbortSignal = this.picsartController.signal;

                options.signal = picsartAbortSignal;

                const beforeTime = new Date();
                // eslint-disable-next-line
                console.log('initiating picsart request');

                this.appStore.postPicsart(options)
                    .then((response) => {
                        if (response.status === 'success') {
                            if (response.data) {
                                this.picsartPreview = response.data.url;
                            }
                        } else if (response.data) {
                            this.picsartPreview = response.data.url;
                        } else if (response.url) {
                            // this is a bad idea but using it temporarily
                            // because calls to picsart/styletransfer
                            // don't return the same format as other calls
                            this.picsartPreview = response.url;
                        } else {
                            // eslint-disable-next-line
                            console.log('something didn\'t happen as expected...');
                            // eslint-disable-next-line
                            console.log(response);
                        }

                        const afterTime = new Date();
                        // eslint-disable-next-line
                        console.log(`concluding picsart request. time passed:  ${afterTime - beforeTime}ms`);
                        // reset loading state
                        // eslint-disable-next-line func-names
                        this.$nextTick(() => {
                            this.waitingOnPicsart = false;
                            this.showPicsartCancelButton = false;
                        });
                    })
                    .catch((err) => {
                        // eslint-disable-next-line
                        console.log(err);
                    });
            },

            updatePreview() {
                if (this.effectStyle === 'texture') {
                    // eslint-disable-next-line func-names
                    this.$nextTick(function () {
                        const textureOptions = {
                            route: 'background/texture',
                            data: {
                                image_id: this.picsartUpload.id,
                                scale: this.picsartTextureScale,
                                pattern: this.picsartSelection,
                                width: 2000,
                                height: 2000,
                            },
                        };
                        this.sendToPicsart(textureOptions);
                    });
                }
            },

            undoPicsart() {
                // check for specific effect first
                if (this.picsartSelection) {
                    this.picsartSelection = '';
                    this.picsartPreview = null;
                    // reset scale slider to default value
                    this.picsartTextureScale = 5;

                    if (this.effectStyle !== 'texture') {
                        this.effectStyle = null;
                        this.previewingEffects = false;
                    }
                } else if (this.effectStyle) {
                    // then check to see if an effect style is set
                    this.effectStyle = null;
                    // and clear all preview options
                    this.picsartPreview = null;
                    this.previewingEffects = false;
                }
            },

            uploadToPicsart() {
                const image = this.currentImage.imageUrl ?? this.currentImage.image;

                // eslint-disable-next-line
                console.log('uploading to picsart');

                this.waitingOnPicsart = true;

                // check for picsart bypass via custom attribute
                if (this.picsartBlocker) {
                    this.bypassPicsart = true;
                    this.waitingOnPicsart = false;
                    this.select();
                    return;
                }

                const beforeTimer = new Date();
                this.appStore.postPicsart({
                    route: 'upload',
                    data: {
                        image_url: this.fixUrl(image),
                    },
                }).then((response) => {
                    if (response.status === 'success') {
                        if (response.data) {
                            this.picsartUpload = response.data;
                            const afterTimer = new Date();
                            this.waitingOnPicsart = false;

                            // eslint-disable-next-line
                            console.log(`uploaded in ${afterTimer - beforeTimer}ms`);
                        }
                    }
                }).catch((err) => {
                    // eslint-disable-next-line
                    console.error(err);
                    // if there is an error uploading the image to picsart,
                    // skip showing picsart options and instead apply the image
                    // directly to the current product.
                    this.bypassPicsart = true;
                    this.waitingOnPicsart = false;
                    this.select();
                });
            },

            removeBackground() {
                const removalOptions = {
                    route: 'removebg',
                    data: {
                        image_id: this.picsartUpload.id,
                    },
                };

                this.sendToPicsart(removalOptions);
            },

            cleanLabel(str) {
                const textWithoutNumbers = str.replace(/\d/g, '');
                return this.capitalize(textWithoutNumbers);
            },

            capitalize(text) {
                return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
            },

            sortByProp(arr, prop) {
                return arr.sort((a, b) => {
                    if (a[prop] < b[prop]) {
                        return -1;
                    }

                    if (a[prop] > b[prop]) {
                        return 1;
                    }

                    return 0;
                });
            },

            abortPicsartRequest() {
                this.picsartController.abort();
                this.picsartController = new AbortController();
                this.waitingOnPicsart = false;
                this.showPicsartCancelButton = false;
            },

            wait(time = 0) {
                // eslint-disable-next-line no-promise-executor-return
                return new Promise((resolve) => setTimeout(resolve, time));
            },
        },
    };
</script>
