import { defineStore, acceptHMRUpdate } from 'pinia';

import axios from 'axios';

function getLogLevel(l) {
    switch (l) {
    case 'debug': return 0;
    case 'info': return 1;
    case 'warning': return 2;
    case 'error': return 3;
    default:
        return 0;
    }
}

// Track events that will be tracked once.
// false - hasn't been tracked yet.
const trackOnceEvent = {
    cyo_edit_moved: false,
    cyo_edit_deleted: false,
    cyo_3D_rotate: false,
};

// eslint-disable-next-line import/prefer-default-export
export const useAppStore = defineStore('app', {
    id: 'app',

    state: () => ({
        logLevel: 'error',

        confirmationPopup: null,

        small: false,

        // A list of seen tooltips. Seen tooltips won't be shown again.
        seenTooltips: new Set(),

        // Currently requested tooltips.
        requestedTooltips: new Set(),

        // Currently visible tooltip.
        nowShowingTooltip: null,

        // Widget configuration options.
        options: {},

        // Set global busy flag.
        globalBusy: false,

        // Loaded fonts.
        loadedFonts: {},

        // Color picker selected callback.
        colorPickerSelected: null,

        // Color picker target.
        colorPickerTarget: null,

        // Starting color for color picker.
        colorPickerStartColor: null,

        // Was upload disclaimer shown already.
        uploadDisclaimerShown: false,

        // Show/hide the AI help modal.
        showAiHelpModal: false,
    }),

    getters: {
        environmentIsLocal() {
            const { hostname } = window.location;

            return /localhost|drive|drrv/.test(hostname);
        },
    },

    actions: {
        /**
         * Save options.
         */
        setOptions(options) {
            this.options = options;
        },

        /**
         * Log an event.
         */
        log({
            level,
            message,
            style,
        }) {
            const allowLevel = getLogLevel(this.logLevel);
            const thisLevel = getLogLevel(level);

            if (thisLevel >= allowLevel) {
                if (style) {
                    // eslint-disable-next-line
                    console.log(`%c${level}: ${message}`, style);
                } else {
                    // eslint-disable-next-line
                    console.log(`${level}: ${message}`);
                }
            }
        },

        /**
         * Shows confirmation popup.
         */
        confirmPopup(data) {
            this.confirmationPopup = data;
        },

        /**
         * Sets "small" state.
         */
        setSmall(v) {
            this.small = v;
        },

        /**
         * Marks a tooltip as seen.
         */
        markTooltipSeen(code) {
            this.seenTooltips.add(code);

            this.nowShowingTooltip = code;

            this.startTooltipHideTimer();
        },

        startTooltipHideTimer() {
            if (this.hideTooltipsTimer) {
                clearTimeout(this.hideTooltipsTimer);
            }

            this.hideTooltipsTimer = setTimeout(() => {
                this.hideTooltips();
            }, 5000);
        },

        requestTooltip(v) {
            this.requestedTooltips.add(v);
        },

        requestTooltipFirst(v) {
            const all = Array.from(this.requestedTooltips);

            this.requestedTooltips.clear();
            this.requestedTooltips.add(v);

            for (const a of all) {
                this.requestedTooltips.add(a);
            }
        },

        isCurrentTooltip(code) {
            return this.nowShowingTooltip === code;
        },

        hideTooltips() {
            if (this.hideGoingOutTimer) {
                return;
            }

            this.nowShowingTooltip = 'going-out';

            this.hideGoingOutTimer = setTimeout(() => {
                this.nowShowingTooltip = null;
                this.hideGoingOutTimer = null;
            }, 2500);
        },

        leftTooltips() {
            return [...this.requestedTooltips].filter((t) => !this.seenTooltips.has(t));
        },

        dismissTooltips() {
            if (this.dismissTooltipsTimer) {
                clearTimeout(this.dismissTooltipsTimer);
            }

            this.dismissTooltipsTimer = setTimeout(() => {
                this.hideTooltips();
            }, 50);
        },

        canShowTooltip(code) {
            // If there are multiple requested tooltips on this screen...
            if (this.requestedTooltips.size > 0) {
                // If showing a tooltip, block other tooltips.
                if (this.nowShowingTooltip) {
                    // ... unless this is a current one.
                    return this.nowShowingTooltip === code;
                }

                // If nothing showing, figure out what to show next.

                // If we already shown this one, bail.
                if (this.seenTooltips.has(code)) {
                    return false;
                }

                // Which codes are left.
                const left = this.leftTooltips();

                // If this is a joint tooltip, prefer some other one first.
                if (code.startsWith('JOINT-')) {
                    const hasNonJoint = left.some((t) => !t.startsWith('JOINT-'));
                    if (hasNonJoint) {
                        return false;
                    }
                }

                // Allow this tooltip.
            }

            return true;
        },

        /**
         * Set global busy indicator.
         */
        setGlobalBusy(v) {
            this.globalBusy = v;
        },

        /**
         * Make a POST request to Picsart API.
         */
        postPicsart({ route, data, signal }) {
            return new Promise((resolve, reject) => {
                const form = new FormData();

                Object.entries(data).forEach(([k, v]) => {
                    form.append(k, v);
                });

                let to = 'https://api.customizer.drivecommerce.com/api/v2/';

                if (this.options.endpoint) {
                    to = this.options.endpoint;
                }

                to += `integrations/picsart/request?route=${route}&apikey=${this.options.apiKey}`;

                const axiosOptions = {
                    method: 'POST',
                    url: to,
                    data: form,
                    headers: { 'Content-Type': 'multipart/form-data' },
                };

                if (signal) {
                    axiosOptions.signal = signal;
                }

                axios(axiosOptions).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        /**
         * Make a GET request to Picsart API.
         */
        getPicsart({ route, data }) {
            return new Promise((resolve) => {
                let to = 'https://api.customizer.drivecommerce.com/api/v2/';

                if (this.options.endpoint) {
                    to = this.options.endpoint;
                }

                to += `integrations/picsart/request?route=${route}&apikey=${this.options.apiKey}`;

                to = new URL(to);

                if (data) {
                    Object.entries(data).forEach(([k, v]) => {
                        to.searchParams.set(k, v);
                    });
                }

                axios({
                    method: 'GET',
                    url: to.toString(),
                }).then((response) => {
                    resolve(response.data);
                });
            });
        },

        /**
         * Loads web font files.
         */
        loadFonts(fonts) {
            fonts.forEach((font) => {
                if (!this.loadedFonts[font.code]) {
                    if (font.custom.webfont) {
                        const el = document.createElement('link');

                        el.setAttribute('rel', 'stylesheet');
                        el.setAttribute('type', 'text/css');
                        el.setAttribute('href', font.custom.webfont);

                        document.querySelector('head').appendChild(el);

                        this.loadedFonts[font.code] = true;
                    }
                }
            });
        },

        /**
         * Show color picker.
         */
        pickColor({ selected, near, color }) {
            this.colorPickerSelected = selected;
            this.colorPickerTarget = near;
            this.colorPickerStartColor = color;
        },

        setUploadDisclaimerShown(v) {
            this.uploadDisclaimerShown = v;
        },

        setShowAiHelpModal(v) {
            this.showAiHelpModal = v;
        },

        trackAnalyticsEvent(trackingEvent) {
            // since dataLayer only exists when GTM is implemented,
            // we can mock it up for local dev & testing
            if (!window.dataLayer) {
                window.dataLayer = [];
            }

            if (window.dataLayer) {
                // certain events - currently deleting and moving layers -
                // should only be tracked once. if this is such an event,
                // update state flags so they aren't tracked again.
                if (trackOnceEvent[trackingEvent.action] != null) {
                    if (trackOnceEvent[trackingEvent.action] === false) {
                        trackOnceEvent[trackingEvent.action] = true;

                        if (trackingEvent.event == null) {
                            trackingEvent.event = 'cyo3';
                        }

                        window.dataLayer.push(trackingEvent);
                    }
                } else {
                    if (trackingEvent.event == null) {
                        trackingEvent.event = 'cyo3';
                    }

                    window.dataLayer.push(trackingEvent);
                }
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
