<template>
    <textarea
        class="cz3__ai-prompt__textarea"
        :placeholder="$t('AI_DESCRIBE_EXAMPLE')"
        rows="3"
        ref="textarea"
        @input="handleTextareaInput"
    ></textarea>

    <div v-if="showFilteredWordsPopup" class="cz3__filtered-words-popup">
        <div>
            {{$t('AI_BLOCKED')}}
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        textarea.invalid-text {
            color: rgb(35, 200, 66);
        }

        textarea.filtered-text {
            color: rgb(255, 47, 0);
        }

        .cz3__ai-prompt__textarea::placeholder {
            color: #404040;
        }

        .cz3__filtered-words-popup {
            position: absolute;

            inset: 0;

            pointer-events: none;

            display: flex;
            align-items: center;
            justify-content: center;

            div {
                width: 111px;
                height: 30px;

                background-color: rgba(239, 49, 49, 0.85);

                border: 1px solid white;
                color: white;

                font-family: $font-main;
                font-size: 11pt;

                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 8px;
            }
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    export default {
        mixins: [allStores],

        props: {
            startPrompt: String,

            buttons: Array,
        },

        emits: ['updatePrompt'],

        data() {
            return {
                tokenMapping: {},

                rawInput: '',
                userInput: '',

                tokenGroups: [],

                showFilteredWordsPopup: false,

                // TODO: Send from service.
                bannedWords: [
                    // eslint-disable-next-line max-len
                    'yollw', 'yollwyzgs', 'xifxrurcrlm', 'yollwb', 'uovhs', 'yifrhvh', 'xizhs', 'xlikhv', 'xifxrurvw', 'xfggrmt', 'wvxzkrgzgv', 'rmuvhgvw', 'tifvhlnv', 'proo', 'rmuvxgvw', 'hzwrhg',
                    // eslint-disable-next-line max-len
                    'hozftsgvi', 'gvizglnz', 'gibkslkslyrz', 'dlfmw', 'xilmvmyvit', 'pslimv', 'xzmmryzo', 'xzmmryzorhn', 'erhxvizo', 'tfgh', 'yollwhslg', 'tlib', 'proormt', 'hfitvib', 'ererhvxgrlm', 'nzhhzxiv',
                    // eslint-disable-next-line max-len
                    'svnltolyrm', 'hfrxrwv', 'wift', 'xlxzrmv', 'svilrm', 'nvgs', 'xizxp', 'hkvvwl', 'mzgfizov', 'yziv', 'mfwv', 'irhjf', 'hxzmgrob', 'xovzeztv', 'hgirkkvw', 'fmxolgsvw', 'ormtvirv', 'mzpvw', 'mvtortvv',
                    // eslint-disable-next-line max-len
                    'zsvtzl', 'krmfk', 'yzootzt', 'kozbylb', 'yrnyl', 'kovzhfiv', 'ylwrob', 'ylfwlri', 'ifov34', 'yilgsvo', 'hvwfxrmt', 'wlnrmzgirc', 'hvwfxgrev', 'vilgrx', 'ufxp', 'hvmhfzo', 'sziwxliv', 'hvcb',
                    // eslint-disable-next-line max-len
                    'svmgzr', 'hszt', 'slimb', 'hsryzir', 'rmxvhg', 'hnfg', 'hfxxfyfh', 'qvip', 'gslg', 'prmyzpf', 'gdvip', 'elofkgflfh', 'mzftsgb', 'rmxvhg', 'litb', 'hfogib', 'ccc', 'ylmwztv', 'ywhn', 'hozevtrio',
                    // eslint-disable-next-line max-len
                    'gzyll', 'uzhxrhg', 'mzar', 'nlsznnvw', 'hozev', 'xllm', 'slmpvb', 'ziivhgvw', 'qzro', 'szmwxfuuh', 'ozyrz', 'nznnzirvh', 'yzwlmpvih', 'nrmtv', 'yllyh', 'mrkkov', 'yllgb', 'lkkzr', 'ylhln', 'litzmh',
                    // eslint-disable-next-line max-len
                    'yivzhgh', 'lezirvh', 'yfhgb', 'kvmrh', 'xofmtv', 'kszoofh', 'xilgxs', 'hvc', 'wrxp', 'hprnkb', 'trigs', 'gsrxp', 'slmpvih', 'eztrmz', 'sllgvih', 'evrmb', 'pmly', 'gligfiv', 'wrhgfiyrmt', 'uzig', 'kllk',
                    // eslint-disable-next-line max-len
                    'dzigh', 'hsrg', 'kovzhfiv', 'vivxg', 'yfmtslov', 'elnrg', 'elofkgflfh', 'hvwfxgrev', 'hkvin', 'slg', 'hvcb', 'hvmhlivw', 'xvmhlivw', 'hrovmxvw', 'wvvkuzpv', 'rmzkkilkirzgv', 'dzruf', 'hfxxfyfh',
                    // eslint-disable-next-line max-len
                    'hfitvib', 'yrgxs', 'xfmg', 'qra', 'xfn', 'xlxp', 'tfm', 'krhglo', 'iruov', 'erlovm', 'grgh', 'yoldqly', 'yoldrmt', 'hfxp', 'kfhhb', 'vrorhs', 'gzboli', 'klpvn',
                    // eslint-disable-next-line max-len
                    'dloevirmv', 'sfop', 'nzmwzolirzm', 'hgzidzih', 'i2w2', 'x3kl', 'hjfrhs', 'nzievo', 'yy8', 'wqzirm', 'tvggb', 'hsfggvihg', 'gifnk', 'yrwvm', 'hgirkkvi',
                    // CODE_MODIFIED AN added 10/27/23 following three lines (banned words)
                    // eslint-disable-next-line max-len
                    'wrhmvb', 'qvwr', 'dzih', 'nrxpvb', 'nrmmrv', 'xrmwvivooz', 'ezwvi', 'wzigs', 'hpbdzopvi', 'ovrz', 'xsvdy', 'blwz', 'ylyz', 'pvmlyr', 'kzokzgrmv', 'i2-w2', 'hkrwvinzm',
                    // eslint-disable-next-line max-len
                    'yzgnzm', 'gsli', 'kldvikfu', 'klggvi', 'svinrlm', 'dvzhovb', 'wfnyovwliv', 'elowvnlig', 'hmzkv', 'nzoulb', 'yvoozgirc', 'ofkrm', 'vkxlg', 'pvgmrka', 'krpzxs',
                    '6gs', 'zoozs', 'klkv', 'izyyr', 'dzpzmwz', 'wvzwkllo', 'zevmtvi',
                ],

                decodedBannedWords: [],

                lastTextAreaLength: 0,
            };
        },

        watch: {
            buttons: {
                deep: true,
                handler(newValue, oldValue) {
                    if (oldValue == null) {
                        this.initialize();
                    } else {
                        this.updateTokensFromButtons();
                    }
                },
            },

            startPrompt() {
                this.$nextTick(() => {
                    this.setValue(this.startPrompt);

                    this.handleTextareaInput();
                });
            },
        },

        mounted() {
            this.decodedBannedWords = this.bannedWords.map((word) => this.decodeReverseAlphabet(word));
        },

        methods: {
            initialize() {
                // Ensure that tokens are in <...>
                this.forAllButtons((button) => {
                    if (!button.tokenString.startsWith('<')) {
                        button.tokenString = `<${button.tokenString}>`;
                    }
                });

                this.forAllButtons((button) => {
                    this.tokenMapping[button.tokenString.toUpperCase()] = button;
                });

                // eslint-disable-next-line
                console.log('%cInitialized with buttons:', 'color: #b00b00; font-weight: bold;', this.buttons);
            },

            handleTextareaInput() {
                /*---------------------------------------------------------------*/
                // Updates rawInput, removes unrecognized tokens, updates userInput, tokenGroups,
                // and button status based on current textarea content.
                // 1. Stores user's direct input without recognized tokens in rawInput.
                // 2. Processes modified tokens by removing unrecognized tokens and update userInput.
                // 3. Updates tokenGroups based on current tokens in userInput.
                // 4. Updates button status based on current active tokens.

                const currentTextareaValue = this.$refs.textarea.value;

                let removePartialTokens = true;

                const selectionStart = this.$refs.textarea.selectionStart; // added to avoid cursor jumping when the user types space characters
                const selectionEnd = this.$refs.textarea.selectionEnd; // ditto

                // Update rawInput to store the user's direct input without any recognized tokens
                this.rawInput = currentTextareaValue.split(/<.*?>/).join(' ').replace(/\s+/g, ' ').trim();

                // Process modified tokens: remove unrecognized tokens
                let contentChanged = true; // assume content changed initially

                const maxIterations = 10; // safeguard against infinite loops

                for (let i = 0; contentChanged && i < maxIterations; i += 1) {
                    if (this.$refs.textarea.value.length > this.lastTextAreaLength) {
                        removePartialTokens = false; // portion of text removed: flag to remove tokens that are incomplete due to user delimiter deletion
                    }

                    // this.lastTextAreaLength=this.$refs.textarea.value.length; //set here to verify on the next input whether text was deleted
                    contentChanged = this.processModifiedTokens(removePartialTokens);
                }

                // Update the current value of the textarea after removing unrecognized tokens
                // this.userInput = this.$refs.textarea.value;
                this.userInput = this.rawInput;

                // Remove specific words
                let filteredString = this.$refs.textarea.value;

                this.decodedBannedWords.forEach((word) => {
                    const regex = new RegExp(`\\b${word}\\b`, 'gi');

                    filteredString = filteredString.replace(regex, '');
                });

                const wasFiltered = this.$refs.textarea.value !== filteredString;

                this.setValue(this.removeDuplicateSpaces(filteredString));

                this.$refs.textarea.setSelectionRange(selectionStart, selectionEnd); // Restore cursor position

                this.lastTextAreaLength = this.$refs.textarea.value.length; // set here to verify on the next input whether text was deleted

                // Update button status based on current active tokens
                this.updateButtonStatus();

                if (wasFiltered) {
                    this.flashText(true, 350);

                    this.triggerFilteredWordsPopup();
                }
            },

            /**
             * Get cached regex for a button token.
             */
            getButtonRegex(button) {
                return new RegExp(this.escapeRegExp(button.tokenString), 'gi'); // AN version 44
            },

            /**
             * Recursively iterates through all buttons.
             */
            forAllButtons(callback, root, parent) {
                this.customizerStore.forAllButtons(callback, root ?? this.buttons, parent);
            },

            /**
             * Removes unrecognized tokens and handle tokens with missing
             * '>' or '<'. Return true if content changed, false otherwise.
             * 1. Checks and remove unrecognized tokens.
             * 2. Handles tokens with missing '>' or '<' if removePartials is true.
             */
            processModifiedTokens(removePartials) {
                if (typeof this.$refs.textarea.value !== 'string') {
                    return false;
                }

                const initialValue = this.$refs.textarea.value;

                // Existing code: Removing unrecognized tokens
                const potentialTokens = initialValue.match(/<[^<>]+>/g) || [];

                potentialTokens.forEach((token) => {
                    // If the token is not a recognized one, remove it entirely.
                    if (!this.isRecognizedToken(token)) {
                        this.setValue(this.$refs.textarea.value.replace(token, ''));

                        this.flashText(false, 200);
                    }
                });

                if (removePartials) {
                    let currentStr = this.$refs.textarea.value;

                    const originalStr = this.$refs.textarea.value;

                    this.setValue(this.handleMissingTrailingDelimiter(currentStr));

                    currentStr = this.$refs.textarea.value;

                    this.setValue(this.handleMissingLeadingDelimiter(currentStr));

                    if (originalStr !== this.$refs.textarea.value) {
                        this.flashText(false, 200);
                    }

                    return initialValue !== this.$refs.textarea.value;
                }

                return false;
            },

            /**
             * Added new method called by processModifiedTokens and cleanIncompleteTokens
             * Handles tokens with missing trailing delimiters
             */
            handleMissingTrailingDelimiter(tempStr) {
                let modifiedStr = tempStr;

                let index = modifiedStr.length + 1;

                while (index > 0) {
                    index -= 1;

                    modifiedStr = `${modifiedStr.slice(0, index)}>${modifiedStr.slice(index)}`;

                    let leadingIndex = index - 1;

                    while (leadingIndex >= 0 && modifiedStr[leadingIndex] !== '<') {
                        leadingIndex -= 1;
                    }

                    if (leadingIndex >= 0) {
                        const potentialToken = modifiedStr.slice(leadingIndex, index + 1);

                        if (this.isRecognizedToken(potentialToken)) {
                            // Check if the token is fully formed (has an existing trailing delimiter)
                            if (index < tempStr.length && tempStr[index] === '>') {
                                index = leadingIndex; // Adjust index to skip the fully formed token
                            } else {
                                modifiedStr = modifiedStr.slice(0, leadingIndex) + modifiedStr.slice(index + 1);
                                return modifiedStr;
                            }
                        }
                    }

                    modifiedStr = tempStr; // Reassign modifiedStr to tempStr before new insertion point
                }

                return tempStr;
            },

            /**
             * Added new method called by processModifiedTokens and cleanIncompleteTokens
             * Handles tokens with missing leading delimiters
             */
            handleMissingLeadingDelimiter(tempStr) {
                let modifiedStr = tempStr;

                let index = -1;

                while (index < modifiedStr.length) {
                    index += 1;

                    modifiedStr = `${modifiedStr.slice(0, index)}<${modifiedStr.slice(index)}`;

                    let trailingIndex = index + 1;

                    while (trailingIndex < modifiedStr.length && modifiedStr[trailingIndex] !== '>') {
                        trailingIndex += 1;
                    }

                    if (trailingIndex < modifiedStr.length) {
                        const potentialToken = modifiedStr.slice(index, trailingIndex + 1);

                        if (this.isRecognizedToken(potentialToken)) {
                            // Check if the token is fully formed (has an existing leading delimiter)
                            if (index > 0 && tempStr[index - 1] === '<') {
                                index = trailingIndex; // Adjust index to skip the fully formed token
                            } else {
                                modifiedStr = modifiedStr.slice(0, index) + modifiedStr.slice(trailingIndex + 1);

                                return modifiedStr;
                            }
                        }
                    }

                    modifiedStr = tempStr; // Reassign modifiedStr to tempStr before new insertion point
                }

                return tempStr;
            },

            /**
             * Checks if the given token is a recognized one. Return true if
             * recognized, false otherwise.
             */
            isRecognizedToken(token) {
                const test = token.toUpperCase();

                let found = false;

                this.forAllButtons((b) => {
                    if (b.tokenString.toUpperCase() === test) {
                        found = true;
                    }
                });

                return found;
            },

            /**
             * Updates tokens from active buttons.
             *
             * Adds or removes the given token based on its current status and
             * the type of button (single select or multi-select).
             * 1. Cleans rawInput of any incomplete tokens.
             * 2. Handles single select and multi-select buttons differently.
             */
            updateTokensFromButtons() {
                // Clean rawInput of any incomplete tokens
                this.cleanIncompleteTokens(); // AN REMAINING INCOMPLETES?

                const enabled = [];

                for (const mainButton of this.buttons) {
                    if (!mainButton.multiSelect) {
                        let active = null;

                        // Ensure single select state.
                        this.forAllButtons((button) => {
                            if (button.isActive) {
                                if (active == null) {
                                    active = button;
                                } else if (button.isActive) {
                                    button.isActive = false;
                                }
                            }
                        }, [mainButton], mainButton);
                    }
                }

                this.forAllButtons((button) => {
                    if (button.isActive) {
                        enabled.push({
                            token: button.tokenString,
                            normalized: button.tokenString.toUpperCase(),
                            selected: false,
                        });
                    }
                });

                let changed = false;

                const sourceTokens = this.$refs.textarea.value.split(/(<[^>]*>)/).filter((x) => x.length > 0);

                // Mark used tokens and remove unselected ones.
                const tokens = sourceTokens.filter((token) => {
                    // Not a token.
                    if (!token.startsWith('<')) {
                        return true;
                    }

                    // const test = token.toUpperCase();
                    // // Should it be enabled?
                    // const match = enabled.find((x) => x.normalized.toUpperCase() === test);
                    // if (match) {
                    //     match.selected = true;
                    //     return true;
                    // }

                    // changed = true;
                    return false;
                });

                // Add unused yet tokens.
                for (const selected of enabled) {
                    if (!selected.selected) {
                        tokens.push(` ${selected.token}`);
                        // changed = true;
                    }
                }

                if (sourceTokens.map((x) => x.trim()).join('') !== tokens.map((x) => x.trim()).join('')) {
                    changed = true;
                }

                if (changed) {
                    // Join and insert spaces.
                    const result = tokens.join('');

                    this.setValue(this.removeDuplicateSpaces(result));

                    // Update button status based on current active tokens
                    this.updateButtonStatus();

                    this.lastTextAreaLength = this.$refs.textarea.value.length; // set here to verify on the next input whether text was deleted
                }
            },

            /**
             * Removes incomplete tokens from textarea. Similar to processTokens() - consider carefully consolidating.
             */
            cleanIncompleteTokens() {
                let currentStr = this.$refs.textarea.value;

                const originalStr = this.$refs.textarea.value;

                this.setValue(this.handleMissingTrailingDelimiter(currentStr));

                currentStr = this.$refs.textarea.value;

                this.setValue(this.handleMissingLeadingDelimiter(currentStr));

                if (originalStr !== this.$refs.textarea.value) {
                    this.flashText(false, 200);
                }

                this.setValue(this.removeDuplicateSpaces(this.$refs.textarea.value));

                this.rawInput = this.$refs.textarea.value.split(/<.*?>/).join(' ').replace(/\s+/g, ' ').trim();
            },

            /**
             * Escapes special characters in the given string for RegExp.
             */
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },

            /**
             * Removes consecutive spaces in the given string. Return the resulting string.
             */
            removeDuplicateSpaces(inputString) {
                inputString.trim();

                return inputString.replace(/\s{2,}/g, ' ');
            },

            /**
             * Update the status of each button based on the current active tokens.
             */
            updateButtonStatus() {
                const validated = new Set();

                // Enable main buttons where there is a child selection.
                this.forAllButtons((subButton, mainButton) => {
                    const regex = this.getButtonRegex(subButton);

                    if (regex.test(this.$refs.textarea.value)) {
                        subButton.isActive = true;

                        mainButton.anySubActive = true;

                        validated.add(mainButton);
                    } else if (subButton.isActive) {
                        subButton.isActive = false;
                    }
                });

                // Disable all other main buttons.
                this.forAllButtons((subButton, mainButton) => {
                    if (!validated.has(mainButton)) {
                        if (mainButton.anySubActive) {
                            mainButton.anySubActive = false;
                        }
                    }
                });

                this.updatePrompt();
            },

            /**
             * Update prompt text replacing tokens with prompt strings.
             */
            updatePrompt() {
                let style = null;

                // Mark used tokens and remove unselected ones.
                const source = this.$refs.textarea.value;

                const tokens = source.split(/(<[^>]*>)/).map((token) => {
                    // Not a token.
                    if (!token.startsWith('<')) {
                        return token;
                    }

                    const button = this.tokenMapping[token.toUpperCase()];

                    if (button) {
                        if (button.sendStyle) {
                            style = button.promptString;
                        } else {
                            return button.promptString;
                        }
                    }

                    return '';
                });

                const prompt = this.removeDuplicateSpaces(tokens.join(' ').trim());

                // eslint-disable-next-line
                console.log('%cUse prompt: \'%s\' %cand style \'%s\'', 'color: #666;', prompt, 'color: #666;', style ?? '<none>');

                this.$emit('updatePrompt', {
                    prompt,
                    style,
                    raw: source,
                });
            },

            decodeReverseAlphabet(encodedString) {
                const alphabet = 'abcdefghijklmnopqrstuvwxyz';
                const reverseAlphabet = 'zyxwvutsrqponmlkjihgfedcba';
                const decodedArray = [];

                for (let i = 0; i < encodedString.length; i += 1) {
                    const char = encodedString[i];
                    const isUpperCase = char === char.toUpperCase();
                    const index = reverseAlphabet.indexOf(char.toLowerCase());
                    // eslint-disable-next-line no-nested-ternary
                    decodedArray.push(index !== -1 ? (isUpperCase ? alphabet[index].toUpperCase() : alphabet[index]) : char);
                }

                return decodedArray.join('');
            },

            flashText(redFlag, timeMs) {
                let flashStyle = 'invalid-text';

                if (redFlag === true) {
                    flashStyle = 'filtered-text';
                }

                // Highlight the entire text
                this.$refs.textarea.classList.add(flashStyle);

                setTimeout(() => {
                    this.$refs.textarea.classList.remove(flashStyle);
                }, timeMs);
            },

            triggerFilteredWordsPopup() {
                this.showFilteredWordsPopup = true;

                setTimeout(() => {
                    this.showFilteredWordsPopup = false;
                }, 1000);
            },

            setValue(v) {
                this.$refs.textarea.value = v;
            },
        },
    };
</script>
