<template>
    <div class="cz3__ai-radios">
        <div class="cz3__ai-radios__header">
            <span>{{$t('AI_SELECT')}} {{button.displayName}}</span>

            <button class="cz3__ai-radios__remove-all"
                @click.prevent="removeAll"
            >
                {{$t(button.multiSelect ? 'AI_REMOVE_ALL' : 'AI_REMOVE')}}
            </button>
        </div>

        <div class="cz3__ai-selected-tab">
            <ai-guided-panel-radio
                :buttons="button.selectionButtons"
                :multiselect="button.multiSelect"
            >
            </ai-guided-panel-radio>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-radios {
            margin-top: 16px;

            padding: 18px 12px;

            border-radius: 8px;
            background: $color-panel-background;
        }

        .cz3__ai-radios__header {
            display: flex;
            justify-content: space-between;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 700;
        }

        .cz3__ai-radios__remove-all {
            padding: 0;

            border: none;
            background: transparent;

            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;

            text-decoration: underline;
            text-underline-offset: 2px;
        }

        .cz3__ai-selected-tab {
            margin-top: 16px;
        }
    }
</style>

<script>
    import AiGuidedPanelRadio from './AiGuidedPanelRadio.vue';

    export default {
        components: {
            AiGuidedPanelRadio,
        },

        props: {
            button: Object,
        },

        methods: {
            removeAll() {
                for (const b of this.button.selectionButtons) {
                    b.isActive = false;
                }
            },
        },
    };
</script>
