// Adds debounce functionality to controls.

export default {
    data() {
        return {
            debouncers: {},
        };
    },
    beforeDestroy() {
        Object.values(this.debouncers).forEach((v) => {
            clearTimeout(v);
        });
    },
    methods: {
        debounce(f, name, time) {
            if (this.debouncers[name]) {
                clearTimeout(this.debouncers[name]);
            }

            this.debouncers[name] = setTimeout(f, time);
        },
    },
};
