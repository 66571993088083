import { useAppStore } from '../stores/app';

export default {
    data() {
        const appStore = useAppStore();

        return {
            delayedTooltipsVisible: false,
            seenTooltips: new Set(appStore.seenTooltips),
            justSeenTooltips: new Set(),
        };
    },

    mounted() {
        this.startDelayedTooltipsTimer();

        document.addEventListener('mousedown', this.tooltipsClickAnywhere);
        document.addEventListener('touchstart', this.tooltipsClickAnywhere);
    },

    beforeUnmount() {
        document.removeEventListener('mousedown', this.tooltipsClickAnywhere);
        document.removeEventListener('touchstart', this.tooltipsClickAnywhere);

        this.clearTooltipsTimeout();
    },

    updated() {
        this.checkTooltipsPositions();
    },

    methods: {
        tooltipRequested(code) {
            const appStore = useAppStore();

            appStore.requestTooltip(code);

            return true;
        },

        tooltipRequestedFirst(code) {
            const appStore = useAppStore();

            appStore.requestTooltipFirst(code);

            return true;
        },

        tooltipVisible(code) {
            const appStore = useAppStore();

            if (appStore.isCurrentTooltip(code)) {
                return true;
            }

            if (!appStore.canShowTooltip(code)) {
                return false;
            }

            // Mark as seen globally.
            appStore.markTooltipSeen(code);

            return true;
        },

        updateTooltips() {
        },

        tooltipsClickAnywhere() {
            const appStore = useAppStore();

            appStore.dismissTooltips();
        },

        clearTooltipsTimeout() {
            if (this.delayTooltipsTimer) {
                clearTimeout(this.delayTooltipsTimer);
            }
        },

        startDelayedTooltipsTimer() {
            this.clearTooltipsTimeout();

            this.delayedTooltipsVisible = false;

            this.delayTooltipsTimer = setTimeout(() => {
                this.delayedTooltipsVisible = true;

                this.$nextTick(() => {
                    this.checkTooltipsPositions();
                });
            }, 5000);
        },

        checkTooltipsPositions() {
            let $el = this.$el;

            if ($el == null || $el.querySelectorAll == null) {
                $el = this.$refs.container;
            }

            // Check visibility of tooltips.
            if ($el) {
                const width = window.innerWidth;

                Array.from($el.querySelectorAll('.cz3__button-tooltip')).forEach((tooltip) => {
                    const rect = tooltip.getBoundingClientRect();

                    if (rect.left < 0 && tooltip.classList.contains('cz3__button-tooltip--below-left')) {
                        tooltip.classList.remove('cz3__button-tooltip--below-left');
                        tooltip.classList.add('cz3__button-tooltip--below-right');
                    }

                    if (rect.right > width && tooltip.classList.contains('cz3__button-tooltip--below-right')) {
                        tooltip.classList.remove('cz3__button-tooltip--below-right');
                        tooltip.classList.add('cz3__button-tooltip--below-left');
                    }
                });
            }
        },
    },
};
