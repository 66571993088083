<template>
    <div class="cz3__edit-placement">
        <div class="cz3__type--header cz3__type--center cz3__mb--standard">
            {{title ?? placement.title}}
        </div>

        <div class="cz3__placement-options">
            <button v-for="c in placementOptions"
                :key="c.component.code"
                :id="`option-${c.title}`"
                class="cz3__placement-option"
                :class="{
                    'cz3__placement-option--selected': c.selected,
                }"
                :aria="c.title"
                @click.prevent="selectColor(c)"
            >
                <span class="cz3__placement-option__color"
                    :style="{
                        backgroundColor: c.color,
                    }"
                >
                </span>

                <span v-if="c.plant"
                    class="cz3__placement-option__plant">
                    <icon-plant-based/>
                </span>
            </button>
        </div>

        <div v-if="selected" class="cz3__placement-selected-option">
            <span>{{selected.title}}</span>
        </div>

        <div v-if="hasPlant" class="cz3__placement-plant-description">
            <span class="cz3__placement-plant-description__icon">
                <icon-plant-based />
            </span>
            <span>{{$t('PLANT_BASED')}}</span>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__edit-placement + .cz3__edit-placement {
            margin-top: $margin-standard-small;

            @include respond-above(sm) {
                margin-top: $margin-standard-large;
            }
        }

        .cz3__placement-options {
            display: flex;

            justify-content: center;

            gap: 6px;

            @include respond-above(sm) {
                gap: 12px;
            }
        }

        .cz3__placement-option {
            flex: 0 0 auto;

            position: relative;

            width: 32px;
            height: 32px;

            padding: 0;

            border: 1px solid $color-gray-mid;
            background: transparent;

            border-radius: 32px;

            &--selected {
                border: 1px solid #000;
            }
        }

        .cz3__placement-option__color {
            position: absolute;

            width: 24px;
            height: 24px;

            left: 3px;
            top: 3px;

            border-radius: 32px;
        }

        .cz3__placement-option__plant {
            position: absolute;

            width: 10px;
            height: 13px;

            right: 0;
            top: 0;
        }

        .cz3__placement-selected-option {
            margin-top: 8px;

            text-align: center;

            font-family: $font-main;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            @include respond-above(sm) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .cz3__placement-plant-description {
            margin-top: 8px;

            text-align: center;

            font-family: $font-main;
            font-style: normal;
            font-weight: 400;
            font-size: 9px;
            line-height: 12px;

            @include respond-above(sm) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .cz3__placement-plant-description__icon {
            display: inline-block;

            vertical-align: middle;

            margin-right: 5px;

            width: 10px;
            height: 13px;

            svg {
                width: 10px;
                height: 13px;
            }

            @include respond-above(sm) {
                width: 15px;
                height: 20px;

                svg {
                    width: 15px;
                    height: 20px;
                }
            }
        }
    }
</style>

<script>
    import IconPlantBased from '../assets/plant-based.svg';
    import allStores from '../stores/all';

    export default {
        mixins: [allStores],
        components: {
            IconPlantBased,
        },

        props: {
            placement: {
                type: Object,
            },
            controller: {
                type: Object,
            },
            title: {
                type: String,
                default: null,
            },
        },

        computed: {
            placementOptions() {
                const list = [];

                const selected = this.controller.selectedAtPlacement(this.placement.placementCode);

                this.placement.placement.components.forEach((c) => {
                    if (!this.controller.isAvailable(c)) {
                        return;
                    }

                    if (c.custom?.['ps-special'] === 'true') {
                        return;
                    }

                    const title = this.controller.componentNames(c).join('');

                    list.push({
                        component: c,
                        title,
                        selected: selected?.code === c.code,
                        plant: c?.custom?.['ps-plant'] === 'true',
                        color: this.toHex(c?.custom?.hex),
                    });
                });

                return list;
            },

            selected() {
                return this.placementOptions.find((x) => x.selected);
            },

            hasPlant() {
                return this.placementOptions.some((o) => o.plant);
            },
        },

        methods: {
            toHex(v) {
                if (v == null) {
                    return '#fff';
                }

                return v.startsWith('#') ? v : `#${v}`;
            },

            selectColor(c) {
                this.appStore.trackAnalyticsEvent({
                    action: 'cyo_color',
                    label: `color_${c.title}`,
                });

                this.controller.updateComponent(this.placement.placementCode, c.component.code);

                this.$emit('selected', {
                    placement: this.placement.placementCode,
                    component: c.component.code,
                });
            },
        },
    };
</script>
