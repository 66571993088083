<template>
    <div class="cz3__ai-radios-tab">
        <button v-for="b in buttons"
            :key="b.displayName"
            class="cz3__ai-radio-toggle"
            :class="{
                'cz3__ai-radio-toggle--enabled': b.isActive,
            }"
            @click.prevent="toggle(b)"
        >
            <span v-if="b.isActive"
                class="cz3__ai-radio-toggle__on"
            >
                <!-- eslint-disable -->
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2.85425" width="18" height="18" rx="9" fill="white"/>
                <rect x="2" y="2.85425" width="18" height="18" rx="9" stroke="#01DF9D" stroke-width="4"/>
                <rect x="5" y="5.85425" width="12" height="12" rx="6" fill="black"/>
                </svg>
                <!-- eslint-enable -->
            </span>

            <span v-else
                class="cz3__ai-radio-toggle__off"
            >
                <!-- eslint-disable -->
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="8.85425" r="7.5" stroke="#595959"/>
                </svg>
                <!-- eslint-enable -->
            </span>

            <span>
                {{b.displayName}}
            </span>
        </button>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-radios-tab {
            display: flex;
            flex-wrap: wrap;

            gap: 16px 8px;
        }

        .cz3__ai-radio-toggle {
            display: flex;
            align-items: center;

            padding: 0;

            border: none;
            background: transparent;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 400;

            &.cz3__ai-radio-toggle--enabled {
                font-weight: 700;
            }
        }

        .cz3__ai-radio-toggle__off, .cz3__ai-radio-toggle__on {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 22px;
            height: 22px;

            margin-right: 5px;
        }
    }
</style>

<script>
    export default {
        props: {
            buttons: Array,
            multiselect: Boolean,
        },

        emits: ['beforeSelect'],

        methods: {
            toggle(button) {
                this.$emit('beforeSelect');

                if (!this.multiselect) {
                    for (const b of this.buttons) {
                        if (b !== button) {
                            b.isActive = false;
                        }
                    }
                }

                button.isActive = !button.isActive;
            },
        },
    };
</script>
