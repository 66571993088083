<template>
    <div class="cz3__looks-cases">
        <div class="cz3__looks-cases-header">
            <transition name="cz3__fade">
                <span v-if="useLooks" class="cz3__looks-cases-header__icon">{{$t('LOOKS')}}</span>
            </transition>
        </div>

        <div class="cz3__looks-cases-info">
            {{$t('LOOKS_INFO_CASES')}}

            <button
                class="cz3__icon-button cz3__select-case-info"
                @click.prevent="showInfo">
                <icon-info/>
            </button>
        </div>

        <div class="cz3__looks-cases-options">
            <toggle-select
                :options="configurableLooks"
                label="name"
                track-by="code"
                :model-value="selectedLooks"
                @update:model-value="updateSelectedLooks"
            />
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__looks-cases {
            height: 150px;
        }

        .cz3__looks-cases-header {
            height: 20px;

            text-align: center;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .cz3__looks-cases-header__icon {
            display: inline-block;
            position: relative;
        }

        .cz3__looks-cases-header__icon:before {
            content: '';

            position: absolute;
            right: calc(100% + 5px);
            top: -6px;

            display: inline-block;

            width: 45px;
            height: 15px;

            margin-right: 10px;

            background: transparent url(../assets/looks-arrow@2x.png) no-repeat center/contain;
        }

        .cz3__looks-cases-options {
            margin-top: 15px;

            max-width: 300px;

            margin-left: auto;
            margin-right: auto;
        }

        .cz3__looks-cases-info {
            max-width: 256px;
            margin-left: auto;
            margin-right: auto;

            margin-top: 8px;

            text-align: left;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
</style>

<script>
    import IconInfo from '../assets/info.svg';

    import allStores from '../stores/all';

    import ToggleSelect from './ToggleSelect.vue';

    export default {
        mixins: [allStores],

        components: {
            IconInfo,
            ToggleSelect,
        },

        data() {
            return {
                selectedLooks: {
                    name: this.$t('LOOKS_STANDARD'),
                    code: 'standard',
                },
            };
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            useLooks() {
                return this.customizerStore.useLooks;
            },

            looksInfo() {
                return this.viewController?.blueprint?.custom?.['ps-looks-info-cases-html'];
            },

            configurableLooks() {
                return [
                    {
                        name: this.$t('LOOKS_SWAPPABLE'),
                        code: 'swappable',
                    },
                    {
                        name: this.$t('LOOKS_STANDARD'),
                        code: 'standard',
                    },
                ];
            },
        },

        methods: {
            showInfo() {
                this.appStore.confirmPopup({
                    messageHtml: this.looksInfo,
                    confirmButton: this.$t('GOT_IT'),
                    closeAction: () => {
                        this.appStore.confirmPopup();
                    },
                    confirmAction: () => {
                        this.appStore.confirmPopup();
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();
                    },
                });
            },

            updateSelectedLooks(v) {
                this.selectedLooks = v;

                this.$emit('useLooks', v.code);
            },
        },
    };
</script>
