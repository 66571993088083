<template>
    <div class="cz3__share__progress-ready">
        <div class="cz3__share__progress-ready__body">
            <div class="cz3__share__progress-ready__text cz3__share__progress-ready__text--bottom">
                <div class="cz3__share__progress-ready__quip">
                    {{$t('SHARE_READY')}}
                </div>

                <div class="cz3__share__progress-ready__buttons">
                    <button class="cz3__primary-button cz3__primary-button--share"
                        @click.prevent="share"
                    >
                        <span class="cz3__button-icon-wrapper">
                            <icon-clipboard />
                        </span>
                        {{$t('SHARE_SHARE')}}
                    </button>

                    <button class="cz3__primary-button cz3__primary-button--share"
                        @click.prevent="shareEmail"
                    >
                        <span class="cz3__button-icon-wrapper">
                            <icon-envelope />
                        </span>
                        {{$t('SHARE_EMAIL')}}
                    </button>
                </div>

                <template v-if="aiImage">
                    <div class="cz3__share__progress-ready__ai-image"
                        :style="{
                            backgroundImage: `url(${aiImage}?fmt=jpg&qlt=50)`,
                        }"
                    >
                    </div>

                    <div class="cz3__share__progress-ready__buttons">
                        <button class="cz3__primary-button cz3__primary-button--share"
                            @click.prevent="shareAi"
                        >
                            <span class="cz3__button-icon-wrapper">
                                <icon-clipboard />
                            </span>
                            {{$t('SHARE_AI_IMAGE')}}
                        </button>
                    </div>
                </template>
            </div>

            <button
                @click="closeAndQuit"
                :aria-label="$t('CLOSE')"
                type="button"
                class="cz3__share__progress-ready__close"
            >
                <icon-close />
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__share__progress-ready {
            position: absolute;

            inset: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            background: rgba(255, 255, 255, 0.6);

            z-index: 2000;
        }

        .cz3__share__progress-ready__body {
            position: relative;

            width: 300px;
            min-height: 178px;
            padding: 60px 0 25px 0;

            border-radius: 22px;
            background: #fff;
            box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.05);

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            overflow: hidden;
        }

        .cz3__share__progress-ready__close {
            position: absolute;
            top: 14px;
            right: 14px;

            width: 44px;
            height: 44px;

            border: none;
            background: transparent;

            svg {
                width: calc(18px * 1.208);
                height: calc(18px * 1.208);
            }
        }

        .cz3__share__progress-ready__text {
            padding: 14px 20px 0;
        }

        .cz3__share__progress-ready__quip {
            text-align: center;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        .cz3__share__progress-ready__buttons {
            margin-top: 15px;

            display: flex;
            flex-direction: column;

            align-items: stretch;
            justify-content: flex-start;

            gap: 8px;
        }

        .cz3__primary-button--share {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }

        .cz3__button-icon-wrapper {
            width: 15px;

            display: flex;
            justify-content: flex-end;
        }

        .cz3__share__progress-ready__ai-image {
            margin-top: 15px;

            width: 250px;
            height: 250px;

            border-radius: 16px;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
</style>

<script>
    import IconClose from '../assets/close.svg';
    import IconClipboard from '../assets/share-clipboard.svg';
    import IconEnvelope from '../assets/share-envelope.svg';

    export default {
        props: {
            aiImage: null,
        },

        components: {
            IconClose,
            IconClipboard,
            IconEnvelope,
        },

        emits: ['quit', 'share'],

        data() {
            return {
            };
        },

        mounted() {
            this.start();
        },

        beforeUnmount() {
        },

        methods: {
            start() {
            },

            share() {
                this.$emit('share');
            },

            shareAi() {
                this.$emit('shareAi');
            },

            shareEmail() {
                this.$emit('shareEmail');
            },

            closeAndQuit() {
                this.$emit('quit');
            },
        },
    };
</script>
