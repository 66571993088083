<template>
    <slider-simple class="cz3__options-bundle__slider"
        :class="{
            'cz3__options-bundle__slider--product': productTile,
        }"
        :edge-to-edge="true" :save-position="true">

        <div v-if="productTile" class="swiper-slide cz3__options-bundle__spacer">
        </div>

        <button v-for="bundle in compatibleOptions"
            :key="bundle.product"
            :id="`bundle-${bundle.name.replace(/\s/g, '')}`"
            class="cz3__bundle-option swiper-slide"
            :class="{
                'cz3__bundle-option--selected': ignoreDevice(bundle.product) === ignoreDevice(lastLoadedProduct),
                'cz3__bundle-option--product': productTile,
            }"
            :data-stylecode="bundle.bundleStyleCode"
            @click.prevent="selectBundle(bundle)"
        >
            <span class="cz3__bundle-option__image cz3__action-tile"
            >
                <span class="cz3__bundle-option__image-background"
                    :style="{
                        backgroundImage: `url(${bundle.image})`,
                    }">
                </span>
            </span>

            <span class="cz3__bundle-details">
                <span class="cz3__bundle-name" v-html="bundle.name"></span>
                <span class="cz3__bundle-upcharge">{{bundlePrice(bundle)}}</span>
            </span>
        </button>

        <div v-if="productTile" class="swiper-slide cz3__options-bundle__spacer">
        </div>
    </slider-simple>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options-bundle__slider.cz3__options-bundle__slider--product {
            width: calc(100% + 40px);

            left: 0%;
            transform: none;

            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: 0;

            @include respond-above(sm) {
                width: calc(100% + 39px + 39px);

                margin-left: -39px;
                margin-right: -39px;
            }
        }

        .swiper-slide.cz3__options-bundle__spacer {
            width: 1px;

            @include respond-above(sm) {
                width: 27px;
            }
        }

        .swiper-slide.cz3__bundle-option.cz3__bundle-option--product {
            position: relative;

            width: 100px;
            height: 110px;

            .cz3__bundle-option__image {
                position: absolute;

                left: 0;
                top: 0;

                width: 100%;
                height: 100%;

                padding: 0;
                margin: 0;
            }

            .cz3__action-tile {
                border: none;
            }

            .cz3__bundle-option__image-background {
                position: absolute;

                inset: 0;
            }

            .cz3__bundle-option__image {
                background: transparent;
            }

            &.cz3__bundle-option--selected {
                &:after {
                    content: '';

                    position: absolute;

                    left: -2px;
                    width: calc(100% + 4px);
                    bottom: -2px;
                    aspect-ratio: 1 / 1;

                    border: 2px solid $color-accent-green;

                    border-radius: 9px;

                    z-index: -1;
                }

                .cz3__bundle-option__image {
                    border: none;
                }
            }

            .cz3__bundle-details {
                position: absolute;

                left: 0;
                top: auto;
                bottom: 4px;
            }

            .cz3__bundle-name {
                text-align: center;
                font-family: $font-alt;
                font-size: 14px;
                font-weight: 500;
                line-height: 11px;
            }

            .cz3__bundle-upcharge {
                display: none;
            }
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import SliderSimple from './SliderSimple.vue';

    export default {
        mixins: [allStores],

        components: {
            SliderSimple,
        },

        props: {
            compatibleOptions: Array,
            lastLoadedProduct: String,
            productTile: Boolean,
        },

        computed: {
        },

        methods: {
            selectBundle(bundle) {
                this.$emit('selectBundle', bundle);
            },

            bundlePrice(bundle) {
                const price = this.customizerStore.prices.find((x) => x.sku === bundle.sku);

                // return price?.relativePriceFormatted ?? '';
                return price?.priceFormatted ?? '';
            },

            // Same as in customizer.js store.
            ignoreDevice(v) {
                if (v == null) {
                    return v;
                }

                const tilde = v.indexOf('~');

                if (tilde > 0) {
                    return v.substr(tilde);
                }

                return v;
            },
        },
    };
</script>
