import {
    Vector3,
    Matrix,
    Quaternion,
} from '@babylonjs/core/Maths/math.vector';

import { TransformNode } from '@babylonjs/core/Meshes/transformNode';

import * as ThreeUtilities from './threeutilities';

export default {
    methods: {
        loadJoints(options) {
            this.buildJoints(options);
            this.reparentMeshesToJoints(options);
        },

        reparentMeshesToJoints(options) {
            // Scan through all sockets.
            const sockets = this.controller.socketsState;

            sockets.forEach((s) => {
                const j = s.socket;
                const { component } = s;

                const key = `${s.on.id}->${component.code}->${j.code}->joint`;

                const existing = this.joints.find((x) => x.customJoint === key);

                // Hmmm... if it doesn't exist, something is weird.
                if (existing == null) {
                    return;
                }

                // If nothing is connected, fine...
                if (s.to == null) {
                    return;
                }

                s.to.state.forEach((otherS) => {
                    // Are there any meshes loaded for this state.

                    // Find the parent mesh.
                    const placementKey = this.placementKey(s.to, otherS.placement.code);
                    const placement = this.placements[placementKey];

                    if (placement == null) {
                        return;
                    }

                    let immediate = false;

                    if (options && options.immediate) {
                        immediate = true;
                    }

                    // Reparent to joint.
                    if (placement.offset?.parent?.parent !== existing) {
                        // Reset transforms.
                        // placement.offset.position = Vector3.Zero();
                        // placement.offset.rotationQuaternion = Quaternion.Identity();

                        // Create intermediate transform node to hold the connector side transformation.
                        const connectorJoint = new TransformNode(`${existing.name}->connector`);

                        connectorJoint.parent = existing;
                        placement.offset.parent = connectorJoint;

                        // eslint-disable-next-line no-unused-vars
                        immediate = true;
                    }

                    // Figure out the alignment.

                    // Is there a connector joint with the same code.
                    const { joints } = otherS.component;

                    if (joints && joints.length > 0) {
                        joints.forEach((otherJ) => {
                            if (otherJ.socketType !== 'Connector') {
                                return;
                            }

                            if (otherJ.custom == null
                                || otherJ.custom['three-position'] == null
                                || otherJ.custom['three-rotation'] == null) {
                                return;
                            }

                            if (otherJ.code === j.code) {
                                // const connectorPosition = ThreeUtilities.toVector3(otherJ.custom['three-position']);
                                // const connectorRotation = ThreeUtilities.toQuaternion(otherJ.custom['three-rotation']);

                                let connectorPosition;
                                let connectorRotation;

                                let viewCode = this.view.code;

                                if (options && options.view) {
                                    viewCode = options.view;
                                }

                                const largeViewCode = viewCode.replace(' Small', '');

                                if (otherJ.views && otherJ.views[viewCode] && otherJ.views[viewCode]['three-position']) {
                                    connectorPosition = ThreeUtilities.toVector3(otherJ.views[viewCode]['three-position']);
                                } else if (otherJ.views && otherJ.views[largeViewCode] && otherJ.views[largeViewCode]['three-position']) {
                                    connectorPosition = ThreeUtilities.toVector3(otherJ.views[largeViewCode]['three-position']);
                                } else {
                                    connectorPosition = ThreeUtilities.toVector3(otherJ.custom['three-position']);
                                }

                                if (otherJ.views && otherJ.views[viewCode] && otherJ.views[viewCode]['three-rotation']) {
                                    connectorRotation = ThreeUtilities.toQuaternion(otherJ.views[viewCode]['three-rotation']);
                                } else if (otherJ.views && otherJ.views[largeViewCode] && otherJ.views[largeViewCode]['three-rotation']) {
                                    connectorRotation = ThreeUtilities.toQuaternion(otherJ.views[largeViewCode]['three-rotation']);
                                } else {
                                    // eslint-disable-next-line no-unused-vars
                                    connectorRotation = ThreeUtilities.toQuaternion(otherJ.custom['three-rotation']);
                                }

                                // Part 1: Currently we have:
                                // socket->connector->offset->meshes
                                //
                                // The problem is that in design time joints are parented to the offset node:
                                // offset->joint + offset->meshes
                                //
                                // So we need to inverse the *connector rotation*.

                                if (placement.offset.rotationQuaternion) {
                                    const inverseRotation = Quaternion.Inverse(placement.offset.rotationQuaternion);

                                    connectorRotation = connectorRotation.multiply(inverseRotation);
                                } else {
                                    const r = placement.offset.rotation;

                                    const offsetRotation = Quaternion.RotationYawPitchRoll(r.y, r.x, r.z);

                                    offsetRotation.conjugateInPlace();

                                    connectorRotation = connectorRotation.multiply(offsetRotation);
                                }

                                const x = new Vector3(1, 0, 0);
                                const y = new Vector3(0, 1, 0);
                                const z = new Vector3(0, 0, 1);

                                x.applyRotationQuaternionInPlace(connectorRotation);
                                y.applyRotationQuaternionInPlace(connectorRotation);
                                z.applyRotationQuaternionInPlace(connectorRotation);

                                // Flip x and z, keep direction.
                                x.negateInPlace();
                                // y.negateInPlace();
                                z.negateInPlace();

                                // New rotation matrix.
                                const m = Matrix.Zero();
                                // eslint-disable-next-line
                                Matrix.FromValuesToRef(x._x, x._y, x._z, 0.0, y._x, y._y, y._z, 0.0, z._x, z._y, z._z, 0.0, 0, 0, 0, 1.0, m);

                                const rotation = Quaternion.FromRotationMatrix(m);
                                rotation.normalize();
                                rotation.conjugateInPlace();

                                // eslint-disable-next-line no-unused-vars
                                const offset = connectorPosition.applyRotationQuaternion(rotation);

                                // Should be already parented to the connector joint.
                                const o = placement.offset.parent;

                                // Part 2: Currently we have:
                                // socket->connector->offset->meshes
                                //
                                // The problem is that in design time joints are parented to the offset node:
                                // offset->joint + offset->meshes
                                //
                                // So we need to inverse the *joint offset*...

                                offset.negateInPlace();

                                placement.offset.computeWorldMatrix(true);

                                // eslint-disable-next-line no-underscore-dangle
                                const invLocal = Matrix.Invert(placement.offset._localMatrix);

                                const localOffset = Vector3.TransformCoordinates(offset, invLocal);

                                let animate = false;

                                if (o.customLastConnectorPosition == null) {
                                    o.customLastConnectorPosition = connectorPosition;
                                } else if (!o.customLastConnectorPosition.equalsWithEpsilon(connectorPosition, 0.000001)) {
                                    animate = true;
                                    o.customLastConnectorPosition = connectorPosition;
                                }

                                if (immediate || !animate) {
                                    o.position = localOffset;
                                    o.rotationQuaternion = rotation;
                                } else {
                                    let duration = 30;

                                    if (this.view.custom && this.view.custom['three-view-transition-frames']) {
                                        duration = +this.view.custom['three-view-transition-frames'];
                                    }

                                    this.animateCurrent(
                                        `${key}-connector-position`,
                                        o,
                                        'position',
                                        localOffset,
                                        30,
                                        duration,
                                    );

                                    this.animateCurrent(
                                        `${key}-connector-rotation`,
                                        o,
                                        'rotationQuaternion',
                                        rotation,
                                        30,
                                        duration,
                                    );
                                }

                                // o.position = localOffset;
                                // o.rotationQuaternion = rotation;
                            }
                        });
                    }
                });
            });

            // this.logScene();
        },

        buildJoints(options) {
            if (this.joints == null) {
                this.joints = [];
            }

            const validated = [];

            // Scan through all sockets.
            const sockets = this.controller.socketsState;

            sockets.forEach((s) => {
                const j = s.socket;
                const { component } = s;

                if (j.custom == null
                    || j.custom['three-position'] == null
                    || j.custom['three-rotation'] == null) {
                    return;
                }

                const key = `${s.on.id}->${component.code}->${j.code}->joint`;

                // Add gizmos.
                const existing = this.joints.find((x) => x.customJoint === key);

                let gizmo = null;

                let position = null;
                let rotation = null;
                let immediate = false;

                if (options && options.immediate) {
                    immediate = true;
                }

                if (existing) {
                    validated.push(existing);

                    gizmo = existing;
                } else {
                    gizmo = new TransformNode(key);

                    gizmo.customJoint = key;

                    // Find the parent mesh.
                    const placementKey = this.placementKey(s.on, s.placement.code);
                    const placement = this.placements[placementKey];

                    let adjustMesh = null;

                    if (placement && placement.offset) {
                        adjustMesh = placement.offset;
                    }

                    if (adjustMesh) {
                        gizmo.setParent(adjustMesh);
                    } else {
                        // eslint-disable-next-line
                        console.log(`Cannot find a parent for joint in ${placementKey}`);
                    }

                    this.joints.push(gizmo);
                    validated.push(gizmo);

                    immediate = true;
                }

                let viewCode = this.view.code;

                if (options && options.view) {
                    viewCode = options.view;
                }

                const largeViewCode = viewCode.replace(' Small', '');

                if (j.views && j.views[viewCode] && j.views[viewCode]['three-position']) {
                    position = ThreeUtilities.toVector3(j.views[viewCode]['three-position']);
                } else if (j.views && j.views[largeViewCode] && j.views[largeViewCode]['three-position']) {
                    position = ThreeUtilities.toVector3(j.views[largeViewCode]['three-position']);
                } else {
                    position = ThreeUtilities.toVector3(j.custom['three-position']);
                }

                if (j.views && j.views[viewCode] && j.views[viewCode]['three-rotation']) {
                    rotation = ThreeUtilities.toQuaternion(j.views[viewCode]['three-rotation']);
                } else if (j.views && j.views[largeViewCode] && j.views[largeViewCode]['three-rotation']) {
                    rotation = ThreeUtilities.toQuaternion(j.views[largeViewCode]['three-rotation']);
                } else {
                    rotation = ThreeUtilities.toQuaternion(j.custom['three-rotation']);
                }

                if (immediate) {
                    gizmo.position = position;
                    gizmo.rotationQuaternion = rotation;
                } else {
                    let duration = 30;

                    if (this.view.custom && this.view.custom['three-view-transition-frames']) {
                        duration = +this.view.custom['three-view-transition-frames'];
                    }

                    this.animateCurrent(
                        `${key}-position`,
                        gizmo,
                        'position',
                        position,
                        30,
                        duration,
                    );

                    this.animateCurrent(
                        `${key}-rotation`,
                        gizmo,
                        'rotationQuaternion',
                        rotation,
                        30,
                        duration,
                    );
                }
            });

            // Delete those that were not validated.
            const good = [];

            this.joints.forEach((j) => {
                if (validated.includes(j)) {
                    good.push(j);
                } else {
                    j.dispose();
                }
            });

            this.joints = good;
        },

        /**
         * Calculates the screen space coordinates of sockets.
         */
        projectJoints() {
            // Scan through all sockets.
            const sockets = this.controller.socketsState;

            sockets.forEach((s) => {
                const j = s.socket;
                const { component } = s;

                if (j.custom == null) {
                    return;
                }

                const key = `${s.on.id}->${component.code}->${j.code}->joint`;

                // Add gizmos.
                if (this.joints) {
                    const existing = this.joints.find((x) => x.customJoint === key);

                    if (existing) {
                        const target = existing.getAbsolutePosition();

                        const screenPosition = Vector3.Project(
                            target,
                            Matrix.IdentityReadOnly,
                            this.scene.getTransformMatrix(),
                            this.camera.viewport.toGlobal(
                                this.engine.getRenderWidth(),
                                this.engine.getRenderHeight(),
                            ),
                        );

                        s.target = target;
                        s.screen = screenPosition;
                    }
                }
            });
        },
    },
};
