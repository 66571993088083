import {
    Vector3,
    Matrix,
} from '@babylonjs/core/Maths/math.vector';

// eslint-disable-next-line no-unused-vars
import { Ray } from '@babylonjs/core/Culling/ray';

import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture';

import { CreateDecal } from '@babylonjs/core/Meshes/Builders/decalBuilder';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';

import * as ThreeUtilities from './threeutilities';
import * as RenderHelper from './renderhelper';

import * as ComponentUtilities from '../../helpers/components';

let imageSequence = 0;

export default {
    methods: {
        /**
         * Apply loaded textures.
         */
        applyTextures() {
            this.needApplyTextures = true;

            this.afterFrames(5, () => {
                this.needApplyTextures = true;
            });
        },

        /**
         * Apply loaded textures.
         */
        updateTextures() {
            if (!this.needApplyTextures) {
                return;
            }

            this.needApplyTextures = false;

            // eslint-disable-next-line
            Object.values(this.textures).forEach((texture) => {
                if (texture.texture == null) {
                    return;
                }

                // TODO: Detect if meshes had changed.

                // Skip if we are using dynamic textures and decal mesh had been already created.
                // if (texture.dynamic && texture.decal) {
                //     return;
                // }

                // Create a decal.
                if (texture.custom['three-decal'] === 'true') {
                    const applyTo = texture.custom['three-decal-placement'];

                    // Clear old one.
                    texture.decal = null;

                    this.placementsByCode(applyTo).forEach((placement) => {
                        // Same placement code, but some other controller.
                        if (placement.controller !== texture.controller) {
                            return;
                        }

                        // No meshes in placement.
                        if (placement.meshes == null) {
                            return;
                        }

                        placement.meshes.forEach((mesh, index) => {
                            // Clear old decals.
                            if (mesh.decals) {
                                mesh.decals.forEach((decal) => {
                                    decal.dispose(true, false);

                                    if (decal.material) {
                                        decal.material.dispose();
                                    }
                                });
                            }

                            mesh.decals = [];

                            if (mesh.mesh.emptyParent === true) {
                                return;
                            }

                            let adjustMesh = mesh.mesh;
                            while (adjustMesh) {
                                if (adjustMesh.name && adjustMesh.name.endsWith('->offset')) {
                                    break;
                                }

                                adjustMesh = adjustMesh.parent;
                            }

                            adjustMesh.computeWorldMatrix(true);

                            // Create a new one.
                            const zOffset = ThreeUtilities.toFloat(texture.custom['three-decal-z-offset']);
                            const zOffsetUnits = ThreeUtilities.toFloat(texture.custom['three-decal-z-offset-units']);
                            const zFloat = ThreeUtilities.toFloat(texture.custom['three-decal-z-float']);

                            let normal = ThreeUtilities.toVector3(texture.custom['three-decal-normal']);
                            let position = ThreeUtilities.toVector3(texture.custom['three-decal-position']);
                            let up = null;
                            let left = null;
                            let rotation = +(texture.custom['three-decal-rotation'] || '0');

                            let size = ThreeUtilities.toVector3(texture.custom['three-decal-size']);

                            if (Number.isNaN(position.x) || Number.isNaN(position.y) || Number.isNaN(position.z)) {
                                // Is it a named socket?
                                const socketCode = texture.custom['three-decal-position'];

                                const sockets = this.controller.socketsState;
                                if (sockets == null) {
                                    return;
                                }

                                const socket = sockets.find((s) => s.socket.code === socketCode);
                                if (socket == null) {
                                    return;
                                }

                                if (socket.socket.custom == null) {
                                    return;
                                }

                                const socketPosition = socket.socket.custom['three-position'];
                                const socketRotation = socket.socket.custom['three-rotation'];
                                const socketSize = socket.socket.custom['three-decal-size'];

                                if (socketPosition == null || socketRotation == null) {
                                    return;
                                }

                                position = ThreeUtilities.toVector3(socketPosition);

                                const connectorRotation = ThreeUtilities.toQuaternion(socketRotation);

                                normal = new Vector3(0, 0, 1);
                                normal.applyRotationQuaternionInPlace(connectorRotation);

                                up = new Vector3(0, 1, 0);
                                up.applyRotationQuaternionInPlace(connectorRotation);

                                left = new Vector3(1, 0, 0);
                                left.applyRotationQuaternionInPlace(connectorRotation);

                                rotation = Math.acos(Math.max(-1, Math.min(1, Vector3.Dot(new Vector3(0, 1, 0), up))));

                                if (socketSize) {
                                    size = ThreeUtilities.toVector3(socketSize);
                                }
                            } else {
                                // TODO: Calculate up/left vectors when decal is configured through settings directly.
                            }

                            const material = new PBRMaterial(`${texture.code}-decal-material-${index}`, this.scene);

                            material.albedoColor = ThreeUtilities.toColor3(texture.custom['three-decal-color']);

                            // May be null but that's fine...
                            material.albedoTexture = texture.texture;
                            material.albedoTexture.hasAlpha = true;
                            material.useAlphaFromAlbedoTexture = true;
                            material.reflectivityColor = ThreeUtilities.toColor3(texture.custom['three-decal-reflectivity-color']);

                            material.metallic = ThreeUtilities.toFloat(texture.custom['three-decal-metallic']);
                            material.roughness = ThreeUtilities.toFloat(texture.custom['three-decal-roughness'], 1);

                            material.zOffset = zOffset;
                            material.zOffsetUnits = zOffsetUnits;

                            // Position, normal, and size are defined in mesh coordinates but the builder needs world.
                            const worldMeshMatrix = adjustMesh.getWorldMatrix();

                            const worldPosition = Vector3.TransformCoordinates(position, worldMeshMatrix);
                            const worldNormal = Vector3.TransformNormal(normal, worldMeshMatrix);
                            // eslint-disable-next-line no-unused-vars
                            const worldLeft = Vector3.TransformNormal(left, worldMeshMatrix);
                            // eslint-disable-next-line no-unused-vars
                            const worldUp = Vector3.TransformNormal(up, worldMeshMatrix);

                            worldNormal.normalize();

                            const decal = CreateDecal(`${texture.code}-decal-${index}`, mesh.mesh, {
                                position: worldPosition,
                                normal: worldNormal,
                                size,
                                angle: rotation,
                            });

                            if (decal.getTotalVertices() === 0) {
                                // Ignore and dispose right away.
                                decal.dispose();
                            } else {
                                if (!mesh.mesh.isEnabled()) {
                                    decal.setEnabled(false);
                                }

                                decal.isPickable = false;
                                decal.material = material;

                                decal.setParent(adjustMesh);

                                mesh.decals.push(decal);

                                decal.locallyTranslate(new Vector3(0, 0, zFloat));

                                if (texture.decal == null) {
                                    // Layout corners.
                                    const lt = position.add(left.scale(size.x * 0.5)).add(up.scale(size.y * 0.5));
                                    const rt = position.subtract(left.scale(size.x * 0.5)).add(up.scale(size.y * 0.5));
                                    const lb = position.add(left.scale(size.x * 0.5)).subtract(up.scale(size.y * 0.5));
                                    const rb = position.subtract(left.scale(size.x * 0.5)).subtract(up.scale(size.y * 0.5));

                                    texture.decal = {
                                        applyTo,
                                        position,
                                        normal,
                                        up,
                                        left,
                                        size,
                                        lt,
                                        rt,
                                        lb,
                                        rb,
                                        tracking: adjustMesh,
                                    };
                                }
                            }
                        });
                    });
                }
            });

            this.showMemoryStats('updateTextures');
        },

        /**
         * Load texture views.
         */
        loadTextures(options) {
            this.forAllControllers((controller) => {
                controller.blueprint.views.forEach((view) => {
                    if (!(view.custom && view.custom['three-texture-view'] === 'true')) {
                        return;
                    }

                    const viewKey = this.viewKey(controller, view.code);

                    // Is there a record already?
                    let texture = this.textures[viewKey];

                    if (texture == null) {
                        texture = {
                            controller: this.controllerKey(controller),
                            code: viewKey,
                            custom: { ...view.custom },

                            texture: null,

                            layers: [],

                            surfaceKey: null,
                        };

                        this.textures[viewKey] = texture;
                    }

                    const layered = view.custom['three-layered'] === 'true';

                    let willLoad = false;

                    if (layered) {
                        willLoad = this.loadLayeredTextures(texture, controller, view, options);
                    } else {
                        willLoad = this.loadStandardTextures(texture, controller, view, options);
                    }

                    if (!willLoad) {
                        this.applyTextureLayers(controller, texture);
                    }
                });
            });
        },

        /**
         * Loads standard non-layered textures.
         */
        loadStandardTextures(texture, controller, view, options) {
            let viewUrl = controller.makeFrameUrl(view, controller.state, view.frameLow, {});

            if (viewUrl.startsWith('//')) {
                viewUrl = `https:${viewUrl}`;
            }

            if (texture.loader) {
                if (texture.url === viewUrl) {
                    // Already loading the same URL.
                    return false;
                }

                if (texture.abortController) {
                    texture.abortController.abort();
                }
            } else if (texture.url === viewUrl) {
                if (options == null || options.skipIfUnchanged == null || options.skipIfUnchanged !== true) {
                    // Already loaded.
                    this.applyTextures();
                }
                return false;
            }

            if (window.AbortController) {
                texture.abortController = new AbortController();
                texture.signal = texture.abortController.signal;
            }

            texture.url = viewUrl;
            texture.loader = this.getAsBase64(viewUrl, texture.signal).then((imageData) => {
                texture.loader = null;

                if (texture.texture) {
                    texture.texture.dispose();
                }

                const viewKey = this.viewKey(controller, view.code);

                texture.texture = Texture.CreateFromBase64String(imageData, viewKey, this.scene);

                this.applyTextures();
            }).catch(() => {
                // Cancelled or failed to load.
            });

            return true;
        },

        /**
         * Loads texture layers.
         */
        loadLayeredTextures(texture, controller, view) {
            if (view.placements == null || view.placements.length === 0) {
                // The view should have explicit placement assignments.
                return false;
            }

            let matchPlacement = null;
            if (view.custom['three-layer-placements']) {
                matchPlacement = new RegExp(view.custom['three-layer-placements']);
            }

            let loading = false;

            view.placements.forEach((viewSettings) => {
                const { code } = viewSettings;

                if (matchPlacement) {
                    if (!matchPlacement.test(code)) {
                        return;
                    }
                }

                const placement = controller.findPlacement(code);
                if (placement == null) {
                    return;
                }

                const component = controller.selectedAtPlacement(code);
                if (component == null) {
                    // Huh...
                    return;
                }

                const scale = 1;

                // eslint-disable-next-line no-unsafe-optional-chaining
                const largeImage = (+controller.blueprint?.custom['layout-1-height']) > 1000;

                // if (this.small && largeImage) {
                //     scale = 0.5;
                // }

                // Find a layer.
                let layer = texture.layers.find((l) => l.code === code);
                if (layer == null) {
                    layer = {
                        code,

                        // scale: 0.5,
                        // scale: this.small ? 0.5 : 1,
                        // scale: 1,

                        scale,
                        quality: largeImage ? 70 : 85,

                        image: null,
                        mask: null,
                        viewMask: null,
                        overlay: null,
                    };

                    texture.layers.push(layer);
                }

                // Update the explicit.
                layer.explicitOrder = placement?.custom?.['explicit-order'];

                // Load the main image.
                const options = {
                    onlyPlacement: code,
                };

                if (viewSettings && viewSettings.custom && viewSettings.custom.group) {
                    options.noBackground = true;
                }

                const imageUrl = controller.makeFrameUrl(view, controller.state, view.frameLow, options);
                layer.image = this.makeLayerLoader('image', imageUrl, layer.image, controller, placement, component, texture, layer);

                if (!layer.image.ready) {
                    loading = true;
                }

                // Check if placement has a mask.
                let mask = null;

                if (component.custom.mask) {
                    // eslint-disable-next-line
                    mask = component.custom.mask;
                }

                if (viewSettings && viewSettings.custom && viewSettings.custom.mask) {
                    // eslint-disable-next-line
                    mask = viewSettings.custom.mask;
                }

                // Have a mask, make a loader.
                if (mask) {
                    // eslint-disable-next-line
                    const maskUrl = controller.baseFrameUrl()
                        + 'src='
                        + controller.mapImageName(
                            controller.imageName(mask, null, view, view.frameLow),
                            controller.blueprint.imageMapping,
                            {
                                background: true,
                            },
                        );

                    layer.mask = this.makeLayerLoader('mask', maskUrl, layer.mask, controller, placement, component, texture, layer);
                } else {
                    if (layer.mask) {
                        layer.mask.cancelLoader();
                    }
                    layer.mask = null;
                }

                // Does the group that the placement renders to have a mask?
                let viewMask = null;

                if (viewSettings && viewSettings.custom && viewSettings.custom.group) {
                    const sourceName = `g!${viewSettings.custom.group}`;

                    // If the current placement renders to a group, find it and check if it has a mask.
                    const useGroup = view.placements.find((p) => p.custom && p.custom.source === sourceName);

                    if (useGroup && useGroup.custom && useGroup.custom.mask) {
                        viewMask = useGroup.custom.mask;
                    }
                }

                // If there a view mask, load it too.
                if (viewMask) {
                    // eslint-disable-next-line
                    const maskUrl = controller.baseFrameUrl()
                        + 'src='
                        + controller.mapImageName(
                            controller.imageName(viewMask, null, view, view.frameLow),
                            controller.blueprint.imageMapping,
                            {
                                background: true,
                            },
                        );

                    layer.viewMask = this.makeLayerLoader('viewMask', maskUrl, layer.viewMask, controller, placement, component, texture, layer);
                } else {
                    if (layer.viewMask) {
                        layer.viewMask.cancelLoader();
                    }
                    layer.viewMask = null;
                }

                // Does the placement have an overlay?
                if (placement.custom) {
                    let { overlay } = placement.custom;

                    if (!overlay) {
                        overlay = view && view.custom && view.custom.overlay;
                    }

                    if (!overlay) {
                        // This could be a group provided overlay...
                        if (viewSettings && viewSettings.custom && viewSettings.custom.group) {
                            const sourceName = `g!${viewSettings.custom.group}`;

                            const useGroup = view.placements.find((p) => p.custom && p.custom.source === sourceName);

                            if (useGroup && useGroup.custom && useGroup.custom.overlay) {
                                // eslint-disable-next-line
                                overlay = useGroup.custom.overlay;
                            }
                        }
                    }

                    if (overlay) {
                        // eslint-disable-next-line
                        const overlayUrl = controller.baseFrameUrl()
                            + 'src='
                            + controller.mapImageName(
                                controller.imageName(overlay, null, view, view.frameLow),
                                controller.blueprint.imageMapping,
                                {
                                    background: true,
                                },
                            );

                        layer.overlay = this.makeLayerLoader('overlay', overlayUrl, layer.overlay, controller, placement, component, texture, layer);
                    } else {
                        if (layer.overlay) {
                            layer.overlay.cancelLoader();
                        }
                        layer.overlay = null;
                    }
                }
            });

            // Validate layers and remove empty.
            // Keep errored or cancelled ones since we don't want to retry bad images.
            texture.layers.forEach((l) => {
                if (l?.image?.empty) {
                    l.image?.cancelLoader();
                    l.mask?.cancelLoader();
                    l.viewMask?.cancelLoader();
                    l.overlay?.cancelLoader();
                }
            });

            texture.layers = texture.layers.filter((l) => !l.image?.empty);

            return loading;
        },

        /**
         * Gets the next image sequence ID.
         */
        nextImageSequence() {
            imageSequence += 1;

            return imageSequence;
        },

        /**
         * Creates image loader.
         */
        makeLayerLoader(type, useUrl, otherLoader, controller, placement, component, texture, layer) {
            if (!ComponentUtilities.isFilled(component)) {
                const loader = {
                    type,

                    url: null,

                    empty: true,
                    ready: true,
                    error: true,

                    image: new Image(),

                    cancelLoader() {},
                };

                if (otherLoader && !otherLoader.empty) {
                    setTimeout(() => {
                        this.afterLayerLoaded(loader, controller, placement, component, texture, layer);
                    });
                }

                return loader;
            }

            let useScale = layer.scale;
            let useQuality = layer.quality;

            if (useQuality == null) {
                useQuality = 65;
            }

            const makeUrl = () => {
                let url = useUrl;

                // Enforce webp format.
                if (url.includes('?')) {
                    url += `&fmt=webp&qlt=${useQuality}`;
                } else {
                    url += `?fmt=webp&qlt=${useQuality}`;
                }

                if (useScale !== 1) {
                    if (url.includes('?')) {
                        url += `&rscale=${useScale.toFixed(2)}`;
                    } else {
                        url += `?rscale=${useScale.toFixed(2)}`;
                    }
                }

                return url;
            };

            let url = makeUrl();

            if (otherLoader != null && typeof otherLoader === 'object') {
                // If the image is already loaded, use the old loader.
                if (otherLoader.url === url) {
                    // However, if the order of placements had changed, trigger the repaint.
                    const explicitOrder = placement.custom['explicit-order'];

                    if (explicitOrder !== otherLoader.explicitOrder) {
                        otherLoader.explicitOrder = explicitOrder;

                        setTimeout(() => {
                            this.afterLayerLoaded(otherLoader, controller, placement, component, texture, layer);
                        });
                    }

                    return otherLoader;
                }

                // Otherwise cancel the old loader.
                otherLoader.cancelLoader();
            }

            const loader = {
                type,

                id: this.nextImageSequence(),

                url,

                explicitOrder: placement.custom['explicit-order'],

                ready: false,
                error: false,
                cancelled: false,

                image: new Image(),
                xhr: new XMLHttpRequest(),

                abortController: null,

                cancelLoader() {
                    if (this.abortController) {
                        this.abortController.abort();
                    }

                    this.cancelled = true;
                },
            };

            if (window.AbortController) {
                loader.abortController = new AbortController();
            }

            // Capture layer positioning when at the time when the image was requested.
            const lastRendered = {};

            if (component.custom['widget-type'] === 'personalization') {
                const w = (+((component.custom && component.custom['personalization-width']) || '0')) * 0.5;
                const h = (+((component.custom && component.custom['personalization-width']) || '0')) * 0.5;

                lastRendered.scale = +(component.custom['personalization-scale'] || 1);
                lastRendered.rotate = +(component.custom['personalization-rotate'] || 0);
                lastRendered.x = +(component.custom['personalization-x'] || 0) + w;
                lastRendered.offsetX = +(component.custom['personalization-offset-x'] || 0) + w;
                lastRendered.y = +(component.custom['personalization-y'] || 0) + h;
                lastRendered.offsetY = +(component.custom['personalization-offset-y'] || 0) + h;
                lastRendered.textRealWidth = 0;
                lastRendered.textRealHeight = 0;
                lastRendered.textRealLeft = 0;
                lastRendered.textRealTop = 0;
            } else {
                lastRendered.scale = +(component.custom['dynamic-image-scale'] || 1);
                lastRendered.rotate = +(component.custom['dynamic-image-rotate'] || 0);
                lastRendered.x = +(component.custom['dynamic-image-x'] || 0);
                lastRendered.offsetX = +(component.custom['dynamic-image-offset-x'] || 0);
                lastRendered.y = +(component.custom['dynamic-image-y'] || 0);
                lastRendered.offsetY = +(component.custom['dynamic-image-offset-y'] || 0);
            }

            const loadImage = () => {
                fetch(url, {
                    method: 'GET',
                    signal: loader.abortController.signal,
                }).then((response) => {
                    const headers = response.headers;

                    response.blob().then((data) => {
                        // Already cancelled, ignore.
                        if (loader.cancelled) {
                            return;
                        }

                        // Loaded successfully...

                        let notes = headers.get('X-Notes');
                        if (notes) {
                            try {
                                notes = JSON.parse(notes);

                                // Capture the actual rendered text bounding box.
                                const realSize = notes.find((n) => n.type === 'TextBox');
                                if (realSize && realSize.note) {
                                    const bounds = realSize.note.split(',').map((x) => parseFloat(x.trim()));

                                    // eslint-disable-next-line
                                    lastRendered.textRealLeft = bounds[0];
                                    // eslint-disable-next-line
                                    lastRendered.textRealTop = bounds[1];
                                    // eslint-disable-next-line
                                    lastRendered.textRealWidth = bounds[2];
                                    // eslint-disable-next-line
                                    lastRendered.textRealHeight = bounds[3];

                                    placement.custom['personalization-bbox-left'] = bounds[0].toString();
                                    placement.custom['personalization-bbox-top'] = bounds[1].toString();
                                    placement.custom['personalization-bbox-width'] = bounds[2].toString();
                                    placement.custom['personalization-bbox-height'] = bounds[3].toString();
                                    placement.custom['personalization-bbox-base-width'] = loader.personalizationWidth;
                                    placement.custom['personalization-bbox-base-height'] = loader.personalizationHeight;
                                }

                                // Capture text metrics.
                                const size = notes.find((n) => n.type === 'TextBounds');
                                if (size && size.note) {
                                    const bounds = size.note.split(',').map((x) => parseFloat(x.trim()));

                                    // eslint-disable-next-line
                                    loader.textWidth = bounds[0];
                                    // eslint-disable-next-line
                                    loader.textHeight = bounds[1];
                                    // eslint-disable-next-line
                                    loader.textSpacing = bounds[2];
                                    // eslint-disable-next-line
                                    loader.textDescent = bounds[4];

                                    placement.custom['personalization-descent'] = bounds[4].toString();
                                    placement.custom['personalization-ascent'] = bounds[3].toString();
                                    placement.custom['personalization-spacing'] = bounds[2].toString();

                                    // Increase the personalization box
                                    let placementWidth = +(component.custom['personalization-width'] || '0');

                                    let adjustWidth = false;
                                    let lockWidth = false;

                                    if (controller.blueprint.custom['personalization-lock-box-width'] === 'true') {
                                        lockWidth = true;
                                    }

                                    if (controller.blueprint.custom['personalization-autosize-box-width'] === 'true') {
                                        if (placementWidth < bounds[0]) {
                                            adjustWidth = true;
                                        }
                                    }

                                    if (adjustWidth || lockWidth) {
                                        const dWidth = bounds[0] - placementWidth;

                                        // eslint-disable-next-line prefer-destructuring
                                        placementWidth = bounds[0];
                                        placement.components.forEach((c) => {
                                            c.custom['personalization-width'] = placementWidth.toString();
                                        });

                                        // Adjust the placement position depending on the text alignment to keep text in place.
                                        // For example, if text is like this and right aligned:
                                        //     ┌────────┐
                                        //   did not fit│
                                        //     └────────┘
                                        //  And we increase the box size
                                        //  ┌────────────┐
                                        //  │ did not fit│
                                        //  └────────────┘
                                        //  We'll need to move the X coordinate left by the amount that we increased the box size.

                                        const align = placement.components[0].custom['personalization-alignment'];

                                        if (align === 'left') {
                                            // Nothing to adjust, text will be left aligned.
                                        }

                                        if (align === 'center') {
                                            let x = +((component.custom && component.custom['personalization-x']) || '0');
                                            x -= dWidth * 0.5;

                                            placement.components.forEach((c) => {
                                                c.custom['personalization-x'] = x.toString();
                                            });
                                        }

                                        if (align === 'right') {
                                            let x = +((component.custom && component.custom['personalization-x']) || '0');
                                            x -= dWidth;

                                            placement.components.forEach((c) => {
                                                c.custom['personalization-x'] = x.toString();
                                            });
                                        }
                                    }

                                    // Save minimum width.
                                    placement.components.forEach((c) => {
                                        c.custom['personalization-min-width'] = bounds[0].toString();
                                    });

                                    let placementHeight = +(component.custom['personalization-height'] || '0');

                                    let adjustHeight = false;

                                    if (controller.blueprint.custom['personalization-autosize-box-height'] === 'true') {
                                        if (placementHeight < bounds[1]) {
                                            adjustHeight = true;
                                        }
                                    }

                                    if (adjustHeight) {
                                        const dHeight = bounds[1] - placementHeight;

                                        // eslint-disable-next-line prefer-destructuring
                                        placementHeight = bounds[1];
                                        placement.components.forEach((c) => {
                                            c.custom['personalization-height'] = placementHeight.toString();
                                        });

                                        // Adjust placement position, same as the vertical stuff.
                                        const align = placement.components[0].custom['personalization-vertical-alignment'];

                                        if (align === 'top') {
                                            // Nothing to adjust.
                                        }

                                        if (align === 'center') {
                                            let y = +((component.custom && component.custom['personalization-y']) || '0');
                                            y -= dHeight * 0.5;

                                            placement.components.forEach((c) => {
                                                c.custom['personalization-y'] = y.toString();
                                            });
                                        }

                                        if (align === 'right') {
                                            let y = +((component.custom && component.custom['personalization-y']) || '0');
                                            y -= dHeight;

                                            placement.components.forEach((c) => {
                                                c.custom['personalization-y'] = y.toString();
                                            });
                                        }
                                    }

                                    // Finally, save the last rendered position and rotation.
                                    let lastRenderedX = +((component.custom && component.custom['personalization-x']) || '0');
                                    let lastRenderedY = +((component.custom && component.custom['personalization-y']) || '0');

                                    lastRenderedX += (+((component.custom && component.custom['personalization-width']) || '0')) * 0.5;
                                    lastRenderedY += (+((component.custom && component.custom['personalization-height']) || '0')) * 0.5;

                                    placement.custom['last-personalization-x'] = lastRenderedX.toString();
                                    placement.custom['last-personalization-y'] = lastRenderedY.toString();

                                    // Not sure what's this.
                                    // // Adjust bounding box.
                                    // if (placement.custom['personalization-bbox-top']) {
                                    //     const dHeightBbox = bounds[1] - (+placement.custom['personalization-bbox-base-height']);
                                    // eslint-disable-next-line max-len
                                    //     placement.custom['personalization-bbox-top'] = (+(placement.custom['personalization-bbox-top']) + (dHeightBbox * 0.5))
                                    //         .toString();
                                    // }

                                    // if (placement.custom['personalization-bbox-left']) {
                                    //     const dWidthBbox = bounds[0] - (+placement.custom['personalization-bbox-base-width']);
                                    // eslint-disable-next-line max-len
                                    //     placement.custom['personalization-bbox-left'] = (+(placement.custom['personalization-bbox-left']) + (dWidthBbox * 0.5))
                                    //         .toString();
                                    // }
                                }
                            } catch (e) {
                                // Nothing.
                            }
                        }

                        // We grabbed a binary data from the server.
                        // Now convert it to image.
                        const blobUrl = URL.createObjectURL(data);

                        loader.image.onload = () => {
                            if (useScale < 1 && loader.image.width < 350) {
                                URL.revokeObjectURL(blobUrl);

                                // Reload at full resolution.
                                layer.scale = 1;
                                useScale = 1;

                                loader.url = makeUrl();
                                url = makeUrl();

                                loadImage();
                            } else {
                                loader.ready = true;

                                // Store last rendered parameters.
                                loader.lastRendered = lastRendered;

                                URL.revokeObjectURL(blobUrl);

                                this.afterLayerLoaded(loader, controller, placement, component, texture, layer);
                            }
                        };

                        loader.image.src = blobUrl;
                    });
                }).catch(() => {
                    if (loader.cancelled) {
                        return;
                    }

                    if (!loader.error) {
                        loader.ready = true;
                        loader.error = true;

                        this.afterLayerLoaded(loader, controller, placement, component, texture, layer);
                    }
                });
            };

            loadImage();

            return loader;
        },

        applyTextureLayers(controller, texture) {
            if (texture.layers) {
                const loadedUrls = [];

                let allReady = true;

                texture.layers.forEach((l) => {
                    if (!l.image || !l.image.ready) {
                        allReady = false;
                        return;
                    }

                    if (l.mask && !l.mask.ready) {
                        allReady = false;
                        return;
                    }

                    if (l.viewMask && !l.viewMask.ready) {
                        allReady = false;
                        return;
                    }

                    if (l.overlay && !l.overlay.ready) {
                        allReady = false;
                        return;
                    }

                    if (l.explicitOrder) {
                        loadedUrls.push(`${l.explicitOrder}-${l.image.id}`);
                    } else {
                        loadedUrls.push(l.image.id);
                    }
                });

                let updated = false;

                if (allReady) {
                    const surfaceKey = loadedUrls.join('-');

                    if (texture.surfaceKey !== surfaceKey) {
                        texture.surfaceKey = surfaceKey;

                        this.updateTextureCanvas(controller, texture);

                        updated = true;
                    }
                }

                if (updated) {
                    this.applyTextures();
                }
            }
        },

        afterLayerLoaded(loader, controller, placement, component, texture) {
            this.applyTextureLayers(controller, texture);
        },

        updateTextureCanvas(controller, texture) {
            let w = null;
            let h = null;

            // Figure out size. Currently all images on the editable surface are expected to be of the same size.
            const ready = texture.layers.find((l) => l.image && l.image.ready);
            if (ready) {
                const { image, scale } = ready;

                w = image.image.width / scale;
                h = image.image.height / scale;
            }

            // Nothing to render... however, if we had something to render in the past, and that layer was cleared, we need to clear the texture now.
            if (w == null || h == null) {
                if (texture.textureSize && texture.textureSize.width && texture.textureSize.height) {
                    w = texture.textureSize.width;
                    h = texture.textureSize.height;
                }
            }

            // Still actually nothing to render yet, not even a previously rendered image.
            if (w == null || h == null || w === 0 || h === 0) {
                // Render empty layer.
                if (controller.blueprint.custom['ps-default-custom-background']) {
                    w = 16;
                    h = 16;
                } else {
                    return;
                }
            }

            // Arrange layers in the order that they appear in the state, or the explicit order assignment.
            const layers = [];

            texture.layers.forEach((layer, index) => {
                layers.push({
                    layer,
                    explicit: layer.explicitOrder != null ? (+layer.explicitOrder) : Number.MAX_SAFE_INTEGER,
                    native: index,
                });
            });

            layers.sort((a, b) => {
                if (a.explicit < b.explicit) {
                    return -1;
                }

                if (a.explicit > b.explicit) {
                    return 1;
                }

                if (a.native < b.native) {
                    return -1;
                }

                if (a.native > b.native) {
                    return 1;
                }

                return 0;
            });

            let useScale = null;
            let sameScale = true;

            layers.forEach((layerOrdered) => {
                const l = layerOrdered.layer;

                if (useScale == null && sameScale) {
                    useScale = l.scale;
                } else if (useScale !== l.scale) {
                    useScale = null;
                    sameScale = false;
                }
            });

            const realWidth = w;
            const realHeight = h;

            if (useScale != null) {
                // useScale = null;
                w *= useScale;
                h *= useScale;
            }

            // Prep the buffer.
            const { buffer: surfaceBuffer } = RenderHelper.getRenderBuffer('surface', w, h);

            const ctx = surfaceBuffer.getContext('2d');

            const sizeHash = [];

            const paintLayers = (apply, update) => {
                // Render each layer...
                layers.forEach((layerOrdered) => {
                    const l = layerOrdered.layer;

                    let renderable = true;

                    if (!l.image || !l.image.ready) {
                        renderable = false;
                    }

                    if (l.mask && !l.mask.ready) {
                        renderable = false;
                    }

                    if (l.viewMask && !l.viewMask.ready) {
                        renderable = false;
                    }

                    if (l.overlay && !l.overlay.ready) {
                        renderable = false;
                    }

                    if (!renderable) {
                        return;
                    }

                    // Render into a temporary buffer, shifted, masked, and overlayed.
                    const { buffer: maskingBuffer } = RenderHelper.getRenderBuffer('masking', w, h);

                    const bufferCtx = maskingBuffer.getContext('2d');

                    if (apply) {
                        bufferCtx.resetTransform();

                        bufferCtx.clearRect(0, 0, w, h);
                    }

                    let lastRenderedX = 0;
                    let lastRenderedY = 0;

                    let currentX = 0;
                    let currentY = 0;
                    let currentRotate = 0;
                    let currentScale = 0;

                    // eslint-disable-next-line prefer-const
                    let adjustX = 0;
                    // eslint-disable-next-line prefer-const
                    let adjustY = 0;

                    let currentDeltaFromRenderedX = 0;
                    let currentDeltaFromRenderedY = 0;
                    let currentDeltaFromRenderedRotate = 0;
                    let currentDeltaFromRenderedScale = 0;

                    let componentWidth = 0;
                    let componentHeight = 0;

                    let progressX = 0;
                    let progressY = 0;
                    let progressRotate = 0;
                    let progressScale = 1;

                    // Is this a selected layer?
                    if (this.selectedLayer?.controller?.id === controller.id && this.selectedLayer.layer.code === l.code) {
                        progressX = (this.editorProgress?.x ?? 0);
                        progressY = (this.editorProgress?.y ?? 0);
                        progressRotate = (this.editorProgress?.rotate ?? 0);
                        progressScale = (this.editorProgress?.scale ?? 1);
                    }

                    // const placement = l.controller.findPlacement(l.code);
                    const component = controller.selectedAtPlacement(l.code);

                    const useProgressScale = 1;

                    if (component.custom['widget-type'] === 'personalization') {
                        currentX = (+(component.custom?.['personalization-x'] ?? '0'))
                            + (+(component.custom?.['personalization-offset-x'] ?? '0'))
                            + (progressX * useProgressScale);

                        currentDeltaFromRenderedX = currentX
                            - (l.image.lastRendered.x)
                            - (l.image.lastRendered.offsetX);

                        currentY = (+(component.custom?.['personalization-y'] ?? '0'))
                            + (+(component.custom?.['personalization-offset-y'] ?? '0'))
                            + (progressY * useProgressScale);

                        currentDeltaFromRenderedY = currentY
                            - (l.image.lastRendered.y)
                            - (l.image.lastRendered.offsetY);

                        currentRotate = (+(component.custom?.['personalization-rotate'] ?? '0'))
                            + progressRotate;

                        currentDeltaFromRenderedRotate = currentRotate
                            - (l.image.lastRendered.rotate);

                        currentScale = (+(component.custom?.['personalization-scale'] ?? '1'))
                            * progressScale;

                        currentDeltaFromRenderedScale = currentScale
                            / (l.image.lastRendered.scale);

                        lastRenderedX = l.image.lastRendered.x + l.image.lastRendered.offsetX;
                        lastRenderedY = l.image.lastRendered.y + l.image.lastRendered.offsetY;

                        componentWidth = (+((component.custom && component.custom['personalization-width']) || '0'));
                        componentHeight = (+((component.custom && component.custom['personalization-height']) || '0'));

                        componentWidth = l.image.lastRendered.textRealWidth;
                        // This is the actual pixel width, adjust for scaling.
                        componentWidth /= l.image.lastRendered.scale;

                        componentHeight = l.image.lastRendered.textRealHeight;
                        componentHeight /= l.image.lastRendered.scale;

                        // adjustX = (l.image.lastRendered.textRealLeft + (l.image.lastRendered.textRealWidth * 0.5)) / l.image.lastRendered.scale;
                        // adjustY = (l.image.lastRendered.textRealTop + (l.image.lastRendered.textRealHeight * 0.5)) / l.image.lastRendered.scale;
                    } else {
                        // The image we have was rendered at l.image.lastRendered.x + .offsetX, .y + .offsetY, with .rotate and .scale parameters.
                        // We want to draw the image at the "progressed" state, which is defined by both the current parameters and by the "editor" offset.

                        currentX = (+(component.custom?.['dynamic-image-x'] ?? '0'))
                            + (+(component.custom?.['dynamic-image-offset-x'] ?? '0'))
                            + (progressX * useProgressScale);

                        currentDeltaFromRenderedX = currentX
                            - (l.image.lastRendered.x)
                            - (l.image.lastRendered.offsetX);

                        currentY = (+(component.custom?.['dynamic-image-y'] ?? '0'))
                            + (+(component.custom?.['dynamic-image-offset-y'] ?? '0'))
                            + (progressY * useProgressScale);

                        currentDeltaFromRenderedY = currentY
                            - (l.image.lastRendered.y)
                            - (l.image.lastRendered.offsetY);

                        currentRotate = (+(component.custom?.['dynamic-image-rotate'] ?? '0'))
                            + progressRotate;

                        currentDeltaFromRenderedRotate = currentRotate
                            - (l.image.lastRendered.rotate);

                        currentScale = (+(component.custom?.['dynamic-image-scale'] ?? '1'))
                            * progressScale;

                        currentDeltaFromRenderedScale = currentScale
                            / (l.image.lastRendered.scale);

                        lastRenderedX = l.image.lastRendered.x + l.image.lastRendered.offsetX;
                        lastRenderedY = l.image.lastRendered.y + l.image.lastRendered.offsetY;

                        componentWidth = (+((component.custom && component.custom['dynamic-image-width']) || '0'));
                        componentHeight = (+((component.custom && component.custom['dynamic-image-height']) || '0'));
                    }

                    bufferCtx.resetTransform();

                    // Rotate and scale the image around the last rendered center.
                    bufferCtx.translate(lastRenderedX * useScale, lastRenderedY * useScale);

                    bufferCtx.rotate(currentDeltaFromRenderedRotate * (Math.PI / 180));

                    const actualScale = currentDeltaFromRenderedScale;

                    bufferCtx.scale(actualScale, actualScale);

                    bufferCtx.translate(-lastRenderedX * useScale, -lastRenderedY * useScale);

                    // eslint-disable-next-line no-bitwise
                    bufferCtx.translate(+((currentDeltaFromRenderedX * useScale).toFixed(0)), +((currentDeltaFromRenderedY * useScale).toFixed(0)));

                    if (useScale != null) {
                        // Nothing, buffer is prescaled.
                        // bufferCtx.scale(useScale, useScale);
                    } else if (l.scale !== 1) {
                        bufferCtx.scale(1 / l.scale, 1 / l.scale);
                    }

                    sizeHash.push(l.image?.url ?? '<image>');

                    // eslint-disable-next-line no-bitwise
                    sizeHash.push(+(currentDeltaFromRenderedX.toFixed(0)));
                    // eslint-disable-next-line no-bitwise
                    sizeHash.push(+(currentDeltaFromRenderedY.toFixed(0)));
                    sizeHash.push(currentDeltaFromRenderedRotate);
                    sizeHash.push(currentDeltaFromRenderedScale);

                    if (apply) {
                        bufferCtx.drawImage(l.image.image, 0, 0);
                    }

                    // Dim non-active images.
                    if (this.selectedLayer) {
                        if (this.selectedLayer.layer.code !== l.code) {
                            sizeHash.push('selected');

                            if (apply) {
                                const oldOp = bufferCtx.globalCompositeOperation;
                                bufferCtx.globalCompositeOperation = 'source-atop';
                                bufferCtx.fillStyle = 'rgba(0, 0, 0, 0.3)';
                                bufferCtx.fillRect(0, 0, w, h);
                                bufferCtx.fillStyle = 'rgba(255, 255, 255, 1)';
                                bufferCtx.globalCompositeOperation = oldOp;
                            }
                        }
                    }

                    if (apply) {
                        if (l.viewMask && l.viewMask.ready && l.viewMask.image) {
                            bufferCtx.resetTransform();

                            const oldOp = bufferCtx.globalCompositeOperation;

                            bufferCtx.globalCompositeOperation = 'destination-in';

                            if (useScale != null) {
                                // Nothing, buffer is prescaled.
                            } else if (l.scale !== 1) {
                                bufferCtx.scale(1 / l.scale, 1 / l.scale);
                            }

                            // Mask is supposed to be of the same dimensions as the base image.
                            // eslint-disable-next-line
                            bufferCtx.drawImage(l.viewMask.image, 0, 0);

                            bufferCtx.globalCompositeOperation = oldOp;
                        }
                    }

                    if (apply) {
                        // Commit to the texture cache.
                        ctx.drawImage(maskingBuffer, 0, 0);
                    }

                    if (apply || update) {
                        // Update the layer position.
                        if (l.location == null) {
                            l.location = {};
                        }

                        l.location.x = currentX + adjustX;
                        l.location.y = currentY + adjustY;
                        l.location.rotate = currentRotate;
                        l.location.scale = currentScale;
                        l.location.width = componentWidth;
                        l.location.height = componentHeight;

                        // Is this a selected layer?
                        if (this.selectedLayer?.controller?.id === controller.id && this.selectedLayer.layer.code === l.code) {
                            this.makeSelectedLayerBorder();
                        }

                        // Mark rendered.
                        component.custom['layer-rendered'] = 'true';
                    }
                });
            };

            // Populate the size hash.
            paintLayers(false);

            const hashed = sizeHash.join('-');

            if (texture.paintHash === hashed) {
                return;
            }

            ctx.clearRect(0, 0, w, h);

            // Are there no layers at all?
            if (sizeHash.length === 0) {
                const color = controller.blueprint.custom['ps-default-custom-background'];
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fillRect(0, 0, w, h);
                }
            }

            texture.paintHash = hashed;

            paintLayers(true, true);

            // Commit the combined set of layers to the texture.
            if (texture.texture == null || texture.textureSize.width !== realWidth || texture.textureSize.height !== realHeight) {
                if (texture.texture) {
                    texture.texture.dispose();
                }

                const name = `${texture.controller}-${texture.code}-dynamic`;

                texture.dynamic = true;
                texture.textureSize = {
                    width: realWidth,
                    height: realHeight,
                };

                texture.texture = new DynamicTexture(name, {
                    width: w,
                    height: h,
                }, this.scene);
            }

            const textureCtx = texture.texture.getContext();

            textureCtx.clearRect(0, 0, w, h);
            textureCtx.drawImage(surfaceBuffer, 0, 0);

            texture.texture.update();

            this.requestRenderUpdate(5);

            this.showMemoryStats('paintTextureCanvas');
        },

        /**
         * Projects a single point onto the screen.
         */
        projectDecalPoint(point, worldMeshMatrix, transform, viewport) {
            return Vector3.Project(
                Vector3.TransformCoordinates(
                    point,
                    worldMeshMatrix,
                ),
                Matrix.IdentityReadOnly,
                transform,
                viewport,
            );
        },

        /**
         * Calculates screen space coordinates of decals.
         */
        projectDecals() {
            if (this.selectedLayer && this.selectedLayer.corners) {
                const transform = this.scene.getTransformMatrix();

                const viewport = this.camera.viewport.toGlobal(
                    this.engine.getRenderWidth(),
                    this.engine.getRenderHeight(),
                );

                const worldMeshMatrix = this.selectedLayer.texture.decal.tracking.getWorldMatrix();

                this.selectedLayer.screen = {
                    center: this.projectDecalPoint(this.selectedLayer.corners.center, worldMeshMatrix, transform, viewport),
                    tl: this.projectDecalPoint(this.selectedLayer.corners.tl, worldMeshMatrix, transform, viewport),
                    tr: this.projectDecalPoint(this.selectedLayer.corners.tr, worldMeshMatrix, transform, viewport),
                    bl: this.projectDecalPoint(this.selectedLayer.corners.bl, worldMeshMatrix, transform, viewport),
                    br: this.projectDecalPoint(this.selectedLayer.corners.br, worldMeshMatrix, transform, viewport),
                    mr: this.projectDecalPoint(this.selectedLayer.corners.mr, worldMeshMatrix, transform, viewport),
                    mt: this.projectDecalPoint(this.selectedLayer.corners.mt, worldMeshMatrix, transform, viewport),
                    mb: this.projectDecalPoint(this.selectedLayer.corners.mb, worldMeshMatrix, transform, viewport),
                    ml: this.projectDecalPoint(this.selectedLayer.corners.ml, worldMeshMatrix, transform, viewport),
                    ftl: this.projectDecalPoint(this.selectedLayer.corners.ftl, worldMeshMatrix, transform, viewport),
                    ftr: this.projectDecalPoint(this.selectedLayer.corners.ftr, worldMeshMatrix, transform, viewport),
                    fbl: this.projectDecalPoint(this.selectedLayer.corners.fbl, worldMeshMatrix, transform, viewport),
                    fbr: this.projectDecalPoint(this.selectedLayer.corners.fbr, worldMeshMatrix, transform, viewport),
                    fmr: this.projectDecalPoint(this.selectedLayer.corners.fmr, worldMeshMatrix, transform, viewport),
                    fmt: this.projectDecalPoint(this.selectedLayer.corners.fmt, worldMeshMatrix, transform, viewport),
                    fmb: this.projectDecalPoint(this.selectedLayer.corners.fmb, worldMeshMatrix, transform, viewport),
                    fml: this.projectDecalPoint(this.selectedLayer.corners.fml, worldMeshMatrix, transform, viewport),
                    outside: this.selectedLayer.corners.outside,
                    containerOrigin: this.projectDecalPoint(this.selectedLayer.corners.containerOrigin, worldMeshMatrix, transform, viewport),
                    containerLeft: this.projectDecalPoint(this.selectedLayer.corners.containerLeft, worldMeshMatrix, transform, viewport),
                    containerUp: this.projectDecalPoint(this.selectedLayer.corners.containerUp, worldMeshMatrix, transform, viewport),
                };

                this.emitter.$emit('renderSelectedLayerUpdated', this.selectedLayer);
            }
        },

        unselectTextureLayer() {
            if (this.selectedLayer) {
                const { controller } = this.selectedLayer;

                const controllerKey = this.controllerKey(controller);

                this.selectedLayer = null;

                // Un-dim every other active on the texture that old layer belongs to.
                Object.values(this.textures).forEach((texture) => {
                    if (texture.controller !== controllerKey) {
                        return;
                    }

                    this.updateTextureCanvas(controller, texture);
                });

                return true;
            }

            return false;
        },

        /**
         * Selects a layer and draws the outline.
         */
        selectTextureLayer(controller, placement) {
            if (controller == null || placement == null) {
                if (this.unselectTextureLayer()) {
                    // Notify consumers that the selection was removed.
                    this.emitter.$emit('renderSelectedLayerUpdated');
                }

                return;
            }

            // Remove the old selection.
            this.unselectTextureLayer();

            const controllerKey = this.controllerKey(controller);

            let selectedLayer = null;
            let selectedTexture = null;

            // Find a texture and actual layer loaded for the controller + placement combo.
            Object.values(this.textures).forEach((texture) => {
                if (texture.controller !== controllerKey) {
                    return;
                }

                if (texture.layers == null || texture.decal == null) {
                    return;
                }

                const layer = texture.layers.find((l) => l.code === placement);

                if (layer) {
                    selectedTexture = texture;
                    selectedLayer = layer;
                }
            });

            if (selectedTexture && selectedLayer) {
                this.selectedLayer = {
                    // Layer controller.
                    controller,
                    // Loaded texture...
                    texture: selectedTexture,
                    // ... a layer from that texture.
                    layer: selectedLayer,
                };

                this.makeSelectedLayerBorder();

                // Dim non-active layers.
                this.updateTextureCanvas(controller, selectedTexture);
            }
        },

        /**
         * Creates a highlight of the selected layer.
         */
        makeSelectedLayerBorder() {
            const { texture, layer } = this.selectedLayer;

            if (texture.decal == null) {
                return;
            }

            if (layer.location == null) {
                // May be not loaded yet.
                return;
            }

            const {
                position,
                up,
                left,
                size,
                normal,
            } = texture.decal;

            // eslint-disable-next-line
            const textureWidth = texture.textureSize.width;
            // eslint-disable-next-line
            const textureHeight = texture.textureSize.height;

            // eslint-disable-next-line
            const layerWidth = layer.location.width;
            // eslint-disable-next-line
            const layerHeight = layer.location.height;

            // left * size -> full decal width
            // left * size * (layer / texture) -> only the layer portion.

            // The layer origin is not the same as the decal origin.
            // First find the top left corner of the decal.
            // Left and up are unit vectors, scale them by the decal size.
            const origin = position.add(left.scale(size.x * 0.5)).add(up.scale(size.y * 0.5));

            // And offset set in the bottom-right direction based on the layer location.
            const layerCenter = origin
                .subtract(left.scale(size.x * (layer.location.x / textureWidth)))
                .subtract(up.scale(size.y * (layer.location.y / textureHeight)));

            // Scale by the layer size.
            const fx = (layerWidth / textureWidth) * layer.location.scale;
            const fy = (layerHeight / textureHeight) * layer.location.scale;

            let layerLeft = left.scale(size.x * fx * 0.5);
            let layerUp = up.scale(size.y * fy * 0.5);

            // And rotate by the layer rotation.
            const rotate = Matrix.RotationAxis(normal, (layer.location.rotate * Math.PI) / 180);

            layerLeft = Vector3.TransformNormal(layerLeft, rotate);
            layerUp = Vector3.TransformNormal(layerUp, rotate);

            // Find corners and midpoints.
            const tl = layerCenter.add(layerLeft).add(layerUp);
            const tr = layerCenter.subtract(layerLeft).add(layerUp);
            const bl = layerCenter.add(layerLeft).subtract(layerUp);
            const br = layerCenter.subtract(layerLeft).subtract(layerUp);
            const mt = layerCenter.add(layerUp);
            const mb = layerCenter.subtract(layerUp);
            const mr = layerCenter.subtract(layerLeft);
            const ml = layerCenter.add(layerLeft);

            // Fit the bounding box of the layer into the layout area.
            const bbCorners = [
                layerLeft.add(layerUp),
                layerUp.subtract(layerLeft),
                layerLeft.subtract(layerUp),
                layerLeft.negate().subtract(layerUp),
            ];

            const projectToLeft = bbCorners.map((v) => left.scale(Vector3.Dot(left, v) / Vector3.Dot(left, left)));
            const projectToUp = bbCorners.map((v) => up.scale(Vector3.Dot(up, v) / Vector3.Dot(up, up)));

            let projectedLeft = null;
            let projectedLeftLength = null;

            projectToLeft.forEach((v) => {
                if (projectedLeftLength == null || v.lengthSquared() > projectedLeftLength) {
                    projectedLeftLength = v.lengthSquared();
                    projectedLeft = v;
                }
            });

            let projectedUp = null;
            let projectedUpLength = null;

            projectToUp.forEach((v) => {
                if (projectedUpLength == null || v.lengthSquared() > projectedUpLength) {
                    projectedUpLength = v.lengthSquared();
                    projectedUp = v;
                }
            });

            // Print corners:
            // console.log(
            //     '!!!!!!',
            //     // eslint-disable-next-line
            //     bbCorners[0].x.toFixed(4), bbCorners[0].y.toFixed(4),
            //     '|',
            //     // eslint-disable-next-line
            //     bbCorners[1].x.toFixed(4), bbCorners[1].y.toFixed(4),
            //     '|',
            //     // eslint-disable-next-line
            //     bbCorners[2].x.toFixed(4), bbCorners[2].y.toFixed(4),
            //     '|',
            //     // eslint-disable-next-line
            //     bbCorners[3].x.toFixed(4), bbCorners[3].y.toFixed(4)
            // );

            // Print projections:
            // console.log(
            //     '!!!!!!!!',
            //     // eslint-disable-next-line
            //     projectedLeft.x.toFixed(4), projectedLeft.y.toFixed(4),
            //     '|',
            //     // eslint-disable-next-line
            //     projectedUp.x.toFixed(4), projectedUp.y.toFixed(4)
            // );

            // Midpoints of the axis-aligned bounding box.
            const bbl = layerCenter.add(projectedLeft);
            const bbr = layerCenter.subtract(projectedLeft);
            const bbt = layerCenter.add(projectedUp);
            const bbb = layerCenter.subtract(projectedUp);

            // Offsets from layout center.
            const bblOffset = bbl.subtract(position);
            const bbrOffset = bbr.subtract(position);
            const bbtOffset = bbt.subtract(position);
            const bbbOffset = bbb.subtract(position);

            // Flatten corner offsets on to left-right / top-bottom axis.
            const bblOffsetFlat = left.scale(Vector3.Dot(left, bblOffset) / Vector3.Dot(left, left));
            const bbrOffsetFlat = left.scale(Vector3.Dot(left, bbrOffset) / Vector3.Dot(left, left));

            const bbtOffsetFlat = up.scale(Vector3.Dot(up, bbtOffset) / Vector3.Dot(up, up));
            const bbbOffsetFlat = up.scale(Vector3.Dot(up, bbbOffset) / Vector3.Dot(up, up));

            const bbWidthLeft = bblOffsetFlat.length();
            const bbWidthRight = bbrOffsetFlat.length();
            const bbHeightTop = bbtOffsetFlat.length();
            const bbHeightBottom = bbbOffsetFlat.length();

            let bbScale = 1;

            if (bbWidthLeft > size.x * 0.5) {
                const f = (size.x * 0.5) / bbWidthLeft;

                if (bbScale > f) {
                    bbScale = f;
                }
            }

            if (bbWidthRight > size.x * 0.5) {
                const f = (size.x * 0.5) / bbWidthRight;

                if (bbScale > f) {
                    bbScale = f;
                }
            }

            if (bbHeightTop > size.y * 0.5) {
                const f = (size.y * 0.5) / bbHeightTop;

                if (bbScale > f) {
                    bbScale = f;
                }
            }

            if (bbHeightBottom > size.y * 0.5) {
                const f = (size.y * 0.5) / bbHeightBottom;

                if (bbScale > f) {
                    bbScale = f;
                }
            }

            const fitLeft = layerLeft.scale(bbScale);
            const fitUp = layerUp.scale(bbScale);

            // Calculate borders of fitted box.
            // eslint-disable-next-line no-unused-vars
            const ftl = layerCenter.add(fitLeft).add(fitUp);
            // eslint-disable-next-line no-unused-vars
            const ftr = layerCenter.subtract(fitLeft).add(fitUp);
            // eslint-disable-next-line no-unused-vars
            const fbl = layerCenter.add(fitLeft).subtract(fitUp);
            // eslint-disable-next-line no-unused-vars
            const fbr = layerCenter.subtract(fitLeft).subtract(fitUp);
            const fmt = layerCenter.add(fitUp);
            const fmb = layerCenter.subtract(fitUp);
            const fmr = layerCenter.subtract(fitLeft);
            const fml = layerCenter.add(fitLeft);

            // Check if the whole thing is outside.
            let outside = false;

            // Offsets must point in the same direction.

            const bbLeftRight = Vector3.Dot(bblOffsetFlat.normalizeToNew(), bbrOffsetFlat.normalizeToNew());
            const bbTopBottom = Vector3.Dot(bbtOffsetFlat.normalizeToNew(), bbbOffsetFlat.normalizeToNew());

            // Print corner offsets from layout center:
            // console.log(
            //     '!!!!!!!!!!! l ',
            //     // eslint-disable-next-line
            //     bblOffsetFlat.x.toFixed(4), bblOffsetFlat.y.toFixed(4),
            //     '| r ',
            //     // eslint-disable-next-line
            //     bbrOffsetFlat.x.toFixed(4), bbrOffsetFlat.y.toFixed(4),
            //     ' | lr',
            //     bbLeftRight.toFixed(4),
            //     '| t ',
            //     // eslint-disable-next-line
            //     bbtOffsetFlat.x.toFixed(4), bbtOffsetFlat.y.toFixed(4),
            //     '| b ',
            //     // eslint-disable-next-line
            //     bbbOffsetFlat.x.toFixed(4), bbbOffsetFlat.y.toFixed(4),
            //     ' | tb',
            //     bbTopBottom.toFixed(4),
            // );

            const halfWidth = size.x * 0.5;
            const halfHeight = size.y * 0.5;

            if (bbLeftRight > 0 && bbWidthLeft > halfWidth && bbWidthRight > halfWidth) {
                outside = true;
            }

            if (bbTopBottom > 0 && bbHeightTop > halfHeight && bbHeightBottom > halfHeight) {
                outside = true;
            }

            // eslint-disable-next-line max-len
            // console.log('!!!!!!!!!!!!!!!!!!', bbLeftRight.toFixed(3), bbTopBottom.toFixed(3), '|', outside, '|', halfWidth.toFixed(3), bbWidthLeft.toFixed(3), bbWidthRight.toFixed(3), '|', halfHeight.toFixed(3), bbHeightTop.toFixed(3), bbHeightBottom.toFixed(3));
            // eslint-disable-next-line max-len
            // console.log('!!!!!!!!!!!!!!!!!!', bbLeftRight.toFixed(3), bbTopBottom.toFixed(3), '|', outside, '|', bbWidthLeft > halfWidth, bbWidthRight > halfWidth, '|', bbHeightTop > halfHeight, bbHeightBottom > halfHeight);
            // outside = false;

            this.selectedLayer.corners = {
                center: layerCenter,
                up: layerUp,
                left: layerLeft,
                layerWidth,
                layerHeight,
                tl,
                tr,
                bl,
                br,
                mt,
                mr,
                mb,
                ml,
                ftl,
                ftr,
                fbl,
                fbr,
                fmt,
                fmr,
                fmb,
                fml,
                outside,
                containerOrigin: position,
                containerLeft: position.add(left.scale(size.x * 0.5)),
                containerWidth: textureWidth * 0.5,
                containerUp: position.add(up.scale(size.y * 0.5)),
                containerHeight: textureHeight * 0.5,
            };
        },

        /**
         * Repaints currently edited texture.
         */
        updateTexturesFromEditor() {
            if (this.editorProgress?.layer) {
                const layer = this.editorProgress.layer;

                if (layer.controller && layer.texture) {
                    this.updateTextureCanvas(layer.controller, layer.texture);
                }
            }
        },

        /**
         * Selects a layer from a point.
         */
        selectTextureLayerFromPoint(options) {
            const identity = Matrix.Identity();

            const ray = this.scene.createPickingRay(options.x, options.y, identity, this.camera, false);

            // eslint-disable-next-line
            Object.values(this.textures).forEach((texture) => {
                const { decal } = texture;

                if (decal == null) {
                    return;
                }

                if (decal.tracking == null) {
                    return;
                }

                // Are any meshes visible?
                let visible = false;

                decal.tracking.getDescendants().forEach((n) => {
                    if (n.getClassName() === 'Mesh' && n.isEnabled()) {
                        visible = true;
                    }
                });

                if (!visible) {
                    return;
                }

                const worldMeshMatrix = decal.tracking.getWorldMatrix();

                // Calculate world coordinates of decal corners.
                const lt = Vector3.TransformCoordinates(decal.lt, worldMeshMatrix);
                const rt = Vector3.TransformCoordinates(decal.rt, worldMeshMatrix);
                const lb = Vector3.TransformCoordinates(decal.lb, worldMeshMatrix);
                const rb = Vector3.TransformCoordinates(decal.rb, worldMeshMatrix);

                let pickPoint = null;

                // Does the ray intersect with the decal?
                const check1 = ray.intersectsTriangle(lt, rt, lb);
                if (check1) {
                    pickPoint = lt.scale(check1.bu)
                        .add(rt.scale(check1.bv))
                        .add(lb.scale(1 - check1.bu - check1.bv));
                } else {
                    const check2 = ray.intersectsTriangle(rb, lb, rt);

                    if (check2) {
                        pickPoint = rb.scale(check2.bu)
                            .add(lb.scale(check2.bv))
                            .add(rt.scale(1 - check2.bu - check2.bv));
                    }
                }

                if (pickPoint) {
                    const invertedWorld = Matrix.Invert(worldMeshMatrix);
                    const invertedPick = Vector3.TransformCoordinates(pickPoint, invertedWorld);

                    // Figure out pixel offset in the texture.
                    // const dp = pickPoint.subtract(decal.position);
                    const dp = invertedPick.subtract(decal.position);

                    // Figure out offsets from the center and normalize them.
                    const onLeft = decal.left.scale(Vector3.Dot(decal.left, dp) / Vector3.Dot(decal.left, decal.left));
                    const onUp = decal.up.scale(Vector3.Dot(decal.up, dp) / Vector3.Dot(decal.up, decal.up));

                    const leftRight = Vector3.Dot(onLeft.normalizeToNew(), decal.left.normalizeToNew());
                    const topBottom = Vector3.Dot(onUp.normalizeToNew(), decal.up.normalizeToNew());

                    let x = onLeft.length() / (decal.size.x * 0.5);
                    let y = onUp.length() / (decal.size.y * 0.5);

                    // Left points... left. Which is technically negative pixel coords.
                    if (leftRight > 0) {
                        x = -x;
                    }

                    if (topBottom > 0) {
                        y = -y;
                    }

                    x = Math.max(Math.min(x, 1), -1);
                    y = Math.max(Math.min(y, 1), -1);

                    // At this point both coords should be in the [-1; 1] range, map them to the image width and height.
                    const pixelX = ((x * texture.textureSize.width) + texture.textureSize.width) * 0.5;
                    const pixelY = ((y * texture.textureSize.height) + texture.textureSize.height) * 0.5;

                    // Read that pixel from each layer and figure out the layer that was hit by the ray.

                    // Quick rejection, read the pixel from the texture first.
                    const textureCtx = texture.texture.getContext();

                    const pixelData = textureCtx.getImageData(pixelX, pixelY, 1, 1);
                    const pixels = pixelData.data;

                    if (pixels.length >= 4 && pixels[3] < 1) {
                        // Transparent pixel detected, don't bother with layer testing.
                        if (this.unselectTextureLayer()) {
                            // Notify consumers that the selection was removed.
                            this.emitter.$emit('renderSelectedLayerUpdated');
                        }

                        return;
                    }

                    // Arrange layers in the order that they appear in the state, or the explicit order assignment.
                    const layers = [];

                    texture.layers.forEach((layer, index) => {
                        layers.push({
                            layer,
                            explicit: layer.explicitOrder != null ? (+layer.explicitOrder) : Number.MAX_SAFE_INTEGER,
                            native: index,
                        });
                    });

                    layers.sort((a, b) => {
                        if (a.explicit < b.explicit) {
                            return -1;
                        }

                        if (a.explicit > b.explicit) {
                            return 1;
                        }

                        if (a.native < b.native) {
                            return -1;
                        }

                        if (a.native > b.native) {
                            return 1;
                        }

                        return 0;
                    });

                    layers.reverse();

                    let selected = null;

                    layers.forEach((l) => {
                        if (selected) {
                            return;
                        }

                        const image = l?.layer?.image?.image;

                        if (image == null) {
                            return;
                        }

                        const { buffer: maskingBuffer } = RenderHelper.getRenderBuffer('masking', image.width, image.height);

                        const bufferCtx = maskingBuffer.getContext('2d');

                        bufferCtx.clearRect(0, 0, image.width, image.height);

                        bufferCtx.drawImage(image, 0, 0);

                        const layerData = bufferCtx.getImageData(pixelX * l.layer.scale, pixelY * l.layer.scale, 1, 1);
                        const layerPixels = layerData.data;

                        if (layerPixels.length > 3 && layerPixels[3] > 1) {
                            selected = l.layer;
                        }
                    });

                    if (selected) {
                        // Find controller corresponding to the texture.
                        this.forAllControllers((controller) => {
                            const key = this.controllerKey(controller);

                            if (key === texture.controller) {
                                let unselected = false;

                                // Already selected?
                                if (this.selectedLayer) {
                                    const otherKey = this.controllerKey(this.selectedLayer.controller);

                                    if (key === otherKey && selected.code === this.selectedLayer.layer.code) {
                                        if (this.unselectTextureLayer()) {
                                            // Notify consumers that the selection was removed.
                                            this.emitter.$emit('renderSelectedLayerUpdated');
                                        }

                                        unselected = true;
                                    }
                                }

                                if (!unselected) {
                                    this.selectTextureLayer(controller, selected.code);
                                    this.requestRenderUpdate(1);
                                }
                            }
                        });
                    }
                }
            });
        },
    },
};
