<template>
    <div class="cz3__add-ai__progress">
        <div class="cz3__add-ai__progress__body">
            <div class="cz3__add-ai__progress__text cz3__add-ai__progress__text--top">
                <div
                    class="cz3__add-ai__progress__quip"
                    :key="currentTextTop + '-' + index"
                    v-html="currentTextTop"
                >
                </div>
            </div>

            <img src="../assets/spinner.gif" width="60" height="60" />

            <div class="cz3__add-ai__progress__text cz3__add-ai__progress__text--bottom">
                <div
                    class="cz3__add-ai__progress__quip"
                    :key="currentTextBottom + '-' + index"
                    v-html="currentTextBottom"
                >
                </div>
            </div>

            <button
                @click="closeAndQuit"
                :aria-label="$t('CLOSE')"
                type="button"
                class="cz3__add-ai__progress__close"
            >
                <icon-close />
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__add-ai__progress {
            position: absolute;

            inset: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            background: rgba(255, 255, 255, 0.6);

            z-index: 2000;
        }

        .cz3__add-ai__progress__body {
            position: relative;

            width: 260px;
            height: 262px;

            border-radius: 22px;
            background: #fff;
            box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.05);

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            overflow: hidden;
        }

        .cz3__add-ai__progress__close {
            position: absolute;
            top: 14px;
            right: 14px;

            width: 44px;
            height: 44px;

            border: none;
            background: transparent;

            svg {
                width: calc(18px * 1.208);
                height: calc(18px * 1.208);
            }
        }

        .cz3__add-ai__progress__text {
            padding: 14px 20px;

            &.cz3__add-ai__progress__text--top {
                min-height: 60px;
            }

            &.cz3__add-ai__progress__text--bottom {
                min-height: 80px;
            }
        }

        .cz3__add-ai__progress__quip {
            text-align: center;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
        }

        .cz3__change-text-enter-active {
            transition: all 0.1s ease-out;
        }

        .cz3__change-text-leave-active {
            transition: all 0.1s ease-out;
        }

        .cz3__change-text-enter-from {
            transform: translateY(20px);
            opacity: 0;
        }

        .cz3__change-text-leave-to {
            transform: translateY(-20px);
            opacity: 0;
        }
    }
</style>

<script>
    import IconClose from '../assets/close.svg';

    export default {
        props: {
            mode: {
                type: String,
                default: 'generate', // or use
            },
        },

        components: {
            IconClose,
        },

        data() {
            return {
                index: -1,

                currentTextTop: '',
                currentTextBottom: '',

                texts: {
                    generate: {
                        loopAt: 0,
                        prompts: {
                            top: this.$t('AI_GENERATE_TIPS_TOP').split('~').map((x) => x.trim()),
                            bottom: this.$t('AI_GENERATE_TIPS_BOTTOM').split('~').map((x) => x.trim()),
                        },
                    },

                    apply: {
                        loopAt: 0,
                        prompts: {
                            top: this.$t('AI_APPLY_TIPS_TOP').split('~').map((x) => x.trim()),
                            bottom: this.$t('AI_APPLY_TIPS_BOTTOM').split('~').map((x) => x.trim()),
                        },
                    },
                },
            };
        },

        mounted() {
            this.start();
        },

        beforeUnmount() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
        },

        methods: {
            start() {
                this.showNext();
            },

            showNext() {
                if (this.timer) {
                    clearTimeout(this.timer);
                }

                const texts = this.texts[this.mode];

                this.index += 1;

                if (this.index >= Math.min(texts.prompts.top.length, texts.prompts.bottom.length)) {
                    this.index = texts.loopAt;
                }

                this.currentTextTop = texts.prompts.top[this.index];
                this.currentTextBottom = texts.prompts.bottom[this.index];

                this.timer = setTimeout(() => {
                    this.showNext();
                }, 7000);
            },

            closeAndQuit() {
                this.$emit('quit');
            },
        },
    };
</script>
