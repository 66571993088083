<template>
    <div ref="container" class="cz3__handles"
        :class="{
            'cz3__handles--visible': visible
        }"
    >
        <!--
        <svg class="cz3__handles__body cz3__handles__body--moveable" v-if="width && height" :width="width" :height="height" :viewBox="viewBox">
            <path
                stroke-width="2"
                stroke="#000000"
                stroke-opacity="0.02"
                fill="#ffffff"
                fill-opacity="0.001"
                :d="linesPath">
            </path>
            <path
                stroke-width="1"
                stroke="#d4d4d4"
                stroke-opacity="0.05"
                fill="none"
                :d="linesPath">
            </path>
        </svg>
        -->

        <svg ref="handleMove" class="cz3__handles__body cz3__handles__body--moveable" v-if="width && height" :width="width" :height="height" :viewBox="viewBox">
            <path
                stroke-width="2"
                stroke="#000000"
                stroke-opacity="0.2"
                fill="#ffffff"
                fill-opacity="0.001"
                :d="fitLinesPath">
            </path>
            <path
                stroke-width="1"
                stroke="#d4d4d4"
                fill="none"
                :d="fitLinesPath">
            </path>
        </svg>

        <button v-for="(h, index) in handles"
            :ref="`handle|${h.type}|${index}`"
            :key="h.type"
            :id="`handle-${h.type}`"
            class="cz3__handle"
            :class="[`cz3__handle--${h.type}`]"
            :style="{
                left: `${h.where.x / aspectRatio}px`,
                top: `${h.where.y / aspectRatio}px`,
            }"
            @click.prevent="clickHandle(h)"
        >
            <span v-if="h.type === 'trash'" :key="'trash'" class="cz3__handle__background">
                <icon-handle-delete/>
            </span>

            <span v-if="h.type === 'size'" :key="'size'" class="cz3__handle__background">
                <icon-handle-size/>
            </span>

            <span v-if="h.type === 'duplicate'" :key="'duplicate'" class="cz3__handle__background">
                <icon-handle-duplicate/>
            </span>

            <span v-if="h.type === 'reset'" :key="'reset'" class="cz3__handle__background">
                <icon-handle-reset/>
            </span>

            <span v-if="h.type === 'rotate'" :key="'rotate'" class="cz3__handle__background">
                <icon-handle-rotate/>
            </span>

            <span v-if="h.type === 'size-top'" :key="'size-top'" class="cz3__handle__background">
                <icon-handle-side/>
            </span>

            <span v-if="h.type === 'size-bottom'" :key="'size-bottom'" class="cz3__handle__background">
                <icon-handle-side/>
            </span>

            <span v-if="h.type === 'size-right'" :key="'size-right'" class="cz3__handle__background">
                <icon-handle-side/>
            </span>

            <span v-if="h.type === 'size-left'" :key="'size-left'" class="cz3__handle__background">
                <icon-handle-side/>
            </span>
        </button>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__handles {
            touch-action: none;

            opacity: 0;

            transition: opacity 0.25s ease-out;
        }

        .cz3__handles--visible {
            opacity: 1;
        }

        .cz3__handle {
            position: absolute;

            width: 26px;
            height: 26px;

            border-radius: 26px;

            margin-left: -13px;
            margin-top: -13px;

            border: 1px solid $color-primary-black;
            background: $color-primary-white;

            pointer-events: auto;

            cursor: pointer;

            &.cz3__handle--size-top,
            &.cz3__handle--size-bottom,
            &.cz3__handle--size-right,
            &.cz3__handle--size-left {
                width: 26px;
                height: 26px;

                border: none;
                background: none;
            }

            &.cz3__handle--reset {
                width: 70px;
                margin-left: -45px;
                margin-top: -10px;
            }

            svg {
                position: absolute;

                left: 50%;
                top: 50%;

                transform: translate(-50%, -50%);
            }
        }

        .cz3__handles__body {
            position: absolute;

            left: 0;
            top: 0;

            pointer-events: none;

            overflow: visible;

            &.cz3__handles__body--moveable {
                pointer-events: auto;
            }
        }
    }
</style>

<script>
    import interact from 'interactjs';

    import allStores from '../stores/all';

    import IconHandleDelete from '../assets/handle-delete.svg';
    import IconHandleSize from '../assets/handle-size.svg';
    import IconHandleDuplicate from '../assets/handle-duplicate.svg';
    import IconHandleRotate from '../assets/handle-rotate.svg';
    import IconHandleSide from '../assets/handle-side.svg';
    import IconHandleReset from '../assets/handle-reset.svg';

    export default {
        mixins: [allStores],

        components: {
            IconHandleDelete,
            IconHandleSize,
            IconHandleDuplicate,
            IconHandleRotate,
            IconHandleSide,
            IconHandleReset,
        },

        props: {
            layer: Object,
            layerWidth: Number,
            layerHeight: Number,
            handles: Array,
            lines: Array,
            fitLines: Array,
            outside: Boolean,
        },

        data() {
            return {
                active: null,

                width: 0,
                height: 0,
                viewBox: '',
            };
        },

        computed: {
            aspectRatio() {
                return this.customizerStore.cameraAspectRatio;
            },

            visible() {
                return this.layerWidth > 0 && this.layerHeight > 0;
            },

            linesPath() {
                const path = [];

                this.lines.forEach((h, index) => {
                    if (index === 0) {
                        path.push(`M ${h.where.x / this.aspectRatio} ${h.where.y / this.aspectRatio}`);
                    } else {
                        path.push(`L ${h.where.x / this.aspectRatio} ${h.where.y / this.aspectRatio}`);
                    }
                });

                path.push('Z');

                return path.join(' ');
            },

            fitLinesPath() {
                const path = [];

                this.fitLines.forEach((h, index) => {
                    if (index === 0) {
                        path.push(`M ${h.where.x / this.aspectRatio} ${h.where.y / this.aspectRatio}`);
                    } else {
                        path.push(`L ${h.where.x / this.aspectRatio} ${h.where.y / this.aspectRatio}`);
                    }
                });

                path.push('Z');

                return path.join(' ');
            },
        },

        watch: {
        },

        mounted() {
            window.addEventListener('resize', this.resize);

            this.resize();

            this.$nextTick(() => {
                this.enableInteract();
            });
        },

        updated() {
            // this.$nextTick(() => {
            //     this.enableInteract();
            // });
        },

        beforeUpdate() {
            // this.removeInteract();
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.resize);

            this.removeInteract();
        },

        methods: {
            resize() {
                const w = this.$refs.container.offsetWidth;
                const h = this.$refs.container.offsetHeight;

                this.width = w;
                this.height = h;

                this.viewBox = `0 0 ${w} ${h}`;
            },

            clickHandle(handle) {
                if (handle.type === 'trash') {
                    this.deleteLayer();

                    this.appStore.trackAnalyticsEvent({
                        action: 'cyo_edit_deleted',
                    });
                }

                if (handle.type === 'duplicate') {
                    this.duplicateLayer();
                }

                if (handle.type === 'reset') {
                    this.resetLayer();
                }
            },

            deleteLayer() {
                if (this.layer.controller && this.layer.layer?.code) {
                    this.customizerStore.clearPlacement(this.layer.controller, this.layer.layer.code);

                    // Also unselect the layer and have the next layer to be selected.
                    // Data path:
                    // EditHandles -> ProductRenderer -> main container -> StepOptionsEditImages -> CameraView -> ... -> back with selected layer.
                    // TODO: How to make this more elegant?
                    // Currently StepOptionsEditImages (ensureSelected...) will detect that the selected layer is gone and select something else.
                }
            },

            resetLayer() {
                const { controller } = this.layer;
                const placement = this.layer.layer?.code;

                if (controller && placement) {
                    const component = controller.selectedAtPlacement(placement);

                    if (component.custom['ps-image-initial-scale']) {
                        controller.commitCustomAttributes(placement, {
                            'dynamic-image-scale': component.custom['ps-image-initial-scale'],
                            'dynamic-image-offset-x': component.custom['ps-image-initial-x'],
                            'dynamic-image-offset-y': component.custom['ps-image-initial-y'],
                            'dynamic-image-rotate': '0',
                            'ps-moved': 'false',
                        });
                    }
                }
            },

            duplicateLayer() {
                const { controller } = this.layer;
                const placement = this.layer.layer?.code;

                if (controller && placement) {
                    const component = controller.selectedAtPlacement(placement);

                    if (component) {
                        if (component.custom['widget-type'] === 'personalization') {
                            this.customizerStore.copyText({
                                controller,
                                placement: controller.findPlacement(placement),
                                component,
                            });
                        } else {
                            const type = component.custom['ps-type'];

                            // Fake an image.
                            const image = {
                                title: component.custom['dynamic-image-title'],
                                name: component.custom['dynamic-image-name'],
                                imageUrl: component.custom['dynamic-image-url'],
                                pngImageUrl: component.custom['dynamic-image-pngUrl'],
                                jpgImageUrl: component.custom['dynamic-image-jpgUrl'],
                                width: +component.custom['dynamic-image-width'],
                                height: +component.custom['dynamic-image-height'],
                                fit: type === 'stickers' ? 'none' : null,
                                sourceCode: component.custom['gallery-source'],
                            };

                            const toPlacement = this.customizerStore.addImage({
                                image,
                                type,
                            });

                            if (toPlacement) {
                                const width = +(component.custom['dynamic-image-width']);
                                const height = +(component.custom['dynamic-image-height']);

                                let imageX = +((component.custom?.['dynamic-image-offset-x']) || '0');
                                let imageY = +((component.custom?.['dynamic-image-offset-y']) || '0');

                                const scale = +((component.custom['dynamic-image-scale'] || '1'));

                                imageX += width * scale * 0.1;
                                imageY += height * scale * 0.1;

                                // This will trigger the texture repaint.
                                controller.commitCustomAttributes(toPlacement, {
                                    'dynamic-image-scale': component.custom['dynamic-image-scale'],
                                    'dynamic-image-rotate': component.custom['dynamic-image-rotate'],
                                    'dynamic-image-offset-x': imageX.toString(),
                                    'dynamic-image-offset-y': imageY.toString(),
                                });
                            }
                        }
                    }
                }
            },

            /**
             * Removes InteractJS integration, typically before VueJS DOM updates.
             * @return {void}
             */
            removeInteract() {
                if (this.interact) {
                    if (this.$refs.handleMove) {
                        interact(this.$refs.handleMove).unset();
                    }

                    Object.keys(this.$refs).forEach((k) => {
                        if (k.startsWith('handle|')) {
                            let el = this.$refs[k];
                            if (Array.isArray(el)) {
                                el = el[0];
                            }

                            if (el) {
                                interact(el).unset();
                            }
                        }
                    });

                    this.interact = false;
                }
            },

            /**
             * Gets device pixel ratio, capped at the same rate as the CameraView.
             */
            scaleFactor() {
                const scale = window.devicePixelRatio;

                return scale > 2 ? 2 : scale;
            },

            setActive(status) {
                this.active = status;
            },

            /**
             * Enables user interaction with the editor.
             */
            enableInteract() {
                this.removeInteract();

                // eslint-disable-next-line
                let mainInteractable = this.$refs.handleMove ? interact(this.$refs.handleMove) : null;

                // eslint-disable-next-line no-unused-vars
                mainInteractable = mainInteractable
                    .on('tap', (e) => {
                        this.emitter.$emit('selectLayerFromPoint', {
                            x: e.offsetX,
                            y: e.offsetY,
                        });
                    })
                    .gesturable({
                        listeners: {
                            start: () => {
                                this.setActive(true);
                            },

                            move: (event) => {
                                let startAngle = this.progress?.startAngle;
                                if (startAngle == null) {
                                    startAngle = event.angle;
                                }

                                this.progress = {
                                    layer: this.layer,
                                    x: 0,
                                    y: 0,
                                    startAngle,
                                    rotate: event.angle - startAngle,
                                    scale: event.scale,
                                };

                                this.emitter.$emit('setEditorProgress', this.progress);
                            },

                            end: () => {
                                this.appStore.trackAnalyticsEvent({
                                    action: 'cyo_edit_moved',
                                });

                                this.setActive(false);

                                if (this.progress) {
                                    // Apply changes.
                                    const { controller } = this.layer;
                                    const placement = this.layer.layer?.code;

                                    if (controller && placement) {
                                        const component = controller.selectedAtPlacement(placement);

                                        if (component) {
                                            if (component.custom['widget-type'] === 'personalization') {
                                                const rotate = (+((component.custom?.['personalization-rotate']) || '1')) + this.progress.rotate;

                                                // This will trigger the texture repaint.
                                                controller.commitCustomAttributes(placement, {
                                                    'personalization-rotate': rotate,
                                                }, {
                                                    propagate: true,
                                                });

                                                const scale = (+((component.custom?.['personalization-scale']) || '1')) * this.progress.scale;

                                                // This will trigger the texture repaint.
                                                controller.commitCustomAttributes(placement, {
                                                    'personalization-scale': scale,
                                                }, {
                                                    propagate: true,
                                                });
                                            } else {
                                                const rotate = (+((component.custom?.['dynamic-image-rotate']) || '1')) + this.progress.rotate;

                                                // This will trigger the texture repaint.
                                                controller.commitCustomAttributes(placement, {
                                                    'ps-moved': 'true',
                                                    'dynamic-image-rotate': rotate,
                                                });

                                                const scale = (+((component.custom?.['dynamic-image-scale']) || '1')) * this.progress.scale;

                                                // This will trigger the texture repaint.
                                                controller.commitCustomAttributes(placement, {
                                                    'ps-moved': 'true',
                                                    'dynamic-image-scale': scale,
                                                });
                                            }
                                        }
                                    }
                                }

                                this.progress = null;
                                this.emitter.$emit('setEditorProgress', null);
                            },
                        },
                    })
                    .draggable({
                        onstart: () => {
                            this.setActive(true);
                        },

                        onend: () => {
                            this.appStore.trackAnalyticsEvent({
                                action: 'cyo_edit_moved',
                            });

                            this.setActive(false);

                            if (this.progress) {
                                // Apply changes.
                                const { controller } = this.layer;
                                const placement = this.layer.layer?.code;

                                if (controller && placement) {
                                    const component = controller.selectedAtPlacement(placement);

                                    if (component) {
                                        if (component.custom['widget-type'] === 'personalization') {
                                            const imageX = +((component.custom?.['personalization-x']) || '0') + (this.progress.x);
                                            const imageY = +((component.custom?.['personalization-y']) || '0') + (this.progress.y);

                                            // This will trigger the texture repaint.
                                            controller.commitCustomAttributes(placement, {
                                                'personalization-x': imageX,
                                                'personalization-y': imageY,
                                            }, {
                                                propagate: true,
                                            });
                                        } else {
                                            const imageX = +((component.custom?.['dynamic-image-offset-x']) || '0') + (this.progress.x);
                                            const imageY = +((component.custom?.['dynamic-image-offset-y']) || '0') + (this.progress.y);

                                            // This will trigger the texture repaint.
                                            controller.commitCustomAttributes(placement, {
                                                'ps-moved': 'true',
                                                'dynamic-image-offset-x': imageX,
                                                'dynamic-image-offset-y': imageY,
                                            });
                                        }
                                    }
                                }
                            }

                            this.$nextTick(() => {
                                // Check if the layer go moved too far.
                                if (this.outside) {
                                    this.deleteLayer();
                                }
                            });

                            this.progress = null;
                            this.emitter.$emit('setEditorProgress', null);
                        },

                        onmove: (event) => {
                            const dx = event.pageX - event.x0;
                            const dy = event.pageY - event.y0;

                            // Figure out the scaling factor in each dimension to make it a bit more 1:1 movement.
                            const { screen, corners } = this.layer;

                            const projectedWidthUnit = screen.containerLeft.subtract(screen.containerOrigin).length() / corners.containerWidth;
                            const projectedHeightUnit = screen.containerUp.subtract(screen.containerOrigin).length() / corners.containerHeight;

                            const f = this.scaleFactor();

                            let x = f * (dx / projectedWidthUnit);
                            let y = f * (dy / projectedHeightUnit);

                            // Snap to center of the area.
                            const { controller } = this.layer;
                            const placement = this.layer.layer?.code;

                            if (controller && placement) {
                                const component = controller.selectedAtPlacement(placement);

                                if (component) {
                                    if (component.custom['widget-type'] === 'personalization') {
                                        const width = +((component.custom?.['personalization-width']) || '0') * 0.5;
                                        const height = +((component.custom?.['personalization-height']) || '0') * 0.5;

                                        const imageX = +((component.custom?.['personalization-x']) || '0') + (width) + (x);
                                        const imageY = +((component.custom?.['personalization-y']) || '0') + (height) + (y);

                                        let areaWidth = +controller.blueprint.custom['layout-1-width'];
                                        let areaHeight = +controller.blueprint.custom['layout-1-height'];

                                        areaWidth *= 0.5;
                                        areaHeight *= 0.5;

                                        if (Math.abs(imageX - areaWidth) < 15) {
                                            const diff = areaWidth - imageX;

                                            x += diff;
                                        }

                                        if (Math.abs(imageY - areaHeight) < 15) {
                                            const diff = areaHeight - imageY;

                                            y += diff;
                                        }
                                    } else {
                                        const imageX = +((component.custom?.['dynamic-image-offset-x']) || '0') + (x);
                                        const imageY = +((component.custom?.['dynamic-image-offset-y']) || '0') + (y);

                                        let areaWidth = +(component.custom['dynamic-image-area-width']);
                                        let areaHeight = +(component.custom['dynamic-image-area-height']);

                                        areaWidth *= 0.5;
                                        areaHeight *= 0.5;

                                        if (Math.abs(imageX - areaWidth) < 15) {
                                            const diff = areaWidth - imageX;

                                            x += diff;
                                        }

                                        if (Math.abs(imageY - areaHeight) < 15) {
                                            const diff = areaHeight - imageY;

                                            y += diff;
                                        }
                                    }
                                }
                            }

                            this.progress = {
                                layer: this.layer,
                                x,
                                y,
                                rotate: 0,
                                scale: 1,
                            };

                            this.emitter.$emit('setEditorProgress', this.progress);
                        },
                    });

                // Size handles.
                Object.keys(this.$refs).forEach((k) => {
                    if (k.startsWith('handle|')) {
                        const prefixLength = 'handle|'.length;

                        const type = k.substr(prefixLength, k.indexOf('|', prefixLength) - prefixLength);

                        if (type.startsWith('rotate')) {
                            let el = this.$refs[k];
                            if (Array.isArray(el)) {
                                el = el[0];
                            }

                            // eslint-disable-next-line
                            let interactable = el ? interact(el) : null;

                            // eslint-disable-next-line no-unused-vars
                            interactable.draggable({
                                onstart: () => {
                                    this.setActive(true);
                                },

                                onend: () => {
                                    this.appStore.trackAnalyticsEvent({
                                        action: 'cyo_edit_moved',
                                    });

                                    this.setActive(false);

                                    if (this.progress) {
                                        // Apply changes.
                                        const { controller } = this.layer;
                                        const placement = this.layer.layer?.code;

                                        if (controller && placement) {
                                            const component = controller.selectedAtPlacement(placement);

                                            if (component) {
                                                if (component.custom['widget-type'] === 'personalization') {
                                                    const rotate = (+((component.custom?.['personalization-rotate']) || '0')) + this.progress.rotate;

                                                    // This will trigger the texture repaint.
                                                    controller.commitCustomAttributes(placement, {
                                                        'personalization-rotate': rotate,
                                                    }, {
                                                        propagate: true,
                                                    });
                                                } else {
                                                    const rotate = (+((component.custom?.['dynamic-image-rotate']) || '0')) + this.progress.rotate;

                                                    // This will trigger the texture repaint.
                                                    controller.commitCustomAttributes(placement, {
                                                        'ps-moved': 'true',
                                                        'dynamic-image-rotate': rotate,
                                                    });
                                                }
                                            }
                                        }
                                    }

                                    this.progress = null;
                                    this.emitter.$emit('setEditorProgress', null);
                                },

                                onmove: (event) => {
                                    const dx = event.pageX - event.x0;
                                    const dy = event.pageY - event.y0;

                                    // Figure out the scaling factor in each dimension to make it a bit more 1:1 movement.
                                    const { screen } = this.layer;

                                    const handle = screen.bl;

                                    let handleX = this.progress?.startHandleX;
                                    let handleY = this.progress?.startHandleY;

                                    if (handleX == null || handleY == null) {
                                        handleX = handle.x;
                                        handleY = handle.y;
                                    }

                                    const startAngle = Math.atan2(handleY - screen.center.y, handleX - screen.center.x);

                                    const f = this.scaleFactor();

                                    const x = handleX + (dx * f);
                                    const y = handleY + (dy * f);

                                    const currentAngle = Math.atan2(y - screen.center.y, x - screen.center.x);

                                    // Apply changes.
                                    let baseAngle = 0;

                                    const { controller } = this.layer;
                                    const placement = this.layer.layer?.code;

                                    if (controller && placement) {
                                        const component = controller.selectedAtPlacement(placement);

                                        if (component) {
                                            if (component.custom['widget-type'] === 'personalization') {
                                                baseAngle = (+((component.custom?.['personalization-rotate']) || '0'));
                                            } else {
                                                baseAngle = (+((component.custom?.['dynamic-image-rotate']) || '0'));
                                            }
                                        }
                                    }

                                    // Snap at 45 degree positions.
                                    let rotate = (((currentAngle - startAngle) * 180) / Math.PI) + baseAngle;

                                    const diff = rotate % 45;

                                    if (Math.abs(diff) < 5 || Math.abs(diff) > 40) {
                                        rotate = Math.round(rotate / 45) * 45;
                                    }

                                    rotate -= baseAngle;

                                    this.progress = {
                                        layer: this.layer,
                                        startHandleX: handleX,
                                        startHandleY: handleY,
                                        x: 0,
                                        y: 0,
                                        rotate,
                                        scale: 1,
                                    };

                                    this.emitter.$emit('setEditorProgress', this.progress);
                                },
                            });
                        }

                        if (type.startsWith('size')) {
                            let el = this.$refs[k];
                            if (Array.isArray(el)) {
                                el = el[0];
                            }

                            // eslint-disable-next-line
                            let interactable = el ? interact(el) : null;

                            // eslint-disable-next-line no-unused-vars
                            interactable.draggable({
                                onstart: () => {
                                    this.setActive(true);
                                },

                                onend: () => {
                                    this.appStore.trackAnalyticsEvent({
                                        action: 'cyo_edit_moved',
                                    });

                                    this.setActive(false);

                                    if (this.progress) {
                                        // Apply changes.
                                        const { controller } = this.layer;
                                        const placement = this.layer.layer?.code;

                                        if (controller && placement) {
                                            const component = controller.selectedAtPlacement(placement);

                                            if (component) {
                                                if (component.custom['widget-type'] === 'personalization') {
                                                    const scale = (+((component.custom?.['personalization-scale']) || '1')) * this.progress.scale;

                                                    // This will trigger the texture repaint.
                                                    controller.commitCustomAttributes(placement, {
                                                        'personalization-scale': scale,
                                                    }, {
                                                        propagate: true,
                                                    });
                                                } else {
                                                    const scale = (+((component.custom?.['dynamic-image-scale']) || '1')) * this.progress.scale;

                                                    // This will trigger the texture repaint.
                                                    controller.commitCustomAttributes(placement, {
                                                        'ps-moved': 'true',
                                                        'dynamic-image-scale': scale,
                                                    });
                                                }
                                            }
                                        }
                                    }

                                    this.progress = null;
                                    this.emitter.$emit('setEditorProgress', null);
                                },

                                onmove: (event) => {
                                    const dx = event.pageX - event.x0;
                                    const dy = event.pageY - event.y0;

                                    // Figure out the scaling factor in each dimension to make it a bit more 1:1 movement.
                                    const { screen } = this.layer;

                                    let handle;

                                    if (type === 'size-top') {
                                        handle = screen.mt;
                                    } else if (type === 'size-left') {
                                        handle = screen.ml;
                                    } else if (type === 'size-bottom') {
                                        handle = screen.mb;
                                    } else if (type === 'size-right') {
                                        handle = screen.mr;
                                    } else {
                                        handle = screen.tr;
                                    }

                                    let handleX = this.progress?.startHandleX;
                                    let handleY = this.progress?.startHandleY;

                                    if (handleX == null || handleY == null) {
                                        handleX = handle.x;
                                        handleY = handle.y;
                                    }

                                    const startDistance = Math.sqrt(
                                        (handleX - screen.center.x) * (handleX - screen.center.x)
                                            + (handleY - screen.center.y) * (handleY - screen.center.y),
                                    );

                                    const f = this.scaleFactor();

                                    const x = handleX + (dx * f);
                                    const y = handleY + (dy * f);

                                    const currentDistance = Math.sqrt(
                                        (x - screen.center.x) * (x - screen.center.x)
                                            + (y - screen.center.y) * (y - screen.center.y),
                                    );

                                    if (startDistance !== 0) {
                                        let scale = currentDistance / startDistance;

                                        if (scale > 3) {
                                            scale = 3;
                                        }

                                        if (scale < 0.2) {
                                            scale = 0.2;
                                        }

                                        this.progress = {
                                            layer: this.layer,
                                            startHandleX: handleX,
                                            startHandleY: handleY,
                                            x: 0,
                                            y: 0,
                                            rotate: 0,
                                            scale,
                                        };

                                        this.emitter.$emit('setEditorProgress', this.progress);
                                    }
                                },
                            });
                        }
                    }
                });

                this.interact = true;
            },
        },
    };
</script>
