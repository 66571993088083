/**
 * Get design area dimensions.
 */
export default function designArea(controller, layout) {
    const useController = controller;

    if (layout) {
        return {
            left: +(useController.blueprint.custom[`layout-${layout}-x`] || '0'),
            top: +(useController.blueprint.custom[`layout-${layout}-y`] || '0'),
            right: +(useController.blueprint.custom[`layout-${layout}-x`] || '0') + (+useController.blueprint.custom[`layout-${layout}-width`]),
            bottom: +(useController.blueprint.custom[`layout-${layout}-y`] || '0') + (+useController.blueprint.custom[`layout-${layout}-height`]),
            width: +useController.blueprint.custom[`layout-${layout}-width`],
            height: +useController.blueprint.custom[`layout-${layout}-height`],
        };
    }

    return {
    };
}
