<template>
    <div class="cz3__ai-guided">
        <div class="cz3__ai-guided__main">
            <button v-for="b in visibleButtons"
                :key="b.displayName"
                class="cz3__ai-guided__main-button"
                :class="{
                    'cz3__ai-guided__main-button--active': b.anySubActive,
                    'cz3__ai-guided__main-button--expanded': b.showSubButtons,
                }"
                @click.prevent="toggleButton(b)"
            >
                <!--eslint-disable -->
                <span v-if="!b.showSubButtons && b.anySubActive" class="cz3__ai-guided__main-button__check">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1752 5.14347C15.4641 5.33237 15.5452 5.7197 15.3563 6.00861L9.68965 14.6753C9.5895 14.8285 9.42723 14.93 9.24568 14.9532C9.06413 14.9764 8.88155 14.9188 8.74613 14.7957L5.07946 11.4624C4.82405 11.2302 4.80523 10.8349 5.03742 10.5795C5.26961 10.3241 5.66489 10.3053 5.9203 10.5374L9.04458 13.3777L14.3101 5.32454C14.499 5.03564 14.8863 4.95457 15.1752 5.14347Z" fill="#181818"/>
                    </svg>
                </span>
                <!-- eslint-enable -->

                <span>
                    {{b.displayName}}
                </span>

                <!--eslint-disable -->
                <span v-if="b.showSubButtons || !b.anySubActive" class="cz3__ai-guided__main-button__expand">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 2005">
                    <path id="Shape" d="M1 11L7 6L1 1" stroke="#181818" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </svg>
                </span>
                <!-- eslint-enable -->
            </button>
        </div>

        <div class="cz3__ai-guided__panels" v-auto-animate>
            <ai-guided-double-radios v-for="b in doubleRadioButtons" :key="b.displayName"
                :button="b"
            ></ai-guided-double-radios>

            <ai-guided-radios v-for="b in radioButtons" :key="b.displayName"
                :button="b"
            ></ai-guided-radios>

            <ai-guided-double-image-radios v-for="b in doubleImageButtons" :key="b.displayName"
                :button="b"
            ></ai-guided-double-image-radios>

            <ai-guided-image-radios v-for="b in imageButtons" :key="b.displayName"
                :button="b"
            ></ai-guided-image-radios>

            <ai-guided-large-image-radios v-for="b in largeImageButtons" :key="b.displayName"
                :button="b"
            ></ai-guided-large-image-radios>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-guided {
            padding: 14px 24px 0;
        }

        .cz3__ai-guided__main {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        .cz3__ai-guided__main-button {
            flex: 0 0 auto;

            padding: 8px 16px;

            display: flex;
            align-items: center;

            border: 1px solid $color-primary-black;
            background: transparent;
            color: $color-primary-black;

            border-radius: 40px;

            font-family: $font-main;
            font-size: 14px;
            line-height: 20px;

            transition: all 0.25s ease-out;

            .cz3__ai-guided__main-button__check {
                display: flex;
            }

            .cz3__ai-guided__main-button__expand {
                display: flex;

                margin-left: 8px;

                transition: transform 0.25s ease-out;
            }

            &--active {
                background: $color-better-green;
                color: $color-primary-black;
            }

            &--expanded {
                background: $color-primary-black;
                color: $color-primary-white;

                .cz3__ai-guided__main-button__expand {
                    transform: rotate(90deg);

                    svg {
                        path {
                            stroke: $color-primary-white;
                        }
                    }
                }
            }
        }
    }
</style>

<script>
    import AiGuidedDoubleRadios from './AiGuidedDoubleRadios.vue';
    import AiGuidedRadios from './AiGuidedRadios.vue';
    import AiGuidedDoubleImageRadios from './AiGuidedDoubleImageRadios.vue';
    import AiGuidedImageRadios from './AiGuidedImageRadios.vue';
    import AiGuidedLargeImageRadios from './AiGuidedLargeImageRadios.vue';

    export default {
        components: {
            AiGuidedDoubleRadios,
            AiGuidedRadios,
            AiGuidedDoubleImageRadios,
            AiGuidedImageRadios,
            AiGuidedLargeImageRadios,
        },

        props: {
            structure: Object,
            buttons: Array,
        },

        data() {
            return {};
        },

        computed: {
            visibleButtons() {
                return (this.buttons ?? []).filter((x) => x.displayName?.trim()?.length > 0);
            },

            doubleRadioButtons() {
                return this.visibleButtons.filter((x) => x.showSubButtons && x.selectionFormat === 'radioButton' && x.type === 'doubleTier');
            },

            radioButtons() {
                return this.visibleButtons.filter((x) => x.showSubButtons && x.selectionFormat === 'radioButton' && x.type === 'singleTier');
            },

            doubleImageButtons() {
                return this.visibleButtons.filter((x) => x.showSubButtons && x.selectionFormat === 'radioButtonImage' && x.type === 'doubleTier');
            },

            imageButtons() {
                return this.visibleButtons.filter((x) => x.showSubButtons && x.selectionFormat === 'radioButtonImage' && x.type === 'singleTier');
            },

            largeImageButtons() {
                return this.visibleButtons.filter((x) => x.showSubButtons && x.selectionFormat === 'largeButtonImage');
            },
        },

        methods: {
            toggleButton(button) {
                for (const b of this.visibleButtons) {
                    if (b !== button) {
                        b.showSubButtons = false;
                    }
                }

                button.showSubButtons = !button.showSubButtons;
            },
        },
    };
</script>
