import { createI18n } from 'vue-i18n';
import enUsTranslations from './lang/en-us.json';

export default function mergeTranslations(locale, overrides) {
    const translations = JSON.parse(JSON.stringify({ 'en-US': enUsTranslations }));

    if (overrides) {
        Object.keys(translations).forEach((lang) => {
            if (overrides[lang]) {
                Object.assign(translations[lang], overrides[lang]);
            }
        });

        Object.keys(overrides).forEach((lang) => {
            if (overrides[lang]) {
                if (translations[lang] == null) {
                    translations[lang] = {};
                }
                Object.assign(translations[lang], overrides[lang]);
            }
        });
    }

    return createI18n({
        locale,

        fallbackLocale: 'en-US',

        messages: translations,

        silentFallbackWarn: true,
        silentTranslationWarn: true,
    });
}
