import { createApp } from 'vue';
import { createPinia } from 'pinia';
import easySpinner from 'vue-easy-spinner';
import { vAutoAnimate } from '@formkit/auto-animate';

import VueSvgInlinePlugin from 'vue-svg-inline-plugin';

import App from './App.vue';

import i18n from './strings';

import emitter from './helpers/bus';

/**
 * Loads Customizer back-end script, also checking if it was already loaded.
 * @param  {[type]} url [description]
 * @return {[type]}     [description]
 */
function loadScript(url) {
    return new Promise((resolve) => {
        // Check if already loaded.
        if ('DriveCustomizer' in window) {
            setTimeout(() => {
                resolve(window.DriveCustomizer);
            });
        } else {
            const script = document.createElement('script');
            script.addEventListener('load', () => resolve(window.DriveCustomizer));
            script.src = url;
            document.head.appendChild(script);
        }
    });
}

function createWidget(options) {
    // Load the API script and other dependencies.
    Promise.all([
        loadScript('https://api.customizer.drivecommerce.com/api/v2/runtime/client?type=production'),
    ]).then(([DriveCustomizer]) => {
        // Create a few new page container.
        const containerId = `id-${new Date().getTime()}`;
        const container = document.createElement('div');
        container.id = containerId;
        container.classList.add('cz3');
        document.querySelector('body').appendChild(container);

        const translations = options.translations;

        const configurationOptions = {
            ...{
                DriveCustomizer,
            },
            ...options,
        };
        const app = createApp(App, {
            options: configurationOptions,
            onSelfDestruct() {
                app.unmount();
            },
        });

        app.use(i18n(options.locale, translations));

        app.use(createPinia());

        app.directive('auto-animate', vAutoAnimate);

        app.use(easySpinner, { prefix: 'easy' });

        app.config.globalProperties.emitter = emitter;

        app.use(VueSvgInlinePlugin, {
            attributes: {
                data: ['src'],
                remove: ['alt'],
            },
            cache: {
                persistent: false,
            },
        });

        app.mount(container);
    });
}

// Export globals.
window.CustomizerWidget = {
    createWidget,
};

// Expose the widget library namespace.
export {
    // eslint-disable-next-line import/prefer-default-export
    createWidget,
};
