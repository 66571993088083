<template>
    <transition name="cz3__fade">
        <step-options-bundle v-if="currentStep === 'selectBundle'"
            :cases="false"
        ></step-options-bundle>
    </transition>

    <transition name="cz3__fade">
        <step-options-bundle v-if="currentStep === 'selectCaseBundle'"
            :cases="true"
        ></step-options-bundle>
    </transition>

    <transition name="cz3__fade">
        <step-options-phone v-if="currentStep === 'selectPhone'"
        ></step-options-phone>
    </transition>

    <transition name="cz3__fade">
        <step-options-edit v-if="currentStep === 'edit'"
        ></step-options-edit>
    </transition>

    <transition name="cz3__fade">
        <step-options-edit-options v-if="currentStep === 'editOptions'"
        ></step-options-edit-options>
    </transition>

    <transition name="cz3__fade">
        <step-options-edit-image v-if="currentStep === 'editImage'"
        ></step-options-edit-image>
    </transition>

    <transition name="cz3__fade">
        <step-options-preview v-if="currentStep === 'preview'"
        ></step-options-preview>
    </transition>

    <transition name="cz3__fade">
        <step-options-preview v-if="currentStep === 'previewFromImage'"
        ></step-options-preview>
    </transition>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options {
            position: absolute;

            width: 100%;

            left: 0;
            // Above the navigation panel.
            // bottom: 72px;
            // pushing this up further to allow for an additional line of text,
            // due to longer product names in certain languages.
            bottom: 90px;

            padding: 0 12px;

            @include respond-above(sm) {
                width: $content-large-width;

                left: calc(50% - #{math.div($content-large-width, 2)});
                // Above the navigation panel + margins.
                // bottom: 104px;
                // pushing this up further to allow for an additional line of text,
                // due to longer product names in certain languages.
                bottom: 120px;

                padding: 0;
            }
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import StepOptionsBundle from './StepOptionsBundle.vue';
    import StepOptionsPhone from './StepOptionsPhone.vue';
    import StepOptionsEdit from './StepOptionsEdit.vue';
    import StepOptionsEditOptions from './StepOptionsEditOptions.vue';
    import StepOptionsEditImage from './StepOptionsEditImage.vue';
    import StepOptionsPreview from './StepOptionsPreview.vue';

    export default {
        mixins: [allStores],

        components: {
            StepOptionsBundle,
            StepOptionsPhone,
            StepOptionsEdit,
            StepOptionsEditOptions,
            StepOptionsPreview,
            StepOptionsEditImage,
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },
        },
    };
</script>
