import { Color4 } from '@babylonjs/core/Maths/math.color';

import * as ThreeUtilities from './threeutilities';

export const mapFormatType = {
    boolean: (v) => (v ? 'true' : 'false'),
    float: (v) => (v || 0).toFixed(4),
    color3: (v) => ThreeUtilities.formatColor3(v.toGammaSpace()),
    color4: (v) => ThreeUtilities.formatColor4(v.toGammaSpace()),

    disableAoTexture: (v) => (v ? 'true' : 'false'),

    edgeEpsilon: (v, m) => (m.customEdgeEpsilon == null ? '' : m.customEdgeEpsilon.toString()),
    edgeWidth: (v, m) => (m.customEdgeWidth == null ? '' : m.customEdgeWidth.toString()),
    edgeColor: (v, m) => (m.customEdgeColor == null ? '' : ThreeUtilities.formatColor4(m.customEdgeColor.toGammaSpace())),
};

export const mapNotifyChange = {
    isRefractionEnabled: true,
    'subSurface.isRefractionEnabled': true,
};

export const mapParseType = {
    boolean: (v) => v === 'true',
    float: (v) => parseFloat(v || '0'),
    color3: (v) => ThreeUtilities.toColor3(v).toLinearSpace(),
    color4: (v) => ThreeUtilities.toColor4(v).toLinearSpace(),

    disableAoTexture: (v, material) => {
        if (v === 'true') {
            if (material.ambientTexture) {
                const t = material.ambientTexture;

                material.ambientTexture = null;

                t.dispose();

                material.customDisableAoTexture = true;
            }
        }
    },

    edgeEpsilon: (v, material, scene) => {
        material.customEdgeEpsilon = v == null || v === '' ? 0 : parseFloat(v);

        scene.meshes.forEach((mesh) => {
            if (mesh.material && mesh.material.id === material.id) {
                // Edge rendering.
                if (material.customEdgeEpsilon != null) {
                    if (material.customEdgeEpsilon < 0.0001) {
                        mesh.disableEdgesRendering();
                    } else {
                        mesh.enableEdgesRendering(material.customEdgeEpsilon);
                    }
                }
            }
        });
    },

    edgeWidth: (v, material, scene) => {
        material.customEdgeWidth = v == null || v === '' ? 0 : parseFloat(v);

        scene.meshes.forEach((mesh) => {
            if (mesh.material && mesh.material.id === material.id) {
                if (material.customEdgeWidth != null) {
                    mesh.edgesWidth = material.customEdgeWidth;
                }
            }
        });
    },

    edgeColor: (v, material, scene) => {
        material.customEdgeColor = v == null || v === '' ? new Color4(0, 0, 0, 1) : ThreeUtilities.toColor4(v).toLinearSpace();

        scene.meshes.forEach((mesh) => {
            if (mesh.material && mesh.material.id === material.id) {
                if (material.customEdgeColor) {
                    mesh.edgesColor = material.customEdgeColor;
                }
            }
        });
    },
};

export const mapAttribute = {
    albedoColor: 'color3',
    reflectivityColor: 'color3',
    microSurface: 'float',
    emissiveColor: 'color3',
    metallic: 'float',
    roughness: 'float',
    indexOfRefraction: 'float',
    metallicF0Factor: 'float',
    metallicReflectanceColor: 'color3',
    alpha: 'float',
    subSurfaceIsTranslucencyEnabled: 'boolean',
    subSurfaceTranslucencyIntensity: 'float',
    subSurfaceIsRefractionEnabled: 'boolean',
    subSurfaceRefractionIntensity: 'float',
    subSurfaceIndexOfRefraction: 'float',
    subSurfaceMinimumThickness: 'float',
    subSurfaceMaximumThickness: 'float',
    subSurfaceTintColor: 'color3',
    subSurfaceTintColorAtDistance: 'float',
    iridescenceIsEnabled: 'boolean',
    iridescenceIndexOfRefraction: 'float',
    iridescenceMinimumThickness: 'float',
    iridescenceMaximumThickness: 'float',
    anisotropyIsEnabled: 'boolean',
    anisotropyIntensity: 'float',
    customDisableAoTexture: 'disableAoTexture',
    edgeEpsilon: 'edgeEpsilon',
    edgeWidth: 'edgeWidth',
    edgeColor: 'edgeColor',
};

export const mapAttributeName = {
    subSurfaceIsTranslucencyEnabled: 'subSurface.isTranslucencyEnabled',
    subSurfaceTranslucencyIntensity: 'subSurface.translucencyIntensity',
    subSurfaceTintColor: 'subSurface.tintColor',
    subSurfaceTintColorAtDistance: 'subSurface.tintColorAtDistance',
    iridescenceIsEnabled: 'iridescence.isEnabled',
    iridescenceIndexOfRefraction: 'iridescence.indexOfRefraction',
    iridescenceMinimumThickness: 'iridescence.minimumThickness',
    iridescenceMaximumThickness: 'iridescence.maximumThickness',
    anisotropyIsEnabled: 'anisotropy.isEnabled',
    anisotropyIntensity: 'anisotropy.intensity',
    subSurfaceIsRefractionEnabled: 'subSurface.isRefractionEnabled',
    subSurfaceRefractionIntensity: 'subSurface.refractionIntensity',
    subSurfaceIndexOfRefraction: 'subSurface.volumeIndexOfRefraction',
    subSurfaceMinimumThickness: 'subSurface.minimumThickness',
    subSurfaceMaximumThickness: 'subSurface.maximumThickness',
};
