<template>
    <div ref="container" :class="['cz-client-camera-view', {'cz-client-camera-view-loading': this.loading === 'loading' }]">
        <canvas ref="canvas"></canvas>

        <transition name="cz3__fade">
            <div v-if="loadingImage" class="cz-client-loading-overlay">
                <img :src="loadingImage" />

                <easy-spinner :type="'circular'" :color="'#181818ff'" :size="'40'" />
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
    .cz-client-camera-view {
        position: absolute;

        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        canvas {
            outline: none;

            opacity: 1;

            transition: opacity 0.25s ease-out;
        }

        &.cz-client-camera-view-loading {
            canvas {
                opacity: 0.6;
            }
        }
    }

    .cz-client-loading-overlay {
        position: absolute;

        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        background: #fff;

        img {
            position: absolute;

            left: 0;
            top: 0;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        svg {
            position: absolute;

            left: 50%;
            top: 50%;

            transform: translate(-50%, -50%);
        }
    }
</style>

<script>
    /* eslint-disable no-underscore-dangle */
    // eslint-disable-next-line max-classes-per-file
    import axios from 'axios';

    import ResizeObserver from 'resize-observer-polyfill';

    import { Engine } from '@babylonjs/core/Engines/engine';
    import { ShaderStore } from '@babylonjs/core/Engines/shaderStore';
    import { Scene } from '@babylonjs/core/scene';
    import { Constants } from '@babylonjs/core/Engines/constants';
    import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
    import { Mesh } from '@babylonjs/core/Meshes/mesh';
    import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
    import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
    import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
    import { SpotLight } from '@babylonjs/core/Lights/spotLight';
    import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
    import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';
    import { Texture } from '@babylonjs/core/Materials/Textures/texture';
    import { Vector3 } from '@babylonjs/core/Maths/math.vector';
    import {
        // eslint-disable-next-line no-unused-vars
        Color3,
        // eslint-disable-next-line no-unused-vars
        Color4,
    } from '@babylonjs/core/Maths/math.color';
    import {
        Axis,
        Space,
    } from '@babylonjs/core/Maths/math.axis';
    import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline';
    import { Animation } from '@babylonjs/core/Animations/animation';
    import '@babylonjs/core/Animations/animatable';
    import {
        EasingFunction,
        CubicEase,
    } from '@babylonjs/core/Animations/easing';
    import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';
    import { HighlightLayer } from '@babylonjs/core/Layers/highlightLayer';
    import { VertexBuffer } from '@babylonjs/core/Buffers/buffer';

    import { ShadowOnlyMaterial } from '@babylonjs/materials/shadowOnly/shadowOnlyMaterial';
    import { MaterialPluginBase } from '@babylonjs/core/Materials/materialPluginBase';
    import { CreateScreenshot } from '@babylonjs/core/Misc/screenshotTools';

    import '@babylonjs/core/Materials/Textures/Loaders';
    import '@babylonjs/core/Rendering/edgesRenderer';

    import '@babylonjs/loaders/glTF/glTFFileLoader';
    import '@babylonjs/loaders/glTF/glTFValidation';
    import '@babylonjs/loaders/glTF/2.0/glTFLoader';
    import '@babylonjs/loaders/glTF/2.0/glTFLoaderExtension';
    import '@babylonjs/loaders/glTF/2.0/glTFLoaderInterfaces';

    import '@babylonjs/loaders/glTF/2.0/Extensions/EXT_lights_image_based';
    import '@babylonjs/loaders/glTF/2.0/Extensions/EXT_mesh_gpu_instancing';
    import '@babylonjs/loaders/glTF/2.0/Extensions/EXT_meshopt_compression';
    import '@babylonjs/loaders/glTF/2.0/Extensions/EXT_texture_webp';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_draco_mesh_compression';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_lights_punctual';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_pbrSpecularGlossiness';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_unlit';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_clearcoat';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_iridescence';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_emissive_strength';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_sheen';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_specular';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_ior';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_variants';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_transmission';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_translucency';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_volume';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_mesh_quantization';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_texture_basisu';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_texture_transform';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_xmp_json_ld';
    import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_animation_pointer';

    // Babylon URL configuration.
    import { KhronosTextureContainer2 } from '@babylonjs/core/Misc/khronosTextureContainer2';
    import { MeshoptCompression } from '@babylonjs/core/Meshes/Compression/meshoptCompression';
    import { DracoCompression } from '@babylonjs/core/Meshes/Compression/dracoCompression';

    // import { AdvancedDynamicTexture } from '@babylonjs/gui/2D/advancedDynamicTexture';

    import * as ThreeUtilities from './threeutilities';
    import * as PaneUtilities from './paneutilities';

    import WithDebounce from './WithDebounce';

    import CameraViewHacks from './CameraView.Hacks';
    import CameraViewShared from './CameraView.Shared';

    // import CameraViewRuntimeAr from './CameraView.Runtime.Ar';
    import CameraViewRuntimeTextures from './CameraView.Runtime.Textures';

    import CameraViewRuntimeJoints from './CameraView.Runtime.Joints';

    // import CameraViewRuntimeDimensions from './CameraView.Runtime.Dimensions';

    import CameraViewRuntimeMaterials from './CameraView.Runtime.Materials';

    import CameraViewRuntimePopsockets from './CameraView.Runtime.Popsockets';

    import allStores from '../../stores/all';

    import loadDevModules from './devModules';

    // URL Config.
    KhronosTextureContainer2.URLConfig.jsDecoderModule = 'https://build.drrv.co/drive/babylon-externals/babylon.ktx2Decoder.js';
    KhronosTextureContainer2.URLConfig.wasmZSTDDecoder = 'https://build.drrv.co/drive/babylon-externals/zstddec.wasm';

    KhronosTextureContainer2.URLConfig.wasmUASTCToASTC = 'https://build.drrv.co/drive/babylon-externals/ktx2Transcoders/1/uastc_astc.wasm';
    KhronosTextureContainer2.URLConfig.wasmUASTCToBC7 = 'https://build.drrv.co/drive/babylon-externals/ktx2Transcoders/1/uastc_bc7.wasm';
    KhronosTextureContainer2.URLConfig.wasmUASTCToRGBA_UNORM = 'https://build.drrv.co/drive/babylon-externals/ktx2Transcoders/1/uastc_rgba8_unorm_v2.wasm';
    KhronosTextureContainer2.URLConfig.wasmUASTCToRGBA_SRGB = 'https://build.drrv.co/drive/babylon-externals/ktx2Transcoders/1/uastc_rgba8_srgb_v2.wasm';
    KhronosTextureContainer2.URLConfig.jsMSCTranscoder = 'https://build.drrv.co/drive/babylon-externals/ktx2Transcoders/1/msc_basis_transcoder.js';
    KhronosTextureContainer2.URLConfig.wasmMSCTranscoder = 'https://build.drrv.co/drive/babylon-externals/ktx2Transcoders/1/msc_basis_transcoder.wasm';

    MeshoptCompression.Configuration.decoder.url = 'https://build.drrv.co/drive/babylon-externals/meshopt_decoder.js';

    DracoCompression.Configuration.decoder.wasmUrl = 'https://build.drrv.co/drive/babylon-externals/draco_wasm_wrapper_gltf.js';
    DracoCompression.Configuration.decoder.wasmBinaryUrl = 'https://build.drrv.co/drive/babylon-externals/draco_decoder_gltf.wasm';
    DracoCompression.Configuration.decoder.fallbackUrl = 'https://build.drrv.co/drive/babylon-externals/draco_decoder_gltf.js';

    // eslint-disable-next-line no-unused-vars
    class HighQualityRefractionPluginMaterial extends MaterialPluginBase {
        get isEnabled() {
            return this._isEnabled;
        }

        set isEnabled(enabled) {
            if (this._isEnabled === enabled) {
                return;
            }
            this._isEnabled = enabled;
            this.markAllDefinesAsDirty();
            this._enable(this._isEnabled);
        }

        constructor(material) {
            super(material, 'HighQualityRefraction', 200, { HIGH_QUALITY_REFRACTION: false });

            this._isEnabled = false;
        }

        prepareDefines(defines) {
            defines.HIGH_QUALITY_REFRACTION = this._isEnabled;
        }

        // eslint-disable-next-line class-methods-use-this
        getClassName() {
            return 'HighQualityRefractionPluginMaterial';
        }

        // eslint-disable-next-line class-methods-use-this
        getCustomCode(shaderType) {
            return shaderType === 'vertex' ? null : {
                CUSTOM_FRAGMENT_DEFINITIONS: `
                    #ifdef HIGH_QUALITY_REFRACTION
                    // From https://www.shadertoy.com/view/4df3Dn

                    // w0, w1, w2, and w3 are the four cubic B-spline basis functions
                    float w0(float a)
                    {
                        return (1.0/6.0)*(a*(a*(-a + 3.0) - 3.0) + 1.0);
                    }

                    float w1(float a)
                    {
                        return (1.0/6.0)*(a*a*(3.0*a - 6.0) + 4.0);
                    }

                    float w2(float a)
                    {
                        return (1.0/6.0)*(a*(a*(-3.0*a + 3.0) + 3.0) + 1.0);
                    }

                    float w3(float a)
                    {
                        return (1.0/6.0)*(a*a*a);
                    }

                    // g0 and g1 are the two amplitude functions
                    float g0(float a)
                    {
                        return w0(a) + w1(a);
                    }

                    float g1(float a)
                    {
                        return w2(a) + w3(a);
                    }

                    // h0 and h1 are the two offset functions
                    float h0(float a)
                    {
                        return -1.0 + w1(a) / (w0(a) + w1(a));
                    }

                    float h1(float a)
                    {
                        return 1.0 + w3(a) / (w2(a) + w3(a));
                    }

                    vec4 texture_bicubic(sampler2D tex, vec2 uv, ivec2 textureSize, int lod)
                    {
                        float flod = float(lod);

                        vec4 texelSize;

                        texelSize.zw = vec2(textureSize.x >> lod, textureSize.y >> lod); // vec2(textureSize.x >> lod);

                        texelSize.xy = 1.0 / texelSize.zw;

                        uv = uv * texelSize.zw + 0.5;

                        vec2 iuv = floor(uv);
                        vec2 fuv = fract(uv);

                        float g0x = g0(fuv.x);
                        float g1x = g1(fuv.x);
                        float h0x = h0(fuv.x);
                        float h1x = h1(fuv.x);
                        float h0y = h0(fuv.y);
                        float h1y = h1(fuv.y);

                        vec2 p0 = (vec2(iuv.x + h0x, iuv.y + h0y) - 0.5) * texelSize.xy;
                        vec2 p1 = (vec2(iuv.x + h1x, iuv.y + h0y) - 0.5) * texelSize.xy;
                        vec2 p2 = (vec2(iuv.x + h0x, iuv.y + h1y) - 0.5) * texelSize.xy;
                        vec2 p3 = (vec2(iuv.x + h1x, iuv.y + h1y) - 0.5) * texelSize.xy;

                        return
                            g0(fuv.y) * (g0x * textureLod(tex, p0, flod) + g1x * textureLod(tex, p1, flod)) +
                            g1(fuv.y) * (g0x * textureLod(tex, p2, flod) + g1x * textureLod(tex, p3, flod));
                    }

                    #endif
                `,

                '!environmentRefraction=sampleRefractionLod\\(refractionSampler,refractionCoords,requestedRefractionLOD\\);': `
                    #ifdef HIGH_QUALITY_REFRACTION
                    ivec2 tSize = textureSize(refractionSampler, 0);
                    vec4 low = texture_bicubic(refractionSampler, refractionCoords, tSize, int(requestedRefractionLOD));
                    vec4 high = texture_bicubic(refractionSampler, refractionCoords, tSize, int(requestedRefractionLOD) + 1);

                    // environmentRefraction = mix(low, high, fract(requestedRefractionLOD));
                    environmentRefraction = sampleRefractionLod(refractionSampler, refractionCoords, requestedRefractionLOD);
                    #else
                    environmentRefraction = sampleRefractionLod(refractionSampler, refractionCoords, requestedRefractionLOD);
                    #endif
                `,
            };
        }
    }

    // eslint-disable-next-line no-unused-vars
    class CustomHighlightLayer extends HighlightLayer {
        constructor(name, scene, options) {
            super(name, scene, options);

            this.intensity = 0;
        }

        _createMergeEffect() {
            ShaderStore.ShadersStore.customGlowMapMergeVertexShader = ShaderStore.ShadersStore.glowMapMergeVertexShader;

            let pixelShader = ShaderStore.ShadersStore.glowMapMergePixelShader;
            pixelShader = pixelShader.replace('#define CUSTOM_FRAGMENT_DEFINITIONS', 'uniform float intensity;');
            // eslint-disable-next-line max-len
            pixelShader = pixelShader.replace('#define CUSTOM_FRAGMENT_MAIN_END', 'gl_FragColor.a = smoothstep(0.0, 0.5, gl_FragColor.a);\ngl_FragColor*=intensity;');
            ShaderStore.ShadersStore.customGlowMapMergePixelShader = pixelShader;

            return this._engine.createEffect(
                'customGlowMapMerge',
                [VertexBuffer.PositionKind],
                ['offset', 'intensity'],
                ['textureSampler'],
                this._options.isStroke ? '#define STROKE \n' : undefined,
            );
        }

        _internalRender(effect, renderIndex) {
            effect.setFloat('intensity', this.intensity);

            super._internalRender(effect, renderIndex);
        }
    }

    // May be a hack...
    // window.createImageBitmap = undefined;

    export default {
        mixins: [
            WithDebounce,

            CameraViewHacks,

            CameraViewShared,

            // CameraViewRuntimeAr,
            CameraViewRuntimeTextures,

            CameraViewRuntimeJoints,

            // CameraViewRuntimeDimensions,

            CameraViewRuntimeMaterials,

            CameraViewRuntimePopsockets,

            allStores,
        ],
        props: {
            controller: Object,
            view: Object,
            viewOptions: Object,
        },
        data() {
            return {
                loadingMain: '',
                loadingImage: null,
                loadingImageRatio: null,

                currentTarget: null,

                small: false,
            };
        },
        computed: {
            loading() {
                return (this.loadingMain === 'loading') ? 'loading' : 'done';
            },
        },
        watch: {
            controller: {
                handler(newValue, oldValue) {
                    if (oldValue) {
                        oldValue.off('stateChanged', this.stateChanged);

                        oldValue.off('socketStateChanged', this.stateChanged);

                        oldValue.off('socketsUpdated', this.socketsUpdated);

                        oldValue.off('socketAttached', this.socketsUpdated);
                    }

                    if (this.engine) {
                        this.selectController();
                    } else {
                        this.createImage();
                    }
                },
                deep: false,
            },

            view: {
                handler() {
                    if (this.view) {
                        if (this.engine) {
                            this.selectView();
                        } else {
                            this.createImage();
                        }
                    }
                },
                deep: false,
            },

            saveMode() {
                this.updateSaveMode();
            },

            editMode() {
                this.setupGizmo();
            },

            gizmoMode() {
                this.setupGizmo();
            },

            copyRequest() {
                this.copy();
            },

            pasteRequest() {
                this.paste();
            },

            unselectRequest() {
                this.unselect();
            },

            saveRequest() {
                this.save();
            },

            undoRequest() {
                this.undo();
            },

            arPreview() {
                this.captureArPreview();
            },
        },
        mounted() {
            this.firstLoad = true;
            this.captureLoadingImage();

            this.small = window.innerWidth < 1024;

            this.createImage();

            window.addEventListener('resize', () => this.resize());

            this.resizeObserver = new ResizeObserver(() => {
                this.resize();
            });

            this.resizeObserver.observe(this.$refs.container);

            this.emitter.$on('clear', this.clearEverything);
            this.emitter.$on('snapshot', this.snapshot);
            this.emitter.$on('viewUpdated', this.onUpdateView);
            this.emitter.$on('cameraUpdated', this.onUpdateCamera);
            this.emitter.$on('forcePickedMesh', this.forcePickedMesh);
            this.emitter.$on('wantTap', this.wantTap);
            this.emitter.$on('jointsUpdated', this.jointsUpdated);
            this.emitter.$on('saveJoints', this.saveJoints);
            this.emitter.$on('wantLastPicked', this.wantLastPicked);
            this.emitter.$on('toggleDimensions', this.toggleDimensions);
            this.emitter.$on('highlightMeshes', this.highlightMeshes);
            this.emitter.$on('clearHighlights', this.clearHighlights);
            this.emitter.$on('selectLayer', this.selectTextureLayer);
            this.emitter.$on('selectLayerFromPoint', this.selectTextureLayerFromPoint);
            this.emitter.$on('setEditorProgress', this.setEditorProgress);
        },
        beforeUnmount() {
            this.clear();

            this.emitter.$off('clear', this.clearEverything);
            this.emitter.$off('snapshot', this.snapshot);
            this.emitter.$off('viewUpdated', this.onUpdateView);
            this.emitter.$off('cameraUpdated', this.onUpdateCamera);
            this.emitter.$off('forcePickedMesh', this.forcePickedMesh);
            this.emitter.$off('wantTap', this.wantTap);
            this.emitter.$off('jointsUpdated', this.jointsUpdated);
            this.emitter.$off('saveJoints', this.saveJoints);
            this.emitter.$off('wantLastPicked', this.wantLastPicked);
            this.emitter.$off('toggleDimensions', this.toggleDimensions);
            this.emitter.$off('highlightMeshes', this.highlightMeshes);
            this.emitter.$off('clearHighlights', this.clearHighlights);
            this.emitter.$off('selectLayer', this.selectTextureLayer);
            this.emitter.$off('selectLayerFromPoint', this.selectTextureLayerFromPoint);
            this.emitter.$off('setEditorProgress', this.setEditorProgress);
        },
        methods: {
            /**
             * Configures rendering environment.
             */
            configureEnvironment() {
                // Environment is only determined by the root controller.

                if (this.controller.blueprint.custom['three-background']) {
                    this.scene.clearColor = ThreeUtilities.toColor4(this.controller.blueprint.custom['three-background']);
                }

                if (this.controller.blueprint.custom['three-ambient']) {
                    this.scene.ambientColor = ThreeUtilities.toColor4(this.controller.blueprint.custom['three-ambient']);
                }

                if (this.view && this.view.custom['three-background']) {
                    this.scene.clearColor = ThreeUtilities.toColor4(this.view.custom['three-background']);
                }

                if (this.view && this.view.custom['three-ambient']) {
                    this.scene.ambientColor = ThreeUtilities.toColor4(this.view.custom['three-ambient']);
                }
            },

            /**
             * Configure camera.
             */
            configureCamera() {
                if (this.view && this.view.custom['three-camera'] === 'arc') {
                    this.camera = new ArcRotateCamera(
                        'camera',
                        this.view.custom['three-camera-alpha'] == null ? Math.PI / 2 : ThreeUtilities.toRad(this.view.custom['three-camera-alpha']),
                        this.view.custom['three-camera-beta'] == null ? Math.PI / 2 : ThreeUtilities.toRad(this.view.custom['three-camera-beta']),
                        this.view.custom['three-camera-radius'] == null ? 1 : +this.view.custom['three-camera-radius'],
                        ThreeUtilities.toVector3(this.view.custom['three-camera-target']),
                        this.scene,
                    );

                    if (this.pipeline) {
                        this.pipeline.addCamera(this.camera);
                    }

                    // Make camera respond to target movements without affecting the rotation.
                    if (this.view.custom['three-camera-follow-target'] == null || this.view.custom['three-camera-follow-target'] === 'false') {
                        this.camera.overrideCloneAlphaBetaRadius = true;
                    }

                    // Disable panning.
                    this.camera.panningSensibility = 0;
                    // Slow down zooming.
                    this.camera.wheelDeltaPercentage = 0.05;

                    if (this.view.custom['three-camera-edit-panning-sensitivity']) {
                        this.camera.panningSensibility = +this.view.custom['three-camera-edit-panning-sensitivity'];
                    }

                    if (this.view.custom['three-camera-z-min']) {
                        this.camera.minZ = ThreeUtilities.toFloat(this.view.custom['three-camera-z-min']);
                    } else {
                        this.camera.minZ = 0.01;
                    }

                    if (this.view.custom['three-camera-z-max']) {
                        this.camera.maxZ = ThreeUtilities.toFloat(this.view.custom['three-camera-z-max']);
                    } else {
                        this.camera.maxZ = 1000;
                    }

                    if (this.view.custom['three-camera-fov']) {
                        this.camera.fov = ThreeUtilities.toRad(this.view.custom['three-camera-fov']);
                    }

                    if (this.view.custom['three-camera-alpha-min']) {
                        this.camera.lowerAlphaLimit = ThreeUtilities.toRad(this.view.custom['three-camera-alpha-min']);
                    }

                    if (this.view.custom['three-camera-alpha-max']) {
                        this.camera.upperAlphaLimit = ThreeUtilities.toRad(this.view.custom['three-camera-alpha-max']);
                    }

                    if (this.view.custom['three-camera-beta-min']) {
                        this.camera.lowerBetaLimit = ThreeUtilities.toRad(this.view.custom['three-camera-beta-min']);
                    }

                    if (this.view.custom['three-camera-beta-max']) {
                        this.camera.upperBetaLimit = ThreeUtilities.toRad(this.view.custom['three-camera-beta-max']);
                    }

                    if (this.view.custom['three-camera-radius-min']) {
                        this.camera.lowerRadiusLimit = ThreeUtilities.toFloat(this.view.custom['three-camera-radius-min']);
                    }

                    if (this.view.custom['three-camera-radius-max']) {
                        this.camera.upperRadiusLimit = ThreeUtilities.toFloat(this.view.custom['three-camera-radius-max']);
                    }

                    this.camera.attachControl(this.$refs.canvas, true);
                    this.camera.inputs.attached.pointers.buttons = [0];

                    this.cameraLocked = null;
                    this.lockViewCamera();

                    // Listen to updates.
                    this.camera.onViewMatrixChangedObservable.add(() => {
                        this.cameraPositionChanged();
                    });

                    this.cameraPositionChanged();
                }
            },

            /**
             * Configures hemispheric lighting.
             */
            configureHemisphericLight(lightIndex) {
                const id = `${this.view.code}-${lightIndex}`;

                const light = new HemisphericLight(
                    id,
                    ThreeUtilities.toVector3(this.view.custom[`three-light-${lightIndex}-direction`]),
                    this.scene,
                );

                this.lights[id] = {
                    light,
                };

                light.diffuse = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-diffuse`]);
                light.specular = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-specular`]);
                light.groundColor = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-ground`]);

                if (this.view.custom[`three-light-${lightIndex}-intensity`]) {
                    light.intensity = parseFloat(this.view.custom[`three-light-${lightIndex}-intensity`]);
                }
            },

            /**
             * Configures directional lighting.
             */
            configureDirectionalLight(lightIndex) {
                const lightName = this.view.custom[`three-light-${lightIndex}-name`];

                const id = `${this.view.code}-${lightIndex}-${lightName || 'light'}`;
                const name = lightName || id;

                const direction = ThreeUtilities.toVector3(this.view.custom[`three-light-${lightIndex}-direction`]);

                const light = new DirectionalLight(
                    id,
                    direction,
                    this.scene,
                );

                this.lights[id] = {
                    light,
                };

                light.position = ThreeUtilities.toVector3(this.view.custom[`three-light-${lightIndex}-position`]);
                light.diffuse = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-diffuse`]);
                light.specular = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-specular`]);

                if (this.view.custom[`three-light-${lightIndex}-shadow-min-z`]) {
                    light.shadowMinZ = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-shadow-min-z`]);
                }

                if (this.view.custom[`three-light-${lightIndex}-shadow-max-z`]) {
                    light.shadowMaxZ = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-shadow-max-z`]);
                }

                if (this.view.custom[`three-light-${lightIndex}-shadow-auto-z`] === 'true') {
                    light.autoUpdateExtends = true;
                    light.autoCalcShadowZBounds = true;
                }

                if (this.view.custom[`three-light-${lightIndex}-intensity`]) {
                    light.intensity = parseFloat(this.view.custom[`three-light-${lightIndex}-intensity`]);
                }

                if (this.view.custom[`three-light-${lightIndex}-casts-shadows`] === 'true') {
                    const size = +(this.view.custom[`three-light-${lightIndex}-shadowmap-size`] || '1024');

                    const shadowGenerator = new ShadowGenerator(size, light);

                    if (this.view.custom[`three-light-${lightIndex}-shadow-darkness`]) {
                        shadowGenerator.darkness = +this.view.custom[`three-light-${lightIndex}-shadow-darkness`];
                    } else {
                        shadowGenerator.darkness = 0.9;
                    }

                    if (this.view.custom[`three-light-${lightIndex}-shadow-type`] === 'pcf') {
                        shadowGenerator.usePercentageCloserFiltering = true;
                    } else if (this.view.custom[`three-light-${lightIndex}-shadow-type`] === 'pcss') {
                        shadowGenerator.useContactHardeningShadow = true;

                        if (this.view.custom[`three-light-${lightIndex}-shadow-penumbra`]) {
                            shadowGenerator.contactHardeningLightSizeUVRatio = +this.view.custom[`three-light-${lightIndex}-shadow-penumbra`];
                        }
                    } else {
                        shadowGenerator.useBlurExponentialShadowMap = true;
                        shadowGenerator.useKernelBlur = true;
                        shadowGenerator.blurScale = 8;
                        shadowGenerator.blurKernel = 4;
                        shadowGenerator.depthScale = 0;
                        shadowGenerator.darkness = 0.9;
                    }

                    this.shadows[name] = shadowGenerator;
                }
            },

            /**
             * Calculate position from target.
             */
            getPosition(target, alpha, beta, distance) {
                const a = ThreeUtilities.toRad(alpha);
                const b = ThreeUtilities.toRad(beta);

                const xz = Math.sin(b) * distance;
                const y = Math.cos(b) * distance;
                const x = Math.cos(a) * xz;
                const z = Math.sin(a) * xz;

                return target.add(new Vector3(x, y, z));
            },

            /**
             * Configures spot lighting.
             */
            configureSpotLight(lightIndex) {
                const lightName = this.view.custom[`three-light-${lightIndex}-name`];

                const id = `${this.view.code}-${lightIndex}-${lightName || 'light'}`;
                const name = lightName || id;

                const target = ThreeUtilities.toVector3(this.view.custom[`three-light-${lightIndex}-target`]);
                const alpha = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-alpha`]);
                const beta = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-beta`]);
                const distance = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-distance`]);

                const position = this.getPosition(target, alpha, beta, distance);
                const direction = target.subtract(position);
                if (direction.lengthSquared > 0) {
                    direction.normalize();
                }

                let angle = 30;
                if (this.view.custom[`three-light-${lightIndex}-angle`]) {
                    angle = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-angle`]);
                }

                angle = ThreeUtilities.toRad(angle);

                let exponent = 1;

                if (this.view.custom[`three-light-${lightIndex}-exponent`]) {
                    exponent = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-exponent`]);
                }

                const light = new SpotLight(
                    id,
                    position,
                    direction,
                    angle,
                    exponent,
                    this.scene,
                );

                this.lights[id] = {
                    light,
                    target,
                    alpha,
                    beta,
                    distance,
                };

                light.diffuse = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-diffuse`]);
                light.specular = ThreeUtilities.toColor3(this.view.custom[`three-light-${lightIndex}-specular`]);

                if (this.view.custom[`three-light-${lightIndex}-shadow-min-z`]) {
                    light.shadowMinZ = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-shadow-min-z`]);
                }

                if (this.view.custom[`three-light-${lightIndex}-shadow-max-z`]) {
                    light.shadowMaxZ = ThreeUtilities.toFloat(this.view.custom[`three-light-${lightIndex}-shadow-max-z`]);
                }

                if (this.view.custom[`three-light-${lightIndex}-shadow-auto-z`] === 'true') {
                    light.autoUpdateExtends = true;
                    light.autoCalcShadowZBounds = true;
                }

                if (this.view.custom[`three-light-${lightIndex}-intensity`]) {
                    light.intensity = parseFloat(this.view.custom[`three-light-${lightIndex}-intensity`]);
                }

                if (this.view.custom[`three-light-${lightIndex}-include-mask`]) {
                    light.includeOnlyWithLayerMask = +this.view.custom[`three-light-${lightIndex}-include-mask`];
                }

                if (this.view.custom[`three-light-${lightIndex}-casts-shadows`] === 'true') {
                    const size = +(this.view.custom[`three-light-${lightIndex}-shadowmap-size`] || '1024');

                    const shadowGenerator = new ShadowGenerator(size, light);

                    if (this.view.custom[`three-light-${lightIndex}-shadow-darkness`]) {
                        shadowGenerator.darkness = +this.view.custom[`three-light-${lightIndex}-shadow-darkness`];
                    } else {
                        shadowGenerator.darkness = 0.9;
                    }

                    if (this.view.custom[`three-light-${lightIndex}-shadow-type`] === 'pcf') {
                        shadowGenerator.usePercentageCloserFiltering = true;
                    } else if (this.view.custom[`three-light-${lightIndex}-shadow-type`] === 'pcss') {
                        shadowGenerator.useContactHardeningShadow = true;

                        if (this.view.custom[`three-light-${lightIndex}-shadow-penumbra`]) {
                            shadowGenerator.contactHardeningLightSizeUVRatio = +this.view.custom[`three-light-${lightIndex}-shadow-penumbra`];
                        }
                    } else {
                        shadowGenerator.useBlurExponentialShadowMap = true;
                        shadowGenerator.useKernelBlur = true;
                        shadowGenerator.blurScale = 8;
                        shadowGenerator.blurKernel = 4;
                        shadowGenerator.depthScale = 0;
                        shadowGenerator.darkness = 0.9;
                    }

                    this.shadows[name] = shadowGenerator;
                }

                if (this.view.custom[`three-light-${lightIndex}-projection-image`]) {
                    light.projectionTexture = new Texture(this.view.custom[`three-light-${lightIndex}-projection-image`], this.scene);
                    light.projectionTexture.wrapU = Constants.TEXTURE_CLAMP_ADDRESSMODE;
                    light.projectionTexture.wrapV = Constants.TEXTURE_CLAMP_ADDRESSMODE;
                }
            },

            /**
             * Configures scene lighting.
             */
            configureLights() {
                // Configure lights.
                if (this.view) {
                    for (let lightIndex = 1; ; lightIndex += 1) {
                        if (this.view.custom[`three-light-${lightIndex}`]) {
                            const lightType = this.view.custom[`three-light-${lightIndex}`];

                            switch (lightType) {
                            case 'hemispheric': {
                                this.configureHemisphericLight(lightIndex);

                                break;
                            }

                            case 'directional': {
                                this.configureDirectionalLight(lightIndex);

                                break;
                            }

                            case 'spot': {
                                this.configureSpotLight(lightIndex);

                                break;
                            }

                            default:
                                break;
                            }
                        } else {
                            break;
                        }
                    }
                }
            },

            /**
             * Configure scene pipeline.
             */
            configurePipeline() {
                // Pipeline is determined only by the root controller.

                this.pipeline = new DefaultRenderingPipeline(
                    'main-pipeline',
                    true,
                    this.scene,
                    [],
                );

                if (this.controller && this.controller.blueprint.custom['three-multisample']) {
                    this.pipeline.samples = +this.controller.blueprint.custom['three-multisample'];
                }

                if (this.controller && this.controller.blueprint.custom['three-fxaa'] === 'true') {
                    this.pipeline.fxaaEnabled = true;
                }

                if (this.controller
                    && (this.controller.blueprint.custom['three-exposure']
                        || this.controller.blueprint.custom['three-exposure'])) {
                    this.pipeline.imageProcessingEnabled = true;

                    if (this.controller && this.controller.blueprint.custom['three-contrast']) {
                        this.pipeline.imageProcessing.contrast = +this.controller.blueprint.custom['three-contrast'];
                    }

                    if (this.controller && this.controller.blueprint.custom['three-exposure']) {
                        this.pipeline.imageProcessing.exposure = +this.controller.blueprint.custom['three-exposure'];
                    }
                }

                this.hackPipeline();
            },

            clearEverything() {
                this.clearScene();
                // this.clear();
            },

            clear() {
                // Clear previous instance.
                if (this.engine) {
                    if (this.utilityLayer) {
                        this.utilityLayer.dispose();
                        this.utilityLayer = null;
                    }

                    if (this.gui) {
                        this.gui.dispose();
                        this.gui = null;
                    }

                    if (this.pipeline) {
                        this.pipeline.dispose();
                        this.pipeline = null;
                    }

                    if (this.camera) {
                        this.camera.dispose();
                        this.camera = null;
                    }

                    if (this.scene) {
                        this.scene.dispose();
                        this.scene = null;
                    }

                    if (this.assetsManager) {
                        this.assetsManager = null;
                    }

                    this.engine.dispose();
                    this.engine = null;
                }
            },

            clearScene() {
                this.appStore.log({
                    level: 'debug',
                    message: 'Clearing scene... --------------------------------------------------------------------------',
                    style: 'color: #900; font-weight: bold;',
                });

                // eslint-disable-next-line no-unreachable
                this.captureLoadingImage();

                this.pendingTasks.forEach((task) => {
                    task.loader.customCancelled = true;
                    if (task.checkTimer) {
                        clearTimeout(task.checkTimer);
                    }
                });

                this.assetsManager._tasks.forEach((task) => {
                    task.customCancelled = true;
                    if (task.customCheckTimer) {
                        clearTimeout(task.customCheckTimer);
                    }
                });

                // Mark placement loaders as cancelled.
                Object.values(this.placements).forEach((p) => {
                    p.cancelled = true;
                });

                // Abort all tasks.
                this.assetsManager.reset();

                // Clear highlight layers.
                this.highlightLayers.forEach((l) => {
                    l.removeAllMeshes();
                });

                // Unassign refraction texture otherwise it will get disposed with meshes.
                // If there are meshes left (how?), dispose them too.
                [...this.scene.meshes].forEach((node) => {
                    if (node.material && node.material.refractionTexture) {
                        node.material.refractionTexture = null;
                    }
                });

                // Delete all meshes.
                [...this.scene.rootNodes].forEach((node) => {
                    node.dispose(false, true);
                });

                // If there are meshes left (how?), dispose them too.
                [...this.scene.meshes].forEach((node) => {
                    node.dispose(false, true);
                });

                // Remove cameras from pipelines.
                if (this.pipeline) {
                    [...this.pipeline.cameras].forEach((c) => {
                        this.pipeline.removeCamera(c);
                    });
                }

                // Remove shadow generators.
                Object.values(this.shadows).forEach((s) => {
                    s.dispose();
                });

                // Remove lights.
                Object.values(this.lights).forEach((l) => {
                    l.light.dispose();
                });

                // Clear camera.
                if (this.camera) {
                    this.camera.dispose();
                }

                this.pendingTasks = [];
                this.lights = {};
                this.shadows = {};
                this.placements = {};
                this.textures = {};
                this.joints = [];
                this.camera = null;

                this.showDimensions = false;
            },

            // Provide a proxy to the API object and listen to important events.
            createImage() {
                this.appStore.log({
                    level: 'debug',
                    message: 'Initialize engine... --------------------------------------------------------------------------',
                    style: 'color: #900; font-weight: bold;',
                });

                this.resize();

                // this.clear();

                if (this.engine) {
                    this.clearScene();
                } else if (this.controller && this.view) {
                    this.engine = new Engine(this.$refs.canvas, true, {
                        adaptToDeviceRatio: true,
                        limitDeviceRatio: 2, // this.small ? 1.5 : 2,
                        preserveDrawingBuffer: true,
                        stencil: true,
                        forceSRGBBufferSupportState: true,
                        // disableWebGL2Support: true,
                    }, true);

                    // Prevent blinkyness on Windows/Chrome.
                    this.engine.getCaps().parallelShaderCompile = undefined;

                    this.calculateAspectRatio();

                    // Disable extra validation for performance.
                    this.engine.validateShaderPrograms = false;

                    this.hackEngine();

                    const checkCaps = this.engine.getCaps();

                    // eslint-disable-next-line
                    console.log('Engine capabilities:', checkCaps);

                    this.scene = new Scene(this.engine);

                    // Scene settings.
                    this.scene.skipPointerDownPicking = true;
                    this.scene.skipPointerMovePicking = true;
                    this.scene.skipPointerUpPicking = true;

                    this.assetsManager = new AssetsManager(this.scene);

                    this.pendingTasks = [];
                    this.lights = {};
                    this.shadows = {};
                    this.placements = {};
                    this.textures = {};
                    this.joints = [];
                    this.framesCallbacks = [];

                    this.showDimensions = false;

                    this.currentEnvironment = null;

                    loadDevModules(this);

                    // Add gui.
                    // this.gui = AdvancedDynamicTexture.CreateFullscreenUI('ui', true);
                    // this.gui.renderScale = 2;

                    // Make a custom loading screen.
                    // eslint-disable-next-line no-inner-declarations
                    function CustomLoadingScreen() {
                    }

                    CustomLoadingScreen.prototype.displayLoadingUI = () => {
                        if (this.loadingDoneTimer) {
                            clearTimeout(this.loadingDoneTimer);
                            this.loadingDoneTimer = null;

                            // eslint-disable-next-line
                            console.log('Cancelled done timer since new a batch of data is now being loaded');
                        } else {
                            this.loadingMain = 'loading';

                            // eslint-disable-next-line
                            console.log('Show loading screen');

                            this.emitter.$emit('cameraLoading', true);

                            // this.captureLoadingImage();
                        }
                    };

                    CustomLoadingScreen.prototype.hideLoadingUI = () => {
                        if (this.loadingDoneTimer) {
                            clearTimeout(this.loadingDoneTimer);
                            this.loadingDoneTimer = null;
                        }

                        this.loadingDoneTimer = setTimeout(() => {
                            this.loadingMain = 'done';
                            this.loadingImage = null;

                            // eslint-disable-next-line
                            console.log('Hide loading screen');

                            this.emitter.$emit('cameraLoading', false);

                            this.loadingDoneTimer = null;
                        }, 250);
                    };

                    this.engine.loadingScreen = new CustomLoadingScreen();

                    this.assetsManager.onProgress = (remainingCount, totalCount) => {
                        if (remainingCount > 0) {
                            this.loadingMain = 'loading';

                            this.appStore.log({
                                level: 'debug',
                                message: `Loading ${remainingCount} of ${totalCount}...`,
                            });
                        }
                    };

                    this.assetsManager.onFinish = () => {
                        this.appStore.log({
                            level: 'debug',
                            message: 'Done loading',
                        });

                        this.loadingMain = 'done';

                        this.showMemoryStats('assetManager.onFinish');

                        // If there are pending tasks, validate and restart loading.
                        this.validatePendingTasks();
                    };

                    // Highlighting.
                    const highlight = new CustomHighlightLayer('hl', this.scene, {
                    });

                    highlight.innerGlow = true;
                    highlight.outerGlow = true;

                    highlight.blurVerticalSize = this.small ? 0.75 : 1;
                    highlight.blurHorizontalSize = this.small ? 0.75 : 1;

                    this.highlightLayers = [];

                    // this.highlightLayers.push(highlightStroke);
                    this.highlightLayers.push(highlight);

                    this.litMeshes = {};
                    this.litStart = null;
                    this.litRampFull = null;
                    this.litRampEnd = null;
                    this.litEnd = null;

                    this.scene.onPointerDown = () => {
                        this.pickPointerDown();
                    };

                    this.scene.onPointerPick = (event, pickInfo) => {
                        if (pickInfo.pickedPoint) {
                            // eslint-disable-next-line
                            console.log(`Picked world point: ${pickInfo.pickedPoint.x} ${pickInfo.pickedPoint.y} ${pickInfo.pickedPoint.z}`);

                            const normal = pickInfo.getNormal(true).normalize();

                            // eslint-disable-next-line
                            console.log(`Picked world normal: ${normal.x} ${normal.y} ${normal.z}`);
                        }

                        this.pickObject(event, pickInfo);
                    };

                    // Configure pipeline.
                    this.configurePipeline();
                }

                if (this.controller && this.view) {
                    this.appStore.log({
                        level: 'debug',
                        message: 'Load data... --------------------------------------------------------------------------',
                        style: 'color: #900; font-weight: bold;',
                    });

                    // Configure background/environment.
                    this.configureEnvironment();

                    // Configure camera.
                    this.configureCamera();

                    // Configure lights.
                    this.configureLights();

                    // Grab gizmos.
                    this.loadGizmos();

                    this.lastProjectionMatrix = null;
                    this.lastViewMatrix = null;

                    this.engine.runRenderLoop(this.renderLoop);

                    // Load models.
                    this.loadModels();

                    // Load textures.
                    this.loadTextures();

                    // Root controller will monitor state changes on the entire assembly.

                    this.controller.on('stateChanged', this.stateChanged);

                    this.controller.on('socketStateChanged', this.stateChanged);

                    this.controller.on('socketsUpdated', this.socketsUpdated);

                    this.controller.on('socketAttached', this.socketsUpdated);

                    // Select image view.
                    this.selectView();
                }
            },

            captureLoadingImage() {
                const width = this.$refs.canvas.width;
                const height = this.$refs.canvas.height;

                if (this.firstLoad) {
                    // eslint-disable-next-line
                    console.log('Set loading screen to blank');

                    this.firstLoad = false;

                    // Transparent 1x1 pixel.
                    this.loadingImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP88x8AAv0B/cfFKfIAAAAASUVORK5CYII=';
                } else if (this.engine && this.camera) {
                    // eslint-disable-next-line
                    console.log('Capture background for the loading screen');

                    CreateScreenshot(
                        this.engine,
                        this.camera,
                        { width, height },
                        (data) => {
                            this.loadingImage = data;
                            this.loadingImageRatio = width / height;
                        },
                    );
                }
            },

            /**
             * Check if camera is not moving anymore.
             */
            isArcRotateCameraStopped(camera) {
                return camera.inertialAlphaOffset === 0
                    && camera.inertialBetaOffset === 0
                    && camera.inertialRadiusOffset === 0
                    && camera.inertialPanningX === 0
                    && camera.inertialPanningY === 0;
            },

            afterFrames(frames, callback) {
                this.framesCallbacks.push({
                    frames,
                    callback,
                });
            },

            requestRenderUpdate(frames) {
                if (this.needsRenderUpdate == null || this.needsRenderUpdate < frames) {
                    this.needsRenderUpdate = frames;
                }
            },

            /**
             * Needed by animations.
             */
            getScene() {
                return this.scene;
            },

            /**
             * Renders current scene.
             * @return {[type]} [description]
             */
            render() {
                if (this.scene && this.camera) {
                    // Do we need to make the baked shadow?
                    if (this.controller.blueprint.custom?.['three-static-ground-shadow'] === 'true') {
                        this.renderStaticShadow();
                    }

                    this.scene.render();
                }
            },

            /**
             * Renders static shadow.
             * @return {[type]} [description]
             */
            renderStaticShadow() {
            },

            /**
             * Render loop.
             */
            renderLoop() {
                if (this.loadingMain === 'loading') {
                    return;
                }

                // Update frame callbacks.
                for (let i = 0; i < this.framesCallbacks.length;) {
                    const f = this.framesCallbacks[i];

                    f.frames -= 1;

                    if (f.frames <= 0) {
                        f.callback();
                        this.framesCallbacks.splice(i, 1);
                    } else {
                        i += 1;
                    }
                }

                if (this.scene && this.camera) {
                    // Something is happening...
                    let shouldRender = this.renderUpdates();

                    // Camera is moving...
                    if (this.camera && !this.isArcRotateCameraStopped(this.camera)) {
                        shouldRender = true;
                    }

                    if (this.hasInspector) {
                        shouldRender = true;
                    }

                    if (shouldRender) {
                        this.render();

                        this.firstStatic = true;
                    } else {
                        if (this.firstStatic) {
                            this.render();
                        }

                        this.firstStatic = false;
                    }
                }
            },

            /**
             * Compares two matrices.
             */
            compareMatrices(a, b, tolerance) {
                const aM = a.m;
                const bM = b.m;

                if (aM.length !== bM.length) {
                    return false;
                }

                for (let i = 0; i < aM.length; i += 1) {
                    if (Math.abs(aM[i] - bM[i]) > tolerance) {
                        return false;
                    }
                }

                return true;
            },

            /**
             * Update render specific data.
             */
            renderUpdates() {
                const projectionMatrix = this.camera.getProjectionMatrix(false);
                const viewMatrix = this.camera.getViewMatrix(false);

                let update = false;

                if (this.lastProjectionMatrix == null) {
                    this.lastProjectionMatrix = projectionMatrix;
                    update = true;
                }

                if (!this.compareMatrices(this.lastProjectionMatrix, projectionMatrix, 0.00001)) {
                    this.lastProjectionMatrix = projectionMatrix;
                    update = true;
                }

                if (this.lastViewMatrix == null) {
                    this.lastViewMatrix = viewMatrix;
                    update = true;
                }

                if (!this.compareMatrices(this.lastViewMatrix, viewMatrix, 0.00001)) {
                    this.lastViewMatrix = viewMatrix;
                    update = true;
                }

                this.updateTextures();

                if (this.animations) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const prop in this.animations) {
                        if (Object.prototype.hasOwnProperty.call(this.animations, prop) && this.animations[prop]) {
                            update = true;
                            break;
                        }
                    }
                }

                if (this.updateHighlights()) {
                    update = true;
                }

                if (this.needsRenderUpdate > 0) {
                    update = true;
                    this.needsRenderUpdate -= 1;
                }

                if (update) {
                    this.onRenderCameraUpdated();
                }

                return update;
            },

            /**
             * Camera position has changed.
             */
            onRenderCameraUpdated() {
                this.updateEnvironmentPosition();

                this.projectJoints();
                this.projectDecals();

                this.$emit('renderTransformUpdated');
            },

            /**
             * Loads helper gizmos.
             */
            loadGizmos() {
            },

            /**
             * Configures lighting environment.
             */
            loadEnvironment(s, controller) {
                // Capture component properties.
                const { code } = s.component;

                if (this.currentEnvironment === code) {
                    return;
                }

                this.currentEnvironment = code;

                // Get the selected image name.
                const name = controller.imageName(s.component, null, this.view);

                // Must use .env extension.
                const envMap = CubeTexture.CreateFromPrefilteredData(`${controller.baseFrameUrl(true)}${name}.env`, this.scene);

                this.environmentOffset = 0;
                this.environmentOffsetCurrent = 0;
                this.environmentFollowsCamera = false;

                if (s.component.custom && s.component.custom['three-environment-offset']) {
                    this.environmentOffsetCurrent = ThreeUtilities.toRad(s.component.custom['three-environment-offset']);
                    this.environmentOffset = this.environmentOffsetCurrent;
                }

                if (s.component.custom && s.component.custom['three-environment-follows-camera'] === 'true') {
                    this.environmentFollowsCamera = true;
                }

                envMap.rotationY = this.environmentOffsetCurrent;

                this.scene.environmentTexture = envMap;

                this.updateEnvironmentPosition();
            },

            getUniqueName() {
                if (this.uniqueSequence == null) {
                    this.uniqueSequence = 1;
                }

                this.uniqueSequence += 1;

                return `id-${this.uniqueSequence}`;
            },

            /**
             * Loads a model.
             */
            loadModel(s, controller) {
                // Capture component properties.
                const { code, custom } = s.component;

                this.appStore.log({
                    level: 'debug',
                    message: `Loading ${code} on ${s.placement.code}...`,
                });

                const placementKey = this.placementKey(controller, s.placement.code);

                let placement = this.placements[placementKey];

                if (custom['three-empty']) {
                    if (placement && placement.meshes) {
                        const toDelete = placement.meshes;

                        toDelete.forEach((mesh) => {
                            this.appStore.log({
                                level: 'info',
                                message: `Disposing of ${mesh.name} because of new component is empty`,
                            });

                            this.clearMesh(mesh);
                        });
                    }

                    // Mark as loaded.
                    placement.currentCode = code;
                    placement.loadCode = null;

                    return false;
                }

                if (placement == null) {
                    const transform = new TransformNode(s.placement.code);

                    transform.customPlacement = s.placement.code;

                    if (s.placement.custom) {
                        if (s.placement.custom['three-placement-position']) {
                            const position = ThreeUtilities.toVector3(s.placement.custom['three-placement-position']);

                            transform.position = position;
                        }

                        if (s.placement.custom['three-placement-scaling']) {
                            const scaling = ThreeUtilities.toVector3(s.placement.custom['three-placement-scaling']);

                            transform.scaling = scaling;
                        }

                        if (s.placement.custom['three-placement-rotation']) {
                            const rotation = ThreeUtilities.toVector3(s.placement.custom['three-placement-rotation']);

                            transform.rotation = rotation;
                        }

                        if (s.placement.custom['three-placement-rotation-alpha']) {
                            const rotation = ThreeUtilities.toFloat(s.placement.custom['three-placement-rotation-alpha']);

                            transform.rotation.x = rotation;
                        }

                        if (s.placement.custom['three-placement-rotation-beta']) {
                            const rotation = ThreeUtilities.toFloat(s.placement.custom['three-placement-rotation-beta']);

                            transform.rotation.y = rotation;
                        }

                        if (s.placement.custom['three-placement-rotation-gamma']) {
                            const rotation = ThreeUtilities.toFloat(s.placement.custom['three-placement-rotation-gamma']);

                            transform.rotation.z = rotation;
                        }
                    }

                    const offset = new TransformNode(`${s.placement.code}->offset`);
                    offset.customHandle = true;
                    offset.customComponent = code;
                    offset.customPlacement = s.placement.code;
                    offset.customController = controller.id;

                    const adjust = new TransformNode(`${s.placement.code}->adjust`);
                    adjust.customHandle = true;
                    adjust.customComponent = code;
                    adjust.customPlacement = s.placement.code;
                    adjust.customController = controller.id;

                    placement = {
                        controller: this.controllerKey(controller),
                        placement: s.placement.code,
                        adjust,
                        transform,
                        offset,
                        meshes: [],
                    };

                    transform.parent = adjust;
                    offset.parent = transform;

                    this.placements[placementKey] = placement;
                } else {
                    placement.adjust.customComponent = code;
                    placement.offset.customComponent = code;
                }

                const loadCode = code;

                // Already loaded?
                if (placement.currentCode === loadCode) {
                    if (placement.loadCode) {
                        placement.loadCode = null;
                    }

                    this.appStore.log({
                        level: 'debug',
                        message: '... already loaded',
                    });

                    return false;
                }

                if (placement.loadCode === loadCode) {
                    this.appStore.log({
                        level: 'debug',
                        message: '... already loading',
                    });

                    return false;
                }

                placement.loadCode = loadCode;

                // Get the selected image name.
                let name = controller.imageName(s.component, null, this.view);

                if (s.component.custom && s.component.custom['three-substitute-with']) {
                    name = s.component.custom['three-substitute-with'];
                }

                const sniffUrl = `${controller.baseFrameUrl(true)}${name}.gltf`;

                this.appStore.log({
                    level: 'debug',
                    message: `... checking content type on ${name}`,
                });

                axios.head(sniffUrl).then((sniffResponse) => {
                    // Loader was already cancelled, but the sniff request was still in flight.
                    if (placement.cancelled) {
                        this.appStore.log({
                            level: 'debug',
                            message: 'A placement task was already cancelled',
                            style: 'color: #f00; font-weight: bold;',
                        });

                        return;
                    }

                    const contentType = sniffResponse.headers['content-type'];

                    let fileName = `${name}.gltf`;
                    if (contentType && contentType.includes('binary')) {
                        fileName = `${name}.glb`;
                    }

                    this.appStore.log({
                        level: 'debug',
                        message: `... resolved as ${fileName}`,
                    });

                    // Models are expected to use GLTF extensions.
                    const loader = this.assetsManager.addMeshTask(this.getUniqueName(), null, controller.baseFrameUrl(true), fileName);

                    const meshTask = {
                        placement: s.placement.code,
                        placementKey,
                        code: loadCode,
                        loader,
                        checkTimer: setInterval(() => {
                            this.appStore.log({
                                level: 'info',
                                message: `${code} status: ${loader.isCompleted ? 'done' : 'loading'} / ${loader.taskState}`,
                                style: 'color: #999;',
                            });
                        }, 1000),
                    };

                    loader.associatedCode = code;
                    loader.customCheckTimer = meshTask.checkTimer;

                    this.pendingTasks.push(meshTask);

                    loader.onError = (task, message, error) => {
                        if (meshTask.checkTimer) {
                            clearInterval(meshTask.checkTimer);
                        }

                        this.assetsManager.removeTask(task);

                        // eslint-disable-next-line no-console
                        if (error != null && console.error) {
                            // eslint-disable-next-line no-console
                            console.error(`Error loading models: ${error.toString()}`);
                        }
                    };

                    loader.onSuccess = (task) => {
                        if (meshTask.checkTimer) {
                            clearInterval(meshTask.checkTimer);
                        }

                        this.assetsManager.removeTask(task);

                        if (task.customCancelled) {
                            this.appStore.log({
                                level: 'debug',
                                message: 'A task was already cancelled',
                                style: 'color: #f00; font-weight: bold;',
                            });

                            task.loadedMeshes.forEach((mesh) => {
                                if (mesh == null) {
                                    // Why is it even here?
                                    return;
                                }

                                // Prevent disposing refraction helper.
                                [mesh, ...mesh.getDescendants()].forEach((node) => {
                                    if (node.material && node.material.refractionTexture) {
                                        node.material.refractionTexture = null;
                                    }
                                });

                                mesh.dispose(true, true);
                            });

                            return;
                        }

                        if (placement.loadCode === loadCode) {
                            placement.currentCode = loadCode;
                            placement.loadCode = null;

                            task.loadedMeshes.forEach((mesh) => {
                                if (mesh == null) {
                                    // Why is it even here?
                                    return;
                                }

                                const loaded = {
                                    name,
                                    code,
                                    mesh,
                                    shadows: [],
                                    decals: [],
                                };

                                placement.meshes.push(loaded);

                                // Save controller reference.
                                mesh.customController = controller.id;

                                if (mesh.parent == null) {
                                    mesh.parent = placement.offset;
                                    mesh.emptyParent = true;
                                }

                                placement.offset.customComponent = code;
                                placement.offset.customPlacement = s.placement.code;

                                if (custom['three-pickable'] === 'false') {
                                    mesh.isPickable = false;
                                }

                                if (s.placement.custom && s.placement.custom['three-pickable'] === 'false') {
                                    mesh.isPickable = false;
                                }

                                if (custom['three-layer-mask']) {
                                    mesh.layerMask = +custom['three-layer-mask'];
                                }

                                if (s.placement.custom && s.placement.custom['three-layer-mask']) {
                                    mesh.layerMask = +s.placement.custom['three-layer-mask'];
                                }

                                let position;

                                if (custom['three-component-position']) {
                                    position = this.getPlacementVector3(custom['three-component-position'], s.placement.code, new Vector3(0, 0, 0));
                                } else {
                                    position = new Vector3(0, 0, 0);
                                }

                                placement.offset.position = position;

                                if (custom['three-component-scaling']) {
                                    const scaling = this.getPlacementVector3(custom['three-component-scaling'], s.placement.code, new Vector3(1, 1, 1));

                                    placement.offset.scaling = scaling;
                                } else {
                                    placement.offset.scaling = new Vector3(1, 1, 1);
                                }

                                if (custom['three-component-rotation']) {
                                    const rotation = this.getPlacementVector3(custom['three-component-rotation'], s.placement.code, new Vector3(0, 0, 0));

                                    placement.offset.rotation = rotation;
                                } else {
                                    placement.offset.rotation = new Vector3(0, 0, 0);
                                }

                                if (custom && custom['three-receives-shadows'] === 'true') {
                                    mesh.receiveShadows = true;
                                }

                                if (custom && custom['three-shadows-only'] === 'true') {
                                    if (mesh.material) {
                                        mesh.material.dispose();
                                    }
                                    mesh.material = new ShadowOnlyMaterial(`${code}-material`, this.scene);
                                }

                                if (custom && custom['three-shadow-color']) {
                                    mesh.material.shadowColor = ThreeUtilities.toColor3(custom['three-shadow-color']);
                                }

                                if (custom && custom['three-casts-shadows']) {
                                    const light = custom['three-casts-shadows'];

                                    if (light && this.shadows[light]) {
                                        if (!mesh.id.includes('root')) {
                                            this.shadows[light].addShadowCaster(mesh, true);
                                        }

                                        loaded.shadows.push(light);
                                    }
                                }

                                if (mesh.material) {
                                    //
                                    // if (mesh.material.pluginManager.getPlugin('HighQualityRefraction') == null) {
                                    //     // eslint-disable-next-line no-new
                                    //     const plugin = new HighQualityRefractionPluginMaterial(mesh.material);
                                    //     plugin.isEnabled = true;
                                    // }
                                }
                            });

                            // Unload old meshes.
                            const toDelete = placement.meshes.filter((x) => x.code !== code);
                            placement.meshes = placement.meshes.filter((x) => x.code === code);

                            // Collate materials.
                            const materials = {};
                            const materialsOnly = {};
                            const meshes = {};

                            placement.meshes.forEach((mesh) => {
                                this.collectMaterials(mesh.mesh, materials, materialsOnly, meshes);
                            });

                            this.applyMaterialSettings(custom, materials, materialsOnly, meshes);

                            toDelete.forEach((mesh) => {
                                this.appStore.log({
                                    level: 'info',
                                    message: `Disposing of ${mesh.name}`,
                                });

                                this.clearMesh(mesh);
                            });
                        } else {
                            // Too late, customer selection had changed.
                            const toDelete = [...task.loadedMeshes];

                            toDelete.forEach((mesh) => {
                                if (mesh) {
                                    // Prevent disposing refraction helper.
                                    [mesh, ...mesh.getDescendants()].forEach((node) => {
                                        if (node.material && node.material.refractionTexture) {
                                            node.material.refractionTexture = null;
                                        }
                                    });

                                    mesh.dispose(true, true);
                                }
                            });
                        }

                        this.applyAdjustments(controller);

                        this.applyTextures(controller);

                        this.applyMaterials(controller);

                        this.loadJoints();

                        // this.loadDimensions();

                        this.requestRenderUpdate(5);
                    };

                    this.appStore.log({
                        level: 'info',
                        message: 'Load asset manager (after checking content types)',
                        style: 'color: #606; font-weight: bold;',
                    });

                    this.assetsManager.load();
                });

                return true;
            },

            logScene() {
                if (this.scene) {
                    this.scene.rootNodes.forEach((node) => {
                        this.logNode(node, 0);
                    });
                }
            },

            logNode(node, level) {
                let indent = '';

                for (let i = 0; i < level; i += 1) {
                    indent += '--';
                }

                // eslint-disable-next-line
                console.log(`${indent} | ${node.name} [instanceof ${node.constructor.name}]`);

                const children = node.getChildren();

                if (children) {
                    children.forEach((n) => {
                        this.logNode(n, level + 1);
                    });
                }
            },

            collectMaterials(mesh, materials, materialsOnly, meshes) {
                if (mesh.material) {
                    if (meshes[mesh.material.id] == null) {
                        meshes[mesh.material.id] = [];
                    }
                    meshes[mesh.material.id].push(mesh);

                    if (materialsOnly[mesh.material.id] == null) {
                        materialsOnly[mesh.material.id] = [];
                    }

                    if (!materialsOnly[mesh.material.id].includes(mesh.material)) {
                        materialsOnly[mesh.material.id].push(mesh.material);
                    }

                    if (mesh.name) {
                        if (materials[mesh.name] == null) {
                            materials[mesh.name] = {};
                        }
                        if (materials[mesh.name][mesh.material.id] == null) {
                            materials[mesh.name][mesh.material.id] = [];
                        }

                        if (!materials[mesh.name][mesh.material.id].includes(mesh.material)) {
                            materials[mesh.name][mesh.material.id].push(mesh.material);
                        }
                    }
                }

                const children = mesh.getChildren();

                children.forEach((c) => {
                    this.collectMaterials(c, materials, materialsOnly, meshes);
                });
            },

            getPlacementVector3(value, placement, defaultValue) {
                if (value && value[0] === '{') {
                    try {
                        const parsed = JSON.parse(value);

                        if (parsed[placement]) {
                            return ThreeUtilities.toVector3(parsed[placement]);
                        }

                        return defaultValue;
                    } catch (e) {
                        // Nothing here.
                    }
                }

                if (value) {
                    return ThreeUtilities.toVector3(value);
                }

                return defaultValue;
            },

            clearMesh(mesh) {
                // Remove from shadow generators.
                if (mesh.shadows) {
                    mesh.shadows.forEach((light) => {
                        if (light && this.shadows[light]) {
                            this.shadows[light].removeShadowCaster(mesh.mesh, true);
                        }
                    });

                    mesh.shadows = [];
                }

                if (mesh.decals) {
                    mesh.decals.forEach((decal) => {
                        decal.dispose(true, false);

                        if (decal.material) {
                            decal.material.dispose();
                        }
                    });

                    mesh.decals = [];
                }

                // Dispose old meshes.
                if (mesh.mesh) {
                    // Prevent disposing refraction helper.
                    [mesh.mesh, ...mesh.mesh.getDescendants()].forEach((node) => {
                        if (node.material && node.material.refractionTexture) {
                            node.material.refractionTexture = null;
                        }
                    });

                    mesh.mesh.dispose(true, true);
                }
            },

            setPlacementValue(placement, container, value) {
                let c;

                if (container == null) {
                    c = {};
                } else if (container[0] !== '{') {
                    c = {};
                } else {
                    c = JSON.parse(container);
                }

                c[placement] = value;

                return JSON.stringify(c);
            },

            placementKey(controller, code) {
                return `${this.controllerKey(controller)}->${code}`;
            },

            viewKey(controller, code) {
                return `${this.controllerKey(controller)}->${code}`;
            },

            placementsByCode(code) {
                return Object.entries(this.placements).filter(([, k]) => k.placement === code).map(([, v]) => v);
            },

            applyAdjustments() {
                // Reset adjustments.
                Object.keys(this.placements).forEach((k) => {
                    const p = this.placements[k];

                    p.adjust.position = new Vector3(0, 0, 0);
                    p.adjust.rotationQuaternion = null;
                    p.adjust.rotation = new Vector3(0, 0, 0);
                });

                // Scan the current state and check if dependent adjustments are needed.
                this.forAllControllers((controller) => {
                    controller.state.forEach((s) => {
                        if (s.component && s.component.custom && s.component.custom['three-adjust-position']) {
                            try {
                                const adjustments = JSON.parse(s.component.custom['three-adjust-position']);

                                Object.keys(adjustments).forEach((code) => {
                                    const add = ThreeUtilities.toVector3(adjustments[code]);

                                    const placement = this.placements[this.placementKey(controller, code)];

                                    if (placement) {
                                        placement.adjust.position = placement.adjust.position.add(add);
                                    }
                                });
                            } catch (e) {
                                // Nothing...
                            }
                        }

                        if (s.component && s.component.custom && s.component.custom['three-adjust-component-position']) {
                            try {
                                const adjustments = JSON.parse(s.component.custom['three-adjust-component-position']);

                                Object.keys(adjustments).forEach((placementCode) => {
                                    Object.keys(adjustments[placementCode]).forEach((code) => {
                                        controller.state.forEach((otherS) => {
                                            if (otherS.placement.code === placementCode && otherS.component.placementIndependentCode === code) {
                                                const add = ThreeUtilities.toVector3(adjustments[placementCode][code]);

                                                const placement = this.placements[this.placementKey(controller, otherS.placement.code)];

                                                if (placement) {
                                                    placement.adjust.position = placement.adjust.position.add(add);
                                                }
                                            }
                                        });
                                    });
                                });
                            } catch (e) {
                                // Nothing...
                            }
                        }

                        if (s.component && s.component.custom && s.component.custom['three-adjust-component-rotation']) {
                            try {
                                const adjustments = JSON.parse(s.component.custom['three-adjust-component-rotation']);

                                Object.keys(adjustments).forEach((placementCode) => {
                                    Object.keys(adjustments[placementCode]).forEach((code) => {
                                        controller.state.forEach((otherS) => {
                                            if (otherS.placement.code === placementCode && otherS.component.placementIndependentCode === code) {
                                                const addAlpha = ThreeUtilities.toRad(adjustments[placementCode][code].alpha);
                                                const addBeta = ThreeUtilities.toRad(adjustments[placementCode][code].beta);
                                                const addGamma = ThreeUtilities.toRad(adjustments[placementCode][code].gamma);

                                                const placement = this.placements[this.placementKey(controller, otherS.placement.code)];

                                                if (placement) {
                                                    placement.adjust.rotate(Axis.Y, addGamma, Space.LOCAL);
                                                    placement.adjust.rotate(Axis.X, addAlpha, Space.LOCAL);
                                                    placement.adjust.rotate(Axis.Z, addBeta, Space.LOCAL);
                                                }
                                            }
                                        });
                                    });
                                });
                            } catch (e) {
                                // Nothing...
                            }
                        }
                    });
                });
            },

            /**
             * Loads background model.
             */
            loadBackground(s, controller) {
                return this.loadModel(s, controller);
            },

            /**
             * Unloads a placement.
             */
            unload(s, controller) {
                if (s.placement.usage === 'Environment') {
                    // Unload environment.
                }

                if (s.placement.usage === 'Model') {
                    // Unload a model.
                    const placement = this.placements[this.placementKey(controller, s.placement.code)];

                    if (placement != null) {
                        placement.currentCode = null;
                        placement.loadCode = null;

                        const toDelete = placement.meshes;

                        toDelete.forEach((mesh) => {
                            this.clearMesh(mesh);
                        });
                    }
                }

                if (s.placement.usage === 'Background') {
                    // Unload background.
                }
            },

            getAsBase64(image, signal) {
                if (image == null) {
                    return Promise.resolve(image);
                }

                return fetch(image, { signal, mode: 'cors' })
                    .then((response) => {
                        if (response.ok) {
                            return response.blob();
                        }

                        throw new Error(`Could not load ${image}`);
                    })
                    .then((blob) => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onerror = () => {
                            reject();
                        };
                        reader.onload = () => {
                            resolve(reader.result);
                        };
                        reader.readAsDataURL(blob);
                    }));
            },

            controllerKey(controller) {
                if (controller.socket) {
                    if (controller.blueprint) {
                        return `${controller.socket}->${controller.blueprint.styleCode}`;
                    }

                    return controller.socket;
                }

                return 'root';
            },

            forAllControllers(callback) {
                if (this.controller) {
                    callback(this.controller, 'root');

                    const sockets = this.controller.socketsState;

                    sockets.forEach((s) => {
                        if (s.to) {
                            callback(s.to, s.id);
                        }
                    });
                }
            },

            /**
             * Loads models from all controllers.
             */
            loadModels() {
                let anyLoaders = false;

                const validatedKeys = [];

                this.forAllControllers((controller) => {
                    if (this.loadModelsController(controller)) {
                        anyLoaders = true;
                    }

                    controller.state.forEach((s) => {
                        validatedKeys.push(this.placementKey(controller, s.placement.code));
                    });
                });

                // Validate placements that may have been completely unloaded.
                const toDelete = [];

                Object.keys(this.placements).forEach((k) => {
                    if (!validatedKeys.includes(k)) {
                        toDelete.push(k);
                    }
                });

                toDelete.forEach((k) => {
                    this.appStore.log({
                        level: 'info',
                        message: `Disposing of unloaded placement ${k}`,
                    });

                    const placement = this.placements[k];

                    placement.currentCode = null;
                    placement.loadCode = null;

                    [...placement.meshes].forEach((mesh) => {
                        this.clearMesh(mesh);
                    });

                    delete this.placements[k];
                });

                return anyLoaders;
            },

            /**
             * Loads models.
             */
            loadModelsController(controller) {
                let anyLoaders = false;

                if (controller) {
                    controller.state.forEach((s) => {
                        // Is this placement shown in the view?
                        let visible = false;

                        if (this.view?.onlySelectedPlacements) {
                            if (this.view.placements) {
                                visible = this.view.placements.some((p) => p.code === s.placement.code);
                            }
                        } else {
                            visible = true;
                        }

                        if (!visible) {
                            // Delete placement.
                            this.unload(s, controller);
                            return;
                        }

                        this.appStore.log({
                            level: 'debug',
                            message: `Load ${s.placement.code} / ${s.component.code}`,
                        });

                        if (this.controllerKey(controller) === 'root') {
                            if (s.placement.usage === 'Environment') {
                                this.loadEnvironment(s, controller);
                            }

                            if (s.placement.usage === 'Background') {
                                if (this.loadBackground(s, controller)) {
                                    anyLoaders = true;
                                }
                            }
                        }

                        if (s.placement.usage === 'Model') {
                            if (this.loadModel(s, controller)) {
                                anyLoaders = true;
                            }
                        }
                    });
                }

                // eslint-disable-next-line no-underscore-dangle
                if (this.assetsManager._tasks) {
                    // eslint-disable-next-line no-underscore-dangle
                    this.assetsManager._tasks.forEach((task) => {
                        this.appStore.log({
                            level: 'info',
                            message: `Task before: ${task.name} / ${task.associatedCode} / ${task.taskState}`,
                            style: 'color: #606;',
                        });
                    });
                }

                this.appStore.log({
                    level: 'info',
                    message: 'Load asset manager',
                    style: 'color: #606; font-weight: bold;',
                });

                this.assetsManager.load();

                // eslint-disable-next-line no-underscore-dangle
                if (this.assetsManager._tasks) {
                    // eslint-disable-next-line no-underscore-dangle
                    this.assetsManager._tasks.forEach((task) => {
                        this.appStore.log({
                            level: 'info',
                            message: `Task after: ${task.name} / ${task.associatedCode} / ${task.taskState}`,
                            style: 'color: #606;',
                        });
                    });
                }

                // Validate pending tasks and "un-pend" the ones that started loading.
                this.pendingTasks = this.pendingTasks.filter((t) => {
                    if (t.loader.taskState !== 0) {
                        this.appStore.log({
                            level: 'info',
                            message: `Task is now running: ${t.loader.name} / ${t.loader.associatedCode}`,
                            style: 'color: #606;',
                        });
                        return false;
                    }

                    return true;
                });

                this.appStore.log({
                    level: 'info',
                    message: `... ${this.pendingTasks.length} tasks pending`,
                    style: 'color: #606; font-weight: bold;',
                });

                return anyLoaders;
            },

            removePendingTask(t) {
                if (t.checkTimer) {
                    clearInterval(t.checkTimer);
                }

                this.assetsManager.removeTask(t.loader);
            },

            /**
             * Validate pending tasks and check if we need to restart loading.
             */
            validatePendingTasks() {
                this.pendingTasks = this.pendingTasks.filter((t) => {
                    const placement = this.placements[t.placementKey];

                    if (placement == null) {
                        this.removePendingTask(t);

                        return false;
                    }

                    if (t.loader.taskState !== 0) {
                        this.appStore.log({
                            level: 'info',
                            message: `... Pending ${t.code} already done`,
                            style: 'color: #060;',
                        });

                        this.removePendingTask(t);

                        return false;
                    }

                    if (placement.currentCode === t.code) {
                        this.appStore.log({
                            level: 'info',
                            message: `... Pending ${t.code} already loaded`,
                            style: 'color: #060;',
                        });

                        if (placement.loadCode) {
                            // Huh?
                            placement.loadCode = null;
                        }

                        this.removePendingTask(t);

                        return false;
                    }

                    if (placement.loadCode && placement.loadCode !== t.code) {
                        this.appStore.log({
                            level: 'info',
                            message: `... Pending ${t.code} was too late and there is a request to load ${placement.loadCode}`,
                            style: 'color: #900;',
                        });

                        this.removePendingTask(t);

                        return false;
                    }

                    return true;
                });

                this.appStore.log({
                    level: 'info',
                    message: `... ${this.pendingTasks.length} tasks pending after validation`,
                    style: 'color: #606; font-weight: bold;',
                });

                if (this.pendingTasks.length > 0) {
                    this.$nextTick(() => {
                        this.appStore.log({
                            level: 'info',
                            message: 'Restart loading',
                            style: 'color: #606; font-weight: bold;',
                        });

                        this.assetsManager.load();
                    });
                }
            },

            /**
             * Process state changes.
             */
            stateChanged() {
                if (!this.loadModels()) {
                    // Apply adjustments right away if we don't need to load anything.
                    this.applyAdjustments();

                    this.applyTextures();

                    this.applyMaterials();

                    this.loadJoints();

                    // this.loadDimensions();

                    this.requestRenderUpdate(1);
                }

                this.loadTextures();
            },

            normalizeAlpha(angle) {
                let a = angle;

                const twoPi = 2 * Math.PI;

                while (a >= twoPi) {
                    a -= twoPi;
                }

                while (a < 0) {
                    a += twoPi;
                }

                return a;
            },

            animateCurrent(name, target, property, to, fps, frames, loopMode, easing) {
                const from = PaneUtilities.getValuePath(target, property);

                this.animate(name, target, property, from, to, fps, frames, loopMode, easing);
            },

            animate(name, target, property, from, to, fps, frames, loopMode, easing) {
                if (this.animations == null) {
                    this.animations = {};
                }

                if (this.animations[name]) {
                    this.animations[name].stop();
                }

                let ease = null;

                if (easing == null) {
                    ease = new CubicEase();

                    ease.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
                } else {
                    ease = easing;
                }

                // Optimize animations.
                if (from != null && to != null) {
                    if (from.equals && to.equals) {
                        if (from.equals(to)) {
                            // Nothing to do.
                            this.requestRenderUpdate(1);

                            delete this.animations[name];

                            return;
                        }
                    } else if (from === to) {
                        // Nothing to do.
                        this.requestRenderUpdate(1);

                        delete this.animations[name];

                        return;
                    }
                }

                const result = Animation.CreateAndStartAnimation(
                    name,
                    target,
                    property,
                    fps,
                    frames,
                    from,
                    to,
                    loopMode == null ? Animation.ANIMATIONLOOPMODE_CONSTANT : loopMode,
                    ease,
                    () => {
                        this.requestRenderUpdate(1);

                        delete this.animations[name];
                    },
                );

                this.animations[name] = result;
            },

            transitionCameraToView() {
                if (this.camera != null) {
                    const fps = 30;
                    let duration = 30;

                    if (this.view.custom && this.view.custom['three-view-transition-frames']) {
                        duration = +this.view.custom['three-view-transition-frames'];
                    }

                    if (this.viewOptions == null || !this.viewOptions.ignoreRotation) {
                        let upTarget = new Vector3(0, 1, 0);

                        // Animate camera.
                        if (this.view.custom && this.view.custom['three-camera-up']) {
                            upTarget = ThreeUtilities.toVector3(this.view.custom['three-camera-up']).normalizeToNew();
                        }

                        this.animateCurrent(
                            'camera-up',
                            this.camera,
                            'upVector',
                            upTarget,
                            fps,
                            duration,
                        );

                        // Animate camera.
                        if (this.view.custom && this.view.custom['three-camera-alpha']) {
                            const target = this.view.custom['three-camera-alpha'] == null
                                ? Math.PI / 2
                                : ThreeUtilities.toRad(this.view.custom['three-camera-alpha']);

                            this.animateCurrent(
                                'camera-alpha',
                                this.camera,
                                'alpha',
                                this.normalizeAlpha(target),
                                fps,
                                duration,
                            );
                        }

                        if (this.view.custom && this.view.custom['three-camera-beta']) {
                            const target = this.view.custom['three-camera-beta'] == null
                                ? Math.PI / 2
                                : ThreeUtilities.toRad(this.view.custom['three-camera-beta']);

                            this.animateCurrent(
                                'camera-beta',
                                this.camera,
                                'beta',
                                target,
                                fps,
                                duration,
                            );
                        }
                    }

                    if (this.view.custom && this.view.custom['three-camera-radius-max']) {
                        const target = ThreeUtilities.toFloat(this.view.custom['three-camera-radius-max']);

                        this.animateCurrent(
                            'camera-radius-max',
                            this.camera,
                            'upperRadiusLimit',
                            target,
                            fps,
                            duration,
                        );
                    }

                    if (this.view.custom && this.view.custom['three-camera-radius-min']) {
                        const target = ThreeUtilities.toFloat(this.view.custom['three-camera-radius-min']);

                        this.animateCurrent(
                            'camera-radius-min',
                            this.camera,
                            'lowerRadiusLimit',
                            target,
                            fps,
                            duration,
                        );
                    }

                    if (this.view.custom && this.view.custom['three-camera-radius']) {
                        const target = ThreeUtilities.toFloat(this.view.custom['three-camera-radius']);

                        this.animateCurrent(
                            'camera-radius',
                            this.camera,
                            'radius',
                            target,
                            fps,
                            duration,
                        );
                    }

                    if (this.view.custom && this.view.custom['three-camera-target']) {
                        let target = ThreeUtilities.toVector3(this.view.custom['three-camera-target']);

                        // POPSOCKETS CUSTOMIZATION
                        // Count the number of looks...
                        if (this.small) {
                            let inserts = 0;

                            this.controller?.forAllControllers((c) => {
                                if (c.blueprint.styleCode.startsWith('C3Insert')) {
                                    inserts += 1;
                                }
                            });

                            if (this.view.custom[`three-camera-target-inserts${inserts}`]) {
                                target = ThreeUtilities.toVector3(this.view.custom[`three-camera-target-inserts${inserts}`]);
                            }
                        }
                        // END OF POPSOCKETS CUSTOMIZATION

                        this.animateCurrent(
                            'camera-target',
                            this.camera,
                            'target',
                            target,
                            fps,
                            duration,
                        );
                    }

                    if (this.view.custom && this.view.custom['three-camera-fov']) {
                        const fov = ThreeUtilities.toRad(this.view.custom['three-camera-fov']);

                        this.animateCurrent(
                            'camera-fov',
                            this.camera,
                            'fov',
                            fov,
                            fps,
                            duration,
                        );
                    }

                    // Environment offset.
                    let environmentTarget = null;

                    if (this.view.custom && this.view.custom['three-environment-offset']) {
                        environmentTarget = ThreeUtilities.toRad(this.view.custom['three-environment-offset']);
                    } else if (this.currentEnvironmentComponent) {
                        if (this.currentEnvironmentComponent.custom && this.currentEnvironmentComponent.custom['three-environment-offset']) {
                            environmentTarget = ThreeUtilities.toRad(this.currentEnvironmentComponent.custom['three-environment-offset']);
                        }
                    }

                    if (environmentTarget != null) {
                        this.animateCurrent(
                            'environment-offset',
                            this,
                            'environmentOffset',
                            environmentTarget,
                            fps,
                            duration,
                        );
                    }
                }
            },

            /**
             * Selects the current view.
             */
            selectView() {
                this.lockViewCamera();

                this.transitionCameraToView();

                if (!this.loadModels()) {
                    // Apply adjustments right away if we don't need to load anything.
                    this.applyAdjustments();

                    this.applyTextures();

                    this.applyMaterials();

                    this.loadJoints();

                    // this.loadDimensions();

                    this.popsocketsSelectView();

                    this.requestRenderUpdate(1);
                }

                this.loadTextures({
                    skipIfUnchanged: true,
                });
            },

            /**
             * Locks or unlocks camera controls.
             */
            lockViewCamera() {
                if (this.view) {
                    const lock = this.view?.custom?.['three-camera-allow-control'] === 'false';

                    if (this.camera) {
                        if (lock) {
                            if (this.cameraLocked == null || this.cameraLocked === false) {
                                this.cameraLocked = true;

                                this.camera.detachControl();
                            }
                        } else if (this.cameraLocked == null || this.cameraLocked === true) {
                            this.cameraLocked = false;

                            this.camera.attachControl(this.$refs.canvas, true);
                        }
                    }
                }
            },

            /**
             * Selects a new controller.
             */
            selectController() {
                this.createImage();
            },

            // Resize image views.
            resize() {
                if (this._resizeThrottle) {
                    return;
                }

                this._resizeThrottle = setTimeout(() => this.resizeThrottled(), 20);
            },

            resizeThrottled() {
                if (this.$refs.canvas && this.$refs.container) {
                    this.showMemoryStats('resizeBefore');

                    const w = this.$refs.container.offsetWidth;
                    const h = this.$refs.container.offsetHeight;

                    if (this._lastSizeWidth !== w || this._lastSizeHeight !== h) {
                        this._lastSizeWidth = w;
                        this._lastSizeHeight = h;

                        this.$refs.canvas.style.width = `${w}px`;
                        this.$refs.canvas.style.height = `${h}px`;

                        this.$refs.canvas.width = w;
                        this.$refs.canvas.height = h;

                        this.resizeEngine();

                        this.requestRenderUpdate(1);

                        this.showMemoryStats('resizeAfter');
                    } else {
                        // eslint-disable-next-line
                        console.log('Skipped resize...');
                    }
                }

                this._resizeThrottle = null;
            },

            resizeEngine() {
                if (this.engine) {
                    this.showMemoryStats('resizeBeforeEngineResize');

                    if (this.scene) {
                        if (this.scene.postProcesses) {
                            for (let i = 0; i < this.scene.postProcesses.length; i += 1) {
                                this.scene.postProcesses[i]._disposeTextureCache();
                            }
                        }
                    }

                    if (this.pipeline.fxaa) {
                        this.pipeline.fxaa._disposeTextureCache();
                    }

                    if (this.pipeline.imageProcessing) {
                        this.pipeline.imageProcessing._disposeTextureCache();
                    }

                    this.engine.resize();

                    // Clear out postprocess caches, they track last 100 textures.
                    this.requestRenderUpdate(200);

                    this.showMemoryStats('resizeAfterEngineResize');

                    this.render();

                    this.showMemoryStats('resizeAfterEngineRender');

                    this.calculateAspectRatio();
                }
            },

            calculateAspectRatio() {
                // Store the current aspect ratio.
                if (this.$refs.canvas) {
                    const width = this.$refs.canvas.width;
                    const styleWidth = parseInt(this.$refs.canvas.style.width, 10);

                    const ratio = width / styleWidth;

                    this.emitter.$emit('setCameraAspectRatio', ratio);
                }
            },

            findHandle(mesh, type) {
                let m = mesh;

                while (m) {
                    if (m.emptyParent) {
                        m = m.parent;
                    } else {
                        if (m.customHandle && m.name.endsWith(`->${type}`)) {
                            return m;
                        }

                        m = m.parent;
                    }
                }

                return m;
            },

            /**
             * Record current camera data so we could check if camera moved.
             */
            pickPointerDown() {
                this.emitter.$emit('cameraClicked');
            },

            /**
             * Picks an object.
             */
            pickObject() {
            },

            /**
             * React to camera position changes.
             */
            cameraPositionChanged() {
                this.updateEnvironmentPosition();
            },

            /**
             * Synchronizes environment and cameras.
             */
            updateEnvironmentPosition() {
                if (this.scene && this.scene.environmentTexture && this.camera) {
                    if (this.environmentFollowsCamera) {
                        const y = this.camera.alpha;

                        this.environmentOffsetCurrent = this.environmentOffset + y;

                        this.updateEnvironmentRotation();
                    } else {
                        this.environmentOffsetCurrent = this.environmentOffset;

                        this.updateEnvironmentRotation();
                    }
                }
            },

            updateEnvironmentRotation() {
                if (this.scene && this.scene.environmentTexture) {
                    if (this.environmentOffsetCurrent !== this.scene.environmentTexture.rotationY) {
                        this.scene.environmentTexture.rotationY = this.environmentOffsetCurrent;
                    }
                }
            },

            onUpdateView() {
            },

            resolveValue(name, current, changed, baseline) {
                if (current === changed) {
                    if (changed.custom && changed.custom[name]) {
                        return changed.custom[name];
                    }

                    if (baseline.custom && baseline.custom[name]) {
                        return baseline.custom[name];
                    }
                } else {
                    // Overridden.
                    if (current.custom && current.custom[name]) {
                        return current.custom[name];
                    }

                    if (baseline.custom && baseline.custom[name]) {
                        return baseline.custom[name];
                    }
                }

                return null;
            },

            runTasks(tasks) {
                return new Promise((resolve) => {
                    const ts = [...tasks];

                    const rt = () => {
                        if (ts.length === 0) {
                            resolve();
                        } else {
                            const task = ts.shift();

                            const promise = task();

                            promise.then(() => {
                                rt();
                            });
                        }
                    };

                    rt();
                });
            },

            socketsUpdated() {
                if (!this.loadModels()) {
                    // Apply adjustments right away if we don't need to load anything.
                    this.applyAdjustments();

                    this.applyTextures();

                    this.applyMaterials();

                    this.loadJoints();

                    // this.loadDimensions();

                    this.requestRenderUpdate(1);
                }

                this.loadTextures();
            },

            toggleDimensions(v) {
                if (v == null) {
                    this.showDimensions = !this.showDimensions;
                } else {
                    this.showDimensions = v;
                }

                // this.loadDimensions();
            },

            highlightMeshes(options) {
                this.litStart = new Date().getTime() + options.delay;
                this.litRampFull = new Date().getTime() + options.delay + options.transition;
                this.litRampEnd = new Date().getTime() + options.delay + options.transition + options.duration;
                this.litEnd = new Date().getTime() + options.delay + options.transition + options.duration + options.transition;
                this.litOptions = options;
            },

            addNodeHighlight(node, options) {
                let record = this.litMeshes[node.name];
                if (record == null) {
                    record = {
                        meshes: [],
                        excluded: [],
                    };

                    this.litMeshes[node.name] = record;
                }

                let addChildren = true;

                if (node instanceof Mesh) {
                    // If it's a mesh, child meshes will be highlighted automatically.
                    addChildren = false;

                    record.meshes.push(node);

                    this.highlightLayers.forEach((h) => {
                        h.addMesh(node, options.color);
                    });
                }

                node.getChildMeshes().forEach((mesh) => {
                    // Exclude decals.
                    if (mesh.name && mesh.name.includes('decal')) {
                        record.excluded.push(mesh);

                        this.highlightLayers.forEach((h) => {
                            h.addExcludedMesh(mesh);
                        });

                        return;
                    }

                    if (addChildren) {
                        record.meshes.push(mesh);

                        this.highlightLayers.forEach((h) => {
                            h.addMesh(mesh, options.color);
                        });
                    }
                });
            },

            removeNodeHighlight(name) {
                if (this.litMeshes[name]) {
                    const node = this.litMeshes[name];

                    node.meshes.forEach((m) => {
                        this.highlightLayers.forEach((h) => {
                            h.removeMesh(m);
                        });
                    });

                    node.excluded.forEach((m) => {
                        this.highlightLayers.forEach((h) => {
                            h.removeExcludedMesh(m);
                        });
                    });

                    delete this.litMeshes[name];
                }
            },

            highlightOptions(options) {
                this.scene.meshes.forEach((node) => {
                    if (options.controller) {
                        if (node.customController !== options.controller.id) {
                            return;
                        }
                    }

                    if (options.meshes && options.meshes.includes(node.name)) {
                        this.removeNodeHighlight(node.name);
                        this.addNodeHighlight(node, options);
                    }
                });

                this.scene.transformNodes.forEach((node) => {
                    if (options.controller) {
                        if (node.customController !== options.controller.id) {
                            return;
                        }
                    }

                    if (options.meshes && options.meshes.includes(node.name)) {
                        this.removeNodeHighlight(node.name);
                        this.addNodeHighlight(node, options);
                    }
                });
            },

            updateHighlights() {
                const now = new Date().getTime();

                let opacity = null;

                if (this.litStart) {
                    if (now > this.litStart) {
                        if (this.litOptions) {
                            this.highlightOptions(this.litOptions);
                            this.litOptions = null;
                        }
                    }

                    // Done.
                    if (now > this.litEnd) {
                        this.clearHighlights();
                    } else if (now > this.litRampEnd) {
                        const f = (now - this.litRampEnd) / (this.litEnd - this.litRampEnd);

                        opacity = 1 - f;
                    } else if (now > this.litRampFull) {
                        opacity = 1;
                    } else if (now > this.litStart) {
                        const f = (now - this.litStart) / (this.litRampFull - this.litStart);

                        opacity = f;
                    }

                    if (opacity != null) {
                        this.highlightLayers.forEach((h) => {
                            h.intensity = opacity;
                        });
                    } else {
                        this.highlightLayers.forEach((h) => {
                            h.intensity = 0;
                        });
                    }
                }

                return this.highlightLayers.length > 0 && this.highlightLayers[0].intensity > 0;
            },

            clearHighlights() {
                Object.keys(this.litMeshes).forEach((n) => {
                    this.removeNodeHighlight(n);
                });

                this.litMeshes = {};
                this.litStart = null;
                this.litRampFull = null;
                this.litRampEnd = null;
                this.litEnd = null;
                this.litOptions = null;
            },

            setEditorProgress(value) {
                this.editorProgress = value;

                if (value?.layer) {
                    if (this.editorProgressTimer == null) {
                        this.editorProgressTimer = setTimeout(() => {
                            this.editorProgressTimer = null;
                            this.updateTexturesFromEditor();
                        }, 20);
                    }
                }
            },

            /**
             * Take snapshot.
             */
            snapshot({
                controllerId,
                view,
                done,
                mimeType,
            }) {
                this.appStore.log({
                    level: 'info',
                    message: `Taking a screenshot using ${view}`,
                });

                let snapView = null;

                this.showMemoryStats('snapshot');

                this.forAllControllers((controller) => {
                    if (controllerId != null && controller.id !== controllerId) {
                        return;
                    }

                    const useView = controller.blueprint.views.find((v) => v.code === view);

                    if (useView) {
                        snapView = useView;
                    }
                });

                if (snapView == null) {
                    done(null);
                    return;
                }

                this.clearHighlights();

                // Copy camera parameters.
                const copyParameters = [
                    'lowerAlphaLimit',
                    'upperAlphaLimit',
                    'lowerBetaLimit',
                    'upperBetaLimit',
                    'alpha',
                    'beta',
                    'lowerRadiusLimit',
                    'upperRadiusLimit',
                    'radius',
                    'fov',
                    'target',
                    'upVector',
                ];

                const savedCamera = {};

                copyParameters.forEach((p) => {
                    if (this.camera[p] instanceof Vector3) {
                        savedCamera[p] = this.camera[p].clone();
                    } else {
                        savedCamera[p] = this.camera[p];
                    }
                });

                // Configure the snapshot camera.
                if (snapView.custom && snapView.custom['three-camera-alpha-min']) {
                    const target = snapView.custom['three-camera-alpha-min'] == null
                        ? Math.PI / 2
                        : ThreeUtilities.toRad(snapView.custom['three-camera-alpha-min']);

                    this.camera.lowerAlphaLimit = target;
                }

                if (snapView.custom && snapView.custom['three-camera-alpha-max']) {
                    const target = snapView.custom['three-camera-alpha-max'] == null
                        ? Math.PI / 2
                        : ThreeUtilities.toRad(snapView.custom['three-camera-alpha-max']);

                    this.camera.upperAlphaLimit = target;
                }

                if (snapView.custom && snapView.custom['three-camera-alpha']) {
                    const target = snapView.custom['three-camera-alpha'] == null
                        ? Math.PI / 2
                        : ThreeUtilities.toRad(snapView.custom['three-camera-alpha']);

                    this.camera.alpha = target;
                }

                if (snapView.custom && snapView.custom['three-camera-beta-min']) {
                    const target = snapView.custom['three-camera-beta-min'] == null
                        ? Math.PI / 2
                        : ThreeUtilities.toRad(snapView.custom['three-camera-beta-min']);

                    this.camera.lowerBetaLimit = target;
                }

                if (snapView.custom && snapView.custom['three-camera-beta-max']) {
                    const target = snapView.custom['three-camera-beta-max'] == null
                        ? Math.PI / 2
                        : ThreeUtilities.toRad(snapView.custom['three-camera-beta-max']);

                    this.camera.upperBetaLimit = target;
                }

                if (snapView.custom && snapView.custom['three-camera-beta']) {
                    const target = snapView.custom['three-camera-beta'] == null
                        ? Math.PI / 2
                        : ThreeUtilities.toRad(snapView.custom['three-camera-beta']);

                    this.camera.beta = target;
                }

                if (snapView.custom && snapView.custom['three-camera-radius-max']) {
                    const target = ThreeUtilities.toFloat(snapView.custom['three-camera-radius-max']);

                    this.camera.upperRadiusLimit = target;
                }

                if (snapView.custom && snapView.custom['three-camera-radius-min']) {
                    const target = ThreeUtilities.toFloat(snapView.custom['three-camera-radius-min']);

                    this.camera.lowerRadiusLimit = target;
                }

                if (snapView.custom && snapView.custom['three-camera-radius']) {
                    const target = ThreeUtilities.toFloat(snapView.custom['three-camera-radius']);

                    this.camera.radius = target;
                }

                if (snapView.custom && snapView.custom['three-camera-fov']) {
                    const target = ThreeUtilities.toRad(snapView.custom['three-camera-fov']);

                    this.camera.fov = target;
                }

                if (snapView.custom && snapView.custom['three-camera-target']) {
                    let target = ThreeUtilities.toVector3(snapView.custom['three-camera-target']);

                    // POPSOCKETS CUSTOMIZATION
                    // Count the number of looks...
                    let inserts = 0;

                    this.controller?.forAllControllers((c) => {
                        if (c.blueprint.styleCode.startsWith('C3Insert')) {
                            inserts += 1;
                        }
                    });

                    if (snapView.custom[`three-camera-target-inserts${inserts}`]) {
                        target = ThreeUtilities.toVector3(snapView.custom[`three-camera-target-inserts${inserts}`]);
                    }
                    // END OF POPSOCKETS CUSTOMIZATION

                    this.camera.target = target;
                }

                if (snapView.custom && snapView.custom['three-camera-up']) {
                    const upTarget = ThreeUtilities.toVector3(snapView.custom['three-camera-up']).normalizeToNew();
                    this.camera.upVector = upTarget;
                } else {
                    this.camera.upVector = new Vector3(0, 1, 0);
                }

                // Placements to restore visibility.
                const unhidePlacements = [];

                this.controller.state.forEach((s) => {
                    let visible = false;

                    if (snapView.onlySelectedPlacements) {
                        if (snapView.placements) {
                            visible = snapView.placements.some((p) => p.code === s.placement.code);
                        }
                    } else {
                        visible = true;
                    }

                    if (!visible) {
                        unhidePlacements.push(s);

                        this.appStore.log({
                            level: 'info',
                            message: `Hiding ${s.placement.code} because it is disabled for the snapshot`,
                        });

                        if (s.placement.usage === 'Model') {
                            const placement = this.placements[s.placement.code];

                            if (placement != null) {
                                placement.meshes.forEach((mesh) => {
                                    mesh.mesh.setEnabled(false);
                                });
                            }
                        }
                    }
                });

                // Apply Popsockets specific visibility rules.
                this.popsocketsSelectView(snapView);

                // Pause animations.
                const unpause = [];

                Object.values(this.animations).forEach((a) => {
                    if (a) {
                        if (!a.paused) {
                            unpause.push(a);
                            a.pause();
                        }
                    }
                });

                // Set joint positions.
                this.loadJoints({
                    immediate: true,
                    view,
                });

                this.updateEnvironmentPosition();

                this.projectJoints();
                this.projectDecals();

                if (snapView.custom && snapView.custom['three-camera-target-joint']) {
                    const joint = snapView.custom['three-camera-target-joint'];

                    this.controller.allSockets().forEach((socket) => {
                        if (socket.socket.code === joint) {
                            if (socket.target) {
                                let target = socket.target;

                                if (snapView.custom['three-camera-target-joint-offset']) {
                                    target = target.add(ThreeUtilities.toVector3(snapView.custom['three-camera-target-joint-offset']));
                                }

                                this.camera.target = target;
                            }
                        }
                    });
                }

                // Screenshot.
                let width = 512;
                let height = 512;

                if (snapView.custom['three-snapshot-width']) {
                    width = +snapView.custom['three-snapshot-width'];
                }

                if (snapView.custom['three-snapshot-height']) {
                    height = +snapView.custom['three-snapshot-height'];
                }

                const withType = mimeType ?? view.view?.custom?.['three-snapshot-mimetype'] ?? 'image/jpeg';
                const background = view.view?.custom?.['three-snapshot-background'] ?? '#ffffff';

                const clearColor = this.scene.clearColor;

                this.scene.clearColor = ThreeUtilities.toColor4(background);

                this.appStore.log({
                    level: 'info',
                    message: `Ready to screenshot ${width} x ${height}`,
                });

                const { canvas } = this.$refs;

                const currentCanvasSizeWidth = canvas.style.width;
                const currentCanvasSizeHeight = canvas.style.height;
                canvas.style.width = `${width}px`;
                canvas.style.height = `${height}px`;

                this.engine.resize(true);

                // Render.
                this.scene.updateTransformMatrix(true);

                this.render();

                CreateScreenshot(
                    this.engine,
                    this.camera,
                    { width, height },
                    (data) => {
                        this.appStore.log({
                            level: 'info',
                            message: 'Done screenshotting',
                        });

                        this.showMemoryStats('snapshotDone');

                        canvas.style.width = currentCanvasSizeWidth;
                        canvas.style.height = currentCanvasSizeHeight;

                        this.engine.resize(true);

                        // Restore settings.
                        this.scene.clearColor = clearColor;

                        // Resume animations.
                        unpause.forEach((a) => {
                            a.restart();
                        });

                        // Restore placements.
                        unhidePlacements.forEach((s) => {
                            if (s.placement.usage === 'Model') {
                                const placement = this.placements[s.placement.code];

                                if (placement != null) {
                                    placement.meshes.forEach((mesh) => {
                                        mesh.mesh.setEnabled(true);
                                    });
                                }
                            }
                        });

                        // Restore camera.
                        copyParameters.forEach((p) => {
                            this.camera[p] = savedCamera[p];
                        });

                        // Reload joint positions.
                        this.loadJoints({
                            immediate: true,
                        });

                        this.updateEnvironmentPosition();

                        this.projectJoints();
                        this.projectDecals();

                        // Apply Popsockets specific visibility rules.
                        this.popsocketsSelectView(this.view);

                        this.scene.updateTransformMatrix(true);

                        this.render();

                        if (done) {
                            done(data);
                        }
                    },
                    withType,
                    false,
                );
            },

            showMemoryStats(context) {
                // eslint-disable-next-line no-undef
                if (this.engine && this.engine._gl) {
                    const ext = this.engine._gl.getExtension('GMAN_webgl_memory');
                    if (ext) {
                        const info = ext.getMemoryInfo();

                        const toMb = (v) => `${(v / 1024 / 1024).toFixed(1)}MB`;

                        // eslint-disable-next-line
                        console.log(`Memory (${context ?? 'none'}): Texture ${toMb(info.memory.texture)}/${info.resources.texture}, Buffer ${toMb(info.memory.buffer)}, Total ${toMb(info.memory.total)}`);
                    }
                }
            },
        },
        components: {
        },
    };
</script>
