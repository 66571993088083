function flattenRelatedInternal(product, to) {
    if (product) {
        to.push(product);

        if (product.related) {
            product.related.forEach((p) => {
                flattenRelatedInternal(p, to);
            });
        }
    }
}

/**
 * Flattens the hierarchy of related products.
 */
// eslint-disable-next-line import/prefer-default-export
export function flattenRelated(controller) {
    const products = [];

    if (controller && controller.blueprint && controller.blueprint.relatedProducts) {
        controller.blueprint.relatedProducts.forEach((p) => {
            flattenRelatedInternal(p, products);
        });
    }

    return products;
}
