<template>
    <div class="cz3__ai-double-radios">
        <div class="cz3__ai-double-radios__header">
            <span>{{$t('AI_SELECT')}} {{button.displayName}}</span>

            <button class="cz3__ai-double-radios__remove-all"
                @click.prevent="removeAll"
            >
                {{$t(button.multiSelect ? 'AI_REMOVE_ALL' : 'AI_REMOVE')}}
            </button>
        </div>

        <ai-tabs :tabs="tabs"></ai-tabs>

        <div v-if="selectedTab" class="cz3__ai-selected-tab">
            <ai-guided-panel-radio
                :buttons="selectedTab.selectionButtons"
                :multiselect="button.multiSelect"
                @beforeSelect="beforeSelect"
            >
            </ai-guided-panel-radio>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-double-radios {
            margin-top: 16px;

            padding: 18px 12px;

            border-radius: 8px;
            background: $color-panel-background;
        }

        .cz3__ai-double-radios__header {
            display: flex;
            justify-content: space-between;

            font-family: $font-main;
            font-size: 14px;
            font-weight: 700;
        }

        .cz3__ai-double-radios__remove-all {
            padding: 0;

            border: none;
            background: transparent;

            font-family: $font-main;
            font-size: 12px;
            font-weight: 400;

            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }
</style>

<script>
    import AiTabs from './AiTabs.vue';
    import AiGuidedPanelRadio from './AiGuidedPanelRadio.vue';

    export default {
        components: {
            AiTabs,
            AiGuidedPanelRadio,
        },

        props: {
            button: Object,
        },

        data() {
            const list = this.button.subButtons;

            for (const l of list) {
                l.showSubButtons = false;
            }

            if (list.length > 0) {
                list[0].showSubButtons = true;
            }

            return {
                tabs: list,
            };
        },

        computed: {
            selectedTab() {
                return this.tabs.find((x) => x.showSubButtons);
            },
        },

        methods: {
            removeAll() {
                for (const sub of this.button.subButtons) {
                    if (sub.selectionButtons) {
                        for (const b of sub.selectionButtons) {
                            b.isActive = false;
                        }
                    }
                }
            },

            beforeSelect() {
                if (!this.button.multiSelect) {
                    this.removeAll();
                }
            },
        },
    };
</script>
