import * as constants from '../constants';

function appendImageSize(url, size, type) {
    let u = url ?? '';

    if (url.includes('?')) {
        u += '&';
    } else {
        u += '?';
    }

    u += size;

    u += `&fmt=${type}&qlt=60`;

    return u;
}

/**
 * Retrieve a list of images and categories from a placement.
 */
// eslint-disable-next-line import/prefer-default-export
export function getGallery(controller, placement) {
    const gallery = [];

    const p = controller.findPlacement(placement);

    if (p == null) {
        return gallery;
    }

    p.components.forEach((c) => {
        if (!controller.isAvailable(c)) {
            return;
        }

        const categoryName = c?.custom?.category;
        if (categoryName == null) {
            return;
        }

        // Skip components without images.
        if (c.custom?.image == null) {
            return;
        }

        let category = gallery.find((x) => x.category === categoryName);
        if (category == null) {
            category = {
                code: c.code,
                category: categoryName,
                title: controller.componentNames(c).join(''),
                thumbnail: null,
                hasCover: false,
                images: [],
            };

            gallery.push(category);
        }

        if (c?.custom?.cover === 'true') {
            category.hasCover = true;
            category.thumbnail = appendImageSize(c?.custom?.image, constants.GALLERY_THUMBNAIL, 'webp');
            category.title = controller.componentNames(c).join('');
        } else {
            category.images.push({
                code: c.code,
                title: controller.componentNames(c).join(''),
                image: c?.custom?.image,
                noUnderprint: c?.custom?.['ps-no-underprint'] === 'true',
                thumbnail: appendImageSize(c?.custom?.image, constants.GALLERY_THUMBNAIL, 'webp'),
            });
        }
    });

    return gallery.filter((x) => x.hasCover);
}
