<template>
    <div class="cz3__navigation cz3__navigation--edit-mode"
        :class="{
            'cz3__navigation--no-background': selectedLayer == null || selectedImageLayer,
            'cz3__navigation--text-mode': selectedLayer && selectedTextLayer,
        }"
    >
        <!-- Nothing is selected -->
        <template v-if="selectedLayer == null">
            <div class="cz3__navigation__mini cz3__renderer-exclude">
                <div class="cz3__navigation-left">
                </div>
                <div class="cz3__navigation-right">
                    <button
                        id="back"
                        class="cz3__outline-button cz3__outline-button--small"
                        @click.prevent="goBack"
                    >
                        {{$t('SAVE_CHANGES')}}
                    </button>
                </div>
            </div>
        </template>

        <!-- Text layer is selected -->
        <template v-if="selectedLayer && selectedTextLayer">
            <div class="cz3__renderer-exclude">
                <edit-text></edit-text>

                <div class="cz3__navigation__mini">
                    <div class="cz3__navigation-left">
                        <button
                            id="back"
                            class="cz3__grey-rounded-button"
                            @click.prevent="goBack"
                        >
                            {{$t('BACK')}}
                        </button>
                    </div>

                    <div class="cz3__navigation-right">
                        <button
                            id="add-text"
                            class="cz3__outline-button cz3__outline-button--small"
                            @click.prevent="commitText"
                        >
                            {{$t('SAVE_CHANGES')}}
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <!-- Image layer is selected -->
        <template v-if="selectedLayer && selectedImageLayer">
            <div class="cz3__navigation__mini cz3__renderer-exclude">
                <div class="cz3__navigation-left">
                    <button
                        id="replace-image"
                        class="cz3__grey-rounded-button"
                        @click.prevent="replaceImage"
                    >
                        <span v-if="layerMode === 'ai'">
                            {{$t('REPLACE_AI')}}
                        </span>
                        <span v-else-if="layerMode === 'stickers'">
                            {{$t('REPLACE_STICKER')}}
                        </span>
                        <span v-else>
                            {{$t('REPLACE_IMAGE')}}
                        </span>
                    </button>
                </div>

                <div class="cz3__navigation-right">
                    <button
                        id="back"
                        class="cz3__outline-button cz3__outline-button--small"
                        @click.prevent="goBack"
                    >
                        {{$t('SAVE_CHANGES')}}
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__navigation.cz3__navigation--edit-mode {
            display: block;
            height: auto;

            .cz3__navigation__mini {
                display: flex;
            }
        }

        .cz3__navigation.cz3__navigation--text-mode {
            @include respond-below(sm) {
                width: calc(100% - 24px);
                margin-left: 12px;
                margin-right: 12px;
                margin-bottom: 12px;
                background: $color-primary-white;
            }
        }

        .cz3__navigation.cz3__navigation--no-background {
            box-shadow: none;
            border: none;
            background: transparent;
        }
    }
</style>

<script>
    import EditText from './EditText.vue';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    export default {
        mixins: [allStores],

        components: {
            EditText,
        },

        data() {
            return {
                layerMode: null,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            currentController() {
                return this.customizerStore.currentController;
            },

            selectedLayer() {
                return this.customizerStore.selectedLayer;
            },

            selectedImageLayer() {
                if (this.selectedLayer?.layer?.code?.startsWith(constants.PLACEMENT_IMAGE)) {
                    return true;
                }

                return false;
            },

            selectedTextLayer() {
                if (this.selectedLayer?.layer?.code?.startsWith(constants.PLACEMENT_TEXT)) {
                    return true;
                }

                return false;
            },
        },

        watch: {
            selectedLayer() {
                this.updateLayerType();
            },
        },

        mounted() {
            this.updateLayerType();
        },

        beforeUpdate() {
        },

        updated() {
        },

        beforeUnmount() {
        },

        methods: {
            goBack() {
                this.customizerStore.removeEmptyLayers();

                // Also clear the joint selection.
                this.customizerStore.selectJoint(null);

                this.customizerStore.setCurrentStep(constants.STEP_EDIT);
            },

            updateLayerType() {
                if (this.selectedLayer) {
                    const { code } = this.selectedLayer.layer;

                    const component = this.currentController.selectedAtPlacement(code);

                    if (component) {
                        if (component?.custom?.['ps-ai-prompt'] && component?.custom?.['ps-ai-prompt'].length > 0) {
                            this.layerMode = 'ai';
                        } else if (component?.custom?.['ps-type'] === 'stickers') {
                            this.layerMode = 'stickers';
                        } else {
                            this.layerMode = 'images';
                        }
                    }
                }
            },

            replaceImage() {
                this.$emit('replaceImage', this.layerMode);
            },

            commitText() {
                // Nothing really?
                this.emitter.$emit('selectLayer');
            },
        },
    };
</script>
