<template>
    <div class="cz3__toggle-select">
        <button v-for="v in options"
            :key="v[trackBy]"
            :id="`type-${v[label]}`"
            class="cz3__toggle-select__option"
            :class="{
                'cz3__toggle-select__option--selected': modelValue ? modelValue[trackBy] === v[trackBy] : false,
            }"
            @click.prevent="selectOption(v)"
        >
            <span>
                {{v[label]}}
            </span>
        </button>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__toggle-select {
            display: flex;

            padding: 3px 3px;

            height: 40px;
            border-radius: 40px;
            border: 1px solid $color-gray-mid;
        }

        .cz3__toggle-select__option {
            flex: 1 1 0;

            height: 32px;
            border-radius: 32px;

            background: transparent;

            border: 1px solid transparent;

            font-family: $font-main;

            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;

            color: $color-gray-dark;

            transition: all 0.25s ease-out;

            &--selected {
                border: 1px solid $color-primary-black;

                color: $color-primary-black;
            }
        }
    }
</style>

<script>
    export default {
        props: {
            options: Array,
            modelValue: Object,
            trackBy: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
        },
        updated() {
        },
        methods: {
            selectOption(v) {
                this.$emit('update:modelValue', v);
            },
        },
    };
</script>
